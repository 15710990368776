import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { supplier } = Dictionary

const supplierDetail = {
  view: async (paramsObj: any) => {
    return axios.get(BASE_URL + supplier.supplier(), { params: paramsObj })
  },
  count: async () => {
    return axios.get(BASE_URL + supplier.supplierCount())
  },
  add: async (data: {}) => {
    return axios.post(BASE_URL + supplier.supplier(), data)
  },
  delete: async (id: string) => {
    return axios.delete(BASE_URL + supplier.supplierById(id))
  },
  edit: async (id: string, data: {}) => {
    return axios.put(BASE_URL + supplier.supplierById(id), data)
  },
  getDetailById: async (id: string) => {
    return axios.get(BASE_URL + supplier.supplierById(id))
  },
}
export default supplierDetail
