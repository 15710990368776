import React, { useContext, useEffect, useState } from 'react'
import organisms from '../../../../../components/organisms'
import Wrapper from '../../../../../components/common/Wrapper/Wrapper'
import service from '../../../../../api/services'
import { HeaderHeading } from '../../../../../Context'
const { LoyaltyList } = organisms
const Index = () => {
  const { setHeader }: any = useContext(HeaderHeading);
  const [loyaltyValue, setLoyaltyValue] = useState()
  const getLoyaltyList = async () => {
    try {
      const response = await service.loyaltyPointsService.getLoyaltyList()
      if (response.status === 200) {
        setLoyaltyValue(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    setHeader("Setting - Other")
    getLoyaltyList()
  }, [])

  return (
    <Wrapper>
      <LoyaltyList
        loyaltyValue={loyaltyValue}
      />
    </Wrapper>
  )
}

export default Index