import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { Accounts } = Dictionary

const AccountsServices = {
    //API for General account
    createGeneralAccount: async (data: {}) => {
        return axios.post(BASE_URL + Accounts.createGeneralAccount(), data)
    },
    listGeneralAccount: async (paramsObj?:any) => {
        return axios.get(BASE_URL + Accounts.listGeneralAccount(), { params: paramsObj })
    },
    detailGeneralAccount: async (id:string) => {
        return axios.get(BASE_URL + Accounts.accountDetailGeneralAccount(id))
    },
    deleteGeneralAccount: async (id: string) => {
        return axios.delete(BASE_URL + Accounts.deleteGeneralAccount(id))
    },
    accountHolderDetail:async(id:string)=>{
        return axios.get(BASE_URL + Accounts.accountHolderType(id))
    },
    getAccountCode:async(id:string)=>{
        return axios.get(BASE_URL + Accounts.getNumericCode(id))
    },

    // API for SUB Account
    createSubAccount: async (payload: {}) => {
        return axios.post(BASE_URL + Accounts.createSubAccount(), payload)
    },
    listSubAccount: async () => {
        return axios.get(BASE_URL + Accounts.listSubAccount())
    },
    detailSubAccount: async (id:string) => {
        return axios.get(BASE_URL + Accounts.accountDetailSubAccount(id))
    },
    deleteSubAccount: async (id: string) => {
        return axios.delete(BASE_URL + Accounts.deleteSubAccount(id))
    },
    UpdateSubAccount: async (payload:{}, id: string) => {
        return axios.put(BASE_URL + Accounts.updateSubAccount(id), payload)
    },

    // Api for Entries
    ViewEntries:async(paramsObj:any)=>{
        return axios.get(BASE_URL+ Accounts.getEntries(),{ params: paramsObj } )
    },

    // for Add Voucher
    ManualTransaction:async(payload:any)=>{
        return axios.post(BASE_URL + Accounts.manualTransaction(),payload)
    }

}
export default AccountsServices
