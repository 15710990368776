import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import atoms from '../../atoms'
import { RxCross2 } from "react-icons/rx";

const { Button } = atoms

const CreateModal = (props: any) => {
    return (
        <>
            <Modal className="h-95 d-flex justify-content-center align-items-center  border-0" isOpen={props.show} >
                <ModalHeader className="d-flex justify-content-center border-0 position-relative" >
                    <div className="mt-4 fs-30 fw-600">{props.header}</div>
                    <div className="position-absolute py-1 px-2" style={{ top: '0', right: '0', backgroundColor: '#222', borderRadius: '0px 4px 0px 8px', cursor: 'pointer' }} onClick={props.onHide}><RxCross2 style={{ color: '#fff' }} /></div>
                </ModalHeader>
                <ModalBody className="rounded-10">
                    {props.children}
                </ModalBody>
                <ModalFooter className="mx-auto border-0 mt-25 mb-4 ">
                    <Button className=''
                        isDisabled={props.buttonDisable}
                        onClick={props.handleSubmit}>{props.submitBtnText}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default CreateModal