import React, { useContext, useEffect } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import { HeaderHeading } from '../../../Context'
import service from '../../../api/services';
import organisms from '../../../components/organisms';
const { Dashboard } = organisms
const Index = () => {
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);

  const getDashboardDetails = async () => {
    try {
      const response = await service.DashboardService.dashboard()
      if (response.status === 200) {
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getDashboardDetails()
    setHeader("Dashboard")
    setSidebar(false)
  }, [])
  return (
    <Wrapper>
      <Dashboard />
    </Wrapper>
  )
}

export default Index