import React, { useState } from "react";
import { FiEdit } from 'react-icons/fi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import constant from "../../../../constants/constant";
import moment from "moment";
import { findConstantLabel } from "../../../../constants/utils"
import atoms from "../../../atoms";
import molecules from "../../../molecules";

const { Table, Button } = atoms
const { SubAccountModal, ConfirmationModal } = molecules

const Index = ({ setAccountForm, createAccount, listData, accountDetail, setShowPopup, showPopup, accountForm, DeleteAccount, UpdateAccount, handleChange }: any) => {

    const [deletePopup, setDeletePopup] = useState<boolean>(false)
    const columns = [
        { name: 'Name', selector: (row: any) => row?.name, sortable: true },
        { name: 'Date', selector: (row: any) => row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : "-", },
        { name: 'Type', selector: (row: any) => `${row?.type ? findConstantLabel(constant.Account_type, row?.type) : "--"}`, sortable: true },
        { name: 'Code', selector: (row: any) => `${row.numeric_code}` },
        // { name: 'Update', selector: (row: any) => row.update },
        { name: 'Action', selector: (row: any) => row.action, minWidth: "30%" },
    ]

    const handleSubmit = async () => {
        if (accountForm?.id) {
            await UpdateAccount(accountForm?.id)
        } else {
            await createAccount()
        }
    }

    const handelCreateButton = async () => {
        await setAccountForm({
            ...accountForm, name: "",
            numeric_code: "",
            type: [],
            id: ""
        })
        setShowPopup(true)
    }

    const handelDeleteButton = async (accountId: string) => {
        await setAccountForm({ ...accountForm, id: accountId })
        setDeletePopup(!deletePopup)
    }

    const handleDeleteAccount = async () => {
        await DeleteAccount()
        setDeletePopup(!deletePopup)
    }

    return (
        <>
            <div className="row d-flex justify-content-end">
                <div className="text-end">
                    <Button name={"Create Account"}
                        className=''
                        onClick={() => handelCreateButton()}
                    />
                </div>
            </div>
            <div>
                <Table
                    columns={columns}
                    data={listData?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            action:
                                <>
                                    <Button className="table-edit-button fs-14 fw-400"
                                        onClick={async () => {
                                            await accountDetail(item?.id)
                                            setShowPopup(true)
                                        }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <FiEdit />
                                            <span className='ms-2 mb-0'>Edit</span>
                                        </div>
                                    </Button>
                                    <Button className="table-delete-button fs-14 fw-400 ms-3"
                                        onClick={() => handelDeleteButton(item?.id)}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <RiDeleteBin6Line />
                                            <span className='ms-2 mb-0'>Delete</span>
                                        </div>
                                    </Button>
                                </>
                        })
                    })}
                />
            </div>
            <div>
                <SubAccountModal
                    header={`${accountForm?.id ? "Update" : "Create"} Sub Account`}
                    submitBtnText="Submit"
                    handleSubmit={() => handleSubmit()}
                    show={showPopup}
                    onHide={() => setShowPopup(false)}
                    setAccountForm={() => setAccountForm()}
                    accountForm={accountForm}
                    handleChange={handleChange}
                    accountFormId={accountForm?.id}
                />
            </div>
            <div>
                {deletePopup && <ConfirmationModal
                    show={deletePopup}
                    handleConfirmItem={() => handleDeleteAccount()}
                    onHide={() => setDeletePopup(!deletePopup)}
                    message={'delete this account'} />}
            </div>
        </>
    )
}

export default Index