import React, { useState, useEffect, useContext } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues";
import { getDropdown } from "../../../../constants/utils"
import atoms from "../../../../components/atoms";
import { useToasts } from 'react-toast-notifications';
import { HeaderHeading } from '../../../../Context'
import constant from "../../../../constants/constant";
import { BASE_URL } from "../../../../constants/defaultValues";


const { GeneralAccount } = organisms
const { Button, Input, Select } = atoms


const Index = () => {
    const { addToast } = useToasts();

    const { setHeader }: any = useContext(HeaderHeading);
    const [initialLoad, setInitialLoad] = useState(false)

    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);

    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [listData, setListData] = useState([])
    const [dropDownOption, setDropDownOption] = useState({
        subType: [],
        accountHolder: []
    })
    const [storeData, setStoreData] = useState()
    // const [reportData, setReportData] = useState({
    //     report_date: Date,
    //     store_ids: [],
    //     report_format: "",
    // })

    const [numericCode, setNumericCode] = useState({
        accNumberCount: ""
    })
    const [accountForm, setAccountForm] = useState<any>({
        gl_account_name: "",
        account_sub_type_id: [],
        account_holder_id: "",
        type: [],
        id: "",

    })

    useEffect(() => {
        setHeader("Accounts - General Account")
        setCurrentPage(1)
        if (currentPage == 1) {
            accountList()
        }
    }, [limitPerPage])

    useEffect(() => {
        if (initialLoad) {
            accountList()
        } else {
            setInitialLoad(true)
        }
    }, [currentPage])

    useEffect(() => {
        getInvoiceData()
    }, [])


    const getInvoiceData = async () => {
        try {
            let storeResponse = await service.inventory.storeView()
            if (storeResponse.status === 200) {
                setStoreData(getDropdown(storeResponse?.data?.data, 'store_name'))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const accountList = async () => {
        try {
            const response = await service.AccountsServices.listGeneralAccount({
                "page": currentPage,
                "limit": limitPerPage,
            });
            setListData(response.data)

        } catch (error) {
            console.log(error)
        }
    }

    const listSubAccount = async () => {
        try {
            // const response = await service.AccountsServices.detailGeneralAccount(id);
            const response = await service.AccountsServices.listSubAccount();
            // setSubTypeDropDown(getDropdown(response?.data?.data, "name"))
            setDropDownOption({ ...dropDownOption, subType: getDropdown(response?.data?.data, "name") })
        } catch (error) {
            console.log(error)
        }
    }
    const accountHolderDetail = async (id: string, name: string) => {
        try {
            const response = await service.AccountsServices.accountHolderDetail(id);
            // setAccountHolderDropDown(getDropdown(response?.data?.data, `${name}`))
            setDropDownOption({ ...dropDownOption, accountHolder: getDropdown(response?.data?.data, `${name}`) })

        } catch (error) {
            console.log(error)
        }
    }

    const getAccountCode = async (id: string) => {
        try {
            const response = await service.AccountsServices.getAccountCode(id)
            if (response?.status == 200) {
                // it give error in logic need to fix before upload
                setNumericCode({ ...numericCode, accNumberCount: response?.data?.count })
            }

        } catch (error) {
            console.log("error", error)
        }
    }

    const createAccount = async () => {
        const payload = {
            gl_account_name: accountForm?.gl_account_name,
            account_sub_type_id: accountForm?.account_sub_type_id?.value,
            account_holder_id: accountForm?.account_holder_id?.value,
            type: accountForm?.type?.value
        }
        try {
            let response = await service.AccountsServices.createGeneralAccount(payload);
            if (response.status == 201) {
                setShowPopup(!showPopup)
                addToast('General-Account Create Successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                setAccountForm((prevState: any) => ({
                    ...prevState,
                    gl_account_name: "",
                    account_sub_type_id: [],
                    account_holder_id: "",
                    type: [],
                    id: "",

                }));
            }
            accountList()
        } catch (error: any) {
            console.log(error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const DeleteAccount = async () => {
        try {
            await service.AccountsServices.deleteGeneralAccount(accountForm?.id);
            accountList()
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = async (name: string, value: any) => {

        if (name == "type") {
            setAccountForm((prevState: any) => ({
                ...prevState,
                account_holder_id: "",
                [name]: value
            }));
            // setAccountForm({...accountForm, account_holder_id:"", type:value})
            if (value?.value < 3) {
                accountHolderDetail(value?.value, `${value?.label.toLowerCase()}_name`)
            }
            if (value?.value == 3) {
                accountHolderDetail(value?.value, "name")
            }
            if (value?.value == 4) {
                // setAccountHolderDropDown([])
                setDropDownOption({ ...dropDownOption, accountHolder: [] })
                // setAccountForm({ ...accountForm, account_sub_type_id: "" })
            }
        } else {
            await setAccountForm({ ...accountForm, [name]: value })
        }
    }

    // const handleReportDownload = async () => {
    //     const storeIds = reportData?.store_ids?.map((item: any) => item.value)
    //     try {
    //         const payload = {
    //             specx_book: true,
    //             report_date: reportData?.report_date,
    //             store_id: storeIds
    //         }

    //         const response = await service.reportService?.trailBalanceReport(payload)
    //         if (response.status === 200) {
    //             let path = response.data.data
    //             let fullPath = `${BASE_URL}${path}`
    //             let cleanPAth = fullPath.replace(/api\/\.\//, '')
    //             let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
    //             window.open(`${cleanPAth}`, '_blank')

    //             setTimeout(() => {
    //                 fetch(`${cleanPAth}`).then((response) => {
    //                     response.blob().then((blob) => {

    //                         // Creating new object of PDF file
    //                         const fileURL =
    //                             window.URL.createObjectURL(blob);

    //                         // Setting various property values
    //                         let alink = document.createElement("a");
    //                         alink.href = fileURL;
    //                         alink.download = `${fileName}`;
    //                         alink.click();
    //                     });
    //                 });
    //             }, 3000);

    //         }
    //     } catch (error: any) {
    //         console.log('error', error)
    //         addToast(error?.response?.data?.message, {
    //             appearance: 'error',
    //             autoDismiss: true,
    //             autoDismissTimeout: 3000,
    //         });
    //     }
    // }

    // const handleReportVariableChange =(name:string, value:any)=>{
    //     setReportData({...reportData, [name]:value})
    // }

    // const buttonValidation = (report_date:any) => {
    //     const parsedDate1 = new Date(report_date);

    //     if (!parsedDate1 || isNaN(parsedDate1.getTime())) {
    //         return true;
    //     } 

    //     if(reportData?.store_ids?.length===0){
    //         return true
    //     }

    //     return false
    // }

    return (
        <>
            <Wrapper>
                {/* <div className="row">
                    <div className="col-8 px-0">
                        <div className="row">
                            <div className="col-4" style={{paddingRight:0}}>
                                <div className="d-flex align-items-center gap-3">
                                    <label className='inputs-label fs-16 fw-600 my-auto secondary-color'>Date</label>
                                    <Input
                                        type={'date'}
                                        className="w-100"
                                        placeholder={"Enter Date"}
                                        onChange={(e: any) => handleReportVariableChange( "report_date", e) } />
                                </div>
                            </div>
                            <div className="col-4 px-0">
                                <div className="d-flex align-items-center gap-2">
                                    <Select
                                        className={"w-100 input-height"}
                                        options={storeData}
                                        onChange={(e: any) =>  handleReportVariableChange( "store_ids", e ) }
                                        placeholder={"Select"}
                                        label={"Stores"}
                                        isMulti={true} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="d-flex align-items-center gap-2">
                                    <Select
                                        className={"w-100 input-height"}
                                        options={constant.DailyReportFormat}
                                        onChange={(e: any) =>  handleReportVariableChange("report_format", e)}
                                        placeholder={"Format"}
                                        label={"Format"}
                                        isMulti={true} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-4">
                        <div className="d-flex justify-content-end gap-3">
                            <div className="">
                                <Button name={"Download Report"}
                                    className=''
                                    isDisabled={buttonValidation(reportData?.report_date)}
                                    onClick={() => { handleReportDownload() }}
                                />
                            </div>
                            <div className="">
                                <Button name={"Create Account"}
                                    className=''
                                    onClick={() => { setShowPopup(true); listSubAccount(); setNumericCode({ ...numericCode, accNumberCount: "" }) }}
                                />
                            </div>
                        </div>
                    </div>

                </div> */}
                <div className="d-flex justify-content-end">
                    <div className="">
                        <Button name={"Create Account"}
                            className=''
                            onClick={() => { setShowPopup(true); listSubAccount(); setNumericCode({ ...numericCode, accNumberCount: "" }) }}
                        />
                    </div>
                </div>
                <GeneralAccount
                    listData={listData}
                    accountForm={accountForm}
                    setAccountForm={setAccountForm}
                    dropDownOption={dropDownOption}

                    createAccount={() => createAccount()}
                    DeleteAccount={() => DeleteAccount()}
                    getAccountCode={(id: string) => getAccountCode(id)}
                    handleChange={(name: string, value: any) => handleChange(name, value)}

                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    numericCode={numericCode}

                    limitPerPage={limitPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setLimitPerPage={setLimitPerPage}
                />
            </Wrapper >
        </>
    )
}

export default Index