import React from "react";
import molecules from "../../../molecules";
import atoms from "../../../atoms";
import moment from "moment";

const { Table, Pagination } = atoms

const Index = ({ feedbacks, retailerDetail, getRetailerDetail,currentPage,setCurrentPage,setLimitPerPage,limitPerPage }: any) => {

    const columns = [
        { name: 'Customer Name', selector: (row: any) => row?.customer_name ? row?.customer_name : "-", sortable: true },
        { name: 'Email', selector: (row: any) => row?.email ? row?.email : "-" },
        { name: 'Contact Number', selector: (row: any) => row?.contact_number ? row?.contact_number : "-" },
        { name: 'Invoice Number', selector: (row: any) => `${row?.invoice_number ? row?.invoice_number : "-"}` },
        { name: 'Store Name', selector: (row: any) => `${row?.store_name ? row?.store_name : "-"}` },
        { name: 'Rating', selector: (row: any) => row?.rating ? row?.rating : "-" },        
        { name: 'Suggestions', selector: (row: any) => row?.suggesstions ? row?.suggesstions : "-" },
        { name: 'Date', selector: (row: any) => row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "-"},
    ]

    return (
        <>
            {(feedbacks?.data && feedbacks?.data?.length > 0) ? <div>
                <Table
                    className="adjustable-row-height"
                    columns={columns}
                    data={feedbacks?.data?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            action: <>
                            </>,
                        })
                    })}
                />
            </div> : <div className='justify-content-center d-flex mt-5 '>There are no records to display</div>}
            <Pagination
                    totalCount={feedbacks?.count}
                    currentPage={currentPage}
                    totalPages={feedbacks?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limitPerPage}
                />
        </>
    )
}

export default Index