import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from "../../../../components/organisms";
import service from '../../../../api/services';
import { HeaderHeading } from '../../../../Context'
const { Shape } = organisms
const Index = () => {
    const [shapeListing, setShapeListing] = useState<any>()
    const [search, setSearch] = useState('');
    const { setHeader ,setSidebar}: any = useContext(HeaderHeading);
    const getShapeListing = async () => {
        try {
            const response = await service.productData.productShapeListingService({ search: search });
            setShapeListing(response.data)
        } catch (error) {

        }
    }
    useEffect(() => {
        setHeader("Content - Shapes")
        setSidebar(false)
        getShapeListing()
    }, [search])

    return (
        <Wrapper>
            <Shape shapeListing={shapeListing} setSearch={setSearch} getShapeListing={getShapeListing} />
        </Wrapper>
    )
}

export default Index