import React, { useEffect } from "react";
import atoms from "../../../atoms";
const { Input, Select } = atoms
const ProductType = ({ brand_margin,
    brand_discount,
    brand_landing_cost, product_type_id,
    specx_landing_cost, handleChangeType, productTypeList, selectDropDown,
    productSelectDropdown, setProductSelectDropdown, errors, currentKey }: any) => {
    let defaultSelectDropdown = { "label": "Select Product", "value": '' }
    const handleSelect = (e: any) => {
        let tempDropdown = []
        tempDropdown = [...selectDropDown]
        handleChangeType("product_type_id", e.value, e)
        tempDropdown[currentKey] = e
        setProductSelectDropdown(tempDropdown)
    }
    const InputValue = (data: number) => {
        if (data) {
            if (data < 100) {
                let value: number = data
                return Math.ceil(value * 100) / 100
            } else {
                return 100
            }
        } else {
            return
        }
    }

    return (
        <>
            <div className="p-brand-col">
                <Select type={"text"} className={"w-100"} options={productTypeList}
                    value={product_type_id !== '' ? productSelectDropdown : defaultSelectDropdown}
                    onChange={(e: any) => handleSelect(e)} placeholder={"Select"}
                    label={"Select Product Type"} errors={errors?.product_type_id} />
            </div>
            <div className="p-brand-col">
                <Input type={"number"} onChange={(e: any) => handleChangeType("brand_margin", InputValue(e.target.value))}
                    value={brand_margin} placeholder={"Enter Brand Margin"} label={"Brand Margin (%)"} errors={errors?.brand_margin} />
            </div>
            <div className="p-brand-col">
                <Input type={"number"} onChange={(e: any) => handleChangeType("brand_discount", InputValue(e.target.value))}
                    value={brand_discount} placeholder={"Enter Brand Discount"} label={"Brand Discount (%)"} errors={errors?.brand_discount} />
            </div>
            <div className="p-brand-col">
                <Input type={"number"} onChange={(e: any) => handleChangeType("brand_landing_cost", InputValue(e.target.value))}
                    value={brand_landing_cost} placeholder={"Enter Brand Landing Cost"} label={"Brand Landing Cost (%)"} errors={errors?.brand_landing_cost} />
            </div>
            <div className="p-brand-col">
                <Input type={"number"} onChange={(e: any) => handleChangeType("specx_landing_cost", InputValue(e.target.value))} value={specx_landing_cost}
                    placeholder={"Enter Landing Cost"} label={"Specx Landing Cost (%)"} errors={errors?.specx_landing_cost} />
            </div>
        </>
    );
}
export default ProductType