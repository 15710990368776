import React, { useState, useEffect, useContext } from "react";
import { HeaderHeading } from "../../../../Context";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from 'react-toast-notifications';
import service from "../../../../api/services";
import { getDropdown, handleReportDownload } from "../../../../constants/utils";


const { OwnershipTransferReport } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [storeData, setStoreData] = useState();
    const [reportData, setReportData] = useState<any>({
        store_ids: [],
        created_from: "",
        created_to: "",
        report_format: NaN,
    })

    const { addToast } = useToasts();

    const getInvoiceData = async () => {
        try {
            let storeResponse = await service.inventory.storeView()
            if (storeResponse.status === 200) {
                setStoreData(getDropdown(storeResponse?.data?.data, 'store_location', 'store_name'))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (name: string, value: any) => {
        setReportData({ ...reportData, [name]: value })
    }

    const handleSubmit = async () => {

        const storeIds = reportData?.store_ids?.map((item: any) => item.value)

        const payload = {
            store_ids: storeIds,
            created_from: reportData?.created_from,
            created_to: reportData?.created_to
        }
        try {
            const response = await service.reportService?.ownershipTransferReport(payload)
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "ownership-transfer-report", reportData?.report_format, "Specxweb", true)
            }

        }
        catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }


    useEffect(() => {
        setHeader("Report - Ownership Transfer Report")
        getInvoiceData()
    }, [])

    return (
        <Wrapper>
            <OwnershipTransferReport
                handleChange={(name: string, value: any) => handleChange(name, value)}
                reportData={reportData}
                storeData={storeData}
                submitIndividualInvoices={() => handleSubmit()}
            />
        </Wrapper>
    )
}

export default Index