import React from "react";
import atoms from "../../../atoms";
import constant from "../../../../constants/constant";

const { Select, Input, Button } = atoms

const Index = ({ handleChange, handleSubmit, reportData, storeData }: any) => {

    const buttonValidation = (date1: Date) => {
        const parsedDate1 = new Date(date1);

        if (!parsedDate1 || isNaN(parsedDate1.getTime())) {
            return true;
        }

        if (!reportData?.specx_book) {
            return true
        }
        if (!reportData?.report_format) {
            return true
        }

        return false
    };

    return (
        <>
            <div className="row">
                <div className="col-4">
                    <Select
                        className={"w-100"}
                        options={storeData}
                        value={reportData.store}
                        onChange={(e: any) => handleChange("specx_book", e?.value)}
                        placeholder={"Select"}
                        label={"Select Books of Account -"} />
                </div>
                <div className="col-4">
                    <Input label={"Report Date"}
                        placeholder={"Report Date"} type="date"
                        onChange={(e: any) => {
                            handleChange("report_date", e)
                        }}
                    />
                </div>
                <div className="col-4">
                    <Select
                        className="w-100"
                        options={constant.DailyReportFormat}
                        onChange={(e: any) => handleChange("report_format", e.value)} placeholder={"Select"}
                        label={"Select Report Format"} />
                </div>
            </div>
            <div className="d-flex justify-content-center" style={{ marginTop: "10rem" }}>
                <Button isDisabled={buttonValidation(reportData?.report_date)} style={{ minWidth: "259px" }} onClick={handleSubmit}>Submit</Button>
            </div>
        </>
    )
}

export default Index