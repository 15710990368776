//
const Allocation = {
  allocation: () => 'admin/brand-allocate/allocation',
  storeAllocation: () => 'admin/brand-allocate/allocated-stores',
  allStoreAllocation: () => 'admin/brand-allocate/stores',
  // storeAllocation: () => 'admin/brand-allocate/store',
  storeById: (id: string) => `admin/brand-allocate/store/${id}`,
  brandByStoreId: (id: string) => `admin/brand-allocate/store/${id}/brands`,
  brandEditByStoreId: (storeId: string, brandId: string) =>
    `admin/brand-allocate/store/${storeId}/brands/${brandId}`,
}
export default Allocation
