import React from "react";
import { indexProps, lostBarcodeProps } from "./interface";
import atoms from "../../../atoms";
import moment from "moment";
import { numberWithCommas } from "../../../../constants/utils";

const { Search, Button, Table, Pagination } = atoms

const Index: React.FC<indexProps> = ({ setSearch, lostBarcode, currentPage, setCurrentPage, setLimitPerPage, limit, setMarkBarcode, markBarcode }) => {

    const columns = [
        { name: 'Barcode', selector: (row: lostBarcodeProps) => row?.bar_code || "", sortable: true },
        { name: 'Product ID', selector: (row: lostBarcodeProps) => row?.product?.product_id_display || "", },
        { name: 'SRP', selector: (row: lostBarcodeProps) => `₹${numberWithCommas(Number(row?.product_srp))}` || "" },
        { name: 'Lost Date', selector: (row: lostBarcodeProps) => moment(row?.lost_inventory?.createdAt).format('DD/MM/YYYY') || "", },
        { name: 'Remark', selector: (row: lostBarcodeProps) => row?.lost_inventory?.remarks || "" },
    ]
    return (
        <>
            <div className="row">
                <div className="col-6"><Search searchCallBack={(e: string) => { setSearch(e) }} /></div>
                <div className="col-6">
                </div>
            </div>
            <div>
                <Table columns={columns}
                    data={lostBarcode?.data}
                />
            </div>
            {(lostBarcode?.data?.length > 0) && <Pagination
                totalCount={lostBarcode?.count}
                currentPage={currentPage}
                totalPages={lostBarcode?.totalPages}
                onChangePage={(page: any) => setCurrentPage(page)}
                setLimit={(e: number) => setLimitPerPage(e)}
                limit={limit}
            />}
        </>
    )
}

export default Index