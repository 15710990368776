export const State_List = [
    {
        "label": "Andaman and Nicobar Islands",
        "value": "Andaman and Nicobar Islands",
        "stateID": 1
    },
    {
        "label": "Andhra Pradesh",
        "value": "Andhra Pradesh",
        "stateID": 2
    },
    {
        "label": "Arunachal Pradesh",
        "value": "Arunachal Pradesh",
        "stateID": 3
    },
    {
        "label": "Assam",
        "value": "Assam",
        "stateID": 4
    },
    {
        "label": "Bihar",
        "value": "Bihar",
        "stateID": 5
    },
    {
        "label": "Chandigarh",
        "value": "Chandigarh",
        "stateID": 6
    },
    {
        "label": "Chhattisgarh",
        "value": "Chhattisgarh",
        "stateID": 7
    },
    {
        "label": "Dadra and Nagar Haveli",
        "value": "Dadra and Nagar Haveli",
        "stateID": 8
    },
    {
        "label": "Delhi",
        "value": "Delhi",
        "stateID": 9
    },
    {
        "label": "Goa",
        "value": "Goa",
        "stateID": 10
    },
    {
        "label": "Gujarat",
        "value": "Gujarat",
        "stateID": 11
    },
    {
        "label": "Haryana",
        "value": "Haryana",
        "stateID": 12
    },
    {
        "label": "Himachal Praddesh",
        "value": "Himachal Praddesh",
        "stateID": 13
    },
    {
        "label": "Himachal Pradesh",
        "value": "Himachal Pradesh",
        "stateID": 14
    },
    {
        "label": "Jammu and Kashmir",
        "value": "Jammu and Kashmir",
        "stateID": 15
    },
    {
        "label": "Jharkhand",
        "value": "Jharkhand",
        "stateID": 16
    },
    {
        "label": "Karnataka",
        "value": "Karnataka",
        "stateID": 17
    },
    {
        "label": "Kerala",
        "value": "Kerala",
        "stateID": 18
    },
    {
        "label": "Madhya Pradesh",
        "value": "Madhya Pradesh",
        "stateID": 19
    },
    {
        "label": "Maharashtra",
        "value": "Maharashtra",
        "stateID": 20
    },
    {
        "label": "Manipur",
        "value": "Manipur",
        "stateID": 21
    },
    {
        "label": "Meghalaya",
        "value": "Meghalaya",
        "stateID": 22
    },
    {
        "label": "Mizoram",
        "value": "Mizoram",
        "stateID": 23
    },
    {
        "label": "Nagaland",
        "value": "Nagaland",
        "stateID": 24
    },
    {
        "label": "Odisha",
        "value": "Odisha",
        "stateID": 25
    },
    {
        "label": "Puducherry",
        "value": "Puducherry",
        "stateID": 26
    },
    {
        "label": "Punjab",
        "value": "Punjab",
        "stateID": 27
    },
    {
        "label": "Rajasthan",
        "value": "Rajasthan",
        "stateID": 28
    },
    {
        "label": "Tamil Nadu",
        "value": "Tamil Nadu",
        "stateID": 29
    },
    {
        "label": "Telangana",
        "value": "Telangana",
        "stateID": 30
    },
    {
        "label": "Tripura",
        "value": "Tripura",
        "stateID": 31
    },
    {
        "label": "Uttar Pradesh",
        "value": "Uttar Pradesh",
        "stateID": 32
    },
    {
        "label": "Uttarakhand",
        "value": "Uttarakhand",
        "stateID": 33
    },
    {
        "label": "West Bengal",
        "value": "West Bengal",
        "stateID": 34
    }
]

export const City_List = [
    {
        "label": "Port Blair",
        "value": "Port Blair",
        "stateID": 1
    },
    {
        "label": "Adoni",
        "value": "Adoni",
        "stateID": 2
    },
    {
        "label": "Amalapuram",
        "value": "Amalapuram",
        "stateID": 2
    },
    {
        "label": "Anakapalle",
        "value": "Anakapalle",
        "stateID": 2
    },
    {
        "label": "Anantapur",
        "value": "Anantapur",
        "stateID": 2
    },
    {
        "label": "Bapatla",
        "value": "Bapatla",
        "stateID": 2
    },
    {
        "label": "Bheemunipatnam",
        "value": "Bheemunipatnam",
        "stateID": 2
    },
    {
        "label": "Bhimavaram",
        "value": "Bhimavaram",
        "stateID": 2
    },
    {
        "label": "Bobbili",
        "value": "Bobbili",
        "stateID": 2
    },
    {
        "label": "Chilakaluripet",
        "value": "Chilakaluripet",
        "stateID": 2
    },
    {
        "label": "Chirala",
        "value": "Chirala",
        "stateID": 2
    },
    {
        "label": "Chittoor",
        "value": "Chittoor",
        "stateID": 2
    },
    {
        "label": "Dharmavaram",
        "value": "Dharmavaram",
        "stateID": 2
    },
    {
        "label": "Eluru",
        "value": "Eluru",
        "stateID": 2
    },
    {
        "label": "Gooty",
        "value": "Gooty",
        "stateID": 2
    },
    {
        "label": "Gudivada",
        "value": "Gudivada",
        "stateID": 2
    },
    {
        "label": "Gudur",
        "value": "Gudur",
        "stateID": 2
    },
    {
        "label": "Guntakal",
        "value": "Guntakal",
        "stateID": 2
    },
    {
        "label": "Guntur",
        "value": "Guntur",
        "stateID": 2
    },
    {
        "label": "Hindupur",
        "value": "Hindupur",
        "stateID": 2
    },
    {
        "label": "Jaggaiahpet",
        "value": "Jaggaiahpet",
        "stateID": 2
    },
    {
        "label": "Jammalamadugu",
        "value": "Jammalamadugu",
        "stateID": 2
    },
    {
        "label": "Kadapa",
        "value": "Kadapa",
        "stateID": 2
    },
    {
        "label": "Kadiri",
        "value": "Kadiri",
        "stateID": 2
    },
    {
        "label": "Kakinada",
        "value": "Kakinada",
        "stateID": 2
    },
    {
        "label": "Kandukur",
        "value": "Kandukur",
        "stateID": 2
    },
    {
        "label": "Kavali",
        "value": "Kavali",
        "stateID": 2
    },
    {
        "label": "Kovvur",
        "value": "Kovvur",
        "stateID": 2
    },
    {
        "label": "Kurnool",
        "value": "Kurnool",
        "stateID": 2
    },
    {
        "label": "Macherla",
        "value": "Macherla",
        "stateID": 2
    },
    {
        "label": "Machilipatnam",
        "value": "Machilipatnam",
        "stateID": 2
    },
    {
        "label": "Madanapalle",
        "value": "Madanapalle",
        "stateID": 2
    },
    {
        "label": "Mandapeta",
        "value": "Mandapeta",
        "stateID": 2
    },
    {
        "label": "Markapur",
        "value": "Markapur",
        "stateID": 2
    },
    {
        "label": "Nagari",
        "value": "Nagari",
        "stateID": 2
    },
    {
        "label": "Naidupet",
        "value": "Naidupet",
        "stateID": 2
    },
    {
        "label": "Nandyal",
        "value": "Nandyal",
        "stateID": 2
    },
    {
        "label": "Narasapuram",
        "value": "Narasapuram",
        "stateID": 2
    },
    {
        "label": "Narasaraopet",
        "value": "Narasaraopet",
        "stateID": 2
    },
    {
        "label": "Narsipatnam",
        "value": "Narsipatnam",
        "stateID": 2
    },
    {
        "label": "Nellore",
        "value": "Nellore",
        "stateID": 2
    },
    {
        "label": "Nidadavole",
        "value": "Nidadavole",
        "stateID": 2
    },
    {
        "label": "Nuzvid",
        "value": "Nuzvid",
        "stateID": 2
    },
    {
        "label": "Ongole",
        "value": "Ongole",
        "stateID": 2
    },
    {
        "label": "Palacole",
        "value": "Palacole",
        "stateID": 2
    },
    {
        "label": "Palasa Kasibugga",
        "value": "Palasa Kasibugga",
        "stateID": 2
    },
    {
        "label": "Parvathipuram",
        "value": "Parvathipuram",
        "stateID": 2
    },
    {
        "label": "Pedana",
        "value": "Pedana",
        "stateID": 2
    },
    {
        "label": "Peddapuram",
        "value": "Peddapuram",
        "stateID": 2
    },
    {
        "label": "Pithapuram",
        "value": "Pithapuram",
        "stateID": 2
    },
    {
        "label": "Ponnur",
        "value": "Ponnur",
        "stateID": 2
    },
    {
        "label": "Proddatur",
        "value": "Proddatur",
        "stateID": 2
    },
    {
        "label": "Punganur",
        "value": "Punganur",
        "stateID": 2
    },
    {
        "label": "Puttur",
        "value": "Puttur",
        "stateID": 2
    },
    {
        "label": "Rajahmundry",
        "value": "Rajahmundry",
        "stateID": 2
    },
    {
        "label": "Rajam",
        "value": "Rajam",
        "stateID": 2
    },
    {
        "label": "Rajampet",
        "value": "Rajampet",
        "stateID": 2
    },
    {
        "label": "Ramachandrapuram",
        "value": "Ramachandrapuram",
        "stateID": 2
    },
    {
        "label": "Rayachoti",
        "value": "Rayachoti",
        "stateID": 2
    },
    {
        "label": "Rayadurg",
        "value": "Rayadurg",
        "stateID": 2
    },
    {
        "label": "Renigunta",
        "value": "Renigunta",
        "stateID": 2
    },
    {
        "label": "Repalle",
        "value": "Repalle",
        "stateID": 2
    },
    {
        "label": "Salur",
        "value": "Salur",
        "stateID": 2
    },
    {
        "label": "Samalkot",
        "value": "Samalkot",
        "stateID": 2
    },
    {
        "label": "Sattenapalle",
        "value": "Sattenapalle",
        "stateID": 2
    },
    {
        "label": "Srikakulam",
        "value": "Srikakulam",
        "stateID": 2
    },
    {
        "label": "Srikalahasti",
        "value": "Srikalahasti",
        "stateID": 2
    },
    {
        "label": "Srisailam Project (Right Flank Colony) Township",
        "value": "Srisailam Project (Right Flank Colony) Township",
        "stateID": 2
    },
    {
        "label": "Sullurpeta",
        "value": "Sullurpeta",
        "stateID": 2
    },
    {
        "label": "Tadepalligudem",
        "value": "Tadepalligudem",
        "stateID": 2
    },
    {
        "label": "Tadpatri",
        "value": "Tadpatri",
        "stateID": 2
    },
    {
        "label": "Tanuku",
        "value": "Tanuku",
        "stateID": 2
    },
    {
        "label": "Tenali",
        "value": "Tenali",
        "stateID": 2
    },
    {
        "label": "Tirupati",
        "value": "Tirupati",
        "stateID": 2
    },
    {
        "label": "Tiruvuru",
        "value": "Tiruvuru",
        "stateID": 2
    },
    {
        "label": "Tuni",
        "value": "Tuni",
        "stateID": 2
    },
    {
        "label": "Uravakonda",
        "value": "Uravakonda",
        "stateID": 2
    },
    {
        "label": "Venkatagiri",
        "value": "Venkatagiri",
        "stateID": 2
    },
    {
        "label": "Vijayawada",
        "value": "Vijayawada",
        "stateID": 2
    },
    {
        "label": "Vinukonda",
        "value": "Vinukonda",
        "stateID": 2
    },
    {
        "label": "Visakhapatnam",
        "value": "Visakhapatnam",
        "stateID": 2
    },
    {
        "label": "Vizianagaram",
        "value": "Vizianagaram",
        "stateID": 2
    },
    {
        "label": "Yemmiganur",
        "value": "Yemmiganur",
        "stateID": 2
    },
    {
        "label": "Yerraguntla",
        "value": "Yerraguntla",
        "stateID": 2
    },
    {
        "label": "Naharlagun",
        "value": "Naharlagun",
        "stateID": 3
    },
    {
        "label": "Pasighat",
        "value": "Pasighat",
        "stateID": 3
    },
    {
        "label": "Barpeta",
        "value": "Barpeta",
        "stateID": 4
    },
    {
        "label": "Bongaigaon City",
        "value": "Bongaigaon City",
        "stateID": 4
    },
    {
        "label": "Dhubri",
        "value": "Dhubri",
        "stateID": 4
    },
    {
        "label": "Dibrugarh",
        "value": "Dibrugarh",
        "stateID": 4
    },
    {
        "label": "Diphu",
        "value": "Diphu",
        "stateID": 4
    },
    {
        "label": "Goalpara",
        "value": "Goalpara",
        "stateID": 4
    },
    {
        "label": "Guwahati",
        "value": "Guwahati",
        "stateID": 4
    },
    {
        "label": "Jorhat",
        "value": "Jorhat",
        "stateID": 4
    },
    {
        "label": "Karimganj",
        "value": "Karimganj",
        "stateID": 4
    },
    {
        "label": "Lanka",
        "value": "Lanka",
        "stateID": 4
    },
    {
        "label": "Lumding",
        "value": "Lumding",
        "stateID": 4
    },
    {
        "label": "Mangaldoi",
        "value": "Mangaldoi",
        "stateID": 4
    },
    {
        "label": "Mankachar",
        "value": "Mankachar",
        "stateID": 4
    },
    {
        "label": "Margherita",
        "value": "Margherita",
        "stateID": 4
    },
    {
        "label": "Mariani",
        "value": "Mariani",
        "stateID": 4
    },
    {
        "label": "Marigaon",
        "value": "Marigaon",
        "stateID": 4
    },
    {
        "label": "Nagaon",
        "value": "Nagaon",
        "stateID": 4
    },
    {
        "label": "Nalbari",
        "value": "Nalbari",
        "stateID": 4
    },
    {
        "label": "North Lakhimpur",
        "value": "North Lakhimpur",
        "stateID": 4
    },
    {
        "label": "Rangia",
        "value": "Rangia",
        "stateID": 4
    },
    {
        "label": "Sibsagar",
        "value": "Sibsagar",
        "stateID": 4
    },
    {
        "label": "Silapathar",
        "value": "Silapathar",
        "stateID": 4
    },
    {
        "label": "Silchar",
        "value": "Silchar",
        "stateID": 4
    },
    {
        "label": "Tezpur",
        "value": "Tezpur",
        "stateID": 4
    },
    {
        "label": "Tinsukia",
        "value": "Tinsukia",
        "stateID": 4
    },
    {
        "label": "Araria",
        "value": "Araria",
        "stateID": 5
    },
    {
        "label": "Arrah",
        "value": "Arrah",
        "stateID": 5
    },
    {
        "label": "Arwal",
        "value": "Arwal",
        "stateID": 5
    },
    {
        "label": "Asarganj",
        "value": "Asarganj",
        "stateID": 5
    },
    {
        "label": "Aurangabad",
        "value": "Aurangabad",
        "stateID": 5
    },
    {
        "label": "Bagaha",
        "value": "Bagaha",
        "stateID": 5
    },
    {
        "label": "Barh",
        "value": "Barh",
        "stateID": 5
    },
    {
        "label": "Begusarai",
        "value": "Begusarai",
        "stateID": 5
    },
    {
        "label": "Bettiah",
        "value": "Bettiah",
        "stateID": 5
    },
    {
        "label": "BhabUrban Agglomeration",
        "value": "BhabUrban Agglomeration",
        "stateID": 5
    },
    {
        "label": "Bhagalpur",
        "value": "Bhagalpur",
        "stateID": 5
    },
    {
        "label": "Buxar",
        "value": "Buxar",
        "stateID": 5
    },
    {
        "label": "Chhapra",
        "value": "Chhapra",
        "stateID": 5
    },
    {
        "label": "Darbhanga",
        "value": "Darbhanga",
        "stateID": 5
    },
    {
        "label": "Dehri-on-Sone",
        "value": "Dehri-on-Sone",
        "stateID": 5
    },
    {
        "label": "Dumraon",
        "value": "Dumraon",
        "stateID": 5
    },
    {
        "label": "Forbesganj",
        "value": "Forbesganj",
        "stateID": 5
    },
    {
        "label": "Gaya",
        "value": "Gaya",
        "stateID": 5
    },
    {
        "label": "Gopalganj",
        "value": "Gopalganj",
        "stateID": 5
    },
    {
        "label": "Hajipur",
        "value": "Hajipur",
        "stateID": 5
    },
    {
        "label": "Jamalpur",
        "value": "Jamalpur",
        "stateID": 5
    },
    {
        "label": "Jamui",
        "value": "Jamui",
        "stateID": 5
    },
    {
        "label": "Jehanabad",
        "value": "Jehanabad",
        "stateID": 5
    },
    {
        "label": "Katihar",
        "value": "Katihar",
        "stateID": 5
    },
    {
        "label": "Kishanganj",
        "value": "Kishanganj",
        "stateID": 5
    },
    {
        "label": "Lakhisarai",
        "value": "Lakhisarai",
        "stateID": 5
    },
    {
        "label": "Lalganj",
        "value": "Lalganj",
        "stateID": 5
    },
    {
        "label": "Madhepura",
        "value": "Madhepura",
        "stateID": 5
    },
    {
        "label": "Madhubani",
        "value": "Madhubani",
        "stateID": 5
    },
    {
        "label": "Maharajganj",
        "value": "Maharajganj",
        "stateID": 5
    },
    {
        "label": "Mahnar Bazar",
        "value": "Mahnar Bazar",
        "stateID": 5
    },
    {
        "label": "Makhdumpur",
        "value": "Makhdumpur",
        "stateID": 5
    },
    {
        "label": "Maner",
        "value": "Maner",
        "stateID": 5
    },
    {
        "label": "Manihari",
        "value": "Manihari",
        "stateID": 5
    },
    {
        "label": "Marhaura",
        "value": "Marhaura",
        "stateID": 5
    },
    {
        "label": "Masaurhi",
        "value": "Masaurhi",
        "stateID": 5
    },
    {
        "label": "Mirganj",
        "value": "Mirganj",
        "stateID": 5
    },
    {
        "label": "Mokameh",
        "value": "Mokameh",
        "stateID": 5
    },
    {
        "label": "Motihari",
        "value": "Motihari",
        "stateID": 5
    },
    {
        "label": "Motipur",
        "value": "Motipur",
        "stateID": 5
    },
    {
        "label": "Munger",
        "value": "Munger",
        "stateID": 5
    },
    {
        "label": "Murliganj",
        "value": "Murliganj",
        "stateID": 5
    },
    {
        "label": "Muzaffarpur",
        "value": "Muzaffarpur",
        "stateID": 5
    },
    {
        "label": "Narkatiaganj",
        "value": "Narkatiaganj",
        "stateID": 5
    },
    {
        "label": "Naugachhia",
        "value": "Naugachhia",
        "stateID": 5
    },
    {
        "label": "Nawada",
        "value": "Nawada",
        "stateID": 5
    },
    {
        "label": "Nokha",
        "value": "Nokha",
        "stateID": 5
    },
    {
        "label": "Patna",
        "value": "Patna",
        "stateID": 5
    },
    {
        "label": "Piro",
        "value": "Piro",
        "stateID": 5
    },
    {
        "label": "Purnia",
        "value": "Purnia",
        "stateID": 5
    },
    {
        "label": "Rafiganj",
        "value": "Rafiganj",
        "stateID": 5
    },
    {
        "label": "Rajgir",
        "value": "Rajgir",
        "stateID": 5
    },
    {
        "label": "Ramnagar",
        "value": "Ramnagar",
        "stateID": 5
    },
    {
        "label": "Raxaul Bazar",
        "value": "Raxaul Bazar",
        "stateID": 5
    },
    {
        "label": "Revelganj",
        "value": "Revelganj",
        "stateID": 5
    },
    {
        "label": "Rosera",
        "value": "Rosera",
        "stateID": 5
    },
    {
        "label": "Saharsa",
        "value": "Saharsa",
        "stateID": 5
    },
    {
        "label": "Samastipur",
        "value": "Samastipur",
        "stateID": 5
    },
    {
        "label": "Sasaram",
        "value": "Sasaram",
        "stateID": 5
    },
    {
        "label": "Sheikhpura",
        "value": "Sheikhpura",
        "stateID": 5
    },
    {
        "label": "Sheohar",
        "value": "Sheohar",
        "stateID": 5
    },
    {
        "label": "Sherghati",
        "value": "Sherghati",
        "stateID": 5
    },
    {
        "label": "Silao",
        "value": "Silao",
        "stateID": 5
    },
    {
        "label": "Sitamarhi",
        "value": "Sitamarhi",
        "stateID": 5
    },
    {
        "label": "Siwan",
        "value": "Siwan",
        "stateID": 5
    },
    {
        "label": "Sonepur",
        "value": "Sonepur",
        "stateID": 5
    },
    {
        "label": "Sugauli",
        "value": "Sugauli",
        "stateID": 5
    },
    {
        "label": "Sultanganj",
        "value": "Sultanganj",
        "stateID": 5
    },
    {
        "label": "Supaul",
        "value": "Supaul",
        "stateID": 5
    },
    {
        "label": "Warisaliganj",
        "value": "Warisaliganj",
        "stateID": 5
    },
    {
        "label": "Chandigarh",
        "value": "Chandigarh",
        "stateID": 6
    },
    {
        "label": "Ambikapur",
        "value": "Ambikapur",
        "stateID": 7
    },
    {
        "label": "Bhatapara",
        "value": "Bhatapara",
        "stateID": 7
    },
    {
        "label": "Bhilai Nagar",
        "value": "Bhilai Nagar",
        "stateID": 7
    },
    {
        "label": "Bilaspur",
        "value": "Bilaspur",
        "stateID": 7
    },
    {
        "label": "Chirmiri",
        "value": "Chirmiri",
        "stateID": 7
    },
    {
        "label": "Dalli-Rajhara",
        "value": "Dalli-Rajhara",
        "stateID": 7
    },
    {
        "label": "Dhamtari",
        "value": "Dhamtari",
        "stateID": 7
    },
    {
        "label": "Durg",
        "value": "Durg",
        "stateID": 7
    },
    {
        "label": "Jagdalpur",
        "value": "Jagdalpur",
        "stateID": 7
    },
    {
        "label": "Korba",
        "value": "Korba",
        "stateID": 7
    },
    {
        "label": "Mahasamund",
        "value": "Mahasamund",
        "stateID": 7
    },
    {
        "label": "Manendragarh",
        "value": "Manendragarh",
        "stateID": 7
    },
    {
        "label": "Mungeli",
        "value": "Mungeli",
        "stateID": 7
    },
    {
        "label": "Naila Janjgir",
        "value": "Naila Janjgir",
        "stateID": 7
    },
    {
        "label": "Raigarh",
        "value": "Raigarh",
        "stateID": 7
    },
    {
        "label": "Raipur",
        "value": "Raipur",
        "stateID": 7
    },
    {
        "label": "Rajnandgaon",
        "value": "Rajnandgaon",
        "stateID": 7
    },
    {
        "label": "Sakti",
        "value": "Sakti",
        "stateID": 7
    },
    {
        "label": "Tilda Newra",
        "value": "Tilda Newra",
        "stateID": 7
    },
    {
        "label": "Silvassa",
        "value": "Silvassa",
        "stateID": 8
    },
    {
        "label": "Delhi",
        "value": "Delhi",
        "stateID": 9
    },
    {
        "label": "New Delhi",
        "value": "New Delhi",
        "stateID": 9
    },
    {
        "label": "Mapusa",
        "value": "Mapusa",
        "stateID": 10
    },
    {
        "label": "Margao",
        "value": "Margao",
        "stateID": 10
    },
    {
        "label": "Marmagao",
        "value": "Marmagao",
        "stateID": 10
    },
    {
        "label": "Panaji",
        "value": "Panaji",
        "stateID": 10
    },
    {
        "label": "Adalaj",
        "value": "Adalaj",
        "stateID": 11
    },
    {
        "label": "Ahmedabad",
        "value": "Ahmedabad",
        "stateID": 11
    },
    {
        "label": "Amreli",
        "value": "Amreli",
        "stateID": 11
    },
    {
        "label": "Anand",
        "value": "Anand",
        "stateID": 11
    },
    {
        "label": "Anjar",
        "value": "Anjar",
        "stateID": 11
    },
    {
        "label": "Ankleshwar",
        "value": "Ankleshwar",
        "stateID": 11
    },
    {
        "label": "Bharuch",
        "value": "Bharuch",
        "stateID": 11
    },
    {
        "label": "Bhavnagar",
        "value": "Bhavnagar",
        "stateID": 11
    },
    {
        "label": "Bhuj",
        "value": "Bhuj",
        "stateID": 11
    },
    {
        "label": "Chhapra",
        "value": "Chhapra",
        "stateID": 11
    },
    {
        "label": "Deesa",
        "value": "Deesa",
        "stateID": 11
    },
    {
        "label": "Dhoraji",
        "value": "Dhoraji",
        "stateID": 11
    },
    {
        "label": "Gandhinagar",
        "value": "Gandhinagar",
        "stateID": 11
    },
    {
        "label": "Godhra",
        "value": "Godhra",
        "stateID": 11
    },
    {
        "label": "Jamnagar",
        "value": "Jamnagar",
        "stateID": 11
    },
    {
        "label": "Kadi",
        "value": "Kadi",
        "stateID": 11
    },
    {
        "label": "Kapadvanj",
        "value": "Kapadvanj",
        "stateID": 11
    },
    {
        "label": "Keshod",
        "value": "Keshod",
        "stateID": 11
    },
    {
        "label": "Khambhat",
        "value": "Khambhat",
        "stateID": 11
    },
    {
        "label": "Lathi",
        "value": "Lathi",
        "stateID": 11
    },
    {
        "label": "Limbdi",
        "value": "Limbdi",
        "stateID": 11
    },
    {
        "label": "Lunawada",
        "value": "Lunawada",
        "stateID": 11
    },
    {
        "label": "Mahemdabad",
        "value": "Mahemdabad",
        "stateID": 11
    },
    {
        "label": "Mahesana",
        "value": "Mahesana",
        "stateID": 11
    },
    {
        "label": "Mahuva",
        "value": "Mahuva",
        "stateID": 11
    },
    {
        "label": "Manavadar",
        "value": "Manavadar",
        "stateID": 11
    },
    {
        "label": "Mandvi",
        "value": "Mandvi",
        "stateID": 11
    },
    {
        "label": "Mangrol",
        "value": "Mangrol",
        "stateID": 11
    },
    {
        "label": "Mansa",
        "value": "Mansa",
        "stateID": 11
    },
    {
        "label": "Modasa",
        "value": "Modasa",
        "stateID": 11
    },
    {
        "label": "Morvi",
        "value": "Morvi",
        "stateID": 11
    },
    {
        "label": "Nadiad",
        "value": "Nadiad",
        "stateID": 11
    },
    {
        "label": "Navsari",
        "value": "Navsari",
        "stateID": 11
    },
    {
        "label": "Padra",
        "value": "Padra",
        "stateID": 11
    },
    {
        "label": "Palanpur",
        "value": "Palanpur",
        "stateID": 11
    },
    {
        "label": "Palitana",
        "value": "Palitana",
        "stateID": 11
    },
    {
        "label": "Pardi",
        "value": "Pardi",
        "stateID": 11
    },
    {
        "label": "Patan",
        "value": "Patan",
        "stateID": 11
    },
    {
        "label": "Petlad",
        "value": "Petlad",
        "stateID": 11
    },
    {
        "label": "Porbandar",
        "value": "Porbandar",
        "stateID": 11
    },
    {
        "label": "Radhanpur",
        "value": "Radhanpur",
        "stateID": 11
    },
    {
        "label": "Rajkot",
        "value": "Rajkot",
        "stateID": 11
    },
    {
        "label": "Rajpipla",
        "value": "Rajpipla",
        "stateID": 11
    },
    {
        "label": "Rajula",
        "value": "Rajula",
        "stateID": 11
    },
    {
        "label": "Ranavav",
        "value": "Ranavav",
        "stateID": 11
    },
    {
        "label": "Rapar",
        "value": "Rapar",
        "stateID": 11
    },
    {
        "label": "Salaya",
        "value": "Salaya",
        "stateID": 11
    },
    {
        "label": "Sanand",
        "value": "Sanand",
        "stateID": 11
    },
    {
        "label": "Savarkundla",
        "value": "Savarkundla",
        "stateID": 11
    },
    {
        "label": "Sidhpur",
        "value": "Sidhpur",
        "stateID": 11
    },
    {
        "label": "Sihor",
        "value": "Sihor",
        "stateID": 11
    },
    {
        "label": "Songadh",
        "value": "Songadh",
        "stateID": 11
    },
    {
        "label": "Surat",
        "value": "Surat",
        "stateID": 11
    },
    {
        "label": "Talaja",
        "value": "Talaja",
        "stateID": 11
    },
    {
        "label": "Thangadh",
        "value": "Thangadh",
        "stateID": 11
    },
    {
        "label": "Tharad",
        "value": "Tharad",
        "stateID": 11
    },
    {
        "label": "Umbergaon",
        "value": "Umbergaon",
        "stateID": 11
    },
    {
        "label": "Umreth",
        "value": "Umreth",
        "stateID": 11
    },
    {
        "label": "Una",
        "value": "Una",
        "stateID": 11
    },
    {
        "label": "Unjha",
        "value": "Unjha",
        "stateID": 11
    },
    {
        "label": "Upleta",
        "value": "Upleta",
        "stateID": 11
    },
    {
        "label": "Vadnagar",
        "value": "Vadnagar",
        "stateID": 11
    },
    {
        "label": "Vadodara",
        "value": "Vadodara",
        "stateID": 11
    },
    {
        "label": "Valsad",
        "value": "Valsad",
        "stateID": 11
    },
    {
        "label": "Vapi",
        "value": "Vapi",
        "stateID": 11
    },
    {
        "label": "Vapi",
        "value": "Vapi",
        "stateID": 11
    },
    {
        "label": "Veraval",
        "value": "Veraval",
        "stateID": 11
    },
    {
        "label": "Vijapur",
        "value": "Vijapur",
        "stateID": 11
    },
    {
        "label": "Viramgam",
        "value": "Viramgam",
        "stateID": 11
    },
    {
        "label": "Visnagar",
        "value": "Visnagar",
        "stateID": 11
    },
    {
        "label": "Vyara",
        "value": "Vyara",
        "stateID": 11
    },
    {
        "label": "Wadhwan",
        "value": "Wadhwan",
        "stateID": 11
    },
    {
        "label": "Wankaner",
        "value": "Wankaner",
        "stateID": 11
    },
    {
        "label": "Bahadurgarh",
        "value": "Bahadurgarh",
        "stateID": 12
    },
    {
        "label": "Bhiwani",
        "value": "Bhiwani",
        "stateID": 12
    },
    {
        "label": "Charkhi Dadri",
        "value": "Charkhi Dadri",
        "stateID": 12
    },
    {
        "label": "Faridabad",
        "value": "Faridabad",
        "stateID": 12
    },
    {
        "label": "Fatehabad",
        "value": "Fatehabad",
        "stateID": 12
    },
    {
        "label": "Gohana",
        "value": "Gohana",
        "stateID": 12
    },
    {
        "label": "Gurgaon",
        "value": "Gurgaon",
        "stateID": 12
    },
    {
        "label": "Hansi",
        "value": "Hansi",
        "stateID": 12
    },
    {
        "label": "Hisar",
        "value": "Hisar",
        "stateID": 12
    },
    {
        "label": "Jind",
        "value": "Jind",
        "stateID": 12
    },
    {
        "label": "Kaithal",
        "value": "Kaithal",
        "stateID": 12
    },
    {
        "label": "Karnal",
        "value": "Karnal",
        "stateID": 12
    },
    {
        "label": "Ladwa",
        "value": "Ladwa",
        "stateID": 12
    },
    {
        "label": "Mahendragarh",
        "value": "Mahendragarh",
        "stateID": 12
    },
    {
        "label": "Mandi Dabwali",
        "value": "Mandi Dabwali",
        "stateID": 12
    },
    {
        "label": "Narnaul",
        "value": "Narnaul",
        "stateID": 12
    },
    {
        "label": "Narwana",
        "value": "Narwana",
        "stateID": 12
    },
    {
        "label": "Palwal",
        "value": "Palwal",
        "stateID": 12
    },
    {
        "label": "Panchkula",
        "value": "Panchkula",
        "stateID": 12
    },
    {
        "label": "Panipat",
        "value": "Panipat",
        "stateID": 12
    },
    {
        "label": "Pehowa",
        "value": "Pehowa",
        "stateID": 12
    },
    {
        "label": "Pinjore",
        "value": "Pinjore",
        "stateID": 12
    },
    {
        "label": "Rania",
        "value": "Rania",
        "stateID": 12
    },
    {
        "label": "Ratia",
        "value": "Ratia",
        "stateID": 12
    },
    {
        "label": "Rewari",
        "value": "Rewari",
        "stateID": 12
    },
    {
        "label": "Rohtak",
        "value": "Rohtak",
        "stateID": 12
    },
    {
        "label": "Safidon",
        "value": "Safidon",
        "stateID": 12
    },
    {
        "label": "Samalkha",
        "value": "Samalkha",
        "stateID": 12
    },
    {
        "label": "Sarsod",
        "value": "Sarsod",
        "stateID": 12
    },
    {
        "label": "Shahbad",
        "value": "Shahbad",
        "stateID": 12
    },
    {
        "label": "Sirsa",
        "value": "Sirsa",
        "stateID": 12
    },
    {
        "label": "Sohna",
        "value": "Sohna",
        "stateID": 12
    },
    {
        "label": "Sonipat",
        "value": "Sonipat",
        "stateID": 12
    },
    {
        "label": "Taraori",
        "value": "Taraori",
        "stateID": 12
    },
    {
        "label": "Thanesar",
        "value": "Thanesar",
        "stateID": 12
    },
    {
        "label": "Tohana",
        "value": "Tohana",
        "stateID": 12
    },
    {
        "label": "Yamunanagar",
        "value": "Yamunanagar",
        "stateID": 12
    },
    {
        "label": "Manali",
        "value": "Manali",
        "stateID": 13
    },
    {
        "label": "Kullu",
        "value": "Kullu",
        "stateID": 14
    },
    {
        "label": "Mandi",
        "value": "Mandi",
        "stateID": 14
    },
    {
        "label": "Nahan",
        "value": "Nahan",
        "stateID": 14
    },
    {
        "label": "Palampur",
        "value": "Palampur",
        "stateID": 14
    },
    {
        "label": "Shimla",
        "value": "Shimla",
        "stateID": 14
    },
    {
        "label": "Solan",
        "value": "Solan",
        "stateID": 14
    },
    {
        "label": "Sundarnagar",
        "value": "Sundarnagar",
        "stateID": 14
    },
    {
        "label": "Anantnag",
        "value": "Anantnag",
        "stateID": 15
    },
    {
        "label": "Baramula",
        "value": "Baramula",
        "stateID": 15
    },
    {
        "label": "Jammu",
        "value": "Jammu",
        "stateID": 15
    },
    {
        "label": "KathUrban Agglomeration",
        "value": "KathUrban Agglomeration",
        "stateID": 15
    },
    {
        "label": "Punch",
        "value": "Punch",
        "stateID": 15
    },
    {
        "label": "Rajauri",
        "value": "Rajauri",
        "stateID": 15
    },
    {
        "label": "Sopore",
        "value": "Sopore",
        "stateID": 15
    },
    {
        "label": "Srinagar",
        "value": "Srinagar",
        "stateID": 15
    },
    {
        "label": "Udhampur",
        "value": "Udhampur",
        "stateID": 15
    },
    {
        "label": "Adityapur",
        "value": "Adityapur",
        "stateID": 16
    },
    {
        "label": "Bokaro Steel City",
        "value": "Bokaro Steel City",
        "stateID": 16
    },
    {
        "label": "Chaibasa",
        "value": "Chaibasa",
        "stateID": 16
    },
    {
        "label": "Chatra",
        "value": "Chatra",
        "stateID": 16
    },
    {
        "label": "Chirkunda",
        "value": "Chirkunda",
        "stateID": 16
    },
    {
        "label": "Deoghar",
        "value": "Deoghar",
        "stateID": 16
    },
    {
        "label": "Dhanbad",
        "value": "Dhanbad",
        "stateID": 16
    },
    {
        "label": "Dumka",
        "value": "Dumka",
        "stateID": 16
    },
    {
        "label": "Giridih",
        "value": "Giridih",
        "stateID": 16
    },
    {
        "label": "Gumia",
        "value": "Gumia",
        "stateID": 16
    },
    {
        "label": "Hazaribag",
        "value": "Hazaribag",
        "stateID": 16
    },
    {
        "label": "Jamshedpur",
        "value": "Jamshedpur",
        "stateID": 16
    },
    {
        "label": "Jhumri Tilaiya",
        "value": "Jhumri Tilaiya",
        "stateID": 16
    },
    {
        "label": "Lohardaga",
        "value": "Lohardaga",
        "stateID": 16
    },
    {
        "label": "Madhupur",
        "value": "Madhupur",
        "stateID": 16
    },
    {
        "label": "Medininagar (Daltonganj)",
        "value": "Medininagar (Daltonganj)",
        "stateID": 16
    },
    {
        "label": "Mihijam",
        "value": "Mihijam",
        "stateID": 16
    },
    {
        "label": "Musabani",
        "value": "Musabani",
        "stateID": 16
    },
    {
        "label": "Pakaur",
        "value": "Pakaur",
        "stateID": 16
    },
    {
        "label": "Patratu",
        "value": "Patratu",
        "stateID": 16
    },
    {
        "label": "Phusro",
        "value": "Phusro",
        "stateID": 16
    },
    {
        "label": "Ramgarh",
        "value": "Ramgarh",
        "stateID": 16
    },
    {
        "label": "Ranchi",
        "value": "Ranchi",
        "stateID": 16
    },
    {
        "label": "Sahibganj",
        "value": "Sahibganj",
        "stateID": 16
    },
    {
        "label": "Saunda",
        "value": "Saunda",
        "stateID": 16
    },
    {
        "label": "Simdega",
        "value": "Simdega",
        "stateID": 16
    },
    {
        "label": "Tenu dam-cum-Kathhara",
        "value": "Tenu dam-cum-Kathhara",
        "stateID": 16
    },
    {
        "label": "Adyar",
        "value": "Adyar",
        "stateID": 17
    },
    {
        "label": "Afzalpur",
        "value": "Afzalpur",
        "stateID": 17
    },
    {
        "label": "Arsikere",
        "value": "Arsikere",
        "stateID": 17
    },
    {
        "label": "Athni",
        "value": "Athni",
        "stateID": 17
    },
    {
        "label": "Ballari",
        "value": "Ballari",
        "stateID": 17
    },
    {
        "label": "Belagavi",
        "value": "Belagavi",
        "stateID": 17
    },
    {
        "label": "Bengaluru",
        "value": "Bengaluru",
        "stateID": 17
    },
    {
        "label": "Chikkamagaluru",
        "value": "Chikkamagaluru",
        "stateID": 17
    },
    {
        "label": "Davanagere",
        "value": "Davanagere",
        "stateID": 17
    },
    {
        "label": "Gokak",
        "value": "Gokak",
        "stateID": 17
    },
    {
        "label": "Hubli-Dharwad",
        "value": "Hubli-Dharwad",
        "stateID": 17
    },
    {
        "label": "Karwar",
        "value": "Karwar",
        "stateID": 17
    },
    {
        "label": "Kolar",
        "value": "Kolar",
        "stateID": 17
    },
    {
        "label": "Lakshmeshwar",
        "value": "Lakshmeshwar",
        "stateID": 17
    },
    {
        "label": "Lingsugur",
        "value": "Lingsugur",
        "stateID": 17
    },
    {
        "label": "Maddur",
        "value": "Maddur",
        "stateID": 17
    },
    {
        "label": "Madhugiri",
        "value": "Madhugiri",
        "stateID": 17
    },
    {
        "label": "Madikeri",
        "value": "Madikeri",
        "stateID": 17
    },
    {
        "label": "Magadi",
        "value": "Magadi",
        "stateID": 17
    },
    {
        "label": "Mahalingapura",
        "value": "Mahalingapura",
        "stateID": 17
    },
    {
        "label": "Malavalli",
        "value": "Malavalli",
        "stateID": 17
    },
    {
        "label": "Malur",
        "value": "Malur",
        "stateID": 17
    },
    {
        "label": "Mandya",
        "value": "Mandya",
        "stateID": 17
    },
    {
        "label": "Mangaluru",
        "value": "Mangaluru",
        "stateID": 17
    },
    {
        "label": "Manvi",
        "value": "Manvi",
        "stateID": 17
    },
    {
        "label": "Mudabidri",
        "value": "Mudabidri",
        "stateID": 17
    },
    {
        "label": "Mudalagi",
        "value": "Mudalagi",
        "stateID": 17
    },
    {
        "label": "Muddebihal",
        "value": "Muddebihal",
        "stateID": 17
    },
    {
        "label": "Mudhol",
        "value": "Mudhol",
        "stateID": 17
    },
    {
        "label": "Mulbagal",
        "value": "Mulbagal",
        "stateID": 17
    },
    {
        "label": "Mundargi",
        "value": "Mundargi",
        "stateID": 17
    },
    {
        "label": "Mysore",
        "value": "Mysore",
        "stateID": 17
    },
    {
        "label": "Nanjangud",
        "value": "Nanjangud",
        "stateID": 17
    },
    {
        "label": "Nargund",
        "value": "Nargund",
        "stateID": 17
    },
    {
        "label": "Navalgund",
        "value": "Navalgund",
        "stateID": 17
    },
    {
        "label": "Nelamangala",
        "value": "Nelamangala",
        "stateID": 17
    },
    {
        "label": "Pavagada",
        "value": "Pavagada",
        "stateID": 17
    },
    {
        "label": "Piriyapatna",
        "value": "Piriyapatna",
        "stateID": 17
    },
    {
        "label": "Puttur",
        "value": "Puttur",
        "stateID": 17
    },
    {
        "label": "Raayachuru",
        "value": "Raayachuru",
        "stateID": 17
    },
    {
        "label": "Rabkavi Banhatti",
        "value": "Rabkavi Banhatti",
        "stateID": 17
    },
    {
        "label": "Ramanagaram",
        "value": "Ramanagaram",
        "stateID": 17
    },
    {
        "label": "Ramdurg",
        "value": "Ramdurg",
        "stateID": 17
    },
    {
        "label": "Ranebennuru",
        "value": "Ranebennuru",
        "stateID": 17
    },
    {
        "label": "Ranibennur",
        "value": "Ranibennur",
        "stateID": 17
    },
    {
        "label": "Robertson Pet",
        "value": "Robertson Pet",
        "stateID": 17
    },
    {
        "label": "Ron",
        "value": "Ron",
        "stateID": 17
    },
    {
        "label": "Sadalagi",
        "value": "Sadalagi",
        "stateID": 17
    },
    {
        "label": "Sagara",
        "value": "Sagara",
        "stateID": 17
    },
    {
        "label": "Sakaleshapura",
        "value": "Sakaleshapura",
        "stateID": 17
    },
    {
        "label": "Sanduru",
        "value": "Sanduru",
        "stateID": 17
    },
    {
        "label": "Sankeshwara",
        "value": "Sankeshwara",
        "stateID": 17
    },
    {
        "label": "Saundatti-Yellamma",
        "value": "Saundatti-Yellamma",
        "stateID": 17
    },
    {
        "label": "Savanur",
        "value": "Savanur",
        "stateID": 17
    },
    {
        "label": "Sedam",
        "value": "Sedam",
        "stateID": 17
    },
    {
        "label": "Shahabad",
        "value": "Shahabad",
        "stateID": 17
    },
    {
        "label": "Shahpur",
        "value": "Shahpur",
        "stateID": 17
    },
    {
        "label": "Shiggaon",
        "value": "Shiggaon",
        "stateID": 17
    },
    {
        "label": "Shikaripur",
        "value": "Shikaripur",
        "stateID": 17
    },
    {
        "label": "Shivamogga",
        "value": "Shivamogga",
        "stateID": 17
    },
    {
        "label": "Shrirangapattana",
        "value": "Shrirangapattana",
        "stateID": 17
    },
    {
        "label": "Sidlaghatta",
        "value": "Sidlaghatta",
        "stateID": 17
    },
    {
        "label": "Sindagi",
        "value": "Sindagi",
        "stateID": 17
    },
    {
        "label": "Sindhagi",
        "value": "Sindhagi",
        "stateID": 17
    },
    {
        "label": "Sindhnur",
        "value": "Sindhnur",
        "stateID": 17
    },
    {
        "label": "Sira",
        "value": "Sira",
        "stateID": 17
    },
    {
        "label": "Sirsi",
        "value": "Sirsi",
        "stateID": 17
    },
    {
        "label": "Siruguppa",
        "value": "Siruguppa",
        "stateID": 17
    },
    {
        "label": "Srinivaspur",
        "value": "Srinivaspur",
        "stateID": 17
    },
    {
        "label": "Surapura",
        "value": "Surapura",
        "stateID": 17
    },
    {
        "label": "Talikota",
        "value": "Talikota",
        "stateID": 17
    },
    {
        "label": "Tarikere",
        "value": "Tarikere",
        "stateID": 17
    },
    {
        "label": "Tekkalakote",
        "value": "Tekkalakote",
        "stateID": 17
    },
    {
        "label": "Terdal",
        "value": "Terdal",
        "stateID": 17
    },
    {
        "label": "Tiptur",
        "value": "Tiptur",
        "stateID": 17
    },
    {
        "label": "Tumkur",
        "value": "Tumkur",
        "stateID": 17
    },
    {
        "label": "Udupi",
        "value": "Udupi",
        "stateID": 17
    },
    {
        "label": "Vijayapura",
        "value": "Vijayapura",
        "stateID": 17
    },
    {
        "label": "Wadi",
        "value": "Wadi",
        "stateID": 17
    },
    {
        "label": "Yadgir",
        "value": "Yadgir",
        "stateID": 17
    },
    {
        "label": "Adoor",
        "value": "Adoor",
        "stateID": 18
    },
    {
        "label": "Alappuzha",
        "value": "Alappuzha",
        "stateID": 18
    },
    {
        "label": "Attingal",
        "value": "Attingal",
        "stateID": 18
    },
    {
        "label": "Chalakudy",
        "value": "Chalakudy",
        "stateID": 18
    },
    {
        "label": "Changanassery",
        "value": "Changanassery",
        "stateID": 18
    },
    {
        "label": "Cherthala",
        "value": "Cherthala",
        "stateID": 18
    },
    {
        "label": "Chittur-Thathamangalam",
        "value": "Chittur-Thathamangalam",
        "stateID": 18
    },
    {
        "label": "Guruvayoor",
        "value": "Guruvayoor",
        "stateID": 18
    },
    {
        "label": "Kanhangad",
        "value": "Kanhangad",
        "stateID": 18
    },
    {
        "label": "Kannur",
        "value": "Kannur",
        "stateID": 18
    },
    {
        "label": "Kasaragod",
        "value": "Kasaragod",
        "stateID": 18
    },
    {
        "label": "Kayamkulam",
        "value": "Kayamkulam",
        "stateID": 18
    },
    {
        "label": "Kochi",
        "value": "Kochi",
        "stateID": 18
    },
    {
        "label": "Kodungallur",
        "value": "Kodungallur",
        "stateID": 18
    },
    {
        "label": "Kollam",
        "value": "Kollam",
        "stateID": 18
    },
    {
        "label": "Kottayam",
        "value": "Kottayam",
        "stateID": 18
    },
    {
        "label": "Koyilandy",
        "value": "Koyilandy",
        "stateID": 18
    },
    {
        "label": "Kozhikode",
        "value": "Kozhikode",
        "stateID": 18
    },
    {
        "label": "Kunnamkulam",
        "value": "Kunnamkulam",
        "stateID": 18
    },
    {
        "label": "Malappuram",
        "value": "Malappuram",
        "stateID": 18
    },
    {
        "label": "Mattannur",
        "value": "Mattannur",
        "stateID": 18
    },
    {
        "label": "Mavelikkara",
        "value": "Mavelikkara",
        "stateID": 18
    },
    {
        "label": "Mavoor",
        "value": "Mavoor",
        "stateID": 18
    },
    {
        "label": "Muvattupuzha",
        "value": "Muvattupuzha",
        "stateID": 18
    },
    {
        "label": "Nedumangad",
        "value": "Nedumangad",
        "stateID": 18
    },
    {
        "label": "Neyyattinkara",
        "value": "Neyyattinkara",
        "stateID": 18
    },
    {
        "label": "Nilambur",
        "value": "Nilambur",
        "stateID": 18
    },
    {
        "label": "Ottappalam",
        "value": "Ottappalam",
        "stateID": 18
    },
    {
        "label": "Palai",
        "value": "Palai",
        "stateID": 18
    },
    {
        "label": "Palakkad",
        "value": "Palakkad",
        "stateID": 18
    },
    {
        "label": "Panamattom",
        "value": "Panamattom",
        "stateID": 18
    },
    {
        "label": "Panniyannur",
        "value": "Panniyannur",
        "stateID": 18
    },
    {
        "label": "Pappinisseri",
        "value": "Pappinisseri",
        "stateID": 18
    },
    {
        "label": "Paravoor",
        "value": "Paravoor",
        "stateID": 18
    },
    {
        "label": "Pathanamthitta",
        "value": "Pathanamthitta",
        "stateID": 18
    },
    {
        "label": "Peringathur",
        "value": "Peringathur",
        "stateID": 18
    },
    {
        "label": "Perinthalmanna",
        "value": "Perinthalmanna",
        "stateID": 18
    },
    {
        "label": "Perumbavoor",
        "value": "Perumbavoor",
        "stateID": 18
    },
    {
        "label": "Ponnani",
        "value": "Ponnani",
        "stateID": 18
    },
    {
        "label": "Punalur",
        "value": "Punalur",
        "stateID": 18
    },
    {
        "label": "Puthuppally",
        "value": "Puthuppally",
        "stateID": 18
    },
    {
        "label": "Shoranur",
        "value": "Shoranur",
        "stateID": 18
    },
    {
        "label": "Taliparamba",
        "value": "Taliparamba",
        "stateID": 18
    },
    {
        "label": "Thiruvalla",
        "value": "Thiruvalla",
        "stateID": 18
    },
    {
        "label": "Thiruvananthapuram",
        "value": "Thiruvananthapuram",
        "stateID": 18
    },
    {
        "label": "Thodupuzha",
        "value": "Thodupuzha",
        "stateID": 18
    },
    {
        "label": "Thrissur",
        "value": "Thrissur",
        "stateID": 18
    },
    {
        "label": "Tirur",
        "value": "Tirur",
        "stateID": 18
    },
    {
        "label": "Vaikom",
        "value": "Vaikom",
        "stateID": 18
    },
    {
        "label": "Varkala",
        "value": "Varkala",
        "stateID": 18
    },
    {
        "label": "Vatakara",
        "value": "Vatakara",
        "stateID": 18
    },
    {
        "label": "Alirajpur",
        "value": "Alirajpur",
        "stateID": 19
    },
    {
        "label": "Ashok Nagar",
        "value": "Ashok Nagar",
        "stateID": 19
    },
    {
        "label": "Balaghat",
        "value": "Balaghat",
        "stateID": 19
    },
    {
        "label": "Bhopal",
        "value": "Bhopal",
        "stateID": 19
    },
    {
        "label": "Ganjbasoda",
        "value": "Ganjbasoda",
        "stateID": 19
    },
    {
        "label": "Gwalior",
        "value": "Gwalior",
        "stateID": 19
    },
    {
        "label": "Indore",
        "value": "Indore",
        "stateID": 19
    },
    {
        "label": "Itarsi",
        "value": "Itarsi",
        "stateID": 19
    },
    {
        "label": "Jabalpur",
        "value": "Jabalpur",
        "stateID": 19
    },
    {
        "label": "Lahar",
        "value": "Lahar",
        "stateID": 19
    },
    {
        "label": "Maharajpur",
        "value": "Maharajpur",
        "stateID": 19
    },
    {
        "label": "Mahidpur",
        "value": "Mahidpur",
        "stateID": 19
    },
    {
        "label": "Maihar",
        "value": "Maihar",
        "stateID": 19
    },
    {
        "label": "Malaj Khand",
        "value": "Malaj Khand",
        "stateID": 19
    },
    {
        "label": "Manasa",
        "value": "Manasa",
        "stateID": 19
    },
    {
        "label": "Manawar",
        "value": "Manawar",
        "stateID": 19
    },
    {
        "label": "Mandideep",
        "value": "Mandideep",
        "stateID": 19
    },
    {
        "label": "Mandla",
        "value": "Mandla",
        "stateID": 19
    },
    {
        "label": "Mandsaur",
        "value": "Mandsaur",
        "stateID": 19
    },
    {
        "label": "Mauganj",
        "value": "Mauganj",
        "stateID": 19
    },
    {
        "label": "Mhow Cantonment",
        "value": "Mhow Cantonment",
        "stateID": 19
    },
    {
        "label": "Mhowgaon",
        "value": "Mhowgaon",
        "stateID": 19
    },
    {
        "label": "Morena",
        "value": "Morena",
        "stateID": 19
    },
    {
        "label": "Multai",
        "value": "Multai",
        "stateID": 19
    },
    {
        "label": "Mundi",
        "value": "Mundi",
        "stateID": 19
    },
    {
        "label": "Murwara (Katni)",
        "value": "Murwara (Katni)",
        "stateID": 19
    },
    {
        "label": "Nagda",
        "value": "Nagda",
        "stateID": 19
    },
    {
        "label": "Nainpur",
        "value": "Nainpur",
        "stateID": 19
    },
    {
        "label": "Narsinghgarh",
        "value": "Narsinghgarh",
        "stateID": 19
    },
    {
        "label": "Narsinghgarh",
        "value": "Narsinghgarh",
        "stateID": 19
    },
    {
        "label": "Neemuch",
        "value": "Neemuch",
        "stateID": 19
    },
    {
        "label": "Nepanagar",
        "value": "Nepanagar",
        "stateID": 19
    },
    {
        "label": "Niwari",
        "value": "Niwari",
        "stateID": 19
    },
    {
        "label": "Nowgong",
        "value": "Nowgong",
        "stateID": 19
    },
    {
        "label": "Nowrozabad (Khodargama)",
        "value": "Nowrozabad (Khodargama)",
        "stateID": 19
    },
    {
        "label": "Pachore",
        "value": "Pachore",
        "stateID": 19
    },
    {
        "label": "Pali",
        "value": "Pali",
        "stateID": 19
    },
    {
        "label": "Panagar",
        "value": "Panagar",
        "stateID": 19
    },
    {
        "label": "Pandhurna",
        "value": "Pandhurna",
        "stateID": 19
    },
    {
        "label": "Panna",
        "value": "Panna",
        "stateID": 19
    },
    {
        "label": "Pasan",
        "value": "Pasan",
        "stateID": 19
    },
    {
        "label": "Pipariya",
        "value": "Pipariya",
        "stateID": 19
    },
    {
        "label": "Pithampur",
        "value": "Pithampur",
        "stateID": 19
    },
    {
        "label": "Porsa",
        "value": "Porsa",
        "stateID": 19
    },
    {
        "label": "Prithvipur",
        "value": "Prithvipur",
        "stateID": 19
    },
    {
        "label": "Raghogarh-Vijaypur",
        "value": "Raghogarh-Vijaypur",
        "stateID": 19
    },
    {
        "label": "Rahatgarh",
        "value": "Rahatgarh",
        "stateID": 19
    },
    {
        "label": "Raisen",
        "value": "Raisen",
        "stateID": 19
    },
    {
        "label": "Rajgarh",
        "value": "Rajgarh",
        "stateID": 19
    },
    {
        "label": "Ratlam",
        "value": "Ratlam",
        "stateID": 19
    },
    {
        "label": "Rau",
        "value": "Rau",
        "stateID": 19
    },
    {
        "label": "Rehli",
        "value": "Rehli",
        "stateID": 19
    },
    {
        "label": "Rewa",
        "value": "Rewa",
        "stateID": 19
    },
    {
        "label": "Sabalgarh",
        "value": "Sabalgarh",
        "stateID": 19
    },
    {
        "label": "Sagar",
        "value": "Sagar",
        "stateID": 19
    },
    {
        "label": "Sanawad",
        "value": "Sanawad",
        "stateID": 19
    },
    {
        "label": "Sarangpur",
        "value": "Sarangpur",
        "stateID": 19
    },
    {
        "label": "Sarni",
        "value": "Sarni",
        "stateID": 19
    },
    {
        "label": "Satna",
        "value": "Satna",
        "stateID": 19
    },
    {
        "label": "Sausar",
        "value": "Sausar",
        "stateID": 19
    },
    {
        "label": "Sehore",
        "value": "Sehore",
        "stateID": 19
    },
    {
        "label": "Sendhwa",
        "value": "Sendhwa",
        "stateID": 19
    },
    {
        "label": "Seoni",
        "value": "Seoni",
        "stateID": 19
    },
    {
        "label": "Seoni-Malwa",
        "value": "Seoni-Malwa",
        "stateID": 19
    },
    {
        "label": "Shahdol",
        "value": "Shahdol",
        "stateID": 19
    },
    {
        "label": "Shajapur",
        "value": "Shajapur",
        "stateID": 19
    },
    {
        "label": "Shamgarh",
        "value": "Shamgarh",
        "stateID": 19
    },
    {
        "label": "Sheopur",
        "value": "Sheopur",
        "stateID": 19
    },
    {
        "label": "Shivpuri",
        "value": "Shivpuri",
        "stateID": 19
    },
    {
        "label": "Shujalpur",
        "value": "Shujalpur",
        "stateID": 19
    },
    {
        "label": "Sidhi",
        "value": "Sidhi",
        "stateID": 19
    },
    {
        "label": "Sihora",
        "value": "Sihora",
        "stateID": 19
    },
    {
        "label": "Singrauli",
        "value": "Singrauli",
        "stateID": 19
    },
    {
        "label": "Sironj",
        "value": "Sironj",
        "stateID": 19
    },
    {
        "label": "Sohagpur",
        "value": "Sohagpur",
        "stateID": 19
    },
    {
        "label": "Tarana",
        "value": "Tarana",
        "stateID": 19
    },
    {
        "label": "Tikamgarh",
        "value": "Tikamgarh",
        "stateID": 19
    },
    {
        "label": "Ujjain",
        "value": "Ujjain",
        "stateID": 19
    },
    {
        "label": "Umaria",
        "value": "Umaria",
        "stateID": 19
    },
    {
        "label": "Vidisha",
        "value": "Vidisha",
        "stateID": 19
    },
    {
        "label": "Vijaypur",
        "value": "Vijaypur",
        "stateID": 19
    },
    {
        "label": "Wara Seoni",
        "value": "Wara Seoni",
        "stateID": 19
    },
    {
        "label": "Achalpur",
        "value": "Achalpur",
        "stateID": 20
    },
    {
        "label": "Ahmednagar",
        "value": "Ahmednagar",
        "stateID": 20
    },
    {
        "label": "Akola",
        "value": "Akola",
        "stateID": 20
    },
    {
        "label": "Akot",
        "value": "Akot",
        "stateID": 20
    },
    {
        "label": "Amalner",
        "value": "Amalner",
        "stateID": 20
    },
    {
        "label": "Ambejogai",
        "value": "Ambejogai",
        "stateID": 20
    },
    {
        "label": "Amravati",
        "value": "Amravati",
        "stateID": 20
    },
    {
        "label": "Anjangaon",
        "value": "Anjangaon",
        "stateID": 20
    },
    {
        "label": "Arvi",
        "value": "Arvi",
        "stateID": 20
    },
    {
        "label": "Aurangabad",
        "value": "Aurangabad",
        "stateID": 20
    },
    {
        "label": "Bhiwandi",
        "value": "Bhiwandi",
        "stateID": 20
    },
    {
        "label": "Dhule",
        "value": "Dhule",
        "stateID": 20
    },
    {
        "label": "Ichalkaranji",
        "value": "Ichalkaranji",
        "stateID": 20
    },
    {
        "label": "Kalyan-Dombivali",
        "value": "Kalyan-Dombivali",
        "stateID": 20
    },
    {
        "label": "Karjat",
        "value": "Karjat",
        "stateID": 20
    },
    {
        "label": "Latur",
        "value": "Latur",
        "stateID": 20
    },
    {
        "label": "Loha",
        "value": "Loha",
        "stateID": 20
    },
    {
        "label": "Lonar",
        "value": "Lonar",
        "stateID": 20
    },
    {
        "label": "Lonavla",
        "value": "Lonavla",
        "stateID": 20
    },
    {
        "label": "Mahad",
        "value": "Mahad",
        "stateID": 20
    },
    {
        "label": "Malegaon",
        "value": "Malegaon",
        "stateID": 20
    },
    {
        "label": "Malkapur",
        "value": "Malkapur",
        "stateID": 20
    },
    {
        "label": "Mangalvedhe",
        "value": "Mangalvedhe",
        "stateID": 20
    },
    {
        "label": "Mangrulpir",
        "value": "Mangrulpir",
        "stateID": 20
    },
    {
        "label": "Manjlegaon",
        "value": "Manjlegaon",
        "stateID": 20
    },
    {
        "label": "Manmad",
        "value": "Manmad",
        "stateID": 20
    },
    {
        "label": "Manwath",
        "value": "Manwath",
        "stateID": 20
    },
    {
        "label": "Mehkar",
        "value": "Mehkar",
        "stateID": 20
    },
    {
        "label": "Mhaswad",
        "value": "Mhaswad",
        "stateID": 20
    },
    {
        "label": "Mira-Bhayandar",
        "value": "Mira-Bhayandar",
        "stateID": 20
    },
    {
        "label": "Morshi",
        "value": "Morshi",
        "stateID": 20
    },
    {
        "label": "Mukhed",
        "value": "Mukhed",
        "stateID": 20
    },
    {
        "label": "Mul",
        "value": "Mul",
        "stateID": 20
    },
    {
        "label": "Mumbai",
        "value": "Mumbai",
        "stateID": 20
    },
    {
        "label": "Murtijapur",
        "value": "Murtijapur",
        "stateID": 20
    },
    {
        "label": "Nagpur",
        "value": "Nagpur",
        "stateID": 20
    },
    {
        "label": "Nanded-Waghala",
        "value": "Nanded-Waghala",
        "stateID": 20
    },
    {
        "label": "Nandgaon",
        "value": "Nandgaon",
        "stateID": 20
    },
    {
        "label": "Nandura",
        "value": "Nandura",
        "stateID": 20
    },
    {
        "label": "Nandurbar",
        "value": "Nandurbar",
        "stateID": 20
    },
    {
        "label": "Narkhed",
        "value": "Narkhed",
        "stateID": 20
    },
    {
        "label": "Nashik",
        "value": "Nashik",
        "stateID": 20
    },
    {
        "label": "Nawapur",
        "value": "Nawapur",
        "stateID": 20
    },
    {
        "label": "Nilanga",
        "value": "Nilanga",
        "stateID": 20
    },
    {
        "label": "Osmanabad",
        "value": "Osmanabad",
        "stateID": 20
    },
    {
        "label": "Ozar",
        "value": "Ozar",
        "stateID": 20
    },
    {
        "label": "Pachora",
        "value": "Pachora",
        "stateID": 20
    },
    {
        "label": "Paithan",
        "value": "Paithan",
        "stateID": 20
    },
    {
        "label": "Palghar",
        "value": "Palghar",
        "stateID": 20
    },
    {
        "label": "Pandharkaoda",
        "value": "Pandharkaoda",
        "stateID": 20
    },
    {
        "label": "Pandharpur",
        "value": "Pandharpur",
        "stateID": 20
    },
    {
        "label": "Panvel",
        "value": "Panvel",
        "stateID": 20
    },
    {
        "label": "Parbhani",
        "value": "Parbhani",
        "stateID": 20
    },
    {
        "label": "Parli",
        "value": "Parli",
        "stateID": 20
    },
    {
        "label": "Partur",
        "value": "Partur",
        "stateID": 20
    },
    {
        "label": "Pathardi",
        "value": "Pathardi",
        "stateID": 20
    },
    {
        "label": "Pathri",
        "value": "Pathri",
        "stateID": 20
    },
    {
        "label": "Patur",
        "value": "Patur",
        "stateID": 20
    },
    {
        "label": "Pauni",
        "value": "Pauni",
        "stateID": 20
    },
    {
        "label": "Pen",
        "value": "Pen",
        "stateID": 20
    },
    {
        "label": "Phaltan",
        "value": "Phaltan",
        "stateID": 20
    },
    {
        "label": "Pulgaon",
        "value": "Pulgaon",
        "stateID": 20
    },
    {
        "label": "Pune",
        "value": "Pune",
        "stateID": 20
    },
    {
        "label": "Purna",
        "value": "Purna",
        "stateID": 20
    },
    {
        "label": "Pusad",
        "value": "Pusad",
        "stateID": 20
    },
    {
        "label": "Rahuri",
        "value": "Rahuri",
        "stateID": 20
    },
    {
        "label": "Rajura",
        "value": "Rajura",
        "stateID": 20
    },
    {
        "label": "Ramtek",
        "value": "Ramtek",
        "stateID": 20
    },
    {
        "label": "Ratnagiri",
        "value": "Ratnagiri",
        "stateID": 20
    },
    {
        "label": "Raver",
        "value": "Raver",
        "stateID": 20
    },
    {
        "label": "Risod",
        "value": "Risod",
        "stateID": 20
    },
    {
        "label": "Sailu",
        "value": "Sailu",
        "stateID": 20
    },
    {
        "label": "Sangamner",
        "value": "Sangamner",
        "stateID": 20
    },
    {
        "label": "Sangli",
        "value": "Sangli",
        "stateID": 20
    },
    {
        "label": "Sangole",
        "value": "Sangole",
        "stateID": 20
    },
    {
        "label": "Sasvad",
        "value": "Sasvad",
        "stateID": 20
    },
    {
        "label": "Satana",
        "value": "Satana",
        "stateID": 20
    },
    {
        "label": "Satara",
        "value": "Satara",
        "stateID": 20
    },
    {
        "label": "Savner",
        "value": "Savner",
        "stateID": 20
    },
    {
        "label": "Sawantwadi",
        "value": "Sawantwadi",
        "stateID": 20
    },
    {
        "label": "Shahade",
        "value": "Shahade",
        "stateID": 20
    },
    {
        "label": "Shegaon",
        "value": "Shegaon",
        "stateID": 20
    },
    {
        "label": "Shendurjana",
        "value": "Shendurjana",
        "stateID": 20
    },
    {
        "label": "Shirdi",
        "value": "Shirdi",
        "stateID": 20
    },
    {
        "label": "Shirpur-Warwade",
        "value": "Shirpur-Warwade",
        "stateID": 20
    },
    {
        "label": "Shirur",
        "value": "Shirur",
        "stateID": 20
    },
    {
        "label": "Shrigonda",
        "value": "Shrigonda",
        "stateID": 20
    },
    {
        "label": "Shrirampur",
        "value": "Shrirampur",
        "stateID": 20
    },
    {
        "label": "Sillod",
        "value": "Sillod",
        "stateID": 20
    },
    {
        "label": "Sinnar",
        "value": "Sinnar",
        "stateID": 20
    },
    {
        "label": "Solapur",
        "value": "Solapur",
        "stateID": 20
    },
    {
        "label": "Soyagaon",
        "value": "Soyagaon",
        "stateID": 20
    },
    {
        "label": "Talegaon Dabhade",
        "value": "Talegaon Dabhade",
        "stateID": 20
    },
    {
        "label": "Talode",
        "value": "Talode",
        "stateID": 20
    },
    {
        "label": "Tasgaon",
        "value": "Tasgaon",
        "stateID": 20
    },
    {
        "label": "Thane",
        "value": "Thane",
        "stateID": 20
    },
    {
        "label": "Tirora",
        "value": "Tirora",
        "stateID": 20
    },
    {
        "label": "Tuljapur",
        "value": "Tuljapur",
        "stateID": 20
    },
    {
        "label": "Tumsar",
        "value": "Tumsar",
        "stateID": 20
    },
    {
        "label": "Uchgaon",
        "value": "Uchgaon",
        "stateID": 20
    },
    {
        "label": "Udgir",
        "value": "Udgir",
        "stateID": 20
    },
    {
        "label": "Umarga",
        "value": "Umarga",
        "stateID": 20
    },
    {
        "label": "Umarkhed",
        "value": "Umarkhed",
        "stateID": 20
    },
    {
        "label": "Umred",
        "value": "Umred",
        "stateID": 20
    },
    {
        "label": "Uran",
        "value": "Uran",
        "stateID": 20
    },
    {
        "label": "Uran Islampur",
        "value": "Uran Islampur",
        "stateID": 20
    },
    {
        "label": "Vadgaon Kasba",
        "value": "Vadgaon Kasba",
        "stateID": 20
    },
    {
        "label": "Vaijapur",
        "value": "Vaijapur",
        "stateID": 20
    },
    {
        "label": "Vasai-Virar",
        "value": "Vasai-Virar",
        "stateID": 20
    },
    {
        "label": "Vita",
        "value": "Vita",
        "stateID": 20
    },
    {
        "label": "Wadgaon Road",
        "value": "Wadgaon Road",
        "stateID": 20
    },
    {
        "label": "Wai",
        "value": "Wai",
        "stateID": 20
    },
    {
        "label": "Wani",
        "value": "Wani",
        "stateID": 20
    },
    {
        "label": "Wardha",
        "value": "Wardha",
        "stateID": 20
    },
    {
        "label": "Warora",
        "value": "Warora",
        "stateID": 20
    },
    {
        "label": "Warud",
        "value": "Warud",
        "stateID": 20
    },
    {
        "label": "Washim",
        "value": "Washim",
        "stateID": 20
    },
    {
        "label": "Yavatmal",
        "value": "Yavatmal",
        "stateID": 20
    },
    {
        "label": "Yawal",
        "value": "Yawal",
        "stateID": 20
    },
    {
        "label": "Yevla",
        "value": "Yevla",
        "stateID": 20
    },
    {
        "label": "Imphal",
        "value": "Imphal",
        "stateID": 21
    },
    {
        "label": "Lilong",
        "value": "Lilong",
        "stateID": 21
    },
    {
        "label": "Mayang Imphal",
        "value": "Mayang Imphal",
        "stateID": 21
    },
    {
        "label": "Thoubal",
        "value": "Thoubal",
        "stateID": 21
    },
    {
        "label": "Nongstoin",
        "value": "Nongstoin",
        "stateID": 22
    },
    {
        "label": "Shillong",
        "value": "Shillong",
        "stateID": 22
    },
    {
        "label": "Tura",
        "value": "Tura",
        "stateID": 22
    },
    {
        "label": "Aizawl",
        "value": "Aizawl",
        "stateID": 23
    },
    {
        "label": "Lunglei",
        "value": "Lunglei",
        "stateID": 23
    },
    {
        "label": "Saiha",
        "value": "Saiha",
        "stateID": 23
    },
    {
        "label": "Dimapur",
        "value": "Dimapur",
        "stateID": 24
    },
    {
        "label": "Kohima",
        "value": "Kohima",
        "stateID": 24
    },
    {
        "label": "Mokokchung",
        "value": "Mokokchung",
        "stateID": 24
    },
    {
        "label": "Tuensang",
        "value": "Tuensang",
        "stateID": 24
    },
    {
        "label": "Wokha",
        "value": "Wokha",
        "stateID": 24
    },
    {
        "label": "Zunheboto",
        "value": "Zunheboto",
        "stateID": 24
    },
    {
        "label": "Balangir",
        "value": "Balangir",
        "stateID": 25
    },
    {
        "label": "Baleshwar Town",
        "value": "Baleshwar Town",
        "stateID": 25
    },
    {
        "label": "Barbil",
        "value": "Barbil",
        "stateID": 25
    },
    {
        "label": "Bargarh",
        "value": "Bargarh",
        "stateID": 25
    },
    {
        "label": "Baripada Town",
        "value": "Baripada Town",
        "stateID": 25
    },
    {
        "label": "Bhadrak",
        "value": "Bhadrak",
        "stateID": 25
    },
    {
        "label": "Bhawanipatna",
        "value": "Bhawanipatna",
        "stateID": 25
    },
    {
        "label": "Bhubaneswar",
        "value": "Bhubaneswar",
        "stateID": 25
    },
    {
        "label": "Brahmapur",
        "value": "Brahmapur",
        "stateID": 25
    },
    {
        "label": "Byasanagar",
        "value": "Byasanagar",
        "stateID": 25
    },
    {
        "label": "Cuttack",
        "value": "Cuttack",
        "stateID": 25
    },
    {
        "label": "Dhenkanal",
        "value": "Dhenkanal",
        "stateID": 25
    },
    {
        "label": "Jatani",
        "value": "Jatani",
        "stateID": 25
    },
    {
        "label": "Jharsuguda",
        "value": "Jharsuguda",
        "stateID": 25
    },
    {
        "label": "Kendrapara",
        "value": "Kendrapara",
        "stateID": 25
    },
    {
        "label": "Kendujhar",
        "value": "Kendujhar",
        "stateID": 25
    },
    {
        "label": "Malkangiri",
        "value": "Malkangiri",
        "stateID": 25
    },
    {
        "label": "Nabarangapur",
        "value": "Nabarangapur",
        "stateID": 25
    },
    {
        "label": "Paradip",
        "value": "Paradip",
        "stateID": 25
    },
    {
        "label": "Parlakhemundi",
        "value": "Parlakhemundi",
        "stateID": 25
    },
    {
        "label": "Pattamundai",
        "value": "Pattamundai",
        "stateID": 25
    },
    {
        "label": "Phulabani",
        "value": "Phulabani",
        "stateID": 25
    },
    {
        "label": "Puri",
        "value": "Puri",
        "stateID": 25
    },
    {
        "label": "Rairangpur",
        "value": "Rairangpur",
        "stateID": 25
    },
    {
        "label": "Rajagangapur",
        "value": "Rajagangapur",
        "stateID": 25
    },
    {
        "label": "Raurkela",
        "value": "Raurkela",
        "stateID": 25
    },
    {
        "label": "Rayagada",
        "value": "Rayagada",
        "stateID": 25
    },
    {
        "label": "Sambalpur",
        "value": "Sambalpur",
        "stateID": 25
    },
    {
        "label": "Soro",
        "value": "Soro",
        "stateID": 25
    },
    {
        "label": "Sunabeda",
        "value": "Sunabeda",
        "stateID": 25
    },
    {
        "label": "Sundargarh",
        "value": "Sundargarh",
        "stateID": 25
    },
    {
        "label": "Talcher",
        "value": "Talcher",
        "stateID": 25
    },
    {
        "label": "Tarbha",
        "value": "Tarbha",
        "stateID": 25
    },
    {
        "label": "Titlagarh",
        "value": "Titlagarh",
        "stateID": 25
    },
    {
        "label": "Karaikal",
        "value": "Karaikal",
        "stateID": 26
    },
    {
        "label": "Mahe",
        "value": "Mahe",
        "stateID": 26
    },
    {
        "label": "Pondicherry",
        "value": "Pondicherry",
        "stateID": 26
    },
    {
        "label": "Yanam",
        "value": "Yanam",
        "stateID": 26
    },
    {
        "label": "Amritsar",
        "value": "Amritsar",
        "stateID": 27
    },
    {
        "label": "Barnala",
        "value": "Barnala",
        "stateID": 27
    },
    {
        "label": "Batala",
        "value": "Batala",
        "stateID": 27
    },
    {
        "label": "Bathinda",
        "value": "Bathinda",
        "stateID": 27
    },
    {
        "label": "Dhuri",
        "value": "Dhuri",
        "stateID": 27
    },
    {
        "label": "Faridkot",
        "value": "Faridkot",
        "stateID": 27
    },
    {
        "label": "Fazilka",
        "value": "Fazilka",
        "stateID": 27
    },
    {
        "label": "Firozpur",
        "value": "Firozpur",
        "stateID": 27
    },
    {
        "label": "Firozpur Cantt.",
        "value": "Firozpur Cantt.",
        "stateID": 27
    },
    {
        "label": "Gobindgarh",
        "value": "Gobindgarh",
        "stateID": 27
    },
    {
        "label": "Gurdaspur",
        "value": "Gurdaspur",
        "stateID": 27
    },
    {
        "label": "Hoshiarpur",
        "value": "Hoshiarpur",
        "stateID": 27
    },
    {
        "label": "Jagraon",
        "value": "Jagraon",
        "stateID": 27
    },
    {
        "label": "Jalandhar",
        "value": "Jalandhar",
        "stateID": 27
    },
    {
        "label": "Jalandhar Cantt.",
        "value": "Jalandhar Cantt.",
        "stateID": 27
    },
    {
        "label": "Kapurthala",
        "value": "Kapurthala",
        "stateID": 27
    },
    {
        "label": "Khanna",
        "value": "Khanna",
        "stateID": 27
    },
    {
        "label": "Kharar",
        "value": "Kharar",
        "stateID": 27
    },
    {
        "label": "Kot Kapura",
        "value": "Kot Kapura",
        "stateID": 27
    },
    {
        "label": "Longowal",
        "value": "Longowal",
        "stateID": 27
    },
    {
        "label": "Ludhiana",
        "value": "Ludhiana",
        "stateID": 27
    },
    {
        "label": "Malerkotla",
        "value": "Malerkotla",
        "stateID": 27
    },
    {
        "label": "Malout",
        "value": "Malout",
        "stateID": 27
    },
    {
        "label": "Mansa",
        "value": "Mansa",
        "stateID": 27
    },
    {
        "label": "Moga",
        "value": "Moga",
        "stateID": 27
    },
    {
        "label": "Mohali",
        "value": "Mohali",
        "stateID": 27
    },
    {
        "label": "Morinda, India",
        "value": "Morinda, India",
        "stateID": 27
    },
    {
        "label": "Mukerian",
        "value": "Mukerian",
        "stateID": 27
    },
    {
        "label": "Muktsar",
        "value": "Muktsar",
        "stateID": 27
    },
    {
        "label": "Nabha",
        "value": "Nabha",
        "stateID": 27
    },
    {
        "label": "Nakodar",
        "value": "Nakodar",
        "stateID": 27
    },
    {
        "label": "Nangal",
        "value": "Nangal",
        "stateID": 27
    },
    {
        "label": "Nawanshahr",
        "value": "Nawanshahr",
        "stateID": 27
    },
    {
        "label": "Pathankot",
        "value": "Pathankot",
        "stateID": 27
    },
    {
        "label": "Patiala",
        "value": "Patiala",
        "stateID": 27
    },
    {
        "label": "Patti",
        "value": "Patti",
        "stateID": 27
    },
    {
        "label": "Pattran",
        "value": "Pattran",
        "stateID": 27
    },
    {
        "label": "Phagwara",
        "value": "Phagwara",
        "stateID": 27
    },
    {
        "label": "Phillaur",
        "value": "Phillaur",
        "stateID": 27
    },
    {
        "label": "Qadian",
        "value": "Qadian",
        "stateID": 27
    },
    {
        "label": "Raikot",
        "value": "Raikot",
        "stateID": 27
    },
    {
        "label": "Rajpura",
        "value": "Rajpura",
        "stateID": 27
    },
    {
        "label": "Rampura Phul",
        "value": "Rampura Phul",
        "stateID": 27
    },
    {
        "label": "Rupnagar",
        "value": "Rupnagar",
        "stateID": 27
    },
    {
        "label": "Samana",
        "value": "Samana",
        "stateID": 27
    },
    {
        "label": "Sangrur",
        "value": "Sangrur",
        "stateID": 27
    },
    {
        "label": "Sirhind Fatehgarh Sahib",
        "value": "Sirhind Fatehgarh Sahib",
        "stateID": 27
    },
    {
        "label": "Sujanpur",
        "value": "Sujanpur",
        "stateID": 27
    },
    {
        "label": "Sunam",
        "value": "Sunam",
        "stateID": 27
    },
    {
        "label": "Talwara",
        "value": "Talwara",
        "stateID": 27
    },
    {
        "label": "Tarn Taran",
        "value": "Tarn Taran",
        "stateID": 27
    },
    {
        "label": "Urmar Tanda",
        "value": "Urmar Tanda",
        "stateID": 27
    },
    {
        "label": "Zira",
        "value": "Zira",
        "stateID": 27
    },
    {
        "label": "Zirakpur",
        "value": "Zirakpur",
        "stateID": 27
    },
    {
        "label": "Ajmer",
        "value": "Ajmer",
        "stateID": 28
    },
    {
        "label": "Alwar",
        "value": "Alwar",
        "stateID": 28
    },
    {
        "label": "Barmer",
        "value": "Barmer",
        "stateID": 28
    },
    {
        "label": "Bharatpur",
        "value": "Bharatpur",
        "stateID": 28
    },
    {
        "label": "Bhilwara",
        "value": "Bhilwara",
        "stateID": 28
    },
    {
        "label": "Bikaner",
        "value": "Bikaner",
        "stateID": 28
    },
    {
        "label": "Jaipur",
        "value": "Jaipur",
        "stateID": 28
    },
    {
        "label": "Jodhpur",
        "value": "Jodhpur",
        "stateID": 28
    },
    {
        "label": "Lachhmangarh",
        "value": "Lachhmangarh",
        "stateID": 28
    },
    {
        "label": "Ladnu",
        "value": "Ladnu",
        "stateID": 28
    },
    {
        "label": "Lakheri",
        "value": "Lakheri",
        "stateID": 28
    },
    {
        "label": "Lalsot",
        "value": "Lalsot",
        "stateID": 28
    },
    {
        "label": "Losal",
        "value": "Losal",
        "stateID": 28
    },
    {
        "label": "Makrana",
        "value": "Makrana",
        "stateID": 28
    },
    {
        "label": "Malpura",
        "value": "Malpura",
        "stateID": 28
    },
    {
        "label": "Mandalgarh",
        "value": "Mandalgarh",
        "stateID": 28
    },
    {
        "label": "Mandawa",
        "value": "Mandawa",
        "stateID": 28
    },
    {
        "label": "Mangrol",
        "value": "Mangrol",
        "stateID": 28
    },
    {
        "label": "Merta City",
        "value": "Merta City",
        "stateID": 28
    },
    {
        "label": "Mount Abu",
        "value": "Mount Abu",
        "stateID": 28
    },
    {
        "label": "Nadbai",
        "value": "Nadbai",
        "stateID": 28
    },
    {
        "label": "Nagar",
        "value": "Nagar",
        "stateID": 28
    },
    {
        "label": "Nagaur",
        "value": "Nagaur",
        "stateID": 28
    },
    {
        "label": "Nasirabad",
        "value": "Nasirabad",
        "stateID": 28
    },
    {
        "label": "Nathdwara",
        "value": "Nathdwara",
        "stateID": 28
    },
    {
        "label": "Neem-Ka-Thana",
        "value": "Neem-Ka-Thana",
        "stateID": 28
    },
    {
        "label": "Nimbahera",
        "value": "Nimbahera",
        "stateID": 28
    },
    {
        "label": "Nohar",
        "value": "Nohar",
        "stateID": 28
    },
    {
        "label": "Nokha",
        "value": "Nokha",
        "stateID": 28
    },
    {
        "label": "Pali",
        "value": "Pali",
        "stateID": 28
    },
    {
        "label": "Phalodi",
        "value": "Phalodi",
        "stateID": 28
    },
    {
        "label": "Phulera",
        "value": "Phulera",
        "stateID": 28
    },
    {
        "label": "Pilani",
        "value": "Pilani",
        "stateID": 28
    },
    {
        "label": "Pilibanga",
        "value": "Pilibanga",
        "stateID": 28
    },
    {
        "label": "Pindwara",
        "value": "Pindwara",
        "stateID": 28
    },
    {
        "label": "Pipar City",
        "value": "Pipar City",
        "stateID": 28
    },
    {
        "label": "Prantij",
        "value": "Prantij",
        "stateID": 28
    },
    {
        "label": "Pratapgarh",
        "value": "Pratapgarh",
        "stateID": 28
    },
    {
        "label": "Raisinghnagar",
        "value": "Raisinghnagar",
        "stateID": 28
    },
    {
        "label": "Rajakhera",
        "value": "Rajakhera",
        "stateID": 28
    },
    {
        "label": "Rajaldesar",
        "value": "Rajaldesar",
        "stateID": 28
    },
    {
        "label": "Rajgarh (Alwar)",
        "value": "Rajgarh (Alwar)",
        "stateID": 28
    },
    {
        "label": "Rajgarh (Churu)",
        "value": "Rajgarh (Churu)",
        "stateID": 28
    },
    {
        "label": "Rajsamand",
        "value": "Rajsamand",
        "stateID": 28
    },
    {
        "label": "Ramganj Mandi",
        "value": "Ramganj Mandi",
        "stateID": 28
    },
    {
        "label": "Ramngarh",
        "value": "Ramngarh",
        "stateID": 28
    },
    {
        "label": "Ratangarh",
        "value": "Ratangarh",
        "stateID": 28
    },
    {
        "label": "Rawatbhata",
        "value": "Rawatbhata",
        "stateID": 28
    },
    {
        "label": "Rawatsar",
        "value": "Rawatsar",
        "stateID": 28
    },
    {
        "label": "Reengus",
        "value": "Reengus",
        "stateID": 28
    },
    {
        "label": "Sadri",
        "value": "Sadri",
        "stateID": 28
    },
    {
        "label": "Sadulpur",
        "value": "Sadulpur",
        "stateID": 28
    },
    {
        "label": "Sadulshahar",
        "value": "Sadulshahar",
        "stateID": 28
    },
    {
        "label": "Sagwara",
        "value": "Sagwara",
        "stateID": 28
    },
    {
        "label": "Sambhar",
        "value": "Sambhar",
        "stateID": 28
    },
    {
        "label": "Sanchore",
        "value": "Sanchore",
        "stateID": 28
    },
    {
        "label": "Sangaria",
        "value": "Sangaria",
        "stateID": 28
    },
    {
        "label": "Sardarshahar",
        "value": "Sardarshahar",
        "stateID": 28
    },
    {
        "label": "Sawai Madhopur",
        "value": "Sawai Madhopur",
        "stateID": 28
    },
    {
        "label": "Shahpura",
        "value": "Shahpura",
        "stateID": 28
    },
    {
        "label": "Shahpura",
        "value": "Shahpura",
        "stateID": 28
    },
    {
        "label": "Sheoganj",
        "value": "Sheoganj",
        "stateID": 28
    },
    {
        "label": "Sikar",
        "value": "Sikar",
        "stateID": 28
    },
    {
        "label": "Sirohi",
        "value": "Sirohi",
        "stateID": 28
    },
    {
        "label": "Sojat",
        "value": "Sojat",
        "stateID": 28
    },
    {
        "label": "Sri Madhopur",
        "value": "Sri Madhopur",
        "stateID": 28
    },
    {
        "label": "Sujangarh",
        "value": "Sujangarh",
        "stateID": 28
    },
    {
        "label": "Sumerpur",
        "value": "Sumerpur",
        "stateID": 28
    },
    {
        "label": "Suratgarh",
        "value": "Suratgarh",
        "stateID": 28
    },
    {
        "label": "Takhatgarh",
        "value": "Takhatgarh",
        "stateID": 28
    },
    {
        "label": "Taranagar",
        "value": "Taranagar",
        "stateID": 28
    },
    {
        "label": "Todabhim",
        "value": "Todabhim",
        "stateID": 28
    },
    {
        "label": "Todaraisingh",
        "value": "Todaraisingh",
        "stateID": 28
    },
    {
        "label": "Tonk",
        "value": "Tonk",
        "stateID": 28
    },
    {
        "label": "Udaipur",
        "value": "Udaipur",
        "stateID": 28
    },
    {
        "label": "Udaipurwati",
        "value": "Udaipurwati",
        "stateID": 28
    },
    {
        "label": "Vijainagar, Ajmer",
        "value": "Vijainagar, Ajmer",
        "stateID": 28
    },
    {
        "label": "Arakkonam",
        "value": "Arakkonam",
        "stateID": 29
    },
    {
        "label": "Aruppukkottai",
        "value": "Aruppukkottai",
        "stateID": 29
    },
    {
        "label": "Chennai",
        "value": "Chennai",
        "stateID": 29
    },
    {
        "label": "Coimbatore",
        "value": "Coimbatore",
        "stateID": 29
    },
    {
        "label": "Erode",
        "value": "Erode",
        "stateID": 29
    },
    {
        "label": "Gobichettipalayam",
        "value": "Gobichettipalayam",
        "stateID": 29
    },
    {
        "label": "Kancheepuram",
        "value": "Kancheepuram",
        "stateID": 29
    },
    {
        "label": "Karur",
        "value": "Karur",
        "stateID": 29
    },
    {
        "label": "Lalgudi",
        "value": "Lalgudi",
        "stateID": 29
    },
    {
        "label": "Madurai",
        "value": "Madurai",
        "stateID": 29
    },
    {
        "label": "Manachanallur",
        "value": "Manachanallur",
        "stateID": 29
    },
    {
        "label": "Nagapattinam",
        "value": "Nagapattinam",
        "stateID": 29
    },
    {
        "label": "Nagercoil",
        "value": "Nagercoil",
        "stateID": 29
    },
    {
        "label": "Namagiripettai",
        "value": "Namagiripettai",
        "stateID": 29
    },
    {
        "label": "Namakkal",
        "value": "Namakkal",
        "stateID": 29
    },
    {
        "label": "Nandivaram-Guduvancheri",
        "value": "Nandivaram-Guduvancheri",
        "stateID": 29
    },
    {
        "label": "Nanjikottai",
        "value": "Nanjikottai",
        "stateID": 29
    },
    {
        "label": "Natham",
        "value": "Natham",
        "stateID": 29
    },
    {
        "label": "Nellikuppam",
        "value": "Nellikuppam",
        "stateID": 29
    },
    {
        "label": "Neyveli (TS)",
        "value": "Neyveli (TS)",
        "stateID": 29
    },
    {
        "label": "O' Valley",
        "value": "O' Valley",
        "stateID": 29
    },
    {
        "label": "Oddanchatram",
        "value": "Oddanchatram",
        "stateID": 29
    },
    {
        "label": "P.N.Patti",
        "value": "P.N.Patti",
        "stateID": 29
    },
    {
        "label": "Pacode",
        "value": "Pacode",
        "stateID": 29
    },
    {
        "label": "Padmanabhapuram",
        "value": "Padmanabhapuram",
        "stateID": 29
    },
    {
        "label": "Palani",
        "value": "Palani",
        "stateID": 29
    },
    {
        "label": "Palladam",
        "value": "Palladam",
        "stateID": 29
    },
    {
        "label": "Pallapatti",
        "value": "Pallapatti",
        "stateID": 29
    },
    {
        "label": "Pallikonda",
        "value": "Pallikonda",
        "stateID": 29
    },
    {
        "label": "Panagudi",
        "value": "Panagudi",
        "stateID": 29
    },
    {
        "label": "Panruti",
        "value": "Panruti",
        "stateID": 29
    },
    {
        "label": "Paramakudi",
        "value": "Paramakudi",
        "stateID": 29
    },
    {
        "label": "Parangipettai",
        "value": "Parangipettai",
        "stateID": 29
    },
    {
        "label": "Pattukkottai",
        "value": "Pattukkottai",
        "stateID": 29
    },
    {
        "label": "Perambalur",
        "value": "Perambalur",
        "stateID": 29
    },
    {
        "label": "Peravurani",
        "value": "Peravurani",
        "stateID": 29
    },
    {
        "label": "Periyakulam",
        "value": "Periyakulam",
        "stateID": 29
    },
    {
        "label": "Periyasemur",
        "value": "Periyasemur",
        "stateID": 29
    },
    {
        "label": "Pernampattu",
        "value": "Pernampattu",
        "stateID": 29
    },
    {
        "label": "Pollachi",
        "value": "Pollachi",
        "stateID": 29
    },
    {
        "label": "Polur",
        "value": "Polur",
        "stateID": 29
    },
    {
        "label": "Ponneri",
        "value": "Ponneri",
        "stateID": 29
    },
    {
        "label": "Pudukkottai",
        "value": "Pudukkottai",
        "stateID": 29
    },
    {
        "label": "Pudupattinam",
        "value": "Pudupattinam",
        "stateID": 29
    },
    {
        "label": "Puliyankudi",
        "value": "Puliyankudi",
        "stateID": 29
    },
    {
        "label": "Punjaipugalur",
        "value": "Punjaipugalur",
        "stateID": 29
    },
    {
        "label": "Rajapalayam",
        "value": "Rajapalayam",
        "stateID": 29
    },
    {
        "label": "Ramanathapuram",
        "value": "Ramanathapuram",
        "stateID": 29
    },
    {
        "label": "Rameshwaram",
        "value": "Rameshwaram",
        "stateID": 29
    },
    {
        "label": "Ranipet",
        "value": "Ranipet",
        "stateID": 29
    },
    {
        "label": "Rasipuram",
        "value": "Rasipuram",
        "stateID": 29
    },
    {
        "label": "Salem",
        "value": "Salem",
        "stateID": 29
    },
    {
        "label": "Sankarankovil",
        "value": "Sankarankovil",
        "stateID": 29
    },
    {
        "label": "Sankari",
        "value": "Sankari",
        "stateID": 29
    },
    {
        "label": "Sathyamangalam",
        "value": "Sathyamangalam",
        "stateID": 29
    },
    {
        "label": "Sattur",
        "value": "Sattur",
        "stateID": 29
    },
    {
        "label": "Shenkottai",
        "value": "Shenkottai",
        "stateID": 29
    },
    {
        "label": "Sholavandan",
        "value": "Sholavandan",
        "stateID": 29
    },
    {
        "label": "Sholingur",
        "value": "Sholingur",
        "stateID": 29
    },
    {
        "label": "Sirkali",
        "value": "Sirkali",
        "stateID": 29
    },
    {
        "label": "Sivaganga",
        "value": "Sivaganga",
        "stateID": 29
    },
    {
        "label": "Sivagiri",
        "value": "Sivagiri",
        "stateID": 29
    },
    {
        "label": "Sivakasi",
        "value": "Sivakasi",
        "stateID": 29
    },
    {
        "label": "Srivilliputhur",
        "value": "Srivilliputhur",
        "stateID": 29
    },
    {
        "label": "Surandai",
        "value": "Surandai",
        "stateID": 29
    },
    {
        "label": "Suriyampalayam",
        "value": "Suriyampalayam",
        "stateID": 29
    },
    {
        "label": "Tenkasi",
        "value": "Tenkasi",
        "stateID": 29
    },
    {
        "label": "Thammampatti",
        "value": "Thammampatti",
        "stateID": 29
    },
    {
        "label": "Thanjavur",
        "value": "Thanjavur",
        "stateID": 29
    },
    {
        "label": "Tharamangalam",
        "value": "Tharamangalam",
        "stateID": 29
    },
    {
        "label": "Tharangambadi",
        "value": "Tharangambadi",
        "stateID": 29
    },
    {
        "label": "Theni Allinagaram",
        "value": "Theni Allinagaram",
        "stateID": 29
    },
    {
        "label": "Thirumangalam",
        "value": "Thirumangalam",
        "stateID": 29
    },
    {
        "label": "Thirupuvanam",
        "value": "Thirupuvanam",
        "stateID": 29
    },
    {
        "label": "Thiruthuraipoondi",
        "value": "Thiruthuraipoondi",
        "stateID": 29
    },
    {
        "label": "Thiruvallur",
        "value": "Thiruvallur",
        "stateID": 29
    },
    {
        "label": "Thiruvarur",
        "value": "Thiruvarur",
        "stateID": 29
    },
    {
        "label": "Thuraiyur",
        "value": "Thuraiyur",
        "stateID": 29
    },
    {
        "label": "Tindivanam",
        "value": "Tindivanam",
        "stateID": 29
    },
    {
        "label": "Tiruchendur",
        "value": "Tiruchendur",
        "stateID": 29
    },
    {
        "label": "Tiruchengode",
        "value": "Tiruchengode",
        "stateID": 29
    },
    {
        "label": "Tiruchirappalli",
        "value": "Tiruchirappalli",
        "stateID": 29
    },
    {
        "label": "Tirukalukundram",
        "value": "Tirukalukundram",
        "stateID": 29
    },
    {
        "label": "Tirukkoyilur",
        "value": "Tirukkoyilur",
        "stateID": 29
    },
    {
        "label": "Tirunelveli",
        "value": "Tirunelveli",
        "stateID": 29
    },
    {
        "label": "Tirupathur",
        "value": "Tirupathur",
        "stateID": 29
    },
    {
        "label": "Tirupathur",
        "value": "Tirupathur",
        "stateID": 29
    },
    {
        "label": "Tiruppur",
        "value": "Tiruppur",
        "stateID": 29
    },
    {
        "label": "Tiruttani",
        "value": "Tiruttani",
        "stateID": 29
    },
    {
        "label": "Tiruvannamalai",
        "value": "Tiruvannamalai",
        "stateID": 29
    },
    {
        "label": "Tiruvethipuram",
        "value": "Tiruvethipuram",
        "stateID": 29
    },
    {
        "label": "Tittakudi",
        "value": "Tittakudi",
        "stateID": 29
    },
    {
        "label": "Udhagamandalam",
        "value": "Udhagamandalam",
        "stateID": 29
    },
    {
        "label": "Udumalaipettai",
        "value": "Udumalaipettai",
        "stateID": 29
    },
    {
        "label": "Unnamalaikadai",
        "value": "Unnamalaikadai",
        "stateID": 29
    },
    {
        "label": "Usilampatti",
        "value": "Usilampatti",
        "stateID": 29
    },
    {
        "label": "Uthamapalayam",
        "value": "Uthamapalayam",
        "stateID": 29
    },
    {
        "label": "Uthiramerur",
        "value": "Uthiramerur",
        "stateID": 29
    },
    {
        "label": "Vadakkuvalliyur",
        "value": "Vadakkuvalliyur",
        "stateID": 29
    },
    {
        "label": "Vadalur",
        "value": "Vadalur",
        "stateID": 29
    },
    {
        "label": "Vadipatti",
        "value": "Vadipatti",
        "stateID": 29
    },
    {
        "label": "Valparai",
        "value": "Valparai",
        "stateID": 29
    },
    {
        "label": "Vandavasi",
        "value": "Vandavasi",
        "stateID": 29
    },
    {
        "label": "Vaniyambadi",
        "value": "Vaniyambadi",
        "stateID": 29
    },
    {
        "label": "Vedaranyam",
        "value": "Vedaranyam",
        "stateID": 29
    },
    {
        "label": "Vellakoil",
        "value": "Vellakoil",
        "stateID": 29
    },
    {
        "label": "Vellore",
        "value": "Vellore",
        "stateID": 29
    },
    {
        "label": "Vikramasingapuram",
        "value": "Vikramasingapuram",
        "stateID": 29
    },
    {
        "label": "Viluppuram",
        "value": "Viluppuram",
        "stateID": 29
    },
    {
        "label": "Virudhachalam",
        "value": "Virudhachalam",
        "stateID": 29
    },
    {
        "label": "Virudhunagar",
        "value": "Virudhunagar",
        "stateID": 29
    },
    {
        "label": "Viswanatham",
        "value": "Viswanatham",
        "stateID": 29
    },
    {
        "label": "Adilabad",
        "value": "Adilabad",
        "stateID": 30
    },
    {
        "label": "Bellampalle",
        "value": "Bellampalle",
        "stateID": 30
    },
    {
        "label": "Bhadrachalam",
        "value": "Bhadrachalam",
        "stateID": 30
    },
    {
        "label": "Bhainsa",
        "value": "Bhainsa",
        "stateID": 30
    },
    {
        "label": "Bhongir",
        "value": "Bhongir",
        "stateID": 30
    },
    {
        "label": "Bodhan",
        "value": "Bodhan",
        "stateID": 30
    },
    {
        "label": "Farooqnagar",
        "value": "Farooqnagar",
        "stateID": 30
    },
    {
        "label": "Gadwal",
        "value": "Gadwal",
        "stateID": 30
    },
    {
        "label": "Hyderabad",
        "value": "Hyderabad",
        "stateID": 30
    },
    {
        "label": "Jagtial",
        "value": "Jagtial",
        "stateID": 30
    },
    {
        "label": "Jangaon",
        "value": "Jangaon",
        "stateID": 30
    },
    {
        "label": "Kagaznagar",
        "value": "Kagaznagar",
        "stateID": 30
    },
    {
        "label": "Kamareddy",
        "value": "Kamareddy",
        "stateID": 30
    },
    {
        "label": "Karimnagar",
        "value": "Karimnagar",
        "stateID": 30
    },
    {
        "label": "Khammam",
        "value": "Khammam",
        "stateID": 30
    },
    {
        "label": "Koratla",
        "value": "Koratla",
        "stateID": 30
    },
    {
        "label": "Kothagudem",
        "value": "Kothagudem",
        "stateID": 30
    },
    {
        "label": "Kyathampalle",
        "value": "Kyathampalle",
        "stateID": 30
    },
    {
        "label": "Mahbubnagar",
        "value": "Mahbubnagar",
        "stateID": 30
    },
    {
        "label": "Mancherial",
        "value": "Mancherial",
        "stateID": 30
    },
    {
        "label": "Mandamarri",
        "value": "Mandamarri",
        "stateID": 30
    },
    {
        "label": "Manuguru",
        "value": "Manuguru",
        "stateID": 30
    },
    {
        "label": "Medak",
        "value": "Medak",
        "stateID": 30
    },
    {
        "label": "Miryalaguda",
        "value": "Miryalaguda",
        "stateID": 30
    },
    {
        "label": "Nagarkurnool",
        "value": "Nagarkurnool",
        "stateID": 30
    },
    {
        "label": "Narayanpet",
        "value": "Narayanpet",
        "stateID": 30
    },
    {
        "label": "Nirmal",
        "value": "Nirmal",
        "stateID": 30
    },
    {
        "label": "Nizamabad",
        "value": "Nizamabad",
        "stateID": 30
    },
    {
        "label": "Palwancha",
        "value": "Palwancha",
        "stateID": 30
    },
    {
        "label": "Ramagundam",
        "value": "Ramagundam",
        "stateID": 30
    },
    {
        "label": "Sadasivpet",
        "value": "Sadasivpet",
        "stateID": 30
    },
    {
        "label": "Sangareddy",
        "value": "Sangareddy",
        "stateID": 30
    },
    {
        "label": "Siddipet",
        "value": "Siddipet",
        "stateID": 30
    },
    {
        "label": "Sircilla",
        "value": "Sircilla",
        "stateID": 30
    },
    {
        "label": "Suryapet",
        "value": "Suryapet",
        "stateID": 30
    },
    {
        "label": "Tandur",
        "value": "Tandur",
        "stateID": 30
    },
    {
        "label": "Vikarabad",
        "value": "Vikarabad",
        "stateID": 30
    },
    {
        "label": "Wanaparthy",
        "value": "Wanaparthy",
        "stateID": 30
    },
    {
        "label": "Warangal",
        "value": "Warangal",
        "stateID": 30
    },
    {
        "label": "Yellandu",
        "value": "Yellandu",
        "stateID": 30
    },
    {
        "label": "Agartala",
        "value": "Agartala",
        "stateID": 31
    },
    {
        "label": "Belonia",
        "value": "Belonia",
        "stateID": 31
    },
    {
        "label": "Dharmanagar",
        "value": "Dharmanagar",
        "stateID": 31
    },
    {
        "label": "Kailasahar",
        "value": "Kailasahar",
        "stateID": 31
    },
    {
        "label": "Khowai",
        "value": "Khowai",
        "stateID": 31
    },
    {
        "label": "Pratapgarh",
        "value": "Pratapgarh",
        "stateID": 31
    },
    {
        "label": "Udaipur",
        "value": "Udaipur",
        "stateID": 31
    },
    {
        "label": "Achhnera",
        "value": "Achhnera",
        "stateID": 32
    },
    {
        "label": "Agra",
        "value": "Agra",
        "stateID": 32
    },
    {
        "label": "Aligarh",
        "value": "Aligarh",
        "stateID": 32
    },
    {
        "label": "Allahabad",
        "value": "Allahabad",
        "stateID": 32
    },
    {
        "label": "Amroha",
        "value": "Amroha",
        "stateID": 32
    },
    {
        "label": "Azamgarh",
        "value": "Azamgarh",
        "stateID": 32
    },
    {
        "label": "Bahraich",
        "value": "Bahraich",
        "stateID": 32
    },
    {
        "label": "Chandausi",
        "value": "Chandausi",
        "stateID": 32
    },
    {
        "label": "Etawah",
        "value": "Etawah",
        "stateID": 32
    },
    {
        "label": "Fatehpur Sikri",
        "value": "Fatehpur Sikri",
        "stateID": 32
    },
    {
        "label": "Firozabad",
        "value": "Firozabad",
        "stateID": 32
    },
    {
        "label": "Hapur",
        "value": "Hapur",
        "stateID": 32
    },
    {
        "label": "Hardoi ",
        "value": "Hardoi ",
        "stateID": 32
    },
    {
        "label": "Jhansi",
        "value": "Jhansi",
        "stateID": 32
    },
    {
        "label": "Kalpi",
        "value": "Kalpi",
        "stateID": 32
    },
    {
        "label": "Kanpur",
        "value": "Kanpur",
        "stateID": 32
    },
    {
        "label": "Khair",
        "value": "Khair",
        "stateID": 32
    },
    {
        "label": "Laharpur",
        "value": "Laharpur",
        "stateID": 32
    },
    {
        "label": "Lakhimpur",
        "value": "Lakhimpur",
        "stateID": 32
    },
    {
        "label": "Lal Gopalganj Nindaura",
        "value": "Lal Gopalganj Nindaura",
        "stateID": 32
    },
    {
        "label": "Lalganj",
        "value": "Lalganj",
        "stateID": 32
    },
    {
        "label": "Lalitpur",
        "value": "Lalitpur",
        "stateID": 32
    },
    {
        "label": "Lar",
        "value": "Lar",
        "stateID": 32
    },
    {
        "label": "Loni",
        "value": "Loni",
        "stateID": 32
    },
    {
        "label": "Lucknow",
        "value": "Lucknow",
        "stateID": 32
    },
    {
        "label": "Mathura",
        "value": "Mathura",
        "stateID": 32
    },
    {
        "label": "Meerut",
        "value": "Meerut",
        "stateID": 32
    },
    {
        "label": "Mirzapur",
        "value": "Mirzapur",
        "stateID": 32
    },
    {
        "label": "Modinagar",
        "value": "Modinagar",
        "stateID": 32
    },
    {
        "label": "Moradabad",
        "value": "Moradabad",
        "stateID": 32
    },
    {
        "label": "Nagina",
        "value": "Nagina",
        "stateID": 32
    },
    {
        "label": "Najibabad",
        "value": "Najibabad",
        "stateID": 32
    },
    {
        "label": "Nakur",
        "value": "Nakur",
        "stateID": 32
    },
    {
        "label": "Nanpara",
        "value": "Nanpara",
        "stateID": 32
    },
    {
        "label": "Naraura",
        "value": "Naraura",
        "stateID": 32
    },
    {
        "label": "Naugawan Sadat",
        "value": "Naugawan Sadat",
        "stateID": 32
    },
    {
        "label": "Nautanwa",
        "value": "Nautanwa",
        "stateID": 32
    },
    {
        "label": "Nawabganj",
        "value": "Nawabganj",
        "stateID": 32
    },
    {
        "label": "Nehtaur",
        "value": "Nehtaur",
        "stateID": 32
    },
    {
        "label": "Niwai",
        "value": "Niwai",
        "stateID": 32
    },
    {
        "label": "Noida",
        "value": "Noida",
        "stateID": 32
    },
    {
        "label": "Noorpur",
        "value": "Noorpur",
        "stateID": 32
    },
    {
        "label": "Obra",
        "value": "Obra",
        "stateID": 32
    },
    {
        "label": "Orai",
        "value": "Orai",
        "stateID": 32
    },
    {
        "label": "Padrauna",
        "value": "Padrauna",
        "stateID": 32
    },
    {
        "label": "Palia Kalan",
        "value": "Palia Kalan",
        "stateID": 32
    },
    {
        "label": "Parasi",
        "value": "Parasi",
        "stateID": 32
    },
    {
        "label": "Phulpur",
        "value": "Phulpur",
        "stateID": 32
    },
    {
        "label": "Pihani",
        "value": "Pihani",
        "stateID": 32
    },
    {
        "label": "Pilibhit",
        "value": "Pilibhit",
        "stateID": 32
    },
    {
        "label": "Pilkhuwa",
        "value": "Pilkhuwa",
        "stateID": 32
    },
    {
        "label": "Powayan",
        "value": "Powayan",
        "stateID": 32
    },
    {
        "label": "Pukhrayan",
        "value": "Pukhrayan",
        "stateID": 32
    },
    {
        "label": "Puranpur",
        "value": "Puranpur",
        "stateID": 32
    },
    {
        "label": "PurqUrban Agglomerationzi",
        "value": "PurqUrban Agglomerationzi",
        "stateID": 32
    },
    {
        "label": "Purwa",
        "value": "Purwa",
        "stateID": 32
    },
    {
        "label": "Rae Bareli",
        "value": "Rae Bareli",
        "stateID": 32
    },
    {
        "label": "Rampur",
        "value": "Rampur",
        "stateID": 32
    },
    {
        "label": "Rampur Maniharan",
        "value": "Rampur Maniharan",
        "stateID": 32
    },
    {
        "label": "Rasra",
        "value": "Rasra",
        "stateID": 32
    },
    {
        "label": "Rath",
        "value": "Rath",
        "stateID": 32
    },
    {
        "label": "Renukoot",
        "value": "Renukoot",
        "stateID": 32
    },
    {
        "label": "Reoti",
        "value": "Reoti",
        "stateID": 32
    },
    {
        "label": "Robertsganj",
        "value": "Robertsganj",
        "stateID": 32
    },
    {
        "label": "Rudauli",
        "value": "Rudauli",
        "stateID": 32
    },
    {
        "label": "Rudrapur",
        "value": "Rudrapur",
        "stateID": 32
    },
    {
        "label": "Sadabad",
        "value": "Sadabad",
        "stateID": 32
    },
    {
        "label": "Safipur",
        "value": "Safipur",
        "stateID": 32
    },
    {
        "label": "Saharanpur",
        "value": "Saharanpur",
        "stateID": 32
    },
    {
        "label": "Sahaspur",
        "value": "Sahaspur",
        "stateID": 32
    },
    {
        "label": "Sahaswan",
        "value": "Sahaswan",
        "stateID": 32
    },
    {
        "label": "Sahawar",
        "value": "Sahawar",
        "stateID": 32
    },
    {
        "label": "Sahjanwa",
        "value": "Sahjanwa",
        "stateID": 32
    },
    {
        "label": "Saidpur",
        "value": "Saidpur",
        "stateID": 32
    },
    {
        "label": "Sambhal",
        "value": "Sambhal",
        "stateID": 32
    },
    {
        "label": "Samdhan",
        "value": "Samdhan",
        "stateID": 32
    },
    {
        "label": "Samthar",
        "value": "Samthar",
        "stateID": 32
    },
    {
        "label": "Sandi",
        "value": "Sandi",
        "stateID": 32
    },
    {
        "label": "Sandila",
        "value": "Sandila",
        "stateID": 32
    },
    {
        "label": "Sardhana",
        "value": "Sardhana",
        "stateID": 32
    },
    {
        "label": "Seohara",
        "value": "Seohara",
        "stateID": 32
    },
    {
        "label": "Shahabad, Hardoi",
        "value": "Shahabad, Hardoi",
        "stateID": 32
    },
    {
        "label": "Shahabad, Rampur",
        "value": "Shahabad, Rampur",
        "stateID": 32
    },
    {
        "label": "Shahganj",
        "value": "Shahganj",
        "stateID": 32
    },
    {
        "label": "Shahjahanpur",
        "value": "Shahjahanpur",
        "stateID": 32
    },
    {
        "label": "Shamli",
        "value": "Shamli",
        "stateID": 32
    },
    {
        "label": "Shamsabad, Agra",
        "value": "Shamsabad, Agra",
        "stateID": 32
    },
    {
        "label": "Shamsabad, Farrukhabad",
        "value": "Shamsabad, Farrukhabad",
        "stateID": 32
    },
    {
        "label": "Sherkot",
        "value": "Sherkot",
        "stateID": 32
    },
    {
        "label": "Shikarpur, Bulandshahr",
        "value": "Shikarpur, Bulandshahr",
        "stateID": 32
    },
    {
        "label": "Shikohabad",
        "value": "Shikohabad",
        "stateID": 32
    },
    {
        "label": "Shishgarh",
        "value": "Shishgarh",
        "stateID": 32
    },
    {
        "label": "Siana",
        "value": "Siana",
        "stateID": 32
    },
    {
        "label": "Sikanderpur",
        "value": "Sikanderpur",
        "stateID": 32
    },
    {
        "label": "Sikandra Rao",
        "value": "Sikandra Rao",
        "stateID": 32
    },
    {
        "label": "Sikandrabad",
        "value": "Sikandrabad",
        "stateID": 32
    },
    {
        "label": "Sirsaganj",
        "value": "Sirsaganj",
        "stateID": 32
    },
    {
        "label": "Sirsi",
        "value": "Sirsi",
        "stateID": 32
    },
    {
        "label": "Sitapur",
        "value": "Sitapur",
        "stateID": 32
    },
    {
        "label": "Soron",
        "value": "Soron",
        "stateID": 32
    },
    {
        "label": "Sultanpur",
        "value": "Sultanpur",
        "stateID": 32
    },
    {
        "label": "Sumerpur",
        "value": "Sumerpur",
        "stateID": 32
    },
    {
        "label": "SUrban Agglomerationr",
        "value": "SUrban Agglomerationr",
        "stateID": 32
    },
    {
        "label": "Tanda",
        "value": "Tanda",
        "stateID": 32
    },
    {
        "label": "Thakurdwara",
        "value": "Thakurdwara",
        "stateID": 32
    },
    {
        "label": "Thana Bhawan",
        "value": "Thana Bhawan",
        "stateID": 32
    },
    {
        "label": "Tilhar",
        "value": "Tilhar",
        "stateID": 32
    },
    {
        "label": "Tirwaganj",
        "value": "Tirwaganj",
        "stateID": 32
    },
    {
        "label": "Tulsipur",
        "value": "Tulsipur",
        "stateID": 32
    },
    {
        "label": "Tundla",
        "value": "Tundla",
        "stateID": 32
    },
    {
        "label": "Ujhani",
        "value": "Ujhani",
        "stateID": 32
    },
    {
        "label": "Unnao",
        "value": "Unnao",
        "stateID": 32
    },
    {
        "label": "Utraula",
        "value": "Utraula",
        "stateID": 32
    },
    {
        "label": "Varanasi",
        "value": "Varanasi",
        "stateID": 32
    },
    {
        "label": "Vrindavan",
        "value": "Vrindavan",
        "stateID": 32
    },
    {
        "label": "Warhapur",
        "value": "Warhapur",
        "stateID": 32
    },
    {
        "label": "Zaidpur",
        "value": "Zaidpur",
        "stateID": 32
    },
    {
        "label": "Zamania",
        "value": "Zamania",
        "stateID": 32
    },
    {
        "label": "Bageshwar",
        "value": "Bageshwar",
        "stateID": 33
    },
    {
        "label": "Dehradun",
        "value": "Dehradun",
        "stateID": 33
    },
    {
        "label": "Haldwani-cum-Kathgodam",
        "value": "Haldwani-cum-Kathgodam",
        "stateID": 33
    },
    {
        "label": "Hardwar",
        "value": "Hardwar",
        "stateID": 33
    },
    {
        "label": "Kashipur",
        "value": "Kashipur",
        "stateID": 33
    },
    {
        "label": "Manglaur",
        "value": "Manglaur",
        "stateID": 33
    },
    {
        "label": "Mussoorie",
        "value": "Mussoorie",
        "stateID": 33
    },
    {
        "label": "Nagla",
        "value": "Nagla",
        "stateID": 33
    },
    {
        "label": "Nainital",
        "value": "Nainital",
        "stateID": 33
    },
    {
        "label": "Pauri",
        "value": "Pauri",
        "stateID": 33
    },
    {
        "label": "Pithoragarh",
        "value": "Pithoragarh",
        "stateID": 33
    },
    {
        "label": "Ramnagar",
        "value": "Ramnagar",
        "stateID": 33
    },
    {
        "label": "Rishikesh",
        "value": "Rishikesh",
        "stateID": 33
    },
    {
        "label": "Roorkee",
        "value": "Roorkee",
        "stateID": 33
    },
    {
        "label": "Rudrapur",
        "value": "Rudrapur",
        "stateID": 33
    },
    {
        "label": "Sitarganj",
        "value": "Sitarganj",
        "stateID": 33
    },
    {
        "label": "Srinagar",
        "value": "Srinagar",
        "stateID": 33
    },
    {
        "label": "Tehri",
        "value": "Tehri",
        "stateID": 33
    },
    {
        "label": "Adra",
        "value": "Adra",
        "stateID": 34
    },
    {
        "label": "AlipurdUrban Agglomerationr",
        "value": "AlipurdUrban Agglomerationr",
        "stateID": 34
    },
    {
        "label": "Arambagh",
        "value": "Arambagh",
        "stateID": 34
    },
    {
        "label": "Asansol",
        "value": "Asansol",
        "stateID": 34
    },
    {
        "label": "Baharampur",
        "value": "Baharampur",
        "stateID": 34
    },
    {
        "label": "Balurghat",
        "value": "Balurghat",
        "stateID": 34
    },
    {
        "label": "Bankura",
        "value": "Bankura",
        "stateID": 34
    },
    {
        "label": "Darjiling",
        "value": "Darjiling",
        "stateID": 34
    },
    {
        "label": "English Bazar",
        "value": "English Bazar",
        "stateID": 34
    },
    {
        "label": "Gangarampur",
        "value": "Gangarampur",
        "stateID": 34
    },
    {
        "label": "Habra",
        "value": "Habra",
        "stateID": 34
    },
    {
        "label": "Hugli-Chinsurah",
        "value": "Hugli-Chinsurah",
        "stateID": 34
    },
    {
        "label": "Jalpaiguri",
        "value": "Jalpaiguri",
        "stateID": 34
    },
    {
        "label": "Jhargram",
        "value": "Jhargram",
        "stateID": 34
    },
    {
        "label": "Kalimpong",
        "value": "Kalimpong",
        "stateID": 34
    },
    {
        "label": "Kharagpur",
        "value": "Kharagpur",
        "stateID": 34
    },
    {
        "label": "Kolkata",
        "value": "Kolkata",
        "stateID": 34
    },
    {
        "label": "Mainaguri",
        "value": "Mainaguri",
        "stateID": 34
    },
    {
        "label": "Malda",
        "value": "Malda",
        "stateID": 34
    },
    {
        "label": "Mathabhanga",
        "value": "Mathabhanga",
        "stateID": 34
    },
    {
        "label": "Medinipur",
        "value": "Medinipur",
        "stateID": 34
    },
    {
        "label": "Memari",
        "value": "Memari",
        "stateID": 34
    },
    {
        "label": "Monoharpur",
        "value": "Monoharpur",
        "stateID": 34
    },
    {
        "label": "Murshidabad",
        "value": "Murshidabad",
        "stateID": 34
    },
    {
        "label": "Nabadwip",
        "value": "Nabadwip",
        "stateID": 34
    },
    {
        "label": "Naihati",
        "value": "Naihati",
        "stateID": 34
    },
    {
        "label": "Panchla",
        "value": "Panchla",
        "stateID": 34
    },
    {
        "label": "PandUrban Agglomeration",
        "value": "PandUrban Agglomeration",
        "stateID": 34
    },
    {
        "label": "Paschim Punropara",
        "value": "Paschim Punropara",
        "stateID": 34
    },
    {
        "label": "Purulia",
        "value": "Purulia",
        "stateID": 34
    },
    {
        "label": "Raghunathganj",
        "value": "Raghunathganj",
        "stateID": 34
    },
    {
        "label": "Raghunathpur",
        "value": "Raghunathpur",
        "stateID": 34
    },
    {
        "label": "Raiganj",
        "value": "Raiganj",
        "stateID": 34
    },
    {
        "label": "Rampurhat",
        "value": "Rampurhat",
        "stateID": 34
    },
    {
        "label": "Ranaghat",
        "value": "Ranaghat",
        "stateID": 34
    },
    {
        "label": "Sainthia",
        "value": "Sainthia",
        "stateID": 34
    },
    {
        "label": "Santipur",
        "value": "Santipur",
        "stateID": 34
    },
    {
        "label": "Siliguri",
        "value": "Siliguri",
        "stateID": 34
    },
    {
        "label": "Sonamukhi",
        "value": "Sonamukhi",
        "stateID": 34
    },
    {
        "label": "Srirampore",
        "value": "Srirampore",
        "stateID": 34
    },
    {
        "label": "Suri",
        "value": "Suri",
        "stateID": 34
    },
    {
        "label": "Taki",
        "value": "Taki",
        "stateID": 34
    },
    {
        "label": "Tamluk",
        "value": "Tamluk",
        "stateID": 34
    },
    {
        "label": "Tarakeswar",
        "value": "Tarakeswar",
        "stateID": 34
    }
]