import React, { useEffect, useState } from 'react'
import atoms from '../../../atoms'
import molecules from '../../../molecules'
import { IPermissionList, IPermissionCreate, IPermissionData } from './interface'
import service from '../../../../api/services'
import { useToasts } from 'react-toast-notifications'
import { FiEdit } from 'react-icons/fi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import ConfirmationModal from '../../../molecules/ConfirmationModal'
import { getDropdown } from '../../../../constants/utils'
import constant from '../../../../constants/constant'
const { Button, Input, Table, Select } = atoms
const { CreateModal } = molecules
const Index = ({ permissionList, getPermissionList, handleDeleteModal, setHandleDeleteModal, _handleDelete, handleDeletePermission }: any) => {
    const [codeError, setCodeError] = useState<boolean>(false)
    const [permission, setPermission] = useState<IPermissionCreate>({
        module_name: '',
        perm_code: '',
        description: '',
        parent_permission_id: '',
        is_store_permission: null

    })
    const [permissionModal, setPermissionModal] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const { addToast } = useToasts();

    const columns = [
        { name: 'Module Name', selector: (row: IPermissionData) => row.module_name },
        { name: 'Permission Code', selector: (row: IPermissionData) => row.perm_code, maxWidth: '20%' },
        { name: 'Description', selector: (row: IPermissionData) => row.description, minWidth: '40%' },
        { name: 'Action', selector: (row: any) => row.action, },
    ]
    const handleSubmit = async () => {
        setError(true)
        // const isEmpty = Object.values(permission).some(item => { return !item })
        const payload = {
            module_name: permission.module_name,
            perm_code: permission.perm_code,
            description: permission.description,
            parent_permission_id: permission.parent_permission_id || null,
            is_store_permission: permission.is_store_permission
        }
        // if (!error && !codeError) {
        try {
            const response = await service.permission.createPermission(payload)
            if (response.status == 201) {
                setPermissionModal(false)
                setError(false)
                getPermissionList()
                addToast(response.data.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log(error)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        setPermission({
            module_name: '',
            perm_code: '',
            description: '',
            parent_permission_id: '',
            is_store_permission: null,

        })
        // }
    }
    const _handleClose = () => {
        setPermissionModal(!permissionModal)
        setPermission({
            module_name: '',
            perm_code: '',
            description: '',
            parent_permission_id: '',
            is_store_permission: null
        })
    }
    const _handleCode = (code: string) => {
        if (code.length === 5) {
            setCodeError(false)
            setPermission({ ...permission, perm_code: code.toUpperCase() })
        } else {
            setCodeError(true)
        }
    }
    return (
        <>
            <div className="row">
                <div className="text-end">
                    <Button name={"Add Permissions"}
                        onClick={() => setPermissionModal(true)}
                    />
                </div>
            </div>
            <div >
                <Table columns={columns}
                    paginationTotalRows={permissionList?.count}
                    data={permissionList?.data?.map((item: IPermissionData, index: number) => {
                        return ({
                            ...item,
                            action:
                                <Button className="table-delete-button fs-14 fw-400 ms-3"
                                    onClick={() => _handleDelete(item.id)}>
                                    <div className='d-flex align-items-center'>
                                        <RiDeleteBin6Line />
                                        <span className='ms-2 mb-0'>Delete</span>
                                    </div>
                                </Button>
                        })
                    })}
                />
            </div>
            {permissionModal && <CreateModal
                show={permissionModal}
                header={`Create Permissions`}
                handleSubmit={handleSubmit}
                submitBtnText={'Create Permissions'}
                onHide={() => _handleClose()}
            >
                <div>
                    <form>
                        <div className="row">
                            <div className="col-6 mt-25">
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Module Name"
                                    onChange={(e: any) => setPermission({ ...permission, module_name: e.target.value })}
                                    defaultValue={permission.module_name}
                                    label='Module Name'
                                />
                                {(error && (permission.module_name.length === 0)) && <span className="error-text">This field is required.</span>}

                            </div>
                            <div className="col-6 mt-25">
                                <Input
                                    type="text"
                                    className="form-control text-uppercase"
                                    placeholder="XYZ01"
                                    onChange={(e: any) => _handleCode(e.target.value)}
                                    defaultValue={permission.perm_code}
                                    label='Permission Code '
                                />
                                {(error && (permission.perm_code.length === 0)) && <span className="error-text">This field is required.</span>}
                                {codeError && <span className="error-text">Code Can Only Have 5 Characters</span>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-25">
                                <Select label='Select Parent permission'
                                    options={getDropdown(permissionList?.data, 'module_name')}
                                    className={"w-100"}
                                    placeholder={"Select Parent "}
                                    onChange={(item: any) => setPermission({ ...permission, parent_permission_id: item.value })}
                                // value={storeDetails.isActive}
                                />
                                {/* {errors.isActive && !storeDetails.isActive && <span className="error-text">{errors.isActive}</span>} */}

                                {(error && (permission.description.length === 0)) && <span className="error-text">This field is required.</span>}

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-25">
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Description"
                                    onChange={(e: any) => setPermission({ ...permission, description: e.target.value })}
                                    defaultValue={permission.description}
                                    label='Description'
                                />
                                {(error && (permission.description.length === 0)) && <span className="error-text">This field is required.</span>}

                            </div>

                        </div>
                        {/* add to identify store permission or specxweb permission */}
                        <div className="row">
                            <div className="col-12 mt-25">
                                <Select label='Select Permissions Granting'
                                    options={constant?.PermissionsGranting}
                                    className={"w-100"}
                                    placeholder={"Select Permission Granting"}
                                    onChange={(item: any) => setPermission({ ...permission, is_store_permission: item.value })}
                                />
                                {(error && (permission.description.length === 0)) && <span className="error-text">This field is required.</span>}
                            </div>

                        </div>

                    </form>
                </div>
            </CreateModal>}
            {handleDeleteModal && <ConfirmationModal
                show={handleDeleteModal}
                handleConfirmItem={() => handleDeletePermission()}
                onHide={() => setHandleDeleteModal(!handleDeleteModal)}
                message={'delete this Permission'} />}
        </>
    )
}

export default Index