import React, { useContext, useEffect, useState } from 'react'
import organisms from '../../../../components/organisms'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import service from '../../../../api/services'
import { HeaderHeading } from '../../../../Context'
const { SubAdmin } = organisms
const Index = () => {
    const [subAdminListing, setSubAdminListing] = useState<any>()
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [search, setSearch] = useState('');
    const [pageCount, setPageCount] = useState<number>(1);
    const [limit, setLimit] = useState(Default_Page_Limit);
    const { setHeader,setSidebar }: any = useContext(HeaderHeading);
    const getStoreListing = async () => {
        try {
            const response = await service.subAdmin.getSubAdminService({ search: search, page: pageCount, limit: limit, });
            setSubAdminListing(response.data)
        } catch (error) {

        }
    }
    useEffect(() => {
        setHeader("Setting - Sub Admin")
        setSidebar(false)
        getStoreListing()
    }, [search, pageCount])

    useEffect(()=>{
        setPageCount(1)
        if(pageCount==1 && initialLoading){
            getStoreListing()
        }
        setInitialLoading(true)
    },[limit])

    const _handleDelete=async(id:string)=>{
        try {
            const response = await service.subAdmin.deleteSubAdmin(id)
            if(response.status==200){
                getStoreListing()
            }
        } catch (error) {

        }
    }

    return (
        <Wrapper>
            <SubAdmin
                subAdminListing={subAdminListing}
                _handleDelete={(id:string)=>_handleDelete(id)}
                setLimitPerPage={setLimit}
                setCurrentPage={setPageCount} setSearch={setSearch}    currentPage={pageCount} limit={limit}/>
        </Wrapper>
    )
}

export default Index