import React, { useContext, useEffect, useState } from 'react';
import organisms from '../../../components/organisms'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import service from '../../../api/services';
import { useToasts } from 'react-toast-notifications';
import { Default_Page_Limit, Default_Page_Number } from '../../../constants/defaultValues';
import { HeaderHeading } from '../../../Context'
const { Warranty } = organisms
const Index = () => {
    const [warrantyData, setWarrantyData] = useState<any>([])
    const [warrantDetail, setWarrantyDetail]= useState<any>()
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [search, setSearch] = useState('');
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();



    const getWarrantyData = async () => {
        try {
            let response = await service.warrantyService.warrantyList({ "page": currentPage, "search": search, "limit": limitPerPage })
            if (response.status === 200) {
                setWarrantyData(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getWarrantyProductDetail = async(id: string) => {
        try {
            let response = await service.warrantyService?.warrantyDetail(id)
            if (response.status === 200) {
                setWarrantyDetail(response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const warrantApproveReject = async(id:string,warrantyStatus:number ) => {
        try {
            let response = await service.warrantyService?.warrantyApproveReject(id, {status:warrantyStatus})
            if (response.status === 200) {
                getWarrantyData()
            }
        } catch (error:any) {
            console.log(error)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    useEffect(() => {
        setHeader("Warrantee")
        setSidebar(false)
        getWarrantyData()
    }, [currentPage])

    useEffect(() => {
        setCurrentPage(1)
        if (currentPage == 1 && initialLoading) {
            getWarrantyData()
        }
        setInitialLoading(true)
    }, [limitPerPage,search])


    return (
        <Wrapper>
            <Warranty
                getWarrantyProductDetail={(id: string) => getWarrantyProductDetail(id)}
                warrantApproveReject={(id:string,warrantyStatus:number)=>warrantApproveReject(id,warrantyStatus)}
                warrantDetail={warrantDetail}
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                setSearch={setSearch}
                warrantyData={warrantyData}
                currentPage={currentPage}
                limit={limitPerPage}
            />
        </Wrapper>
    )
}
export default Index