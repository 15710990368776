import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { allocation } = Dictionary

const brandAllocation = {
  add: async (data: {}) => {
    return axios.post(BASE_URL + allocation.allocation(), data)
  },
  view: async (paramsObj: any) => {
    return axios.get(BASE_URL + allocation.storeAllocation(), {
      params: paramsObj,
    })
  },
  getDetailById: async (id: string) => {
    return axios.get(BASE_URL + allocation.storeById(id))
  },
  getBrandDetailById: async (id: string) => {
    return axios.get(BASE_URL + allocation.brandByStoreId(id))
  },
   getAllStore: async () => {
    return axios.get(BASE_URL + allocation.allStoreAllocation())
  },
  deleteBrandDetailById: async (storeId: string, brandId: string) => {
    return axios.delete( BASE_URL + allocation.brandEditByStoreId(storeId, brandId))
  },
  editBrandDetailById: async (storeId: string, brandId: string, data: {}) => {
    return axios.put(
      BASE_URL + allocation.brandEditByStoreId(storeId, brandId),
      data
    )
  },
  edit: async (id: string, data: {}) => {
    return axios.put(BASE_URL + allocation.storeById(id), data)
  },
}
export default brandAllocation
