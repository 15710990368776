import React from "react";
import molecules from "../../../molecules";
import atoms from "../../../atoms";
import moment from "moment";

const { Table, Pagination } = atoms

const Index = ({ retailers, retailerDetail, getRetailerDetail,currentPage,setCurrentPage,setLimitPerPage,limitPerPage }: any) => {

    const columns = [
        { name: 'Store Name', selector: (row: any) => row?.store_name ? row?.store_name : "-", sortable: true },
        { name: 'Store Address', selector: (row: any) => row?.store_address ? row?.store_address : "-" },
        { name: 'Contact Person Name', selector: (row: any) => row?.contact_person_name ? row?.contact_person_name : "-" },
        { name: 'Position', selector: (row: any) => `${row?.position ? row.position : "-"}` },
        { name: 'Contact Email', selector: (row: any) => `${row?.contact_email ? row?.contact_email : "-"}` },
        { name: 'Contact Phone', selector: (row: any) => row?.contact_phone ? row?.contact_phone : "-" },
        { name: 'Operating Years', selector: (row: any) => row?.operating_years ? row?.operating_years : "-" },
        { name: 'number of Outlets', selector: (row: any) => row?.num_of_outlets ? row?.num_of_outlets :"-" },
        { name: 'Featured Brands', selector: (row: any) => row?.featured_brands ? row?.featured_brands : "-" },
        { name: 'Date', selector: (row: any) => row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "-"},
        // { name: 'Action', selector: (row: any) => row?.narration },
    ]

    return (
        <>
            {(retailers?.data && retailers?.data?.length > 0) ? <div>
                <Table
                    className="adjustable-row-height"
                    columns={columns}
                    data={retailers?.data?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            action: <>
                            </>,
                        })
                    })}
                />
            </div> : <div className='justify-content-center d-flex mt-5 '>There are no records to display</div>}
            <Pagination
                    totalCount={retailers?.count}
                    currentPage={currentPage}
                    totalPages={retailers?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limitPerPage}
                />
        </>
    )
}

export default Index