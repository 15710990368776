import React from 'react'
import atoms from '../../../atoms'
import moment from 'moment'
import './Store.scss'
import { Link, useNavigate } from 'react-router-dom'
import constant from '../../../../constants/constant'
import { FiEdit } from "react-icons/fi";
import ThreeDots from '../../../../assets/Images/threeDots.svg'
import { AiOutlinePoweroff } from 'react-icons/ai'
import { AllPermissions } from '../../../../constants/permissions'
import { checkPermissionsInArray, getGstTypeName, findConstantLabel } from '../../../../constants/utils'

const { Search, Button, Table, Image, Pagination } = atoms
const Index = ({ setSearch, storeListing, setLimitPerPage, setCurrentPage, handleStatus, currentPage, limit }: any) => {
    const navigate = useNavigate()
    const columns = [
        { name: 'Store ID', selector: (row: any) => row.store_id_display, sortable: true, },
        { name: 'Store Name', selector: (row: any) => row.store_name ? row.store_name : '-', sortable: true, minWidth: "150px" },
        { name: 'Store Incharge', selector: (row: any) => row?.manager_name || '-', sortable: true, },
        { name: 'Incharge Contact No.', selector: (row: any) => row?.manager_mobile || '-', },
        { name: 'Created Date', selector: (row: any) => moment().format('DD/MM/YYYY'), sortable: true, },
        { name: 'Deposit Amount', selector: (row: any) => row?.store_accounts[0]?.balance, sortable: true, },
        { name: 'Credit Amount', selector: (row: any) => row?.credit, sortable: true, },
        { name: 'Additional Charges', selector: (row: any) => row?.additional_charge, sortable: true, },
        { name: 'GST Type', selector: (row: any) => getGstTypeName(constant.GST_DETAILS, row?.gst_type) },

        { name: 'Store City', selector: (row: any) => row.city, sortable: true, },
        { name: 'Area Pincode', selector: (row: any) => row.pin_code, sortable: true, },
        { name: 'Status', selector: (row: any) => row.isActive, },
        { name: 'Action', selector: (row: any) => row.action, },

    ]
    return (
        <div>
            <div className="row align-items-center">
                <div className="col-6">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} />
                </div>
                {checkPermissionsInArray(AllPermissions.Store_Add) && <div className="col-6 text-end">
                    <Button name={'Add Stores'}
                        className=''
                        onClick={() => navigate('/stores/add')
                        }
                    />
                </div>}
            </div>
            <div>
                <Table
                    AdditionalClassName="add-invertory-table"
                    columns={columns}
                    data={storeListing?.data?.map((item: any) => {
                        return ({
                            ...item,
                            isActive: (item.store_status === 1) ? (
                                <div className="background-light-green border-product-radius">
                                    <p className="color-green m-1 p-1">Active</p>
                                </div>) : (item.store_status === 2) ? (<div className="background-light-red border-product-radius">
                                    <p className="color-red m-1 p-1">Deactive</p>
                                </div>) : (<div className="background-light-red border-product-radius">
                                    <p className="color-red m-1 p-1">Blocked</p>
                                </div>),
                            action:

                                <> <div className='table-data-width'>
                                    <button
                                        className="btn dropdown-dots shadow-none action-button"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <Image src={ThreeDots} />
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end dropdown-style"
                                        aria-labelledby="dropdownMenuButton1"
                                    >

                                        {checkPermissionsInArray(AllPermissions.Store_Edit) && <><li>
                                            <button
                                                className="dropdown-item dropdown-item-size"
                                                onClick={() => navigate(`/stores/edit/${item.id}`)}
                                            >
                                                <div className='d-flex align-items-center'>
                                                    <FiEdit />
                                                    <span className='ms-2 mb-0'>Edit</span>
                                                </div>
                                            </button>
                                        </li>
                                        </>
                                        }
                                        {(checkPermissionsInArray(AllPermissions.Store_Is_Active) && checkPermissionsInArray(AllPermissions.Store_Edit)) && <li>
                                            <hr className="my-1"></hr>
                                        </li>}
                                        {checkPermissionsInArray(AllPermissions.Store_Is_Active) &&
                                            <>
                                                {(!(item?.store_status === 1)) && <>
                                                    <li>
                                                        <button
                                                            className="dropdown-item dropdown-item-size"
                                                            onClick={() => handleStatus(item.id, constant?.STORE_STATUS?.ACTIVE)}

                                                        >
                                                            <div className='d-flex align-items-center'>
                                                                <AiOutlinePoweroff />
                                                                <span className='ms-2 mb-0'>
                                                                    Active
                                                                </span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                    {!(item?.store_status === 3) && <hr className="my-1"></hr>}

                                                </>}
                                                {((item?.store_status === 1)) && <>
                                                    <li>
                                                        <button
                                                            className="dropdown-item dropdown-item-size"
                                                            onClick={() => handleStatus(item.id, constant?.STORE_STATUS?.INACTIVE)}

                                                        >
                                                            <div className='d-flex align-items-center'>
                                                                <AiOutlinePoweroff />
                                                                <span className='ms-2 mb-0'>
                                                                    Deactive
                                                                </span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                    <hr className="my-1"></hr>
                                                </>}

                                                {(!(item?.store_status === 3)) && <li>
                                                    <button
                                                        className="dropdown-item dropdown-item-size"
                                                        onClick={() => handleStatus(item.id, constant?.STORE_STATUS?.BLOCKED)}

                                                    >
                                                        <div className='d-flex align-items-center'>
                                                            <AiOutlinePoweroff />
                                                            <span className='ms-2 mb-0'>
                                                                Blocked
                                                            </span>
                                                        </div>
                                                    </button>
                                                </li>}
                                            </>
                                        }

                                    </ul>
                                </div></>

                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={storeListing?.count}
                    currentPage={currentPage}
                    totalPages={storeListing?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
        </div>
    )
}

export default Index