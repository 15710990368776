const movement = {
    procurement: () => `admin/procurements`,
    details: (id:string) =>  `admin/procurement/${id}`,
    pending: () => `admin/pending-procurements`,
    store: (id: string) => `admin/procurement/${id}/assign-store`,
    storeListing: (id:string) => `admin/procurement/${id}/stores`,
    // ------------------------------in-process----------------------------------------
    inProcess: () => `admin/processed-procurements`,
    inProcessById: (id:string)=> `admin/procurement/${id}/shipment-info`,
    barcodeById: (id:string)=> `admin/procurement/${id}/barcodes`,
    inProcessPostById: (id:string)=> `admin/procurement/${id}/deliver`,
    // -------------------------------delivered------------------------------------------
    deliver: () => `admin/delivered-procurements`,
    cancelListing: () => `admin/cancelled-procurements`,
    cancelMovement:(id:string)=>`admin/procurement/${id}/cancel`, //wrong data 

    cancelledProduct:(id:string)=>`admin/procurement/${id}/pending`

    
}
export default movement 