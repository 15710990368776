import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { subAdmin } = Dictionary

const subAdminService = {
    createSubAdmin: async (data: {}) => {
        return axios.post(BASE_URL + subAdmin.subAdmin(), data)
    },
    getSubAdminService: async (paramsObj: {}) => {
        return axios.get(BASE_URL + subAdmin.subAdmin(), { params: paramsObj })
    },
    getSubAdminById: async (id: string) => {
        return axios.get(BASE_URL + subAdmin.subAdminByID(id))
    },
    updateSubAdmin: async (id: string, data: {}) => {
        return axios.put(BASE_URL + subAdmin.subAdminByID(id), data)
    },
    deleteSubAdmin:async(id:string)=>{
        return axios.delete(BASE_URL + subAdmin.subAdminByID(id))
    }
}
export default subAdminService
