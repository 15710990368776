import React, { useState, useEffect, useContext } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import { HeaderHeading } from "../../../../Context";
import {Default_Page_Limit} from '../../../../constants/defaultValues'

const { FeedbackForm } = organisms

const Index = () => {
    const [initialLoading, setInitialLoading]= useState<boolean>(false)
    const [feedbacks, setFeedbacks] = useState<any>();
    const [feedbackDetail, setFeedbackDetail] = useState("")
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [currentPage, setCurrentPage] = useState(1);
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);

    const getFeedbacksList = async () => {
        try {
            let response = await service.WebsiteFormService?.feedbackList()
            if (response.status === 200) {
                setFeedbacks(response?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getFeedbackDetail = async (id: string) => {
        try {
            let response = await service.WebsiteFormService?.feedbackDetail(id)
            if (response.status === 200) {
                setFeedbacks(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setHeader("Website Form - Feedback Form")
    }, [])

    useEffect(() => {
        setSidebar(false)
        getFeedbacksList()
    }, [currentPage])

    useEffect(() => {
        setCurrentPage(1)
        if (currentPage == 1 && initialLoading) {
            getFeedbacksList()
        }
        setInitialLoading(true)
    }, [limitPerPage])

    return (
        <Wrapper>
            <FeedbackForm
                feedbacks={feedbacks}
                feedbackDetail={feedbackDetail}
                getFeedbackDetail={(id: string) => getFeedbackDetail(id)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setLimitPerPage={setLimitPerPage}
                limitPerPage={limitPerPage}
            />
        </Wrapper>
    )
}

export default Index