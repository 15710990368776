import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import { HeaderHeading } from '../../../../Context'

const { Movement } = organisms
const Index = () => {
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);
  const [movementListing, setMovementListing] = useState<any>()
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState<number>(1);
  const [limit, setLimit] = useState(Default_Page_Limit);
  const [count, setCount] = useState({
    totalCount: NaN,
    totalPages: NaN
  })
  const getStoreListing = async () => {
    try {
      const response = await service.movement.movementList({ search: search, page: pageCount, limit: limit, });
      // setMovementListing(response.data)
      if (response.status === 200) {
        let data: any = response?.data?.data
        setCount({ ...count, totalCount: response?.data?.count, totalPages: response?.data?.totalPages })
        response.data.data.forEach((items: any) => {
          const groupedData: any = [];
          items.procurement_items.forEach((element: any) => {
            if (groupedData[element.status]) {
              groupedData[element.status].push(element)
            } else {
              groupedData[element.status] = []
              groupedData[element.status].push(element)
            }
          })
          items.groupData = groupedData
        })
        setMovementListing(data)
      }

    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    setHeader("Movement - Movement")
    setSidebar(false)
    getStoreListing()
  }, [ pageCount])

  useEffect(() => {
    setPageCount(1)
    if (pageCount == 1 && initialLoading) {
      getStoreListing()
    }
    setInitialLoading(true)
  }, [limit,search])

  return (
    <Wrapper>
      <Movement
        limit={limit}
        currentPage={pageCount}
        count={count}
        movementListing={movementListing}
        setSearch={setSearch}
        setLimitPerPage={setLimit}
        setCurrentPage={setPageCount} />
    </Wrapper>
  )
}

export default Index

