import React from "react";
import atoms from "../../../atoms";
import constant from "../../../../constants/constant";

const { Input, Select, Button } = atoms

const Index = ({ handleChange, reportData ,submitIndividualBarcode}: any) => {


    const buttonValidation = (date1: Date) => {
        const parsedDate1 = new Date(date1);
        // const parsedDate2 = new Date(date2);

        if (!parsedDate1 || isNaN(parsedDate1.getTime())) {
            return true;
        }
        // if (!parsedDate2 || isNaN(parsedDate2.getTime())) {
        //     return true;
        // }

        if(!reportData?.report_format){
            return true;
        }

        return false
    };

    return (
        <>
            <div className="row">
                <div className="col-4">
                    <Input label={"Report Date"}
                        placeholder={"Report Date"} type="date"
                        value={reportData?.report_date}
                        onChange={(e: any) => {
                            handleChange("report_date", e)
                        }}
                    />
                </div>
                {/* <div className="col-4">
                    <Input label={"End Date"}
                        placeholder={"Enter Invoice Date"} type="date"
                        value={reportData?.created_to}
                        onChange={(e: any) => {
                            handleChange("created_to", e)
                        }}
                    />
                </div> */}
                <div className="col-4" style={{color:"#000000"}}>
                    <Select
                        className="w-100"
                        options={constant.DailyReportFormatExcel}
                        value={reportData?.report_format}
                        onChange={(e: any) => handleChange("report_format", e)} placeholder={"Select"}
                        label={"Select Report Format"} />
                </div>
                <div className="col-4"></div>
            </div>
            <div className="d-flex justify-content-center" style={{ marginTop: "10rem" }}>
                <Button
                    isDisabled={buttonValidation(reportData?.report_date)}
                    style={{ minWidth: "259px" }}
                    onClick={submitIndividualBarcode}>Submit
                </Button>
            </div>
        </>
    )
}

export default Index