import React, { useContext, useEffect, useState } from 'react'
import organisms from '../../../../../components/organisms'
import Wrapper from '../../../../../components/common/Wrapper/Wrapper'
import service from '../../../../../api/services'
import { HeaderHeading } from '../../../../../Context'
import constant from '../../../../../constants/constant'
import { useToasts } from 'react-toast-notifications';
const { TempleSizeList } = organisms

const Index = () => {
  const { setHeader }: any = useContext(HeaderHeading);
  const [sizeList, setSizeList] = useState<number>()
  const { addToast } = useToasts();

  const getLoyaltyList = async () => {
    try {
      const response = await service.SettingService.sizeList({ size_for: constant?.SIZE_FOR.TEMPLE })
      if (response.status === 200) {
        setSizeList(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const _handleDelete = async (id: string) => {
    try {
      const response = await service.SettingService.deleteSize(id)
      if (response.status === 200) {
        getLoyaltyList()
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }
    }catch (error: any) {
      addToast(error.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  useEffect(() => {
    setHeader("Content - Temple Size")
    getLoyaltyList()
  }, [])

  return (
    <Wrapper>
      <TempleSizeList
        sizeList={sizeList}
        _handleDelete={(id:string)=>_handleDelete(id)}
      />
    </Wrapper>
  )
}

export default Index