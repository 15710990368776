import React from 'react'
import BrandPlaceHolder from '../../../assets/Images/placeholderimg.png';
import atoms from '../../atoms';
import { findConstantLabel, numberWithCommas } from '../../../constants/utils';
import constant from '../../../constants/constant';

const { Image } = atoms

const Index = ({ productCartData, handleSearchSelect, removeItem }: any) => {
    const repeatItem = (item: {}) => {
        handleSearchSelect(item)
    }

    return (
        <>
            {productCartData ?
                <div className='checkout-product-card mt-4'>
                    <div>
                        <div className='row'>
                            <div className='col-6 d-flex flex-wrap  align-items-center  gap-4 '>
                                <h2 className='mb-0 fs-14 fw-600'>Product ID :-</h2>
                                <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                    <h2 className='mb-0 fs-12 fw-400'>{productCartData?.product_id_display}</h2>
                                    <Image src={'Copy'} alt="" className='img-fluid' />
                                </div>
                            </div>

                            <div className=' col-6 justify-content-end d-flex flex-wrap align-items-center gap-3'>
                                <h2 className='mb-0 fs-14 fw-500 text-decoration-underline cursor-pointer' onClick={() => { removeItem(productCartData) }} >Remove</h2>
                                <h2 className='mb-0 fs-14 fw-500 text-decoration-underline cursor-pointer' onClick={() => { repeatItem(productCartData) }} >Repeat</h2>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-md-3 mt-4">
                                <div style={{ backgroundImage: `url(${productCartData?.images[0] || BrandPlaceHolder})` }}
                                    className='img-fluid product-image ' />
                            </div>

                            <div className="col-12 col-md-9 mt-4">
                                <div className='d-flex flex-wrap justify-content-between align-items-end '>
                                    <div className='w-75'>
                                        <h2 className='mb-0 fs-16 fw-600 dark-gray'>{productCartData?.brand?.brand_name}</h2>
                                        <div className="d-flex gap-3 align-items-center my-2 ">
                                            <h2 className='mb-0 fs-13 fw-400 dark-gray'>Model Number: <span className='mb-0 fs-13 fw-600'>{productCartData?.modal_number}</span></h2>
                                            <h2 className='mb-0 fs-13 fw-400 dark-gray'>Warrantee: <span className='mb-0 fs-13 fw-600'>{findConstantLabel(constant.PRODUCT_WARRANTY, productCartData?.warranty)} </span></h2>
                                            <h2 className='mb-0 fs-13 fw-400 dark-gray'>Polarized: <span className='mb-0 fs-13 fw-600'>{productCartData?.isPolarised == 1 ? 'Yes' : 'No'}</span></h2>
                                        </div>

                                        <div className="d-flex gap-3 align-items-center mb-2">
                                            <h2 className='mb-0 fs-13 fw-400 dark-gray'>Colour Code: <span className='mb-0 fs-13 fw-600'>{productCartData?.brand_color_code}  </span></h2>
                                            <h2 className='mb-0 fs-13 fw-400 dark-gray'>With Case: <span className='mb-0 fs-13 fw-600'>{productCartData?.product_with_case ? "Yes" : "No"}</span></h2>
                                            <h2 className='mb-0 fs-13 fw-400 dark-gray'>Size: <span className='mb-0 fs-13 fw-600'>{productCartData?.glass_size}</span></h2>
                                        </div>
                                        <div className="d-flex gap-3 align-items-center mb-2">
                                            {<h2 className='mb-0 fs-13 fw-400 dark-gray'>Features:
                                                {productCartData?.features?.length > 0 && productCartData?.features?.map((item: any, index: number) => {
                                                    return (
                                                        <span className='mb-0 fs-13 fw-600'>
                                                            {item ? ` ${findConstantLabel(constant.PRODUCT_FEATURES, item)} ${index < (productCartData?.features?.length - 1) ? "," : ""}` : ""}
                                                        </span>
                                                    )
                                                })}
                                            </h2>}
                                        </div>
                                        <p className='mb-0 fs-14 fw-400 dark-gray mt-3'>{productCartData?.description}</p>
                                    </div>
                                    <div className='text-sm-start text-md-start text-lg-end mt-3 mt-md-0'>
                                        <p className='mb-0 fs-14 fw-500 dark-gray text-decoration-line-through'>₹{numberWithCommas(parseFloat(productCartData?.product_srp?.toFixed(2)))}(SRP)</p>
                                        <p className='mb-0 fs-16 fw-600 dark-gray'>₹{numberWithCommas(parseFloat(productCartData?.landing_cost))}(L.Cost)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='text-center mt-4'>
                    <span className='fs-14 fw-400'>There are no records to display</span>
                </div>

            }
        </>

    )
}

export default Index