import React, { useEffect, useContext, useState } from "react";
import { HeaderHeading } from "../../../../Context";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from 'react-toast-notifications';
import service from "../../../../api/services";
import { handleReportDownload } from "../../../../constants/utils";
import constant from "../../../../constants/constant";


const { StoreReport } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [reportData, setReportData] = useState({
        report_date: Date,
        report_format: NaN,
    })
    const { addToast } = useToasts();



    const handleSubmit = async () => {
        try {
            const response = await service.reportService?.store({ report_date: reportData?.report_date })
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "store-report", constant?.DailyReportFormat[1].value, "")
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleChange = (name: string, value: any) => {
        setReportData({ ...reportData, [name]: value })
    }


    useEffect(() => {
        setHeader("Report - Store Report")
    }, [])

    return (
        <Wrapper>
            <StoreReport handleSubmit={handleSubmit} handleChange={(name: string, value: any) => handleChange(name, value)} reportData={reportData} />
        </Wrapper>
    )
}

export default Index