const store = {
    store: () => `admin/store`,
    storeByID: (id: string) => `admin/store/${id}`,
    storeStatus: (id: string) => `admin/store/${id}/status`,
    storeImages: () => `admin/store/images`,
    storeCount: () => `admin/store/count`,
    changePassword: () => `admin/user/change-password`,
    searchUser: (userID: string) => `admin/search/${userID}`,
    searchStoreLocation: () => 'admin/store/location-search'

}
export default store 