import Store from './Store/AddStores'
import Movement from './Movement/Movement'
import MovementDetail from './Movement/MovementDetails'
import MovementPending from './Movement/Pending'
import MovementInprocess from './Movement/In-process'
import MovementDelivered from './Movement/Delivered'
import MovementCancelled from './Movement/Cancelled'
import AddStoreOrganism from './Store/AddStores/addStore'
import ApprovedProduct from './Product/Approved'
import PendingProduct from './Product/Pending'
import ReworkProduct from './Product/Rework'
import BulkEdit from './Product/BulkEdit'

import AddProduct from './Product/addProduct'
import Brand from './Data/Brand'
import ProductType from './Data/ProductType'
import Material from './Data/Material'
import Color from './Data/Color'
import Shape from './Data/Shape'
import AddBrand from './Data/Brand/AddBrand'
import Supplier from './Data/Supplier'
import SupplierAdd from './Data/Supplier/addSupplier'
import SubAdmin from './Setting/SubAdmin'
import AddSubAdminOrg from './Setting/SubAdmin/addSubAdmin'
import Permissions from './Setting/Permissions'
import PendingInventory from './Inventory/Pending-Rework'
import ApproveInventory from './Inventory/Approved'
import InventorySearch from './Inventory/SearchIn'
import InventoryQuantity from './Inventory/Quantity'
import AddInventoryOrganism from './Inventory/Addition/AddInventory'
import BarcodeStatus from './Inventory/BarcodeStatus'
import PendingBarcodeStatus from './Inventory/BarcodeStatus/pendingIndex'
import MoveOutBarcode from "./Inventory/MoveOutBarcode"
import LostBarcodeHistory from "./Inventory/LostBarcodeHistory"
import LostBarcode from "./Inventory/LostBarcode"

import Role from './Setting/Role'
import AddRoleOrg from './Setting/Role/addRole'
import BrandAllocation from './Store/BrandAllocation/index'
import StoreAllocation from './Store/AllocatedBrand/index'
import EditBrandAllocation from './Store/AllocatedBrand/EditBrandAllocation'
import AddBrandAllocation from './Store/BrandAllocation/AddBrandAllocation'
import BulkUpload from './Product/BulkUpload'
import SubAccount from './Accounts/SubAccount'
import GeneralAccount from './Accounts/GeneralAccounts'
import StoreBooks from './Accounts/AddVoucher/StoreBooks'
import SpecxwebBooks from './Accounts/AddVoucher/SpecxwebBooks'
import AllEntries from './Accounts/AllEntries'
import Procurement from './Store/Procurement'
import LoyaltyPoints from './Inventory/LoyaltyPoints/LoyaltyPoints'
import LoyaltyPointsItems from './Inventory/LoyaltyPoints/LoyaltyPointsItem'
import AddLoyalty from './Setting/Loyalty/AddLoyalty'
import LoyaltyList from './Setting/Loyalty/List'
import Warranty from './Warranty'
import InventoryAudit from './Inventory/InventoryAudit'
import BridgeSizeList from './Data/BridgeSize/List'
import AddBridgeSize from './Data/BridgeSize/AddBridgeSize'
import TempleSizeList from './Data/TempleSize/List'
import AddTempleSize from './Data/TempleSize/AddTempleSize'
import SizeList from './Data/Size/List'
import AddSize from './Data/Size/AddSize'

import PurchaseReport from './Report/PurchaseReport'
import StoreSalesReport from './Report/StoreSalesReport'
import InventoryMovementReport from './Report/InventoryMovementReport'
import InventoryReport from './Report/InventoryReport'
import GSTOutputReport from './Report/GstOutputReport'
import CustomerReport from './Report/CustomerReport'
import StoreReport from './Report/StoreReport'
import BarcodeAuditTrail from './Report/BarcodeAuditTrail'
import InventoryAdditionInvoiceReport from './Report/InventoryAdditionalReport/InvoiceWise'
import InventoryAdditionBarcodeReport from './Report/InventoryAdditionalReport/BarcodeWise'
import InvoicingReport from './Report/InvoicingReport'
import TransactionReport from './Accounts/TransactionReport'
import GeneralAccountReport from './Accounts/GeneralAccountReport'
import StoreBalanceReport from './Accounts/StoreBalanceReport'
import TrailBalanceReport from './Accounts/TrailBalanceReport'
import StoreSalesReportInvoiceWise from './Report/StoreSalesReport/InvoiceWise'
import StoreSalesReportBrandWise from './Report/StoreSalesReport/BrandWise'
import StoreSalesReportMonthWise from './Report/StoreSalesReport/MonthWise'
import OwnershipTransferReport from './Report/OwnershipTransferReport'
import Dashboard from './Dashboard'

import FeedbackForm from './WebsiteForm/FeedbackForm'
import RetailerForm from './WebsiteForm/RetailerForm'


const organisms = {
  Store,
  Movement,
  MovementDetail,
  MovementPending,
  MovementInprocess,
  MovementDelivered,
  MovementCancelled,
  AddStoreOrganism,
  ApprovedProduct,
  PendingProduct,
  ReworkProduct,
  BulkEdit,
  AddProduct,
  Brand,
  ProductType,
  Material,
  Color,
  Shape,
  AddBrand,
  BridgeSizeList,
  AddBridgeSize,
  TempleSizeList,
  AddTempleSize,
  SizeList,
  AddSize,
  Supplier,
  SupplierAdd,
  SubAdmin,
  AddSubAdminOrg,
  Permissions,
  PendingInventory,
  ApproveInventory,
  AddInventoryOrganism,
  BarcodeStatus,
  PendingBarcodeStatus,
  MoveOutBarcode,
  LostBarcodeHistory,
  LostBarcode,
  Role,
  AddRoleOrg,
  BrandAllocation,
  StoreAllocation,
  EditBrandAllocation,
  AddBrandAllocation,
  InventorySearch,
  InventoryQuantity,
  BulkUpload,
  SubAccount,
  GeneralAccount,
  StoreBooks,
  SpecxwebBooks,
  AllEntries,
  Procurement,
  LoyaltyPoints,
  LoyaltyPointsItems,
  AddLoyalty,
  LoyaltyList,
  Warranty,
  InventoryAudit,
  PurchaseReport,
  StoreSalesReport,
  InventoryMovementReport,
  InventoryReport,
  GSTOutputReport,
  CustomerReport,
  StoreReport,
  BarcodeAuditTrail,
  InventoryAdditionInvoiceReport,
  InventoryAdditionBarcodeReport,
  InvoicingReport,
  TransactionReport,
  GeneralAccountReport,
  OwnershipTransferReport,
  StoreBalanceReport,
  TrailBalanceReport,
  StoreSalesReportBrandWise,
  StoreSalesReportInvoiceWise,
  StoreSalesReportMonthWise,
  Dashboard,
  FeedbackForm,
  RetailerForm,
}
export default organisms
