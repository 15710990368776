import React, { useState, useEffect, useContext } from "react";
import organisms from "../../../../../components/organisms";
import Wrapper from "../../../../../components/common/Wrapper/Wrapper";
import service from "../../../../../api/services";
import {Default_Page_Number,Default_Page_Limit} from "../../../../../constants/defaultValues"
import { HeaderHeading } from "../../../../../Context";
import { useParams } from 'react-router'


const {LoyaltyPointsItems}= organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [loyaltyItemList, setLoyaltyItemList]= useState()
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage]=useState<number>(Default_Page_Number)
    const [limitPerPage, setLimitPerPage]=useState<number>(Default_Page_Limit)

  const params = useParams()


    useEffect(() => {
        setHeader("Report - Loyalty Points")
        setSidebar(false)
        loyaltyPointsData()
    }, [currentPage])

    useEffect(() => {
        setCurrentPage(1)
        if (currentPage == 1 && initialLoading) {
            loyaltyPointsData()
        }
        setInitialLoading(true)
    }, [limitPerPage])

    const loyaltyPointsData = async () => {
        try {
            const Response = await service?.loyaltyPointsService?.loyaltyPointsListItems(params?.id || '')
            if (Response?.status == 200) {
                setLoyaltyItemList(Response?.data)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    return (
        <Wrapper>
            <LoyaltyPointsItems
            loyaltyItemList={loyaltyItemList}
            currentPage={currentPage}
            setCurrentPage={(page:number)=>setCurrentPage(page)}
            setLimitPerPage={(e:number)=>setLimitPerPage(e)}
            limit={limitPerPage}
            />
        </Wrapper>
    )
}

export default Index