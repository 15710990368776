import axios from "axios"
import { BASE_URL } from "../../constants/defaultValues"
import dictionary from "../dictionary"

const productData = {
    // ----- Brand -----
    brandListingService: async (paramsObj: any) => {
        return axios.get(BASE_URL + dictionary.productData.brand(), { params: paramsObj })
    },
    createBrandService: async (payload: {}) => {
        return axios.post(BASE_URL + dictionary.productData.brand(), payload)
    },
    updateBrandService: async (id: string, payload: {}) => {
        return axios.put(BASE_URL + dictionary.productData.brandById(id), payload)
    },
    deleteBrandService: async (id: string) => {
        return axios.delete(BASE_URL + dictionary.productData.brandById(id))
    },
    getBrandByIdService: async (id: string) => {
        return axios.get(BASE_URL + dictionary.productData.brandById(id))
    },


    // ----- Product Type -----
    productTypeListingService: async (paramsObj: any) => {
        return axios.get(BASE_URL + dictionary.productData.productType(), { params: paramsObj })
    },
    createProductTypeService: async (payload: {}) => {
        return axios.post(BASE_URL + dictionary.productData.productType(), payload)
    },
    updateProductTypeService: async (id: string, payload: {}) => {
        return axios.put(BASE_URL + dictionary.productData.productTypeById(id), payload)
    },
    deleteProductTypeService: async (id: string) => {
        return axios.delete(BASE_URL + dictionary.productData.productTypeById(id))
    },
    getProductTypeByIdService: async (id: string) => {
        return axios.get(BASE_URL + dictionary.productData.productTypeById(id))
    },

    // ----- Product Material -----
    productMaterialListingService: async (paramsObj: any) => {
        return axios.get(BASE_URL + dictionary.productData.productMaterial(), { params: paramsObj })
    },
    createProductMaterialService: async (payload: {}) => {
        return axios.post(BASE_URL + dictionary.productData.productMaterial(), payload)
    },
    updateProductMaterialService: async (id: string, payload: {}) => {
        return axios.put(BASE_URL + dictionary.productData.productMaterialById(id), payload)
    },
    deleteProductMaterialService: async (id: string) => {
        return axios.delete(BASE_URL + dictionary.productData.productMaterialById(id))
    },
    getProductMaterialByIdService: async (id: string) => {
        return axios.get(BASE_URL + dictionary.productData.productMaterialById(id))
    },


    // ----- Product Shape -----
    productShapeListingService: async (paramsObj: any) => {
        return axios.get(BASE_URL + dictionary.productData.productShape(), { params: paramsObj })
    },
    createProductShapeService: async (payload: {}) => {
        return axios.post(BASE_URL + dictionary.productData.productShape(), payload)
    },
    updateProductShapeService: async (id: string, payload: {}) => {
        return axios.put(BASE_URL + dictionary.productData.productShapeById(id), payload)
    },
    deleteProductShapeService: async (id: string) => {
        return axios.delete(BASE_URL + dictionary.productData.productShapeById(id))
    },
    getProductShapeByIdService: async (id: string) => {
        return axios.get(BASE_URL + dictionary.productData.productShapeById(id))
    },


    // ----- Product Color -----
    productColorListingService: async (paramsObj: any) => {
        return axios.get(BASE_URL + dictionary.productData.productColor(), { params: paramsObj })
    },
    createProductColorService: async (payload: {}) => {
        return axios.post(BASE_URL + dictionary.productData.productColor(), payload)
    },
    updateProductColorService: async (id: string, payload: {}) => {
        return axios.put(BASE_URL + dictionary.productData.productColorById(id), payload)
    },
    deleteProductColorService: async (id: string) => {
        return axios.delete(BASE_URL + dictionary.productData.productColorById(id))
    },
    getProductColorByIdService: async (id: string) => {
        return axios.get(BASE_URL + dictionary.productData.productColorById(id))
    },


    sizeDetails: async () => {
        return axios.get(BASE_URL + dictionary.productData.sizeDetails())
    },



}
export default productData