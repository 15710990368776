import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import atoms from "../../../../components/atoms";
import molecules from "../../../../components/molecules";
import { MyComponentProps, SupplierDetail } from "./interface"
import moment from 'moment';
import { FiEdit } from "react-icons/fi";
const { Search, Button, Table, Pagination } = atoms
const Index: React.FC<MyComponentProps> = ({ setLimitPerPage, setCurrentPage, setSearch, supplierData, currentPage,limit }) => {
    const navigate = useNavigate()
    const columns = [
        { name: 'Supplier id', selector: (row: SupplierDetail) => row.supplier_id_display, sortable: true },
        { name: 'Date', selector: (row: any) =>row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : "-", },
        { name: 'Supplier Name', selector: (row: SupplierDetail) => row.supplier_name },
        { name: 'Supplier Address', selector: (row: SupplierDetail) => row.address },
        { name: 'Supplier Contact Number', selector: (row: SupplierDetail) => row.contact_number },
        { name: 'Action', selector: (row: any) => row.action, },
    ]

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} /></div>
                <div className="col-6 text-end">
                    <Button name={"Add Supplier"}
                        className=''
                        onClick={() => navigate('/supplier/add')}
                    />
                </div>
            </div>
            <div >

                <Table columns={columns}
                    // pagination
                    // currentPage={currentPage}
                    // paginationTotalRows={supplierData.count}
                    // setLimitPerPage={(e: number) => setLimitPerPage(e)}
                    // setCurrentPage={(e: number) => setCurrentPage(e)}
                    // totalPages={supplierData && supplierData.totalPages}
                    data={supplierData?.data?.map((item: SupplierDetail, index: number) => {
                        return ({
                            ...item,
                            action:
                                <Link
                                    to={`/supplier/edit/${item.id}`}
                                >
                                    <Button className="table-edit-button fs-14 fw-400">
                                        <div className='d-flex align-items-center'>
                                            <FiEdit />
                                            <span className='ms-2 mb-0'>Edit</span>
                                        </div>
                                    </Button>
                                </Link>
                        })
                    })
                    }
                />


            </div>
            <div>
                <Pagination
                totalCount={supplierData?.count}
                    currentPage={currentPage}
                    totalPages={supplierData?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
        </>
    )
}
export default Index