import React, { useState, useEffect, useContext } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import { HeaderHeading } from "../../../../Context";
import {Default_Page_Limit} from '../../../../constants/defaultValues'

const { RetailerForm } = organisms

const Index = () => {
    const [retailers, setRetailers] = useState<any>();
    const [retailerDetail, setRetailerDetail] = useState("")
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [currentPage, setCurrentPage] = useState(1);
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);

    const getRetailersList = async () => {
        try {
            let response = await service.WebsiteFormService.retailerList()
            if (response.status === 200) {
                setRetailers(response?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getRetailerDetail = async (id: string) => {
        try {
            let response = await service.WebsiteFormService.retailerDetail(id)
            if (response.status === 200) {
                setRetailers(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setHeader("Website Form - Retailer Form")
        getRetailersList()
    }, [])

    return (
        <Wrapper>
            <RetailerForm
                retailers={retailers}
                retailerDetail={retailerDetail}
                getRetailerDetail={(id: string) => getRetailerDetail(id)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setLimitPerPage={setLimitPerPage}
                limitPerPage={limitPerPage}
            />
        </Wrapper>
    )
}

export default Index