import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import constant from '../../../constants/constant';
import atoms from '../../atoms'
import { RxCross2 } from "react-icons/rx";

const { Button, Input, Select } = atoms

const SubAccountModal = ({ header, submitBtnText, handleSubmit, show, onHide, accountForm, handleChange,accountFormId }: any) => {
   const [error ,setError]= useState<string>()
    // useEffect(() => {
    //     if (accountForm.id) {
    //     }

    // }, [])

    return (
        <>
            <Modal className="h-100 d-flex justify-content-center align-items-center  border-0" isOpen={show} >
                <ModalHeader className="d-flex justify-content-center border-0 position-relative" >
                    <div className="mt-4 fs-30 fw-600">{header}</div>
                    <div className="position-absolute py-1 px-2" style={{ top: '0', right: '0', backgroundColor: '#222', borderRadius: '0px 4px 0px 8px', cursor: 'pointer' }} onClick={onHide}><RxCross2 style={{ color: '#fff' }} /></div>
                </ModalHeader>
                <ModalBody className="rounded-10">
                    <form>
                        <div>
                            <Input
                                type={'text'}
                                label={"Name"}
                                placeholder={"Enter Name"}
                                value={accountForm?.name}
                                onChange={(e: any) => {
                                    handleChange("name", e.target.value)
                                }} />
                        </div>
                        <div className='mt-2'>
                            <Select
                                label={"Select Account Type"}
                                options={constant.Account_type}
                                name={"type"}
                                value={accountForm?.type}
                                isDisabled={accountForm?.id}
                                className={"w-100"}
                                placeholder={"Select Account Type"}
                                onChange={(e: any) => { handleChange("type", e) }}
                            />
                        </div>
                        <div className='mt-2'>
                            <Input
                                type={'number'}
                                label={"Numeric Code"}
                                placeholder={"Enter Numeric Code"}
                                disabled={accountForm?.id}
                                value={accountForm?.numeric_code}
                                onChange={(e: any) => {
                                    handleChange("numeric_code", e.target.value < 100 ? e.target.value : 99)
                                    setError(`${e.target.value <10 ? "Numeric code between 10 to 99" : ""}`)
                                }} 
                                // errors={`${accountForm?.numeric_code <10 ? "Numeric code between 10 to 99" : ""}`}
                                errors={error}

                                />
                        </div>

                    </form>
                    <div className='d-flex mt-3 p-1 gap-2'>
                        <div className='inputs-label mb-2 fs-16 fw-600  secondary-color'><span>Numeric Code :-</span></div>
                        <div className='border text-center' style={{ width: "50px" }}>
                           {accountFormId ? <span>{`${accountForm?.numeric_code}`}</span> :<span>{`${accountForm?.type?.value ? accountForm?.type?.value : ""}${accountForm?.numeric_code}`}</span>}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="mx-auto border-0 mt-25 mb-4 ">
                    <Button className=''
                    isDisabled={accountForm?.numeric_code<10 || !accountForm?.name || !accountForm?.numeric_code}
                        onClick={() => handleSubmit()}>{submitBtnText}</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default SubAccountModal