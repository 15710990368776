import React, { useState, useEffect, useContext } from 'react';
import service from '../../../../api/services';
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import organisms from '../../../../components/organisms';
import { Default_Page_Limit, Default_Page_Number } from '../../../../constants/defaultValues';
import { InvoiceData } from '../Addition/interface'
import { HeaderHeading } from '../../../../Context'
import { useToasts } from 'react-toast-notifications';

const { PendingInventory } = organisms
const Index = () => {
    const { addToast } = useToasts();
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [invoiceDetail, setInvoiceDetail] = useState<InvoiceData | any>({});
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [search, setSearch] = useState('');
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);

    const getInvoiceData = async () => {
        try {
            let response = await service.inventory.view({ "page": currentPage, "search": search, "limit": limitPerPage, "status": "" })
            if (response.status === 200) {
                setInvoiceDetail(response.data)
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    const handleUpdateStatus = async (id: any, status: any) => {
        try {
            let response = await service.inventory.updateStatusById(id, { "status": status });
            getInvoiceData()
        } catch (error) {
            console.log(error)
        }
    }
    const handleDelete = async (id: string) => {
        try {
            const response = await service.inventory.deletePendingInventory(id);
            if (response?.status === 200) {
                getInvoiceData()
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log(error)
            addToast(error.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        
    }

    useEffect(() => {
        setHeader("Inventory - Pending/Rework")
        setSidebar(false)
        getInvoiceData()
    }, [currentPage])

    useEffect(() => {
        setCurrentPage(1)
        if (currentPage == 1 && initialLoading) {
            getInvoiceData()
        }
        setInitialLoading(true)
    }, [limitPerPage,search])
    return (
        <Wrapper>
            <PendingInventory
                invoiceDetail={invoiceDetail}
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                updateVerificationStatus={(id: string, status: any) => handleUpdateStatus(id, status)}
                setSearch={setSearch}
                currentPage={currentPage}
                limit={limitPerPage}
                handleDelete={(id: string) => handleDelete(id)}
            />
        </Wrapper>
    )
}
export default Index