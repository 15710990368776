import React, { useState, useRef, useEffect } from "react";
import atoms from "../../../atoms";
import molecules from "../../../molecules";
import { MyComponentProps } from "./interface";
const { Search } = atoms
const { PurchaseCard, BillingSummary, SearchTab } = molecules

const Index: React.FC<MyComponentProps> = ({ handleSearch, productList,
  handleSearchSelect, selectedProductList,
  removeItem, checkout, findLandingDiscount,storeBillingDetail }) => {
    const currentRef:any = useRef()
  const [isOpen, setIsOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("")
  const searchTabContainer = useRef<HTMLDivElement>(null);
  
  const handleClickOutside = (event: any) => {
    if (searchTabContainer.current && !searchTabContainer.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const searchCallBack = (search: string) => {
    setSearchVal(search)
    handleSearch(search)
  }

  const handleCheckout=(data:any)=>{
    checkout(data)
    currentRef.current.value = ''
  }

  return (
    <>
      <div className="col-12 col-md-8 m-0">
        <div className='checkout-card'>
          <h2 className='mb-0 fs-18 fw-600'>Cart ({selectedProductList?.length} items)</h2>

          <div className='mt-4' ref={searchTabContainer}>
            <div className="col-12 position-relative" onClick={() => setIsOpen(!isOpen)} >
              <Search refs={currentRef} setIsOpen={setIsOpen} searchCallBack={searchCallBack} placeholder='Enter Model Number' className='w-100 fs-16 border-dark-gray' heading="Search Product"/>
              {searchVal && isOpen && <SearchTab data={productList?.data} searchCallBack={(searchQuery: any) => handleSearchSelect(searchQuery)} setSearchVal={setSearchVal} />}
            </div>
          </div>
          {selectedProductList && selectedProductList?.map((item: any, index: number) => {
            return (
              <PurchaseCard
                productCartData={item}
                handleSearchSelect={(searchSelect: string) => handleSearchSelect(searchSelect)}
                removeItem={(Item: string) => removeItem(Item, index)}
                findLandingDiscount={(product: any, store: any) => findLandingDiscount(product, store)}
              />
            )
          })}
        </div>
      </div>
      <div className="col-12 col-md-4">
        <BillingSummary
          productCartData={selectedProductList}
          findLandingDiscount={(product: any, store: any) => findLandingDiscount(product, store)}
          checkout={(data:any)=>handleCheckout(data)}
          storeBillingDetail={storeBillingDetail} />
      </div>
    </>
  )
}

export default Index