import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { warranty } = Dictionary

const warrantyService = {
  warrantyList: async (paramsObj:{}) => {
    return axios.get(BASE_URL + warranty.warrantyProductList(), { params: paramsObj })
  },
  warrantyDetail: async (id:string) => {
    return axios.get(BASE_URL + warranty.warrantyProductDetail(id))
  },
  warrantyApproveReject: async (id:string, data: {}) => {
    return axios.put(BASE_URL + warranty.approveReject(id), data)
  },

}
export default warrantyService
