import React, { useState, useEffect, useContext } from "react";
import organisms from "../../../../../components/organisms";
import Wrapper from "../../../../../components/common/Wrapper/Wrapper";
import service from "../../../../../api/services";
import { Default_Page_Number, Default_Page_Limit } from "../../../../../constants/defaultValues"
import { HeaderHeading } from "../../../../../Context";

const { LoyaltyPoints } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [loyaltyPointsList, setLoyaltyPointsList] = useState()
    // const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [search, setSearch] = useState<string>()
    const [currentPage, setCurrentPage] = useState<number>(Default_Page_Number)
    const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit)

    const loyaltyPointsData = async () => {
        try {
            const Response = await service?.loyaltyPointsService?.getLoyaltyPointsList({search:search})
            if (Response?.status == 200) {
                setLoyaltyPointsList(Response?.data)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        setHeader("Report - Loyalty Points")
        setSidebar(false)
        loyaltyPointsData()
    }, [search])

    return (
        <Wrapper>
            <LoyaltyPoints
                setSearch={(search: string) => setSearch(search)}
                loyaltyPointsList={loyaltyPointsList}
                currentPage={currentPage}
                setCurrentPage={(page: number) => setCurrentPage(page)}
                setLimitPerPage={(e: number) => setLimitPerPage(e)}
                limit={limitPerPage}
            />
        </Wrapper>
    )
}

export default Index