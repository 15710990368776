import React from "react";
import atoms from "../../../../atoms";
import VoucherComponent from '../voucherComponent'
import { StoreBookInterface } from "./storeBookInterface";

const { Select } = atoms

const Index = ({ storeData, storeValue, handleChangeStoreData, getGlAccountListData, storeBookGLNumberList, storeBookGLNumber, debitData, setDebitData, creditData, setCreditData }: StoreBookInterface) => {


    const addDebitComponent = () => {
        setDebitData([...debitData, {
            gl_number: '',
            gl_name: '',
            amount: NaN
        }]);
    }

    const addCreditComponent = () => {
        setCreditData([...creditData, {
            gl_number: '',
            gl_name: '',
            amount: NaN
        }]);
    }

    const handleDebitComponentDelete = (index: number) => {
        let tempComponent = [...debitData]
        tempComponent?.length > 1 ? tempComponent.splice(index, 1) : tempComponent=[{ gl_number: '', gl_name: '', amount: NaN }],
        setDebitData(tempComponent)
    }
    const handleCreditComponentDelete = (index: number) => {
        let tempComponent = [...creditData]
        tempComponent?.length > 1 ? tempComponent.splice(index, 1) : tempComponent=[{ gl_number: '', gl_name: '', amount: NaN }]
        setCreditData(tempComponent)
    }

    const handleDebitChange = (index: number, name: string, value: any) => {
        const updatedComponents: any = [...debitData];
        updatedComponents[index][name] = value;
        setDebitData(updatedComponents);
    }
    const handleCreditChange = (index: number, name: string, value: any) => {
        const updatedComponents: any = [...creditData];
        updatedComponents[index][name] = value;
        setCreditData(updatedComponents);
    }

    const getGlName = (id: string, name: string, index: number, type: string) => {
        if (storeBookGLNumber) {
            const glName: any = storeBookGLNumber.find((item: any) => item.id === id);
            type === "debit" ? handleDebitChange(index, name, glName?.gl_account_name) : handleCreditChange(index, name, glName?.gl_account_name)
        }
    }




    return (
        <>
            <div className="fs-16 fw-800 secondary-color">Store Books</div>
            <div className="mt-1">
                <Select
                    className={"w-100"}
                    options={storeData}
                    value={storeValue}
                    onChange={(e: any) => handleChangeStoreData(e) }
                    placeholder={"Select Store"} 
                    isClearable={true}/>
            </div>
            <div className="mt-4">
                <div className="">
                    <h1 className="fs-16 fw-600 text-center">Debit</h1>

                    {debitData && debitData?.map((component: any, index: number) => (
                        <div className="card p-2 mt-2">
                            <VoucherComponent
                                handleChange={(name: string, value: any) => handleDebitChange(index, name, value)}
                                glNumber={component?.gl_number}
                                glName={component?.gl_name}
                                amount={component?.amount}
                                glAccountList={storeBookGLNumberList}
                                getGlName={(id: string) => getGlName(id, "gl_name", index, "debit")}
                                handleDelete={() => handleDebitComponentDelete(index)}
                                Index={(debitData?.length === (index + 1)) && index + 1}
                                addDebitComponent={() => addDebitComponent()}
                            />
                        </div>
                    ))}
                </div>
                <div className="mt-3">
                    <h1 className="fs-16 fw-600 text-center">Credit</h1>
                    {creditData && creditData?.map((component: any, index: number) => (
                        <div className="card p-2 mt-2">
                            <VoucherComponent
                                handleChange={(name: string, value: any) => handleCreditChange(index, name, value)}
                                glNumber={component?.gl_number}
                                glName={component?.gl_name}
                                amount={component?.amount}
                                glAccountList={storeBookGLNumberList}
                                getGlName={(id: string) => getGlName(id, "gl_name", index, "credit")}
                                handleDelete={() => handleCreditComponentDelete(index)}
                                Index={(creditData?.length === (index + 1)) && index + 1}
                                addDebitComponent={() => addCreditComponent()}
                            />
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}

export default Index