import React from "react";
import atoms from "../../../atoms";
import { numberWithCommas } from "../../../../constants/utils";
import constant from "../../../../constants/constant";
import moment from "moment";
import { Tooltip } from 'react-tooltip'


const { Search, Button, Table, Select, Pagination, CustomImages } = atoms

const Index = ({ moverOutBarcode, setLimitPerPage, setCurrentPage, currentPage, limit, storeData, handleChange, setSearch }: any) => {

    const columns = [
        { name: 'Barcode', selector: (row: any) => `${row.bar_code}`, sortable: true, },
        { name: 'Date', selector: (row: any) => row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : "-", },
        { name: 'Brand', selector: (row: any) => row?.product?.brand?.brand_name },
        { name: 'Store Name', selector: (row: any) => row?.store?.store_name || "" },
        { name: 'Cover Photo', cell: (row: any) => <CustomImages src={row?.product?.images[0] && row?.product?.images[0]} />, center: true },
        { name: 'Product Type', selector: (row: any) => row?.product?.product_type?.name },
        { name: 'Product ID', selector: (row: any) => `${row?.product?.product_id_display}`, },
        { name: 'Model No', selector: (row: any) => `${row?.product?.modal_number}` },
        { name: 'Brand Color', selector: (row: any) => row.color },
        { name: 'Glass Size', selector: (row: any) => row?.product?.glass_size },
        { name: 'SRP (₹)', selector: (row: any) => `₹${numberWithCommas(Number(row?.product?.product_srp || 0))}` },
        { name: 'Status', selector: (row: any) => row.isActive },
    ]

    const coloredDivs = (color: any, index: number) => {
        if (color?.product?.specx_colors?.length === 2) {
            // Handle the case where there are two colors
            const [color1, color2] = color?.product?.specx_colors;

            return (
                <div key={`color${index}`} className="diagonal-color-div cursor-pointer"
                    data-tooltip-id={`color${index}`}
                    data-tooltip-content={color?.brand_color_name}
                >
                    <div
                        className="diagonal-color-div cursor-pointer rounded-circle"
                        style={{ backgroundColor: color1?.color_code }}
                    ></div>
                    <Tooltip id={`color${index}`} />
                </div>
            );
        } else if (color?.product?.specx_colors?.length === 1) {
            // Handle the case where there is only one color
            const singleColor = color?.product?.specx_colors;
            return (
                <div key={`color${index}`}
                    style={{ backgroundColor: singleColor[0].color_code, }}
                    className="color-div cursor-pointer"
                    data-tooltip-id={`color${index}`}
                    data-tooltip-content={color.brand_color_name}
                >

                    <Tooltip id={`color${index}`} />
                </div>
            );
        } else {
            return null; // Handle other cases or add error handling as needed
        }
    };

    return (
        <>
            <div className="d-flex align-items-center gap-3">
                <div className="">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} className="w-100"/>
                </div>
                <div className=''>
                    <div className="" style={{ color: "#000000", minWidth: "230px" }}>
                        <Select
                            className={"w-100 select-min-height"}
                            options={storeData}
                            onChange={(e: any) => handleChange(e)}
                            placeholder={"Select Store"}
                            isMulti={true} />
                    </div>
                </div>
            </div>
            {(moverOutBarcode?.data?.length > 0) && <div>
                <Table
                    columns={columns}
                    data={moverOutBarcode?.data?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            color: <> {coloredDivs(item, index)}</>,
                            isActive: item.is_active ? (
                                <div className="background-light-green border-product-radius">
                                    <p className="color-green m-1 p-1">{constant.STATUS.ACTIVE}</p>
                                </div>) : <div className="background-light-red border-product-radius">
                                <p className="color-red m-1 p-1">{constant.STATUS.DEACTIVE}</p>
                            </div>
                        })
                    })}
                />
            </div>}

            {(moverOutBarcode?.data?.length > 0) && <Pagination
                totalCount={moverOutBarcode?.count}
                currentPage={currentPage}
                totalPages={moverOutBarcode?.totalPages}
                onChangePage={(page: any) => setCurrentPage(page)}
                setLimit={(e: number) => setLimitPerPage(e)}
                limit={limit}
            />}
        </>
    )
}

export default Index;