import React from "react";
import { PriceRangeProp } from "./interface";
// import Slider from "@mui/material/Slider";
import atoms from "../../atoms";
const { Input }= atoms
const PriceRange = (props: PriceRangeProp) => {
    const { range, onChange, handleInputPrice } = props
    return (
        <>
            {/* <div className="d-flex py-1" style={{ padding: "20px" }}>
                <Slider value={range} onChange={onChange} min={0} max={50000} valueLabelDisplay="auto" />
            </div> */}
            <div className="d-flex gap-3">
                <div className="fs-16 text-secondary">
                    {/* Min Price:  */}
                    <Input
                             id="minPrice"
                             type="number"
                             name="minPrice"
                             onChange={handleInputPrice}
                             placeholder="Min Price"
                             value={range.minPrice}
                             className="price-ragne-height"
                             label={"Min Price"}
                    />
                </div>
                <div className="fs-16 text-secondary">
                    {/* Max Price:   */}
                      <Input
                             id="minPrice"
                             type="number"
                             name="maxPrice"
                             onChange={handleInputPrice}
                             placeholder="Max Price"
                             value={range.maxPrice}
                             className="price-ragne-height"
                             label={"Max Price"}
                    />
                </div>

            </div>
        </>
    )
}

export default PriceRange