import React, { useState } from "react";
import { RiDeleteBin6Line } from 'react-icons/ri'
import constant from "../../../../constants/constant";
import { numberWithCommas } from "../../../../constants/utils";
import moment from "moment";
import atoms from "../../../atoms";
import molecules from "../../../molecules";

const { Input, Table, Button, Pagination, Select } = atoms
const { CreateModal, ConfirmationModal } = molecules

const Index = (
    {
        listData, accountForm, setAccountForm,
        createAccount, DeleteAccount,getAccountCode,
        showPopup, setShowPopup,
        handleChange, numericCode,  dropDownOption,
        limitPerPage, currentPage, setCurrentPage, setLimitPerPage,
    }: any) => {

    const [deletePopup, setDeletePopup] = useState<boolean>(false)
    const columns = [
        { name: 'Account Number', selector: (row: any) => `${row?.gl_number ? row?.gl_number : "--"}` },
        { name: 'Date', selector: (row: any) =>row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : "-", },
        { name: 'Name', selector: (row: any) => row?.gl_account_name, sortable: true },
        { name: 'Account Balance', selector: (row: any) => `${numberWithCommas(parseFloat(row.balance?.toFixed(2)))}` },
        { name: 'Account Sub Type', selector: (row: any) => row?.account_sub_type?.name, sortable: true },
        { name: 'Action', selector: (row: any) => row.delete, },
    ]

    const emptyData = () => {
        setAccountForm((prevState: any) => ({
            ...prevState,
            gl_account_name: "",
            account_sub_type_id: [],
            account_holder_id: "",
            type: [],
            id: "",

        }));
    }

    return (
        <>
            {listData?.data?.length > 0 && <div>
                <Table
                    columns={columns}
                    data={listData?.data?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            delete: (

                                <>
                                    <Button className="table-delete-button fs-14 fw-400 ms-3"
                                        onClick={() => { setAccountForm({ ...accountForm, id: item?.id }); setDeletePopup(!deletePopup) }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <RiDeleteBin6Line />
                                            <span className='ms-2 mb-0'>Delete</span>
                                        </div>
                                    </Button>
                                </>

                            ),

                        })
                    })}
                />
            </div>}
            <div>
                <Pagination
                    totalCount={listData?.count}
                    currentPage={currentPage}
                    totalPages={listData?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limitPerPage}
                />
            </div>
            <div>
                {showPopup && <CreateModal
                    show={showPopup}
                    header={`${"Create Account"}`}
                    handleSubmit={() => { createAccount() }}
                    submitBtnText={`Submit`}
                    onHide={() => { setShowPopup(!showPopup); emptyData() }}
                >
                    <div>
                        <form>
                            <div className="row">
                                <div className="mt-25">
                                    <Input
                                        type={'text'}
                                        label={"Name"}
                                        placeholder={"Enter Name"}
                                        onChange={(e: any) => {
                                            handleChange("gl_account_name", e.target.value)
                                        }} />

                                </div>
                                <div className="mt-25">
                                    <Select
                                        label={"Account Type"}
                                        options={constant.ACCOUNT_HOLDER_TYPES}
                                        name={"type"}
                                        value={accountForm?.type}
                                        className={"w-100"}
                                        placeholder={"Select Account Type"}
                                        onChange={(e: any) => { handleChange("type", e) }}
                                    />
                                </div>
                                {/* accountHolderDropDown */}
                                <div className="mt-25">
                                    <Select
                                        label={"Select Account Holder type"}
                                        options={dropDownOption?.accountHolder}
                                        value={accountForm?.account_holder_id}
                                        name={"type"}
                                        className={"w-100"}
                                        placeholder={"Select Holder Account Type"}
                                        isDisabled={(accountForm?.type?.value==4)}
                                        onChange={(e: any) => { handleChange("account_holder_id", e) }}
                                    />
                                </div>
                                <div className="mt-25">
                                    <Select
                                        label={"Sub-Account Type"}
                                        options={dropDownOption?.subType}
                                        name={"type"}
                                        // value={accountForm?.account_sub_type_id}
                                        className={"w-100"}
                                        placeholder={"Select Sub-Account Type"}
                                        onChange={(e: any) => { handleChange("account_sub_type_id", e); getAccountCode(e.value) }}
                                    />
                                </div>

                                <div className='d-flex mt-3 p-1 gap-2'>
                                    <div className='inputs-label mb-2 fs-16 fw-600 p-2 secondary-color'><span>Numeric Code :-</span></div>
                                    <div className='text-center p-2'>
                                        <span>{`${numericCode?.accNumberCount ? numericCode?.accNumberCount : ""}`}</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </CreateModal>}
            </div>
            <div>
                {deletePopup && <ConfirmationModal
                    show={deletePopup}
                    handleConfirmItem={() => { DeleteAccount(); setDeletePopup(!deletePopup) }}
                    onHide={() => setDeletePopup(!deletePopup)}
                    message={'delete this account'} />}
            </div>
        </>
    )
}

export default Index