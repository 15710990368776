import React from 'react';
import molecules from '../../../molecules';
import atoms from '../../../atoms';
import { BrandAllocation,BrandData } from './interface';

const { BrandTable } = molecules
const { Button } = atoms
const AddBrandAllocation = ({ brandList, setBrandList, search, setSearch, selectedBrand, setSelectedBrand, setButtonClick }: BrandAllocation) => {
    return (
        <div>
            <div>
                <BrandTable brandList={brandList} setBrandList={setBrandList} step={0} search={search}
                    setSearch={setSearch}
                    selectedBrand={selectedBrand}
                    setSelectedBrand={(data:BrandData) => setSelectedBrand(data)}
                />
            </div>
            <div className="d-flex justify-content-center mt-4">
                <Button name="Add Brand"  onClick={() => { setButtonClick(true) }} />
            </div>
        </div>

    )
}
export default AddBrandAllocation