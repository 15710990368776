import React, { useState, useEffect } from 'react'
import atoms from '../../../atoms'
import molecules from '../../../molecules'
import { CiFilter } from "react-icons/ci";
import { colors } from 'react-select/dist/declarations/src/theme';
import { MdPadding } from 'react-icons/md';
import { Default_Page_Number } from '../../../../constants/defaultValues'
import { convertJsonToExcel } from '../../../../constants/utils'
const { Input, Select, Button, Pagination } = atoms
const { QuantityTable, PriceRange } = molecules

const Index = ({
  brandListing,
  searchData,
  setSearchData,
  _handleSearch,
  listingData,
  range,
  limit,
  handlePriceRange,
  handleInputPrice,
  setLimitPerPage,
  isOpen,
  setIsOpen,
  noDataText,
  storeData,
}: any) => {
  // const [isOpen, setIsOpen] = useState(true);
  const [newCurrentPage, setNewCurrentPage] = useState(Default_Page_Number);
  const [displayedData, setDisplayedData] = useState([]);
  const [pageIndex, setPageIndex] = useState({
    tableStartIndex: 0,
    tableEndIndex: 0
  })
  const handleChange = (name: string, value: any) => {
    setSearchData((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const handleCloseFilter = () => {
    setIsOpen(!isOpen)
  }

  // Download Excel
  const handleDownloadExcel = () => {
    let excelData: any = [];
    listingData.forEach((item: any) => {
      excelData.push({
        Product_Id: item.product_id_display,
        SRP: item.product_srp,
        Good_Count: parseFloat(item.inventory_quantity),
        Impair_Count: item.impair_count || 0,
        Minimum_Quantity: item.min_quantity_required_for_sale,
      })
    });
    convertJsonToExcel(excelData, "Inventory_Quantity_Excel")
  }

  const totalPages = Math.ceil(listingData?.length / limit);
  useEffect(() => {
    if (listingData) {
      if (newCurrentPage) {
        const startIndex = (newCurrentPage - 1) * limit;
        const endIndex = startIndex + limit;
        const slicedData = listingData?.slice(startIndex, endIndex);
        setPageIndex({ ...pageIndex, tableStartIndex: startIndex, tableEndIndex: endIndex })
        setDisplayedData(slicedData);
      }
    }
  }, [newCurrentPage, listingData, limit]);

  useEffect(() => {
    setNewCurrentPage(1)
  }, [limit])

  const handlePageChange = (newPage: any) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setNewCurrentPage(newPage);
    }
  };

  const mystyle = {
    height: "45px",
    margin: 0,
    background: isOpen && "#05B7E4",
    color: isOpen && "#fff",
  };

  return (
    <>
      <div className='col-12 d-flex justify-content-end'>
        <Button className='d-flex align-items-center border button-transparent rounded' style={mystyle} onClick={handleCloseFilter}>
          <div className='fs-18 me-2 '>
            <CiFilter className='fw-600' />
          </div>
          <div className='fs-16 fw-400'>Filter</div>
        </Button>
        <Button name='Download Excel' onClick={() => handleDownloadExcel()} style={mystyle} className='mx-3' />
      </div>
      <>
        {isOpen && (
          <div className='p-3 mt-3' style={{ background: "transparent", border: "1px solid #BFBFBF30" }}>
            <div className="row">
              <div className="col-3">
                <Input type={"text"} name="product_id" onChange={(e: any) => handleChange("product_id", e.target.value.trim())}
                  value={searchData.product_id} placeholder={"Enter Product Id"}
                  label={"Product Id"} />
              </div>
              <div className="col-3">
                <Input type={"text"} name="modal_number" onChange={(e: any) => handleChange("modal_number", e.target.value.trim())}
                  value={searchData.modal_number} placeholder={"Enter Model No"}
                  label={"Model No"} />
              </div>
              <div className="col-3">
                <Select className={"w-100"} options={brandListing} value={searchData.brand}
                  onChange={(e: any) => handleChange("brand", e)} placeholder={"Select"}
                  label={"Select Brand"} isMulti={true} />
              </div>
              <div className="col-3">
                <PriceRange range={range} onChange={handlePriceRange} handleInputPrice={handleInputPrice} />
                {/* <Input type={"text"} name="price" onChange={(e: any) => handleChange("price", e.target.value)}
            value={searchData.price} placeholder={"Select Price Range"}
            label={"Price"} /> */}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Select
                  className={"w-100"}
                  options={storeData}
                  value={searchData.store}
                  onChange={(e: any) => handleChange("store", e)}
                  placeholder={"Select"}
                  label={"Select Stores"}
                  isMulti={true} /*errors={errors.store_name}*/
                />
              </div>
              <div className="col-9"></div>
            </div>
            {/* <div className="row mt-4">

              <div className="col-6">
                <Select className={"w-100"} options={brandListing} value={searchData.brand}
                  onChange={(e: any) => handleChange("brand", e)} placeholder={"Select"}
                  label={"Select Brand"} isMulti={true} />
              </div>
              <div className="col-6">
                <PriceRange range={range} onChange={handlePriceRange} handleInputPrice={handleInputPrice} />
              </div>
            </div> */}
            <div className=" mt-4 d-flex justify-content-end">
              {/* <Button name='Download Excel' /> */}
              <Button
                name="Search"
                isDisabled={
                  !searchData?.modal_number &&
                  !searchData?.product_id &&
                  (!range.maxPrice || !range.minPrice) &&
                  !(searchData?.store.length > 0) &&
                  !(searchData?.brand.length > 0)
                }
                onClick={() => _handleSearch()}
              />
            </div>
          </div>
        )
        }
      </>
      {listingData?.length > 0 ? 
      <div className="row">
        <div className="col">
          <QuantityTable
            listingData={displayedData}
            _handleSearch={_handleSearch}
          // setBrandList={setSelectedBrand} setSelectedBrand={setSelectedBrand} 
          // search={search}
          // setSearch={setSearch} 
          />
        </div>
        <div className={`${
          // isOpen && 
          'position-relative'}`} style={{ top: "12px" }}>
          <Pagination
            totalCount={listingData?.length}
            currentPage={newCurrentPage}
            totalPages={totalPages}
            onChangePage={(page: any) => handlePageChange(page)}
            setLimit={(e: number) => setLimitPerPage(e)}
            limit={limit}
          />
        </div>
      </div> :
        <div className='mt-3 d-flex justify-content-center'>
          {noDataText && <span>There are no records to display</span>}
        </div>
      }

    </>
  )
}

export default Index