import React, { useState, useEffect, useContext } from "react";
import { HeaderHeading } from "../../../../Context";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from 'react-toast-notifications';
import service from "../../../../api/services";
import { getDropdown, handleReportDownload } from "../../../../constants/utils"
import constant from "../../../../constants/constant";

const { InvoicingReport } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [storeData, setStoreData] = useState();
    const [reportData, setReportData] = useState({
        store_ids: [],
        created_from: Date,
        created_to: Date,
        report_format: NaN,
    })
    const { addToast } = useToasts();



    const getInvoiceData = async () => {
        try {
            let storeResponse = await service.inventory.storeView()
            if (storeResponse.status === 200) {
                setStoreData(getDropdown(storeResponse?.data?.data, 'store_location' ,'store_name'))
            }
        } catch (error) {
            console.log(error)
        }
    }



    const handleSubmit = async () => {
        const storeIds = reportData?.store_ids?.map((item: any) => item.value)

        const payload = {
            "store_ids": storeIds
        }
        try {
            const response = await service.reportService?.invoicing(payload, { created_from: reportData?.created_from, created_to: reportData?.created_to })
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "invoicing-report", reportData?.report_format, "Specxweb", true)
            }
        }
        catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleChange = (name: string, value: any) => {
        setReportData({ ...reportData, [name]: value })
    }

    useEffect(() => {
        setHeader("Report - Invoicing Report")
        getInvoiceData()
    }, [])
    return (
        <Wrapper>
            <InvoicingReport
                handleChange={(name: string, value: any) => handleChange(name, value)}
                handleSubmit={() => handleSubmit()}
                storeData={storeData}
                reportData={reportData} />
        </Wrapper>
    )
}

export default Index