import React, { useContext, useEffect, useState } from "react";
import { HeaderHeading } from "../../../../Context";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import service from "../../../../api/services";
import organisms from "../../../../components/organisms";
import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues";

const { LostBarcodeHistory } = organisms

const Index = () => {
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [markBarcode, setMarkBarcode] = useState<boolean>(false)
    const [search, setSearch] = useState("")
    const [pageParams, setPageParams] = useState({
        currentPage: Default_Page_Number,
        limitPerPage: Default_Page_Limit
    })
    const [lostBarcode, setLostBarcode] = useState<any>()
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);

    const getLostBarcode = async () => {
        try {
            let response = await service.barcodeStatus.getLostBarcode({ "page": pageParams?.currentPage, "search": search, "limit": pageParams?.limitPerPage })
            if (response.status === 200) {
                setLostBarcode(response.data)
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    const handleChangeParams = (name: string, value: number) => {
        setPageParams({ ...pageParams, [name]: value })
    }

    useEffect(() => {
        setHeader("Inventory - Lost Barcode History")
        getLostBarcode()
        setSidebar(false)
    }, [pageParams.currentPage])

    useEffect(() => {
        handleChangeParams("currentPage", 1)
        if (pageParams.currentPage == 1 && initialLoading) {
            getLostBarcode()
        }
        setInitialLoading(true)
    }, [pageParams.limitPerPage, search])

    return (
        <Wrapper>
            <LostBarcodeHistory
                setSearch={(value: string) => setSearch(value)}
                lostBarcode={lostBarcode}
                currentPage={pageParams?.currentPage}
                setCurrentPage={(page: number) => handleChangeParams("currentPage", page)}
                setLimitPerPage={(limit: number) => handleChangeParams("limitPerPage", limit)}
                limit={pageParams?.limitPerPage}
                setMarkBarcode={(value: boolean) => setMarkBarcode(value)}
                markBarcode={markBarcode}
            />
        </Wrapper>
    )
}

export default Index