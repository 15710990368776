import React, { useEffect, useState } from 'react';
import './checkbox.scss'
import { TreeNode } from './interface';

const TreeNodeComponent: React.FC<{ node: TreeNode, selectedCodes: string[], setSelectedCodes: Function }> = ({ node, selectedCodes, setSelectedCodes }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const selectedCode = selectedCodes ? selectedCodes : []
    useEffect(() => {
        if (selectedCode && selectedCode.includes(node.perm_code)) {
            setIsChecked(true);
            setIsOpen(true);
        } else {
            setIsChecked(false);
            setIsOpen(false);
        }
    }, [selectedCode, node.perm_code]);

    const handleCheckboxChange = (code: string, children: TreeNode[]) => {
        let updatedSelectedCodes;
        if (isChecked) {
            // Function to get all descendant codes
            const getAllDescendantCodes = (nodes: TreeNode[]) => {
                let codes: string[] = [];
                nodes.forEach(node => {
                    codes.push(node.perm_code, ...getAllDescendantCodes(node.child || []));
                });
                return codes;
            };

            // Get all descendant codes and remove them from selectedCodes
            const descendantCodes = getAllDescendantCodes(children);
            updatedSelectedCodes = selectedCode.filter((item: string) =>
                item !== code && !descendantCodes.includes(item));
        } else {
            updatedSelectedCodes = [...selectedCode, code];
        }

        setIsChecked(!isChecked);
        setIsOpen(!isOpen);
        setSelectedCodes(updatedSelectedCodes);
    };

    return (
        <li className='list-unstyled permission-checkbox '>
            <label>
                <input type="checkbox" className=' me-2 my-2'
                    checked={isChecked}
                    onChange={() => handleCheckboxChange(node.perm_code, node.child)} />
                {node.module_name}
            </label>
            {isOpen && node.child.length > 0 && (
                <ul>
                    {node.child.map((child: any, index: number) => (
                        <TreeNodeComponent key={index} node={child} selectedCodes={selectedCodes}
                            setSelectedCodes={setSelectedCodes} />
                    ))}
                </ul>
            )}
        </li>
    );
};

const UserPermissionUI = ({ structuredPermission, handleCheckboxChange, defaultPermission }: any) => {
    const [selectedCodes, setSelectedCodes] = useState<string[]>([]);

    const extractPermCodes = (permission: any) =>
        [
            permission.perm_code,
            ...(permission.child ? permission && permission.child.flatMap(extractPermCodes) : [])
        ];

    useEffect(() => {
        if (defaultPermission) {
            const extractedCodes = defaultPermission && defaultPermission.flatMap(extractPermCodes);
            setSelectedCodes(extractedCodes);
        }
        // handleCheckboxChange(selectedCodes);
    }, [defaultPermission]);
    useEffect(() => {
        handleCheckboxChange(selectedCodes);
    }, [selectedCodes]);


    return (
        <div id="tree">
            <ul>
                {structuredPermission && structuredPermission.map((node: any, index: number) => (
                    <TreeNodeComponent key={index} node={node} selectedCodes={selectedCodes}
                        setSelectedCodes={setSelectedCodes}
                    />
                ))}
            </ul>
        </div>
    );
};

export default UserPermissionUI;
