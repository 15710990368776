import React from 'react';
import './Stepper.scss'; 
import atoms from '../../atoms';
const { Button } = atoms
export const Stepper = ({ steps, step }: any) => {


  return (
    <section className="d-none d-md-block border-bottom pb-4 mb-5">
      <div className="container">
        <ul className="list-unstyled multi-steps">
          {steps.map((stepTitle: any, index: number) => (
            <li
              key={index}
              className={`${step === index ? 'is-active' : ''} fs-14 fw-600 main-color`}
            >
              {stepTitle}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};


export const BottomNav = ({ steps, handleSubmit, setStep, step,handleNextStep,handleCancel }: any) => {

  const handleNext = () => {
    let validation = handleNextStep()
    if (validation) {
      if (step < steps.length - 1) {
        setStep(step + 1);
      }
    }
  };

  const handlePrevious = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };
  return (
    <div className="step-buttons d-flex justify-content-center mt-4">
      {step !== 0 && <Button name={'Previous'} onClick={handlePrevious}
        className={`w-25`} />}
      {(step !== (steps.length - 1)) && <Button name={'Next'} onClick={handleNext}
        className={`w-25 ms-2`}
      />}
      {step === steps.length - 1 && <Button name={'Submit'} onClick={handleSubmit}
        className={`w-25 ms-2`}
      />}
        {step !== 0 && <Button name={'Cancel'} onClick={()=>handleCancel()}
        className={`ms-2 w-25 background-red`}/>}
    </div>
  )
}
