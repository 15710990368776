import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import { HeaderHeading } from '../../../../Context'
import { getDropdown } from '../../../../constants/utils'
const { MovementPending } = organisms
const Index = () => {
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);
  const [pendingListing, setPendingListing] = useState<any>()
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState<number>(1);
  const [limit, setLimit] = useState(Default_Page_Limit);
  const [storeData, setStoreData] = useState();
  const [selectedId, setSelectedId] = useState<string>('')

  const getPendingMomentsList = async () => {
    try {
      const response = await service.movement.pendingListing({ search: search, page: pageCount, limit: limit, status: '1' });
      if (response.status == 200) {
        setPendingListing(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const storeListing = async (selectedId: string) => {
    try {
      let response = await service.movement.storeListing(selectedId)
      if (response.status === 200) {
        let dropdown: any = response?.data?.data.map((value: any) => ({
          "value": value.id,
          "label": `${value.store_name} (Total : ${value.total_product_count}) (Impaired : ${value.impair_product_count})`,
        }))
        setStoreData(dropdown)
        // setStoreData(getDropdown(response?.data?.data, 'store_name'))
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    setHeader("Movement - Pending")
    setSidebar(false)
    getPendingMomentsList()
  }, [ pageCount])

  useEffect(() => {
    setPageCount(1)
    if (pageCount == 1 && initialLoading) {
      getPendingMomentsList()
    }
    setInitialLoading(true)
  }, [limit,search])

  return (
    <Wrapper>
      <MovementPending
        limit={limit}
        currentPage={pageCount}
        pendingListing={pendingListing}
        setSearch={setSearch}
        setLimitPerPage={setLimit}
        setCurrentPage={setPageCount}
        storeListing={(id: string) => storeListing(id)}
        storeData={storeData}
        setSelectedId={setSelectedId}
        getPendingMomentsList={getPendingMomentsList}
      />
    </Wrapper>
  )
}

export default Index