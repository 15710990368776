import React, { useState, useEffect, useContext } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import service from "../../../../api/services";
import constant from "../../../../constants/constant";
import { findConstantLabel } from "../../../../constants/utils"
import organisms from "../../../../components/organisms";
import { useToasts } from 'react-toast-notifications';
import { HeaderHeading } from '../../../../Context'

const { SubAccount } = organisms

const Index = () => {
    const { addToast } = useToasts();
    const [listData, setListData] = useState([])
    const [showPopup, setShowPopup] = useState<boolean>(false)

    const { setHeader }: any = useContext(HeaderHeading);
    const [accountForm, setAccountForm] = useState<any>({
        name: "",
        numeric_code: null,
        type: [],
        id: ""
    })

    useEffect(() => {
        setHeader("Accounts - Sub Types")
        accountList()
    }, [])

    const getDropdown = (response: any, name: string) => {
        let dropdown = {
            "key": response.id,
            "value": response[name],
            "label": findConstantLabel(constant.Account_type, response[name]),
        }
        return dropdown
    }

    const accountList = async () => {
        try {
            const response = await service.AccountsServices.listSubAccount();
            setListData(response?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const accountDetail = async (id: string) => {
        try {
            const response = await service.AccountsServices.detailSubAccount(id);
            setAccountForm({
                ...accountForm, name: response.data.data.name,
                numeric_code: response.data.data.numeric_code,
                type: getDropdown(response.data.data, "type"),
                id: response.data.data.id
            })

            // setAccountForm({ ...accountForm, type: getDropdown(response.data.data, "type") })
        } catch (error) {
            console.log(error)
        }
    }

    const createAccount = async () => {
        const payload = {
            name: accountForm?.name,
            type: accountForm?.type.value,
            numeric_code: `${accountForm?.type.value}${accountForm?.numeric_code}`
        }
        try {
            let response = await service.AccountsServices.createSubAccount(payload);
            if (response.status == 201) {
                setShowPopup(false)
                addToast('Sub-Account Create Successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
            accountList()
        } catch (error: any) {
            console.log("error", error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const DeleteAccount = async () => {
        try {
            let response = await service.AccountsServices.deleteSubAccount(accountForm?.id);
            if (response.status == 200) {
                addToast('Sub-Account Delete Successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log(error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        accountList()
    }

    const UpdateAccount = async (id: string) => {
        const payload = {
            name: accountForm?.name
        }
        try {
            let response = await service.AccountsServices.UpdateSubAccount(payload, id);
            if (response.status == 200) {
                setShowPopup(false)
                addToast('Sub-Account Create Successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
            accountList()
        } catch (error: any) {
            console.log(error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleChange = (name: string, value: any) => {
        setAccountForm({ ...accountForm, [name]: value })
    }


    return (
        <>
            <Wrapper>
                <SubAccount
                    listData={listData}
                    setAccountForm={setAccountForm}
                    accountForm={accountForm}
                    createAccount={() => createAccount()}
                    accountDetail={(id: string) => accountDetail(id)}
                    UpdateAccount={(id: string) => UpdateAccount(id)}
                    DeleteAccount={() => DeleteAccount()}
                    setShowPopup={setShowPopup}
                    showPopup={showPopup}
                    handleChange={(name: string, value: any) => handleChange(name, value)} />
            </Wrapper>
        </>
    )
}

export default Index