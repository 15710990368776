import React from "react";
import atoms from "../../../atoms";
import constant from "../../../../constants/constant";

const { Input, Select, Button } = atoms

const Index = ({ handleChange, handleSubmit, reportData }: any) => {
    const parsedDate1 = new Date(reportData?.report_date);

    return (
        <>
            <div className="row">
                <div className="col-4">
                    <Input label={"Report Date"}
                        placeholder={"Report Date"} type="date"
                        onChange={(e: any) => { handleChange("report_date", e) }}
                    />
                </div>
                <div className="col-4">
                    <Select
                        className="w-100"
                        options={constant.DailyReportFormat}
                        onChange={(e: any) => handleChange("report_format", e.value)} placeholder={"Select"}
                        label={"Select Report Format"} />
                </div>
            </div>
            <div className="d-flex justify-content-center" style={{marginTop:"10rem"}}>
                <Button isDisabled={isNaN(parsedDate1?.getTime()) || !reportData?.report_format} onClick={() => handleSubmit()} style={{ minWidth: "259px" }}>Submit</Button>
            </div>
        </>
    )
}

export default Index