import React, { useState } from 'react'
import atoms from '../../../atoms'
import molecules from '../../../molecules'
import './style.scss'
import { useParams } from 'react-router'
import { emailValidation, handleNumber } from '../../../../constants/utils'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
const { Input, Button, Select } = atoms
const { CheckboxDropdown, TopBar, CreateModal } = molecules
const AddSubAdmin = ({ setSubAdminDetails, subAdminDetails, structuredPermission,
  _handleSubmitSubAdmin, defaultPermission, rolesList, errors, handleSelectedRole, showPassword, setShowPassword, handleChangePassword, setErrors }: any) => {
  const [emailError, setEmailError] = useState('')
  const [createModal, setCreateModal] = useState<boolean>(false)
  const [password, setPassword] = useState({
    newPassword: "",
    conformPassword: "",
    showPassword: false,
    showConformPassword: false,
  })

  let params = useParams();
  let subAdminId: string = params.id || ''
  const handleChange = (name: string, value: any) => {
    setSubAdminDetails((prevState: any) => ({ ...prevState, [name]: value }));
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
  };
  const _handleCheckboxChange = (codeArr: []) => {
    setSubAdminDetails((prevState: any) => ({ ...prevState, permissions: codeArr }));
  }
  const emailValidationMessage = (email: string) => {
    if (!emailValidation(email)) {
      setEmailError("Invalid Email")
    } else {
      setEmailError("")
    }
  };

  const changePassword = () => {
    handleChangePassword(password?.newPassword)
    handleCloseModal()
  }

  const handleCloseModal = () => {
    setPassword({ ...password, newPassword: "", conformPassword: "" })
    setCreateModal(!createModal)
  }


  return (
    <div>
      <div className='row align-items-center mb-4'>
        <div className="col-6">
          <TopBar heading={subAdminId ? 'Update Sub Admin' : 'Add New'} />
        </div>
        {/* <div className='col-6 '>
          <h4 className='mb-0 fw-600'>{subAdminId ? 'Update Sub Admin' : 'Add New'}</h4>
        </div> */}
      </div>
      <div className='row mt-3'>
        <div className='col-4'>
          <Input label={"User Id"} type={'text'} placeholder={"Enter User Id"}
            value={subAdminDetails.user_id}
            onChange={(e: any) => { handleChange("user_id", e.target.value) }}
            errors={errors.user_id}
          />
        </div>
        <div className='col-4'>
          <Input label={"User Name"} type={'text'} placeholder={"Enter Name"}
            value={subAdminDetails.username}
            onChange={(e: any) => { handleChange("username", e.target.value) }}
            errors={errors.username}
          />
        </div>
        <div className='col-4'>
          <Input label={"Contact Number"} type={'number'} placeholder={"Enter Mobile Number"}
            value={subAdminDetails.mobile}
            onChange={(e: any) => { handleChange("mobile", e.target.value) }}
            errors={errors.mobile}
          />
        </div>

      </div>
      <div className='row mt-3'>
        <div className='col-4'>
          <Input label={"Email Address"} type={'email'} placeholder={"Enter Email Address"}
            value={subAdminDetails.email}
            // onChange={(e: any) => { handleChange("email", e.target.value) }}
            onChange={(e: any) => { handleChange("email", e.target.value), emailValidationMessage(e.target.value) }}
            errors={emailError ? emailError : errors.email}
          />
        </div>
        <div className="col-4 position-relative">
          <div className='d-flex justify-content-between'>

            {<label className='inputs-label mb-2 fs-16 fw-600  secondary-color' htmlFor="password">Password</label>}
            {subAdminId &&
              <a className='fs-14' onClick={(() => { setCreateModal(!createModal) })} style={{ cursor: "pointer" }}>Change Password</a>
            }
          </div>
          <Input
            type={`${showPassword ? "text" : "password"}`}
            placeholder={"Enter Password"}
            value={subAdminId ? "********" : subAdminDetails.password}
            disabled={subAdminId && true}
            onChange={(e: any) => { handleChange("password", e.target.value) }}
            errors={!subAdminId && errors.password}
          />
          {!subAdminId && <span className='position-absolute cursor-pointer' style={{ top: '49%', right: '10%', fontSize: "20px" }} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </span>}

        </div>

        <div className="col-4">
          <Select type={"text"} className={"w-100"}
            options={rolesList}
            value={subAdminDetails.role_id}
            onChange={(e: any) => handleSelectedRole(e)}
            placeholder={"Select"} label={"Select Role"}
            errors={errors?.role_id} />

        </div>
      </div>
      <div className="row mt-3">
        <div className='col-6'>
          <label className='inputs-label mb-2 fs-16 fw-600  secondary-color'>User Permission</label>
          <div className='member-permission'>
            <CheckboxDropdown
              structuredPermission={structuredPermission}
              handleCheckboxChange={(codeArr: []) => _handleCheckboxChange(codeArr)}
              defaultPermission={defaultPermission}
            />
          </div>
        </div>
      </div>
      <div className='justify-content-center d-flex mt-4'>
        <Button name={subAdminId ? 'Update Member' : "Add Member"} className={"w-25 m-4 "} onClick={() => _handleSubmitSubAdmin(true)} />
      </div>

      {createModal && <CreateModal
        show={createModal}
        header={' New Password'}
        handleSubmit={() => changePassword()}
        buttonDisable={!(password?.conformPassword == password?.newPassword) || !password.newPassword}
        submitBtnText={"Change Password"}
        onHide={() => handleCloseModal()}
      >
        <div>
          <form>
            <div className=" mt-25">
              <Input
                type={`${(password?.showPassword) ? "text" : "password"}`}
                className="form-control"
                placeholder="Enter Password"
                onChange={(e: any) => setPassword({ ...password, newPassword: e.target.value })}
                // defaultValue={storeDetails.admin_change_password}
                label='New Password'
              />
              <span className='position-absolute cursor-pointer' style={{ top: '33%', fontSize: "20px", right: '10%' }} onClick={() => setPassword({ ...password, showPassword: !(password?.showPassword) })}>{password?.showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </span>
            </div>
            <div className='mt-4'>
              <Input
                type={`${(password?.showConformPassword) ? "text" : "password"}`}
                className="form-control"
                placeholder="Enter Password"
                onChange={(e: any) => setPassword({ ...password, conformPassword: e.target.value })}
                // defaultValue={storeDetails.admin_change_password}
                label='Confirm Password'
              />
              <span className='position-absolute cursor-pointer' style={{ top: '76%', right: '10%', fontSize: "20px", }} onClick={() => setPassword({ ...password, showConformPassword: !(password?.showConformPassword) })}>{password?.showConformPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </span>

            </div>
          </form>
        </div>
      </CreateModal>}
    </div>
  )
}

export default AddSubAdmin