const report = {
    customerReport: () => `admin/report/customer`,
    storeReport: () => `admin/report/store`,
    inventoryAdditionalInvoiceWiseReport: () => `admin/report/inventory-addition`,
    inventoryAdditionalBarcodeWiseReport: () => `admin/report/inventory-addition-barcode-wise`,
    invoicingReport: () => `admin/report/store-invoicing`,
    invoicingReturn: () => `admin/report/sales-return-invoicing`,
    barcodeAuditReport: () => `admin/report/barcode-audit-trail`,
    gstInputReport:()=>`admin/report/gst-input`,
    gstOutputReport: () => `admin/report/gst-output`,
    inventoryMovementReport: () => `admin/report/inventory-movement`,

    storeSalesInvoiceReport: () => `admin/report/store-sales/invoice`,
    storeSalesReturnReport: () => `admin/report/sales-return`,
    storeSalesMonthReport: () => `admin/report/store-sales/month`,
    storeSalesBrandReport: () => `admin/report/store-sales/brand`,

    ownershipTransferReport: () => `admin/report/ownership-transfer`,

    inventoryBarcodeReport: () => `admin/report/inventory/barcode-wise`,
    inventoryProductReport: () => `admin/report/inventory/product-wise`,

    // For Account Report
    glAccountReport: () => `admin/report/gl-account`,
    transactionReport: () => `admin/report/transactions`,
    trailBalanceReport: () => `admin/report/trail-balance`,
    storeBalanceReport: () => `admin/report/store-balances`,

    barcodeSearch:()=>'admin/report/barcode/search',
    generalAccountList:()=>'admin/report/account/search'

}
export default report 