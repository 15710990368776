import { useEffect, useState, useRef } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import atoms from '../../atoms'
// import molecules from '../../molecules'
import PlaceHolderImage from '../../../assets/Images/placeholderimg.png'
import constant from '../../../constants/constant'
import CopyIcon from '../../../assets/Images/copy-icon.svg'
import './index.scss'
import { RxCross2 } from "react-icons/rx";
import { findConstantLabel, numberWithCommas } from "../../../constants/utils"
import { useReactToPrint } from 'react-to-print';
import moment from 'moment'
import ConfirmationModal from '../ConfirmationModal'

const { Button, Image, Table, CustomImages } = atoms
// const {ConfirmationModal} = molecules
const SidePopUp = (props: any) => {
    const PdfRef = useRef(null);
    const handleUpdateStatus = (status: number) => {
        props.updateVerificationStatus(props?.isViewData?.id, status)
        props.onHide()
    }

    const [productDataTable, setProductDataTable] = useState<any>()
    const [inventoryConfirmation, setInventoryConfirmation] = useState<boolean>(false)
    const [rejectConfirmation, setRejectConfirmation] = useState<boolean>(false)
    useEffect(() => {
        if (props.isViewData?.product_details) {
            const tempProductData = [...props.isViewData?.product_details];
            if (tempProductData?.length > 0) {
                tempProductData.push({});
            }
            setProductDataTable(tempProductData);
        }
    }, [props.isViewData?.product_details]);

    const handlePrint = useReactToPrint({
        content: () => PdfRef.current,
        documentTitle: "Inventory" + Math.floor(100000 + Math.random() * 900000).toString()
    });

    // if (data !== null && typeof data !== 'undefined' && data.product && typeof type !== 'undefined') {
    //     // Perform the operation only if data and type are not null or undefined
    //     const productType = data.product[type];
    //     // Use productType or perform further operations
    // } else {
    //     // Handle the case where data or type is null or undefined
    //     console.log('Data or type is null or undefined.');
    // }

    const getNestedData = (data: any, type: string) => {
        let temp = 0
        data && data.map((value: any, index: number) => {
            if (type === 'quantity') {
                temp += value[type]
            } else {
                if (value?.product?.product_srp) {
                    temp += value?.product[type]
                }
            }
        })
        return temp
    }
    const columns = [
        { name: 'Product ID', selector: (row: any) => row?.product?.product_id_display || "", width: "130px" },
        { name: 'Product Type', selector: (row: any) => !row?.product?.product_type ? <span className='fw-600 fs-14'>Total</span> : row?.product?.product_type?.name, sortable: true, width: "110px" },
        { name: 'Product Image', cell: (row: any) => row?.product?.images?.length > 0 ? <CustomImages src={row?.product?.images?.length > 0 && row?.product?.images[0]} /> : "", center: true, width: "120px" },
        { name: 'Brand', selector: (row: any) => row?.product?.brand?.brand_name || "", width: "80px" },
        { name: 'Model Number', selector: (row: any) => row?.product?.modal_number || "", width: "90px" },
        { name: 'Brand Color Code', selector: (row: any) => row?.product?.brand_color_code || '', width: "100px" },
        { name: 'Glass Size', selector: (row: any) => row?.product?.glass_size || "", width: "80px" },
        { name: 'SRP (₹)', selector: (row: any) => !row?.product ? <span className='fw-600 fs-14'>₹{(numberWithCommas(parseFloat(totalSRP(props?.isViewData?.product_details)?.toFixed(2))))}</span> : row?.product?.product_srp ? numberWithCommas(parseFloat(row?.product.product_srp?.toFixed(2))) : "", width: "90px" },
        { name: 'QTY', selector: (row: any) => !row?.product ? <span className='fw-600 fs-14'>{getNestedData(props.isViewData.product_details, "quantity")}</span> : row?.quantity, width: "55px" },
        { name: 'Amount', selector: (row: any) => !row?.product ? <span className='fw-600 fs-14'>₹{numberWithCommas(parseFloat(props.isViewData.total_amount?.toFixed(2)))}</span> : numberWithCommas(parseFloat(row.total_price?.toFixed(2))), width: "90px" },
        {
            name: 'Verification Status', selector: (row: any) => row?.product?.verification_status === constant.VERIFICATION_STATUS.PENDING ?
                (<div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : row?.product?.verification_status === constant.VERIFICATION_STATUS.VERIFIED ?
                    (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Approved"}</p></div>) : row?.product?.verification_status === constant.VERIFICATION_STATUS.REWORK ?
                        (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"Rework"}</p></div>) : '', width: "120px"
        },
    ]

    const totalSRP = (data: Array<any>): number => {
        return data.reduce((total: number, item: any) => {
            const productSRP = item?.product?.product_srp;
            if (typeof productSRP === 'number' && !isNaN(productSRP)) {
                return total + productSRP;
            }
            return total;
        }, 0);
    };

    const size = '297mm 210mm';

    const getPageMargins = () => {
        return `
      @page {
         margin-top: 10mm; 
         margin-right: 10mm; 
         margin-bottom:10mm; 
         margin-left: 10mm; 
         size: 297mm 210mm; !important
        } 

        div.page {
            page-break-after: always;
          }
         `;
    };

    // Call the function to get the CSS string
    const cssString = getPageMargins();

    return (
        <Modal className="rounded-0"
            isOpen={props.show}
            wrapClassName="modal-right"
        >
            <ModalHeader className="d-flex border-0 position-relative" >
                {/* <div className="mt-4 fs-24 fw-600">{props.header}</div> */}
                <div className="mt-4 fs-24 fw-600 mb-0">Inventory</div>
                <div className="position-absolute py-1 px-2" style={{ top: '15%', right: '3%', backgroundColor: 'transparent', border: '1px solid #222', borderRadius: '50%', cursor: 'pointer' }} onClick={() => props.onHide()}><RxCross2 style={{ color: '#222' }} /></div>
            </ModalHeader>
            <ModalBody className="pt-md-0">
                <div className='d-flex justify-content-between'>
                    <div>
                        <div className=' d-flex gap-3 align-items-center mb-4'>
                            <h2 className='mb-0 fs-18 fw-700 secondary-color '>Invoice Addition ID:</h2>
                            <div className='d-flex justify-content-around align-items-center py-2 add-product-custom-div cursor-pointer' >
                                <h2 className='mb-0 fs-16 fw-600 secondary-color'>{props?.isViewData?.invoice_id_display}</h2>
                                <div className=''>
                                    <Image src={CopyIcon} onClick={() => navigator.clipboard.writeText(props?.isViewData?.invoice_id_display)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button isDisabled={!props.isViewData} onClick={handlePrint}>Download PDF</Button>
                    </div>
                </div>
                <hr className="mt-3" style={{ border: '1px dashed #222', }} />
                <div className='printAbleComponent' ref={PdfRef}>
                    <style>{cssString}</style>
                    <div className='row mt-4'>
                        <div className="col-4 fs-16 fw-800 secondary-color">Physical Store</div>
                        <div className="col-4 fs-16 fw-800 secondary-color">Supplier Invoice No</div>
                        <div className="col-4 fs-16 fw-800 secondary-color"> Supplier Invoice Date</div>
                    </div>
                    <div className='row'>
                        <div className="col-4 fs-16 fw-400 secondary-color">{props.isViewData?.physical_store?.store_name}</div>
                        <div className="col-4 fs-16 fw-400 secondary-color">{props.isViewData?.supplier_invoice_number || ""}</div>
                        <div className="col-4 fs-16 fw-400 secondary-color">{moment(props.isViewData?.supplier_invoice_date || "").format('DD-MM-YYYY')}</div>
                    </div>
                    <div className='row mt-4'>
                        <div className="col-4 fs-16 fw-800 secondary-color">Supplier Details</div>
                        <div className="col-4 fs-16 fw-800 secondary-color">Created Date</div>
                        <div className="col-4 fs-16 fw-800 secondary-color">Actual Invoice Value</div>
                    </div>

                    <div className='row'>
                        <div className="col-4 fs-16 fw-400 secondary-color">{props.isViewData?.supplier?.supplier_name || ""}</div>
                        <div className="col-4 fs-16 fw-400 secondary-color">{moment(props.isViewData?.createdAt).format('DD-MM-YYYY') || ""}</div>
                        <div className="col-4 fs-16 fw-400 secondary-color">₹{numberWithCommas(parseFloat(props.isViewData?.actual_invoice?.toFixed(2)))}</div>
                    </div>
                    <div className='row mt-4'>
                        <div className="col-4  fs-16 fw-800 secondary-color">Computed Invoice Value</div>
                        <div className="col-4  fs-16 fw-800 secondary-color">Total Product Case </div>
                        <div className="col-4  fs-16 fw-800 secondary-color">Total GST</div>
                    </div>
                    <div className='row'>
                        <div className="col-4 fs-16 fw-400 secondary-color">₹{numberWithCommas(parseFloat(props.isViewData?.total_amount?.toFixed(2))) || ""}</div>
                        <div className="col-4 fs-16 fw-400 secondary-color">{props.isViewData?.total_product_case || ""}</div>
                        <div className="col-4 fs-16 fw-400 secondary-color">₹{numberWithCommas(parseFloat(props.isViewData?.total_gst?.toFixed(2))) || ""}</div>
                    </div>
                    <div className='row mt-4'>
                        <div className="col-4  fs-16 fw-800 secondary-color">Category</div>
                    </div>
                    <div className='row'>
                        <div className="col-4 fs-16 fw-400 secondary-color">{findConstantLabel(constant.INVOICE_CATEGORY, props.isViewData?.category)}</div>
                    </div>

                    <div>
                        <Table
                            columns={columns}
                            data={productDataTable}
                            className="inventory-product-border" />
                    </div>
                    <hr className="mt-5" style={{ border: '1px dashed #222', }} />
                    <div className='row mt-4'>
                        <div className="col-4 fs-20 fw-600 secondary-color">Remarks</div>

                    </div>
                    <div className='row mt-4 add-inventory-custom-div' >
                        <div className="col-4 fs-20 fw-600 secondary-color">{props?.isViewData?.remark}</div>

                    </div>
                </div>
            </ModalBody>

            {props?.isViewData?.verification_status == constant.VERIFICATION_STATUS.PENDING &&
                <ModalFooter className="border-0 d-flex" style={{ flexWrap: 'inherit' }}>
                    <Button className="fs-16 fw-500 reject-btn"
                        onClick={() =>
                            setRejectConfirmation(!rejectConfirmation)
                        }>
                        Reject
                    </Button>{" "}

                    <Button className="fs-16 fw-500 approve-btn" onClick={() =>
                        setInventoryConfirmation(!inventoryConfirmation)
                    }>
                        Approve
                    </Button>
                </ModalFooter>
            }

            {inventoryConfirmation && <ConfirmationModal
                show={inventoryConfirmation}
                handleConfirmItem={() => { handleUpdateStatus(constant.VERIFICATION_STATUS.VERIFIED); setInventoryConfirmation(!inventoryConfirmation) }}
                onHide={() => setInventoryConfirmation(!inventoryConfirmation)}
                message={'approve inventory'} />}

            {rejectConfirmation && <ConfirmationModal
                show={rejectConfirmation}
                handleConfirmItem={() => { handleUpdateStatus(constant.VERIFICATION_STATUS.REWORK); setRejectConfirmation(!rejectConfirmation) }}
                onHide={() => setRejectConfirmation(!rejectConfirmation)}
                message={'reject'} />}

        </Modal>
    )
}

export default SidePopUp