import React from 'react'
import atoms from '../../../../atoms'
import { FiEdit } from 'react-icons/fi'
import { useNavigate } from 'react-router'
import molecules from '../../../../molecules'
const { Table, Button } = atoms

const Index = ({ loyaltyValue }: any) => {
    const navigate = useNavigate()
    const columns = [
        { name: 'Key', selector: (row: any) => row?.key, },
        { name: 'Value', selector: (row: any) => row?.value, },
        { name: 'Action', selector: (row: any) => row?.action, },
    ]
    return (
        <>
            <div>
                <Table
                    AdditionalClassName="add-invertory-table"
                    columns={columns}
                    data={loyaltyValue?.data.map((item: any, index: number) => {
                        return ({
                            ...item,
                            action:
                                <>
                                    <Button className="table-edit-button fs-14 fw-400"
                                        onClick={() => navigate(`/setting/loyalty/edit/${item.id}`)}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <FiEdit />
                                            <span className='ms-2 mb-0'>Edit</span>
                                        </div>
                                    </Button>
                                </>
                        })
                    })}
                />
            </div>
        </>
    )
}

export default Index