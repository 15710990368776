import React, { useState, useEffect, useContext } from "react";
import { HeaderHeading } from "../../../../Context";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from 'react-toast-notifications';
import {handleReportDownload} from '../../../../constants/utils'
import constant from "../../../../constants/constant";
import service from "../../../../api/services";


const { GSTOutputReport } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [reportData, setReportData] = useState({
        report_format: NaN,
        created_from: Date,
        created_to: Date
    })
    const { addToast } = useToasts();

    const handleSubmit = async () => {
        try {
            const response = await service.reportService?.gstOutput({ created_from: reportData?.created_from, created_to: reportData?.created_to })
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "gst-output-report", constant?.DailyReportFormat[1].value, "")
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleChange = (name: string, value: any) => {
        setReportData({ ...reportData, [name]: value })
    }

    useEffect(() => {
        setHeader("Report - GST Output Report")
    }, [])
    return (
        <Wrapper>
            <GSTOutputReport
                handleChange={(name: string, value: any) => handleChange(name, value)}
                handleSubmit={() => handleSubmit()}
                reportData={reportData} />
        </Wrapper>
    )
}

export default Index