import React, { useContext, useEffect, useState } from 'react'
import organisms from '../../../../components/organisms'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import { HeaderHeading } from '../../../../Context'
const { Role } = organisms
const Index = () => {
    const [rolesList, setRolesList] = useState()
    const [search, setSearch] = useState()
    const { setHeader,setSidebar }: any = useContext(HeaderHeading);
    const getRolesList = async () => {
        try {
            let response = await service.roles.list({ search: search })
            setRolesList(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        setHeader("Setting - Roles")
        setSidebar(false)
        getRolesList()
    }, [search])
    return (
        <Wrapper>
            <Role rolesList={rolesList} setSearch={setSearch} 
            
            />
        </Wrapper>
    )
}

export default Index