import axios from "axios";
import { BASE_URL } from "../../constants/defaultValues";
import dictionary from "../dictionary";

const barcodeStatus ={
// approved
barcodeStatusService: async (data: {}) => {
    return axios.post(BASE_URL + dictionary.barcodeStatus.barcodeStatus(), data)
},
statusChangeService: async (id:string)=>{
    return axios.put(BASE_URL +dictionary.barcodeStatus.barcodeStatusById(id));
},
statusVerificationService: async (data:any)=>{
   return axios.put(BASE_URL +dictionary.barcodeStatus.barcodeStatusVerification(),data);
},
// pending status
barcodePendingService: async (data:{}) => {
    return axios.post(BASE_URL + dictionary.barcodeStatus.barcodeStatusPending(), data)
},

// Move Out Barcode
barcodeMoveOutService: async (data: any) => {
    return axios.post(BASE_URL + dictionary.barcodeStatus.barcodeStatusMoveOut(), data)
},

// Lost Barcode
getLostBarcode: async (paramsObj: {}) => {
    return axios.get(BASE_URL + dictionary.barcodeStatus.getLostBarcode(), { params: paramsObj })
},
searchLostBarcode: async (paramsObj: {}) => {
    return axios.get(BASE_URL + dictionary.barcodeStatus.searchLostBarcode(), { params: paramsObj })
},
markBarcodeLost: async (payload: any) => {
    return axios.post(BASE_URL + dictionary.barcodeStatus.markBarcodeLost(), payload)
},

}
export default barcodeStatus;
