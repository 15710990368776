import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { product } = Dictionary

const productDetail = {
  view: async (paramsObj: any) => {
    return axios.get(BASE_URL + product.product(), { params: paramsObj })
  },
  add: async (data: {}, headers: any) => {
    return axios.post(BASE_URL + product.product(), data, { headers: headers })
  },
  deleteImages: async (data: {}) => {
    return axios.post(BASE_URL + product.deleteProductImage(), data)
  },
  delete: async (id: string) => {
    return axios.delete(BASE_URL + product.productById(id))
  },
  edit: async (id: string, data: {}, headers: any) => {
    return axios.put(BASE_URL + product.productById(id), data, {
      headers: headers,
    })
  },
  getDetailById: async (id: string) => {
    return axios.get(BASE_URL + product.productById(id))
  },
  updateStatusById: async (id: string, data: any) => {
    return axios.put(BASE_URL + product.verificationStatus(id), data)
  },
  deleteProductImages: async (payload: {}) => {
    return axios.post(BASE_URL + product.productImages(), payload)
  },
  addInventorySearch: async (search:any) => {
    return axios.get(BASE_URL + product.productSearch(), { params: search })
  },
  bulkProductUpload: async (payload: {},headers:any) => {
    return axios.post(BASE_URL + product.bulkUpload(), payload,{ headers: headers })
  },
  productDelete:async(id:string)=>{
    return axios.delete(BASE_URL + product.deleteProduct(id))
  },
}
export default productDetail
