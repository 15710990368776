import React from 'react'
import DataTable from 'react-data-table-component';
import './Table.scss'
const Index = ({
  columns, data,
  selectableRows,
  handleSelectedRow,
  paginationTotalRows,
  pagination, className, AdditionalClassName,
  contextActions
}: any) => {

  const customStyles: any = {
    headCells: {
      style: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        paddingTop: '15px',
        paddingBottom: '15px',
        background: '#D0F6FF',
        borderBottom: '1px solid #000',
        fontSize: '14px',
      },
    },
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        // overflow:"scroll",
        // innerHeight:"80%"
        // borderBottom: '1px solid black',
        // borderLeft: '1px solid black'
      },
    },
  }
  return (
    <>
      <div className={`${className} mt-4`}>
        <div className={`data-table-container ${AdditionalClassName}`}>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data}
            pagination={pagination}
            striped
            selectableRows={selectableRows}
            onSelectedRowsChange={handleSelectedRow}
            selectableRowsHighlight
            // theme="dark"
            paginationServer
            paginationRowsPerPageOptions={[20, 50, 100,200]}
            paginationTotalRows={paginationTotalRows}
            contextActions={contextActions}
          // onChangeRowsPerPage={(limit) => setLimitPerPage(limit)}
          // onChangePage={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <div>
      </div>
    </>
  )
}

export default Index