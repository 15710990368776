import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import atoms from "../../../atoms";
import molecules from "../../../molecules";
import constant from "../../../../constants/constant";
import PlaceHolderImage from '../../../../assets/Images/placeholderimg.png'
import { ProductDetail, MyComponentProps } from "../interface"
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from 'react-icons/ri';
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
// import ThreeDots from '../../../assets/Images/threeDots.svg'
import ThreeDots from '../../../../assets/Images/threeDots.svg'
import moment from "moment";
import { checkPermissionsInArray, numberWithCommas } from "../../../../constants/utils"
import { AllPermissions } from "../../../../constants/permissions";
const { Search, Button, Table, Image, Pagination, Select, Input, CustomImages } = atoms
const { SidePopUp, ImagePopup, ConfirmationModal } = molecules
const Index: React.FC<MyComponentProps> = ({ productResponse, setLimitPerPage, currentPage, setCurrentPage, setSearch, limit, productDetail, getDetailById, updateVerificationStatus, handleProductDelete, brand, productType, setProductData, handleDownload }) => {
    const navigate = useNavigate()
    const [deletePopup, setDeletePopup] = useState<boolean>(false)
    const [subAdminId, setSubAdminId] = useState("")
    const [showSidebarModal, setShowSidebarModal] = useState(false)
    const [showImageLarge, setShowImageLarge] = useState(false)
    const [imageUrl, setImageUrl] = useState<any[]>([])
    const [imageIndex, setImageIndex] = useState<any>()
    const [tableData, setTableData] = useState<any>()

    useEffect(() => {
        if (productResponse?.data) {
            const result = productResponse?.data?.filter((items: any) => items?.verification_status == constant.VERIFICATION_STATUS.VERIFIED);
            setTableData(result)
        }
    }, [productResponse])
    const columns = [
        { name: 'Brand', selector: (row: ProductDetail) => row.brand.brand_name, sortable: true, minWidth: '15%' },
        { name: 'Date', selector: (row: any) => row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : "-", },
        { name: 'Product ID', selector: (row: ProductDetail) => `${row.product_id_display}`, minWidth: '15%' },
        { name: 'Cover Photo', cell: (row: any) => <CustomImages src={row?.images?.length > 0 && row?.images[0]} />, center: true },
        { name: 'Model No.', selector: (row: ProductDetail) => `${row.modal_number}` },
        { name: 'Product Type', selector: (row: ProductDetail) => row.product_type.name },
        { name: 'Product SRP (₹)', selector: (row: ProductDetail) => `${row.product_srp !== null ? `₹${numberWithCommas(row.product_srp)}` : '-'}`, },
        { name: 'Status', selector: (row: ProductDetail) => row.isActive },
        { name: 'Verification Status', selector: (row: ProductDetail) => row.verification_status },
        { name: 'Action', selector: (row: any) => row.action, },
    ]

    const showImage = (imageUrlData: any, index: any) => {
        setShowImageLarge((prevState) => !prevState)
        setImageIndex(index)
        setImageUrl(imageUrlData)
    }

    const closeImage = () => {
        setShowImageLarge((prevState) => !prevState)
        setImageIndex(0)
    }

    const handleChange = (name: string, value: any) => {
        setProductData((prevState: any) => ({
            ...prevState,
            [name]: value
        }));

    };
    // const handleBrand = (value: any) => {
    //     if (brandResponse && value) {
    //         setProductData((prev: any) => ({ ...prev, product_type_id: "" }));
    //         const filteredData: any = brandResponse?.filter((item: any) => item?.id === value?.value);
    //         setProductType(getDropdown(filteredData[0]?.product_types))
    //     }
    // }

    const handelDeleteButton = (id: string) => {
        setSubAdminId(id)
        setDeletePopup(!deletePopup)
    }
    return (
        <>
            <div className="row">
                <div className="col-3"><Search className="w-100" searchCallBack={(e: string) => { setSearch(e) }} /></div>
                <div className="col-6 mt-0">
                    <div className='row'>
                        <div className='col-4 d-flex'>
                            <Select
                                options={brand}
                                className={"w-100 product_multi_select"}
                                placeholder={"Select Brand"}
                                isMulti={true}
                                onChange={(e: any) => { handleChange("brand_id", e) }}
                            />
                        </div>
                        <div className='col-4 d-flex'>
                            <Select
                                options={productType}
                                className={"w-100 product_multi_select"}
                                placeholder={"Select Type"}
                                isMulti={true}
                                onChange={(e: any) => { handleChange("product_type_id", e) }}
                            />
                        </div>
                        <div className='col-4'>
                            <Input
                                type={'text'}
                                className="input-height"
                                placeholder={"Enter Model Number"}
                                onChange={(e: any) => {
                                    handleChange("modal_number", e.target.value)
                                }} />
                        </div>
                    </div>

                </div>
                <div className="col-3 gap-1">
                    <div className="d-flex justify-content-end">
                        {checkPermissionsInArray(AllPermissions.Product_Approved_Download_Button) &&
                            <div className="mx-3">

                                <Button name={"Download"}
                                    className=''
                                    onClick={() => handleDownload()}
                                />
                            </div>}
                        {checkPermissionsInArray(AllPermissions.Product_Add) && <div className="">
                            <Button name={"Add Product"}
                                className=''
                                onClick={() => navigate('/products/add')}
                            />
                        </div>}
                    </div>
                </div>
            </div>
            <div >
                <Table columns={columns}
                    AdditionalClassName="add-invertory-table"
                    data={tableData?.map((item: ProductDetail, index: number) => {
                        return ({
                            ...item,
                            isActive: item.isActive ? (
                                <div className="background-light-green border-product-radius">
                                    <p className="color-green m-1 p-1">{constant.STATUS.ACTIVE}</p>
                                </div>) : <div className="background-light-red border-product-radius">
                                <p className="color-red m-1 p-1">{constant.STATUS.DEACTIVE}</p>
                            </div>,
                            verification_status: item.verification_status === constant.VERIFICATION_STATUS.PENDING ? (
                                <div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : item.verification_status === constant.VERIFICATION_STATUS.VERIFIED ?
                                (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Verified"}</p></div>) : item.verification_status === constant.VERIFICATION_STATUS.REWORK ? (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"Rejected"}</p></div>) : ''
                            ,
                            action:
                                <> <div className='table-data-width'>
                                    <button
                                        className="btn dropdown-dots shadow-none action-button"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <Image src={ThreeDots} />
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end dropdown-style"
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <li>
                                            {/* {item.verification_status === constant.VERIFICATION_STATUS.VERIFIED ? ( */}
                                            <button
                                                className="dropdown-item dropdown-item-size"
                                                onClick={() => {
                                                    getDetailById(item.id);
                                                    setShowSidebarModal(true);
                                                }}
                                            >
                                                <div className='d-flex align-items-center'>
                                                    <AiOutlineEye />
                                                    <span className='ms-2 mb-0'>View</span>
                                                </div>
                                            </button>
                                        </li>

                                        {/* ) : ( */}
                                        {checkPermissionsInArray(AllPermissions.Product_Approved_Edit_Option) &&
                                            <>
                                                <li>
                                                    <hr className="my-1"></hr>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item dropdown-item-size"
                                                        onClick={() => navigate(`/products/edit/${item.id}`)}
                                                    >
                                                        <div className='d-flex align-items-center'>
                                                            <FiEdit />
                                                            <span className='ms-2 mb-0'>Edit</span>
                                                        </div>
                                                    </button>
                                                    {/* )} */}
                                                </li>
                                            </>}

                                        {/* ) : ( */}
                                        <li>
                                            <hr className="my-1"></hr>
                                        </li>
                                        <li>
                                            <button
                                                className="dropdown-item dropdown-item-size"
                                                onClick={() => navigate(`/products/edit/${item.id}?isDuplicate=${true}`)}
                                            >
                                                <div className='d-flex align-items-center'>
                                                    <FiEdit />
                                                    <span className='ms-2 mb-0'>Duplicate</span>
                                                </div>
                                            </button>
                                            {/* )} */}
                                        </li>

                                        {/* ) : ( */}
                                        <li>
                                            <hr className="my-1"></hr>
                                        </li>
                                        <li>
                                            <button
                                                className="dropdown-item dropdown-item-size"
                                                onClick={() => handelDeleteButton(item?.id)}
                                            >
                                                <div className='d-flex align-items-center text-danger'>
                                                    <RiDeleteBin6Line />
                                                    <span className='ms-2 mb-0'>Delete</span>
                                                </div>
                                            </button>
                                            {/* )} */}
                                        </li>

                                    </ul>
                                </div></>
                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={productResponse?.count}
                    currentPage={currentPage}
                    totalPages={productResponse?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
            {(showSidebarModal) && <div>

                <SidePopUp show={showSidebarModal}
                    onHide={() => setShowSidebarModal(false)}
                    productDetail={productDetail}
                    showImage={(imageUrl: any, index: any) => showImage(imageUrl, index)}
                    updateVerificationStatus={(id: string, status: number) => updateVerificationStatus(id, status)}

                />
            </div>}
            {showImageLarge &&
                <>
                    <ImagePopup imageUrlData={imageUrl} handleClose={closeImage} Index={imageIndex} />
                </>
            }

            {deletePopup && <ConfirmationModal
                show={deletePopup}
                handleConfirmItem={() => { handleProductDelete(subAdminId); setDeletePopup(!deletePopup) }}
                onHide={() => setDeletePopup(!deletePopup)}
                message={'delete this product'} />}
        </>
    )
}
export default Index