import React, { useState } from 'react'
import atoms from '../../../atoms'
import { FiEdit } from 'react-icons/fi'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
const { Table, Button, Search } = atoms

const Index = ({ rolesList ,setSearch}: any) => {
    const navigate = useNavigate()

    const columns = [
        { name: 'Role', selector: (row: any) => row.role_name, sortable: true },
        { name: 'Action', selector: (row: any) => row.action, },
    ]
    return (
        <div>
            <div className="row">
                <div className="col-6">
                    {/* <Search
                    searchCallBack={(e: string) => { setSearch(e) }} 
                    /> */}
                </div>
                <div className="col-6 text-end">
                    <Button name={"Add Role"}
                        className=''
                        onClick={() => navigate('/setting/role/add')}
                    />
                </div>
            </div>
            <div>
             
                    <Table columns={columns}
                        data={rolesList?.data?.map((item: any) => {
                            return ({
                                ...item,
                                action: <Link
                                    to={`/setting/role/edit/${item.id}`}>

                                    <Button className="table-edit-button fs-14 fw-400">
                                        <div className='d-flex align-items-center'>
                                            <FiEdit />
                                            <span className='ms-2 mb-0'>Edit</span>
                                        </div>
                                    </Button>
                                </Link>
                            })
                        })}
                    />
            </div>
        </div>
    )
}

export default Index