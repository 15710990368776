import React, { useEffect, useState } from "react";
import service from "../../../../api/services";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import { addSupplier } from './interface'
import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { getDropdownValue } from '../../../../constants/utils';
import { Store_Legal_Status } from '../../../../constants/defaultValues'
import { City_List, State_List } from "../../../../constants/state_cities";
const { SupplierAdd } = organisms
const AddSupplier = () => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    let params = useParams();
    let supplierEditID = params.id || ''
    const [buttonClick, setButtonClick] = useState(false)

    const [supplier, setSupplier] = useState<any>({
        isActive: false,
        supplier_name: "",
        owner_name: "",
        owner_contact_number: "",
        owner_email: "",
        contact_person_name: "",
        contact_number: "",
        contact_email: "",
        address: "",
        pin_code: "",
        city: "",
        state: "",
        latitude: 0.0000,
        longitude: 0.000,
        bank_name: "",
        ifsc_code: "",
        account_number: "",
        gst_number: "",
        pan_number: "",
        supplier_id: '',
        supplier_count: '',
        legal_status:"",
    })

    useEffect(() => {
        if (buttonClick) {
            addSupplier()
        }
       
    }, [buttonClick])

    useEffect(() => {
        if (supplierEditID) {
            getDataById()
        }
        if (!params.id) {
            getCount()
        }
    }, [])

    const getCount = async () => {
        let initialCount = "21000"
        try {
            let response = await service.supplier.count()
            if (response.status === 200) {
                // (storeDetails.store_count)?.toString()?.padStart(3, '0')
                let count = response.data.count.toString()?.padStart(2, '0')
                let totalCount = (initialCount + count)
                setSupplier((prevState: any) => ({ ...prevState, supplier_id: totalCount }));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const addSupplier = async () => {
        let payload = {
            legal_status: supplier?.legal_status?.value,
            supplier_name: supplier?.supplier_name,
            owner_name: supplier?.owner_name,
            owner_contact_number: supplier?.owner_contact_number,
            owner_email: supplier?.owner_email,
            contact_person_name: supplier?.contact_person_name,
            contact_number: supplier?.contact_number,
            contact_email: supplier?.contact_email,
            address: supplier?.address,
            pin_code: supplier?.pin_code,
            city: supplier?.city?.value,
            state: supplier?.state.value,
            latitude: 0.0000,
            longitude: 0.000,
            bank_name: supplier?.bank_name,
            ifsc_code: supplier?.ifsc_code,
            account_number: supplier?.account_number,
            gst_number: supplier?.gst_number,
            pan_number: supplier?.pan_number,
            supplier_id: supplier?.supplier_id,
        }
        try {
            let response: any
            if (supplierEditID) {
                response = await service.supplier.edit(supplierEditID, payload)
                if (response.status === 200) {
                    addToast(response?.data?.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                    navigate(-1)
                }
            } else {
                response = await service.supplier.add(payload)
                if (response.status === 201) {
                    addToast(response?.data?.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                    navigate(-1)
                }
            }
        } catch (error: any) {
            console.log(error)
            addToast(error?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        setButtonClick(false)
    }

    const getDataById = async () => {
        try {
            let response = await service.supplier.getDetailById(supplierEditID)
            handleEditData(response.data.data)
        } catch (error) {

        }
    }
    const handleEditData = (response: any) => {
        let tempEdit = {
            legal_status: getDropdownValue(Store_Legal_Status, response?.legal_status),
            supplier_name: response?.supplier_name,
            owner_name: response?.owner_name,
            owner_contact_number: response?.owner_contact_number,
            owner_email: response?.owner_email,
            contact_person_name: response?.contact_person_name,
            contact_number: response?.contact_number,
            contact_email: response?.contact_email,
            address: response?.address,
            pin_code: response?.pin_code,
            city: getDropdownValue(City_List, response?.city),
            state: getDropdownValue(State_List, response?.state),
            latitude: 0.0000,
            longitude: 0.000,
            bank_name: response?.bank_details?.bank_name,
            ifsc_code: response?.bank_details?.ifsc_code,
            account_number: response?.bank_details?.account_number,
            gst_number: response?.bank_details?.gst_number,
            pan_number: response?.bank_details?.pan_number,
            supplier_id: response?.supplier_id_display,
        }
        setSupplier(tempEdit)
    }
    return (
        <Wrapper>
            <SupplierAdd
                supplier={supplier}
                setSupplier={setSupplier}
                setButtonClick={setButtonClick}
            />
        </Wrapper>
    )
}
export default AddSupplier