import React, { useState } from 'react'
import atoms from '../../atoms'
import { FiCheck, FiEdit } from 'react-icons/fi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import Image from '../../atoms/Image'
import service from '../../../api/services'
import { numberWithCommas } from '../../../constants/utils'
const { Button, Input } = atoms

const Index = ({ listingData, _handleSearch }: any) => {
    // const [isEditable, setIsEditable] = useState(false)
    // const [currentSelected, setCurrentSelected] = useState<string>('')
    const [minQuantity, setMinQuantity] = useState(1)
    const [expandedIndex, setExpendedIndex] = useState<number>();

    // const _handleEdit = (id: string) => {
    //     setIsEditable(true)
    //     setCurrentSelected(id)
    // }
    const _handleSave = async (productId: string) => {
        const payload = {
            min_quantity: minQuantity
        }
        if (minQuantity > 0) {
            try {
                let Response = await service.inventory.updateQuantity(payload, productId)
                if (Response) {
                    await _handleSearch()
                    expandDiv(0)
                }
            } catch (error) {
                console.log('error', error)
            }
        }
        else {
            expandDiv(0)
        }

        // setIsEditable(false)
    }
    // const _handleCancel = (id: string, preValue: number) => {
    //     setIsEditable(false)
    //     setMinQuantity(preValue)
    // }

    const _handleInputChange = (e: any) => {
        e.preventDefault();
        // setMinQuantity(e.target.value)
    }
    const expandDiv = (index: number) => {
        if (index === expandedIndex) {
            setExpendedIndex(0)
        } else {
            setExpendedIndex(index)
        }
    }
    return (
        <div style={{ paddingBottom: "2rem" }}>
            <div className={`custom_table table-responsive mt-4 hide-scroll inventory-quantity-table-height`}>
                <table className='mb-5 w-100 position-relative '>
                    <thead className='table-thead background-blue color-black position-sticky sticky-top '>
                        <tr>
                            <th className='text-center'>Product Id</th>
                            <th className='text-center'>Cover Image </th>
                            <th className='text-center'>SRP (₹)</th>
                            <th className='text-center'>Good Count</th>
                            <th className='text-center'>Impair Count</th>
                            <th className='text-center'>Minimum Quantity</th>
                            <th className='text-center'>Action</th>
                        </tr>
                    </thead>
                    <tbody className='mt-3 table-tbody' >

                        {listingData?.map((item: any, index: number) => {
                            return (
                                <tr key={`${item?.id}_${index}`}>
                                    <td className='text-center'>{item?.product_id_display}</td>
                                    <td className='text-center'>
                                        <Image className='w-100 object-contain searchClass' src={item?.images[0]} />
                                    </td>
                                    <td className='text-center'>{`₹${numberWithCommas(parseFloat(item?.product_srp?.toFixed(2)))}`}</td>
                                    <td className='text-center'>{item?.inventory_quantity}</td>
                                    <td className='text-center'>{item?.impair_count ? item?.impair_count : 0}</td>
                                    <td className='text-center'>
                                        <div className='d-flex justify-content-center'>
                                            {expandedIndex == item?.id ?
                                                <Input type='number' className={"custom-input-height fs-12 w-50 mx-auto "}
                                                    placeholder={"Enter Minimum Quantity"}
                                                    defaultValue={item?.min_quantity}
                                                    // disabled={!isEditable || (currentSelected !== item.id)}
                                                    onChange={(e: any) => setMinQuantity(e.target.value)}
                                                    min={1}
                                                /> :
                                                <Input type='number' className={"custom-input-height fs-12 w-50 mx-auto "}
                                                    placeholder={"Enter Minimum Quantity"}
                                                    value={item?.min_quantity_required_for_sale}
                                                    defaultValue={item?.min_quantity}
                                                    // disabled={!isEditable || (currentSelected !== item.id)}
                                                    disabled={expandedIndex != item?.id}
                                                    onChange={_handleInputChange}
                                                />}

                                        </div>
                                    </td>
                                    <td className='border-left'>
                                        <div className='d-flex gap-3'>
                                            {/* {!isEditable || (currentSelected !== item.id)  */}
                                            {expandedIndex == item?.id ?
                                                <Button className="table-edit-button fs-14 fw-400 "
                                                    onClick={() => { _handleSave(item?.id) }} >
                                                    <div className='d-flex align-items-center btn-success '>
                                                        <FiCheck className='fs-20 fw-600' />
                                                    </div>
                                                </Button> :
                                                <Button className="table-edit-button fs-14 fw-400 "
                                                    onClick={() => {
                                                        //  _handleEdit(item.id);
                                                        expandDiv(item?.id)
                                                    }} >
                                                    <div className='d-flex align-items-center'>
                                                        <FiEdit className='fs-20 fw-600' />
                                                        {/* <span className='ms-2 mb-0'>Edit</span> */}
                                                    </div>
                                                </Button>
                                            }
                                            {expandedIndex == item?.id && <Button className="custom-delete-button"
                                                onClick={() => {
                                                    // _handleCancel(item?.id, item?.min_quantity); 
                                                    expandDiv(0)
                                                }}>
                                                <div className='d-flex align-items-center fs-18 fw-400 mx-2'>
                                                    <RiDeleteBin6Line />
                                                </div>
                                            </Button>}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Index