import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import service from '../../../../api/services'
import { getDropdown, handleReportDownload } from '../../../../constants/utils'
import { HeaderHeading } from '../../../../Context'
import { useToasts } from 'react-toast-notifications'
import constant from '../../../../constants/constant'
const { StoreSalesReportInvoiceWise } = organisms
const Index = () => {
    const { setHeader }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();
    const [storeData, setStoreData] = useState();
    const [reportData, setReportData] = useState<any>({
        store_ids: [],
        store_id: "",
        created_from: "",
        created_to: "",
        month_from: "",
        month_to: "",
        report_format: NaN,
        report_type: 1
    })
    const getStoreList = async () => {
        try {
            let storeResponse = await service.inventory.storeView()
            if (storeResponse.status === 200) {
                setStoreData(getDropdown(storeResponse?.data?.data, 'store_location' , 'store_name'))
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (name: string, value: any) => {
        setReportData({ ...reportData, [name]: value })
    }
    const submitIndividualInvoices = async () => {
        const storeIds = reportData?.store_ids?.map((item: any) => item.value)

        const payload = {
            store_ids: storeIds,
            created_from: reportData?.created_from,
            created_to: reportData?.created_to
        }
        try {
            const response = await service.reportService?.storeSalesReturn(payload)
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "store-sales-return-report", constant?.DailyReportFormat[1].value,"")
            }

        }
        catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    useEffect(() => {
        setHeader("Report - Store Sales Return Report")
        getStoreList()
    }, [])
    return (
        <Wrapper>
            <StoreSalesReportInvoiceWise
                handleChange={(name: string, value: any) => handleChange(name, value)}
                reportData={reportData}
                storeData={storeData}
                submitIndividualInvoices={() => submitIndividualInvoices()}
            />
        </Wrapper>

    )
}

export default Index