import React, { useEffect, useState, useContext } from "react";
import { HeaderHeading } from "../../../../Context";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import service from "../../../../api/services";
import { BASE_URL } from "../../../../constants/defaultValues";
import { useToasts } from 'react-toast-notifications';
import * as XLSX from "xlsx"
import constant from "../../../../constants/constant";
import { generatePdfHeader } from '../../../../constants/utils'

const { BarcodeAuditTrail } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [barcodeAuditData, setBarcodeAuditData]= useState()
    const [reportData, setReportData] = useState({
        barcode: NaN,
        report_format: NaN
    })
    const { addToast } = useToasts();

    const getBarcodeData = async (barcode: number) => {
        try {
            let barcodeResponse = await service.reportService.barcodeSearch({ search: barcode })
            return barcodeResponse?.data?.data
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async () => {
        let barcodeIds = await getBarcodeData(reportData?.barcode)

        const payload = {
            barcodes: [barcodeIds[0]?.id]
        }
        try {
            if (barcodeIds?.length === 1) {
                const response = await service.reportService?.barcodeAuditTrail(payload)
                if (response.status === 200) {
                    let path = response.data.data
                    let fullPath = `${BASE_URL}${path}`
                    let cleanPAth = fullPath.replace(/api\/\.\//, '')
                    let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
                    // window.open(`${cleanPAth}`, '_blank')

                    if (reportData?.report_format === constant?.DailyReportFormat[0].value) {

                        // convert the data to json
                        const xlsResponse = await fetch(cleanPAth);
                        const xlsData = await xlsResponse.arrayBuffer();

                        // Convert XLS data to JSON
                        const workbook = XLSX.read(new Uint8Array(xlsData), { type: 'array' });
                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];
                        const jsonData = XLSX.utils.sheet_to_json(sheet);
                        generatePdfHeader(jsonData, 4, "barcode_audit_trail_report","Specxweb")
                    }

                    if (reportData?.report_format === constant?.DailyReportFormat[1].value) {

                        setTimeout(() => {
                            fetch(`${cleanPAth}`).then((response) => {
                                response.blob().then((blob) => {

                                    // Creating new object of PDF file
                                    const fileURL =
                                        window.URL.createObjectURL(blob);

                                    // Setting various property values
                                    let alink = document.createElement("a");
                                    alink.href = fileURL;
                                    alink.download = `${fileName}`;
                                    alink.click();
                                });
                            });
                        }, 2000);
                    }

                }
            }
            else {
                throw new Error
            }

        } catch (error: any) {
            console.log('error', error)
            const errorMessage = error?.response?.data?.message || "There is empty data"
            addToast(errorMessage, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleView = async () => {
        let barcodeIds = await getBarcodeData(reportData?.barcode)

        const payload = {
            barcodes: [barcodeIds[0]?.id]
        }
        try {
            if (barcodeIds?.length === 1) {
                const response = await service.reportService?.barcodeAuditTrail(payload)
                if (response.status === 200) {
                    let path = response.data.data
                    let fullPath = `${BASE_URL}${path}`
                    let cleanPAth = fullPath.replace(/api\/\.\//, '')
                    // window.open(`${cleanPAth}`, '_blank')

                    // convert the data to json
                    const xlsResponse = await fetch(cleanPAth);
                    const xlsData = await xlsResponse.arrayBuffer();

                    // Convert XLS data to JSON
                    const workbook = XLSX.read(new Uint8Array(xlsData), { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet);
                    setBarcodeAuditData(generatePdfFromData(jsonData,4))
                }
            }
        } catch (error: any) {
            console.log('error', error)
            const errorMessage = error?.response?.data?.message || "There is empty data"
            addToast(errorMessage, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const generatePdfFromData = (data: any, countOfRowHaveData: number) => {
        if (!data || data.length < countOfRowHaveData) return;

        const headerRow = data[countOfRowHaveData - 1];
        const header = Object.values(headerRow);

        const pdfData = data.slice(countOfRowHaveData).map((row: any, index: number) => {
            const headerValues = Object.values(row);
            const obj: any = { id: index + 1 };
            header.forEach((key: any, j: number) => {
                const trimmedKey = key.split(" ").join(""); // Remove leading and trailing spaces
                obj[trimmedKey] = headerValues[j];
            });
            return obj;
        });
        

        const getValueFromString = (valueArr: any, name: string) => {
            const lastIndex = valueArr.lastIndexOf(name); // find the last occurrence of the delimiter
            return lastIndex !== -1 ? valueArr.substring(lastIndex + 1) : '';
        };

        const storeName = getValueFromString(Object.values(data[0])[0], ":");
        const reportGeneratedOn = getValueFromString(Object.values(data[1])[0], "n");

        pdfData.storeName = storeName;
        pdfData.reportGeneratedOn = reportGeneratedOn;

        return pdfData
    };


    useEffect(() => {
        setHeader("Report - Barcode Audit Trail")
    }, [])

    const handleChange = (name: string, value: any) => {
        setReportData({ ...reportData, [name]: value })
    }
    return (
        <Wrapper>
            <BarcodeAuditTrail
                handleChange={(name: string, value: any) => handleChange(name, value)}
                reportData={reportData}
                handleSubmit={() => handleSubmit()}
                handleView={() => handleView()} 
                barcodeAuditData={barcodeAuditData}/>
        </Wrapper>
    )
}

export default Index