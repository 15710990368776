import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import service from '../../../../api/services'
import { IPermissionList } from '../../../../components/organisms/Setting/Permissions/interface'
import { useToasts } from 'react-toast-notifications'
import { HeaderHeading } from '../../../../Context'
const { Permissions } = organisms
const Index = () => {
    const [permissionList, setPermissionList] = useState<IPermissionList>()
    const [selectedId, setSelectedId] = useState<string>('')
    const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false)
    const { setHeader,setSidebar }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();

    const getPermissionList = async () => {
        try {
            const response = await service.permission.getPermissionService()
            setPermissionList(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const _handleDelete = (id: string) => {
        setSelectedId(id)
        setHandleDeleteModal(true)
    }
    const handleDeletePermission = async () => {
        try {
            const response = await service.permission.deletePermission(selectedId)
            if (response.status === 200) {
                setHandleDeleteModal(false)
                addToast('Product material deleted successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log("error", error);
            setHandleDeleteModal(false)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        getPermissionList()
    }
    useEffect(() => {
        setHeader("Setting - Permission")
        setSidebar(false)
        getPermissionList()
    }, [])
    return (
        <Wrapper>
            <Permissions
                permissionList={permissionList}
                getPermissionList={getPermissionList}
                handleDeletePermission={handleDeletePermission}
                handleDeleteModal={handleDeleteModal}
                _handleDelete={_handleDelete}
                setHandleDeleteModal={setHandleDeleteModal}
            />
        </Wrapper>
    )
}

export default Index