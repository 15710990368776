import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import service from '../../../../api/services'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import { HeaderHeading } from '../../../../Context'
const { Brand } = organisms
const Index = () => {
    const [search, setSearch] = useState<any>('');
    const [brandData, setBrandData] = useState([])
    const { setHeader,setSidebar }: any = useContext(HeaderHeading);
    const getBrandList = async () => {
        try {
            let response = await service.productData.brandListingService({ "search": search, })
            if (response.status === 200) {
                setBrandData(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        setHeader("Content - Brands")
        setSidebar(false)
        getBrandList()
    }, [search])


    return (
        <Wrapper>
            <Brand
                setSearch={setSearch}
                brandData={brandData}
                setBrandData={setBrandData}
            />
        </Wrapper>
    )
}
export default Index