import product from './product'
import store from './stores'
import movement from './movement'
import productData from './productData'
import auth from './auth'
import supplier from './supplier'
import subAdmin from './subAdmin'
import permission from './permission'
import inventory from './inventory'
import barcodeStatus from './barcodeStatus'
import roles from './roles'
import allocation from './allocation'
import Accounts from './accounts'
import BulkEdit from './bulkEdit'
import loyaltyPoints from './loyaltyPoints'
import procurement from './procurement'
import InventoryAudit from './inventoryAudit'
import warranty from './warranty'
import report from './report'
import storeSetting from './setting'
import dashboard from './dashboard'
import websiteForm from './websiteForm'

const dictionary = {
  store,
  movement,
  product,
  productData,
  auth,
  supplier,
  subAdmin,
  permission,
  inventory,
  barcodeStatus,
  roles,
  allocation,
  Accounts,
  BulkEdit,
  loyaltyPoints,
  procurement,
  InventoryAudit,
  warranty,
  report,
  storeSetting,
  dashboard,
  websiteForm,
}
export default dictionary
