import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { loyaltyPoints } = Dictionary

const loyaltyPointsService = {
  getLoyaltyPointsList: async (paramsObj:{}) => {
    return axios.get(BASE_URL + loyaltyPoints.loyaltyPointsList(),{params:paramsObj})
  },
  loyaltyPointsListItems: async (id: string) => {
    return axios.get(BASE_URL + loyaltyPoints.loyaltyPointsItems(id))
  },
  getLoyaltyList: async () => {
    return axios.get(BASE_URL + loyaltyPoints.loyaltyList())
  },
  loyaltyItemDetail: async (id: string) => {
    return axios.get(BASE_URL + loyaltyPoints.loyaltyItemDetail(id))
  },
  loyaltyItemDetailUpdate: async (id: string, payload: {}) => {
    return axios.put(BASE_URL + loyaltyPoints.loyaltyItemDetail(id), payload)
  },
}
export default loyaltyPointsService
