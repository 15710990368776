import React, { useState } from 'react'
import atoms from '../../../atoms'
import molecules from '../../../molecules'
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import service from '../../../../api/services'
import { useToasts } from 'react-toast-notifications';
import './index.scss'
import { AllPermissions } from '../../../../constants/permissions';
import { checkPermissionsInArray } from '../../../../constants/utils';
const { Search, Button, Table, Input } = atoms
const { ConfirmationModal, CreateModal } = molecules

const Index = ({ setSearch, colorListing, getColorListing }: any) => {
    const { addToast } = useToasts();
    const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false)
    const [nameError, setNameError] = useState<boolean>(false)
    const [codeError, setCodeError] = useState<boolean>(false)
    const [createModal, setCreateModal] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [colorValues, setProductColorValues] = useState({ color_name: '', color_code: '', })
    const [selectedId, setSelectedId] = useState<string>('')
    const columns = [
        { name: 'Color Name', selector: (row: any) => row.color_name, sortable: true, },
        { name: 'Color Code', selector: (row: any) => row.color_code, sortable: true, },
        { name: 'Color ', selector: (row: any) => <div style={{ backgroundColor: `${row.color_code}`, width: '22px', height: '22px', borderRadius: '50%', border: '1px solid #000' }}></div>, },
        { name: 'Action', selector: (row: any) => row.action, },

    ]
    const handleSubmit = async () => {
        const payload = {
            color_name: colorValues.color_name,
            color_code: colorValues.color_code
        }
        if (colorValues.color_name && colorValues.color_code) {
            try {
                if (isEdit) {
                    // for update product type values
                    const response = await service.productData.updateProductColorService(selectedId, payload)
                    if (response.status === 200) {
                        setCreateModal(false)
                        addToast('Product color updated successfully', {
                            appearance: 'success',
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                    }
                } else {
                    // for Create product type values
                    const response = await service.productData.createProductColorService(payload)
                    if (response.status === 201) {
                        setCreateModal(false)
                        addToast('Product color added successfully', {
                            appearance: 'success',
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                    }
                }
                getColorListing()
                setProductColorValues({
                    color_name: '',
                    color_code: ''
                })
                setIsEdit(false)
            } catch (error: any) {
                console.log("error", error);
                addToast(error.response.data.message, {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } else {
            setNameError(true)
            setCodeError(true)
        }
    }
    const _handleDelete = (id: string) => {
        setSelectedId(id)
        setHandleDeleteModal(true)
    }
    const handleDeleteType = async () => {
        try {
            const response = await service.productData.deleteProductColorService(selectedId)
            if (response.status === 200) {
                setHandleDeleteModal(false)
                addToast('Product color deleted successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log("error", error);
            setHandleDeleteModal(false)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        getColorListing()
    }

    const _handleEdit = async (id: string) => {
        setSelectedId(id)
        handleProductColorValues(id)
    }
    const handleProductColorValues = async (id: string) => {
        setIsEdit(true)

        try {
            const response = await service.productData.getProductColorByIdService(id)
            let resData = response.data.data
            if (response.status === 200) {
                setProductColorValues({
                    color_name: resData?.color_name,
                    color_code: resData.color_code
                })
                setCreateModal(true)
            }
        } catch (error: any) {
            console.log("error", error);
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    const _handleClose = () => {
        setCreateModal(!createModal)
        setProductColorValues({
            color_name: '',
            color_code: ''
        })
        setIsEdit(false)
    }
    const handleChange = (name: string, value: string) => {
        setProductColorValues({ ...colorValues, [name]: value })
        setNameError(false);
        setCodeError(false);
    }
    return (
        <div>
            <div>
                <div className="row align-items-center">
                    <div className="col-6">
                        <Search searchCallBack={(e: string) => { setSearch(e) }} />
                    </div>
                    {checkPermissionsInArray(AllPermissions.Sw_color_Add) && <div className="col-6 text-end">
                        <Button name={'Create Specx color'}
                            onClick={() => setCreateModal(!createModal)}
                        />
                    </div>}
                </div>
            </div>
            <div>
                <Table
                    columns={columns}
                    data={colorListing?.data?.map((item: any) => {
                        return ({
                            ...item,
                            action: <>
                                {checkPermissionsInArray(AllPermissions.Sw_color_Edit) && <Button className="table-edit-button fs-14 fw-400"
                                    onClick={() => _handleEdit(item.id)}>
                                    <div className='d-flex align-items-center'>
                                        <FiEdit />
                                        <span className='ms-2 mb-0'>Edit</span>
                                    </div>
                                </Button>}
                                {checkPermissionsInArray(AllPermissions.Sw_color_Delete) && <Button className="table-delete-button fs-14 fw-400 ms-3"
                                    onClick={() => _handleDelete(item.id)}>
                                    <div className='d-flex align-items-center'>
                                        <RiDeleteBin6Line />
                                        <span className='ms-2 mb-0'>Delete</span>
                                    </div>
                                </Button>}
                            </>
                        })
                    })}
                />
            </div>
            {handleDeleteModal && <ConfirmationModal
                show={handleDeleteModal}
                handleConfirmItem={() => handleDeleteType()}
                onHide={() => setHandleDeleteModal(!handleDeleteModal)}
                message={'delete this Product Material'} />}

            {createModal && <CreateModal
                show={createModal}
                header={`${isEdit ? 'Update' : 'Create'} Specxweb color`}
                handleSubmit={handleSubmit}
                submitBtnText={`${isEdit ? 'Update' : 'Create'} color`}
                onHide={() => _handleClose()}
            >
                <div>
                    <form>
                        <div className="row">
                            <div className="col-6 mt-25">
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Color Name"
                                    onChange={(e: any) => handleChange("color_name", e.target.value)}
                                    defaultValue={colorValues.color_name}
                                    label='Enter Color Name'
                                />
                                {(nameError && (colorValues.color_name.length === 0)) && <span className="error-text">This field is required.</span>}

                            </div>
                            <div className="col-6 mt-25">
                                <label className='inputs-label mb-1 fs-16 fw-600  secondary-color' >Color code</label>
                                <div className='custom-color-input'>
                                    <input
                                        type="color" className="form-control shadow-none "
                                        onChange={(e: any) => handleChange("color_code", e.target.value)}
                                        defaultValue={colorValues.color_code} />
                                    <div className='w-75 ms-3'>{colorValues.color_code}</div>

                                </div>
                                {(codeError && (colorValues.color_code.length === 0)) && <span className="error-text">This field is required.</span>}
                            </div>
                        </div>
                    </form>
                </div>
            </CreateModal>}
        </div>
    )
}

export default Index