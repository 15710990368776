import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from "../../../../components/organisms";
import service from '../../../../api/services';
import { HeaderHeading } from '../../../../Context'
const { Material } = organisms
const Index = () => {
    const [materialListing, setMaterialListing] = useState<any>()
    const [search, setSearch] = useState('');
    const { setHeader,setSidebar }: any = useContext(HeaderHeading);
    const getMaterialListing = async () => {
        try {
            const response = await service.productData.productMaterialListingService({ search: search });
            setMaterialListing(response.data)
        } catch (error) {

        }
    }
    useEffect(() => {
        setSidebar(false)
        setHeader("Content - Product Material")
        getMaterialListing()
    }, [search])

    return (
        <Wrapper>
            <Material materialListing={materialListing} setSearch={setSearch} getMaterialListing={getMaterialListing} />
        </Wrapper>
    )
}

export default Index