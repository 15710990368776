import React from 'react'
import './Wrapper.scss'
const Wrapper = ({ children }: any) => {
    return (
        <div className="wrapper-layout" style={{height:'95vh'}} >
            <main className="wrapper-content">
                <div className="wrapper-card mb-0 position-relative" >
                    {children}
                </div>
            </main>
        </div>
    )
}

export default Wrapper