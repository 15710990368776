import React, { useState, useEffect, useContext } from "react";
import { HeaderHeading } from "../../../../Context";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from 'react-toast-notifications';
import { BASE_URL } from "../../../../constants/defaultValues";
import service from "../../../../api/services";
import { getDropdown, generatePdfHeader } from "../../../../constants/utils"
import * as XLSX from "xlsx"
import constant from "../../../../constants/constant";


const { TransactionReport } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [storeData, setStoreData] = useState<any>();
    const [reportData, setReportData] = useState({
        store_ids: "",
        created_from: Date,
        created_to: Date,
        report_format: NaN,
        specx_book: false
    })
    const { addToast } = useToasts();


    const newDropDown = (data: any) => {
        let newArr = []
        newArr.push(constant?.BooksOfAccount[0])
        newArr.push(...data)
        return newArr
    }

    const getInvoiceData = async () => {
        try {
            let storeResponse = await service.inventory.storeView()
            if (storeResponse.status === 200) {
                setStoreData(newDropDown(getDropdown(storeResponse?.data?.data,'store_location' ,'store_name')))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async () => {
        const payload = {
            store_id: typeof (reportData?.specx_book) === 'string' ? reportData?.specx_book : "",
            created_from: reportData?.created_from,
            created_to: reportData?.created_to
        };

        try {
            const response = await service.reportService?.TransactionReport(payload)
            if (response.status === 200) {
                let path = response.data.data
                let fullPath = `${BASE_URL}${path}`
                let cleanPAth = fullPath.replace(/api\/\.\//, '')
                let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
                // window.open(`${cleanPAth}`, '_blank')

                if (reportData?.report_format === constant?.DailyReportFormat[0].value) {

                    // convert the data to json
                    const xlsResponse = await fetch(cleanPAth);
                    const xlsData = await xlsResponse.arrayBuffer();

                    // Convert XLS data to JSON
                    const workbook = XLSX.read(new Uint8Array(xlsData), { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet);
                    generatePdfHeader(jsonData, 4, "transaction-report","")
                }

                if (reportData?.report_format === constant?.DailyReportFormat[1].value) {

                    setTimeout(() => {
                        fetch(`${cleanPAth}`).then((response) => {
                            response.blob().then((blob) => {

                                // Creating new object of PDF file
                                const fileURL = window.URL.createObjectURL(blob);

                                // Setting various property values
                                let alink = document.createElement("a");
                                alink.href = fileURL;
                                alink.download = `${fileName}`;
                                alink.click();
                            });
                        });
                    }, 2000);
                }

            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleChange = (name: string, value: any) => {
        setReportData({ ...reportData, [name]: value })
    }

    useEffect(() => {
        setHeader("Accounts - Transaction Report")
        getInvoiceData()
    }, [])
    return (
        <Wrapper>
            <TransactionReport
                handleChange={(name: string, value: any) => handleChange(name, value)}
                handleSubmit={() => handleSubmit()}
                storeData={storeData}
                reportData={reportData} />
        </Wrapper>
    )
}

export default Index