import React from 'react'

const Index = () => {

  return (
    <div className="">
      <h2 className="mb-0">Coming soon...</h2>
    </div>
  )
}

export default Index