import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import { HeaderHeading } from '../../../../Context'
const { MovementCancelled } = organisms
const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [cancelListing, setCancelListing] = useState<any>()
    const [search, setSearch] = useState('');
    const [pageCount, setPageCount] = useState<number>(1);
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [limit, setLimit] = useState(Default_Page_Limit);

    const getProcessListing = async () => {
        try {
            const response = await service.movement.cancelledListing({ search: search, page: pageCount, limit: limit, });
            if (response.status === 200) {
                setCancelListing(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setHeader("Movement - Cancelled")
        setSidebar(false)
        getProcessListing()
    }, [ pageCount])

    useEffect(() => {
        setPageCount(1)
        if (pageCount == 1 && initialLoading) {
            getProcessListing()
        }
        setInitialLoading(true)
    }, [limit,search])

    return (
        <Wrapper>
            <MovementCancelled
                limit={limit}
                currentPage={pageCount}
                cancelListing={cancelListing}
                setSearch={setSearch}
                setLimitPerPage={setLimit}
                setCurrentPage={setPageCount}
            />
        </Wrapper>
    )
}

export default Index