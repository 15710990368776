import React from 'react'
import molecules from '../../../../molecules'
import atoms from '../../../../atoms'

const { Input, Button } = atoms
const { TopBar } = molecules

const Index = ({ handleSubmit, isSubmit, loyalty, setLoyalty }: any) => {
    return (
        <>
            <div className="row">
                <div className="col-6 d-flex my-auto">
                    <TopBar heading={`${loyalty?.key.split('_').join(" ")}`} />
                </div>
            </div>
            <div className="w-50  mt-4">
                <div className="">
                    <Input label={"Key"} type={'text'}
                        value={loyalty?.key}
                        onChange={(e: any) => ''}
                        disabled={true}
                    />
                </div>
                <div className="mt-3">
                    <Input label={"Value"} type={'number'}
                        placeholder={"Enter Value"}
                        value={loyalty.value}
                        onChange={(e: any) => setLoyalty({ ...loyalty, value: e.target.value })}
                        errors={(!loyalty.value && isSubmit) && 'This field is required'}
                    />
                </div>

                <div className="mt-5 text-end">
                    <Button
                        onClick={() => handleSubmit()}>Submit</Button>
                </div>
            </div>
        </>
    )
}

export default Index