import React, { useState } from 'react'
import atoms from '../../../atoms'
import molecules from '../../../molecules';
import service from '../../../../api/services';
import { useNavigate } from 'react-router-dom'
import constant from '../../../../constants/constant';
import { findConstantLabel } from '../../../../constants/utils';
import PlaceHolderImage from '../../../../assets/Images/placeholderimg.png'
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
const { CreateModal } = molecules
const { Search, Button, Table, Pagination, Select,CustomImages } = atoms

const Index = ({ setSearch, pendingListing, setLimitPerPage, setCurrentPage, currentPage, limit,
    storeListing, storeData, getPendingMomentsList }: any) => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [createModal, setCreateModal] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [selectedStoreId, setSelectedStoreId] = useState('')
    const [productId, setProductId] = useState('')
    const columns = [
        { name: 'Product ID', selector: (row: any) => row.product.product_id_display,minWidth:"150px" },
        { name: 'Movement ID', selector: (row: any) => row.procurement.procurement_display_id || '-',minWidth:"110px" },
        { name: 'Date Time', selector: (row: any) => `${row?.createdAt ? moment(row?.updatedAt).format('DD/MM/YYYY , H:mm:ss') : "--"}`, sortable: true,minWidth:"100px" },
        { name: 'Cover Photo', cell: (row: any) => <CustomImages src={row?.product?.images?.length>0 && row?.product?.images[0]} />, center: true, minWidth:"150px" },
        {
            name: 'Product Type / Frame /Glass size', selector: (row: any) =>
                `${row?.product?.product_type?.name}
         ${row?.product?.rim_type ? findConstantLabel(constant?.PRODUCT_RIM_TYPE, row?.product?.rim_type) : ''}
         ${row?.product?.glass_size}`,minWidth:"150px"
        },
        { name: 'Requested Store Name', selector: (row: any) => row.requested_store.store_location || "", minWidth:"180px" },
        // { name: 'Best Possible store Name', selector: (row: any) => row.acti || '-', },
        // { name: 'Best Possible Delivery Boy', selector: (row: any) => row.acti || '-', },
        { name: 'Order ID/Warranty Claim ID', selector: (row: any) => row?.order?.order_id_display ? row?.order?.order_id_display : row?.warranty_claimed_product?.claim_id, },
        { name: 'Cancel Store Name', selector: (row: any) => row?.cancelled_by || '-', minWidth:"140px"},
        { name: 'Cancel Reason', selector: (row: any) => row?.cancel_reason || '-',minWidth:"130px" },
        { name: 'Action', selector: (row: any) => row.action, minWidth:"170px"},
    ]

    const handleSubmit = async () => {
        let payload = {
            pickup_store_id: selectedStoreId
        }
        try {
            const response = await service.movement.assignStore(productId, payload)
            if (response.status === 200) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                setIsEdit(false)
                setCreateModal(false)
                getPendingMomentsList()
                // navigate("/movement/in-process");
            }
        } catch (error) {
            console.log(error)
        }
    }
    const _handleEdit = async (id: string) => {
        storeListing(id)
        setProductId(id)
        setIsEdit(true)
        setCreateModal(true)

    }
    const _handleClose = () => {
        setIsEdit(false)
        setCreateModal(false)
    }
    return (
        <div>
            <div className="row align-items-center">
                <div className="col-6">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} />
                </div>
            </div>
            <div>
                <Table
                    AdditionalClassName="add-invertory-table data-table-container"
                    columns={columns}
                    data={pendingListing?.data?.map((item: any) => {
                        return ({
                            ...item,
                            action:
                                <div style={{ overflow: 'unset', textOverflow: 'unset' }}>
                                    <Button className="table-edit-button fs-14 fw-400"
                                        onClick={() => {
                                            _handleEdit(item.id)
                                        }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <span className='ms-2 mb-0'>Assign to store</span>
                                        </div>
                                    </Button>
                                </div>
                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={pendingListing?.count}
                    currentPage={currentPage}
                    totalPages={pendingListing?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
            {createModal && <CreateModal
                show={createModal}
                header={` Store`}
                handleSubmit={handleSubmit}
                submitBtnText={`Assign`}
                onHide={() => _handleClose()}
            >
                <div>
                    <form>
                        <div className="row">
                            <div className="col-12">
                                <Select className={"w-100"} options={storeData}
                                    // value={assignstore.pickup_store_id}
                                    onChange={(e: any) => setSelectedStoreId(e.value)}
                                    placeholder={"Select"}
                                    label={"Select Stores"} />
                            </div>
                        </div>
                    </form>
                </div>
            </CreateModal>}
        </div>
    )
}

export default Index