import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { storeSetting } = Dictionary

const SettingService = {
  sizeList: async (paramsObj: {}) => {
    return axios.get(BASE_URL + storeSetting.sizeList(), { params: paramsObj })
  },
  addSize: async (payload: any) => {
    return axios.post(BASE_URL + storeSetting.addSize(), payload)
  },
  updateSize: async (id: string, payload: any) => {
    return axios.put(BASE_URL + storeSetting.UpdateSize(id), payload)
  },
  deleteSize: async (id: string) => {
    return axios.delete(BASE_URL + storeSetting.deleteSize(id))
  }

}
export default SettingService
