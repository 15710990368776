import React from "react";
import atoms from "../../../atoms";
import { numberWithCommas, findConstantLabel } from "../../../../constants/utils";
import moment from 'moment'
import constant from "../../../../constants/constant";
import { EntriesProps, EntriesData } from "./interface";

const { Table, Pagination, Search, Input } = atoms

const Index = ({ data, handleChange }: EntriesProps) => {
    const columns = [
        { name: 'Date Time', selector: (row: EntriesData) => `${row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY , H:mm:ss') : "--"}`, sortable: true },
        { name: 'Entry number', selector: (row: EntriesData) => row?.new_entry_number ? row?.new_entry_number : "-" },
        { name: 'Category', selector: (row: EntriesData) => row?.event_type ? findConstantLabel(constant?.ACCOUNTING_EVENTS, row?.event_type) : "-" },
        { name: 'GL Name', selector: (row: EntriesData) => `${row.account?.gl_account_name ? row.account?.gl_account_name : "--"}` },
        { name: 'GL Account Number', selector: (row: any) => `${row.account?.gl_number ? row.account?.gl_number : "--"}` },
        { name: 'Amount', selector: (row: EntriesData) => row?.amount ? row.transaction_amount : "-" },
        { name: 'Narration', selector: (row: EntriesData) => row?.narration, minWidth: "15%" },
    ]

    return (
        <>
            <div className="row align-items-center">
                <div className="col-6">
                    <Search className="" searchCallBack={(e: string) => { handleChange("search", e) }} />
                </div>
                <div className="col-6">
                    <div className="d-flex gap-4 align-items-center justify-content-end">
                        <div className="d-flex align-items-center gap-2">
                            <label className='inputs-label mb-2 fs-16 fw-600 my-auto secondary-color'>From</label>
                            <Input
                                type={'Date'}
                                className="input-height"
                                placeholder={"Enter End Date"}
                                onChange={(e: any) => {
                                    handleChange("created_from", e.target.value)
                                }} />
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <label className='inputs-label mb-2 fs-16 fw-600 my-auto secondary-color'>To</label>
                            <Input
                                type={'Date'}
                                className="input-height"
                                placeholder={"Enter Start Date"}
                                onChange={(e: any) => {
                                    handleChange("created_to", e.target.value)
                                }} />
                        </div>
                    </div>
                </div>
            </div>
            {(data?.listData?.data && data?.listData?.data?.length > 0) ? <div>
                <Table
                    className="adjustable-row-height"
                    columns={columns}
                    data={data?.listData?.data?.map((item: EntriesData, index: number) => {
                        return ({
                            ...item,
                            transaction_amount: <>
                                {(item?.transaction_type == constant?.TRANSACTION_TYPE[0]?.value) ?
                                    <span>- {numberWithCommas(parseFloat(Number(item?.amount).toFixed(2)))}</span> :
                                    (item?.transaction_type == constant?.TRANSACTION_TYPE[1]?.value) ?
                                        <span>+ {numberWithCommas(parseFloat(Number(item?.amount).toFixed(2)))}</span> : null}
                            </>,
                        })
                    })}
                />
            </div> : <div className='justify-content-center d-flex mt-5 '>There are no records to display</div>}
            <div>
                <Pagination
                    totalCount={data?.listData?.count}
                    currentPage={data?.currentPage}
                    totalPages={data?.listData?.totalPages}
                    onChangePage={(page: any) => handleChange("currentPage", page)}
                    setLimit={(e: number) => handleChange("limitPerPage", e)}
                    limit={data?.limitPerPage}
                />
            </div>
        </>
    )
}

export default Index