import React, { useEffect, useState } from 'react'
import atoms from '../../../atoms'
import molecules from '../../../molecules'
import { useParams } from 'react-router'
const { Input, Button, } = atoms
const { CheckboxDropdown, TopBar } = molecules

const AddRole = ({ structuredPermission, setRolesDetails, _handleSubmit, defaultPermission, rolesDetails }: any) => {
    let params = useParams();
    let roleID: string = params.id || ''
    const [roleError, setRoleError] = useState<any>({})
    const handleChange = (name: string, value: any) => {
        setRolesDetails((prevState: any) => ({ ...prevState, [name]: value }));
        // setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));

    };
    const _handleCheckboxChange = (codeArr: []) => {
        setRolesDetails((prevState: any) => ({ ...prevState, permissions: codeArr }));
    }
    useEffect(() => {
        setRolesDetails((prevState: any) => ({ ...prevState, role_name: rolesDetails?.roleDetails?.role_name }));
    }, [rolesDetails?.roleDetails?.role_name])

    const handleClick = () => {
        let error: any = {}
        if (rolesDetails?.role_name === undefined || rolesDetails?.role_name === '') {
            error.name = "Please Enter Role Name"
        }
        if (rolesDetails?.permissions?.length === 0) {
            error.role = "Please Select Permissions"
        }
        if (rolesDetails?.role_name && rolesDetails?.permissions?.length > 0) {
            _handleSubmit()
        }
        setRoleError(error)
    }

    return (
        <div>
            <div className="col-6">
                <TopBar heading={"Add Role"} />
            </div>
            <div className='row mt-3'>
                <div className='col-4'>
                    <Input label={"Role"}
                        type={'text'} placeholder={"Enter Role"}
                        value={rolesDetails?.role_name}
                        onChange={(e: any) => { handleChange("role_name", e.target.value) }}
                        errors={!rolesDetails?.role_name && roleError.name}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className='col-6'>
                    <label className='inputs-label mb-2 fs-16 fw-600  secondary-color'>Role Permissions</label>
                    <div className='member-permission'>
                        <CheckboxDropdown structuredPermission={structuredPermission}
                            handleCheckboxChange={(codeArr: []) => _handleCheckboxChange(codeArr)}
                            defaultPermission={defaultPermission}
                        />
                    </div>
                    {roleError.role && rolesDetails?.permissions?.length === 0 && <span className="error-text">{roleError.role}</span>}
                </div>
                <div className='justify-content-center d-flex mt-4'>
                    <Button name={`${roleID ? 'Update Role' : 'Add Role'}`} className={"w-25 m-4 "} onClick={() =>
                        handleClick()} />

                </div>
            </div>
        </div >
    )
}

export default AddRole