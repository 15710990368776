import React from "react";
import atoms from "../../../atoms";
import constant from "../../../../constants/constant";

const { Select, Input, Button } = atoms

const Index = ({ handleChange, handleSubmit, reportData, storeData }: any) => {
    const buttonValidation = (date1: Date, date2: Date) => {
        const parsedDate1 = new Date(date1);
        const parsedDate2 = new Date(date2);

        if (!parsedDate1 || isNaN(parsedDate1.getTime())) {
            return true;
        }
        if (!parsedDate2 || isNaN(parsedDate2.getTime())) {
            return true;
        }

        if (!reportData?.report_format) {
            return true
        }

        if(reportData?.store_ids?.length===0){
            return true
        }

        return false
    };

    return (
        <>
            <div className="row">
                <div className="col-4">
                    <Select
                        className={"w-100"}
                        options={storeData}
                        value={reportData.store}
                        onChange={(e: any) => handleChange("store_ids", e)}
                        placeholder={"Select"}
                        label={"Select Stores"}
                        isMulti={true} />
                </div>
                <div className="col-4">
                    <Input label={"Start Date"}
                        placeholder={"Start Date"} type="date"
                        onChange={(e: any) => {
                            handleChange("created_from", e)
                        }}
                    />
                </div>
                <div className="col-4">
                    <Input label={"End Date"}
                        placeholder={"Enter Invoice Date"} type="date"
                        onChange={(e: any) => {
                            handleChange("created_to", e)
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-4">
                    <Select
                        className="w-100"
                        options={constant.DailyReportFormat}
                        onChange={(e: any) => handleChange("report_format", e.value)} placeholder={"Select"}
                        label={"Select Report Format"} />
                </div>
            </div>
            <div className="d-flex justify-content-center" style={{ marginTop: "10rem" }}>
                <Button
                    isDisabled={buttonValidation(reportData?.created_from, reportData?.created_to)}
                    style={{ minWidth: "259px" }}
                    onClick={handleSubmit}>Submit
                </Button>
            </div>
        </>
    )
}

export default Index