import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { procurement } = Dictionary

const Procurement = {
  searchProducts: async (paramsObj: {}) => {
    return axios.post(BASE_URL + procurement.searchProducts(),'', { params: paramsObj })
  },
  view: async () => {
    return axios.get(BASE_URL + procurement.viewApi())
  },
  addProduct: async (payload: {}) => {
    return axios.post(BASE_URL + procurement.addProductApi(), payload)
  },
  removeProduct: async (id: string) => {
    return axios.delete(BASE_URL + procurement.removeProductApi(id))
  },
  checkout: async (payload: {}) => {
    return axios.post(BASE_URL + procurement.checkoutApi(), payload)
  },

  procurementStoreAccountDetail: async(id:string)=>{
return axios.get(BASE_URL + procurement.storeAccountDetail(id))
  }
}
export default Procurement
