import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../../components/common/Wrapper/Wrapper'
import { HeaderHeading } from '../../../../../Context';
import organisms from '../../../../../components/organisms';
import { useToasts } from 'react-toast-notifications';
import service from '../../../../../api/services';
import { useNavigate, useParams } from 'react-router';
const { AddLoyalty } = organisms
const Index = () => {
    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    const params = useParams()
    const navigate = useNavigate()
    const [loyalty, setLoyalty] = useState({
        key: '',
        value: NaN
    })
    const { addToast } = useToasts();
    const getItemDetails = async () => {
        try {
            const response = await service.loyaltyPointsService.loyaltyItemDetail(params.id || '')
            if (response.status === 200) {
                let res = response?.data?.data
                setLoyalty({
                    key: response?.data?.data.key,
                    value: response?.data?.data.value,
                })
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    const handleSubmit = async () => {
        setIsSubmit(true)

        try {
            const payload = {
                value: loyalty.value,
            }
            const response = await service.loyaltyPointsService.loyaltyItemDetailUpdate(params.id || '', payload)
            if (response.status === 200) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                setIsSubmit(false)
                navigate('/setting/loyalty/list')

            }
        } catch (error) {

        }
    }

    useEffect(() => {
        getItemDetails()
    }, [])

    return (
        <Wrapper>
            <AddLoyalty
                handleSubmit={handleSubmit}
                isSubmit={isSubmit}
                loyalty={loyalty}
                setLoyalty={setLoyalty}
            />
        </Wrapper>
    )
}

export default Index