const Accounts = {
  // for General Account
  createGeneralAccount: () => 'admin/account',
  listGeneralAccount:()=> 'admin/account',
  accountDetailGeneralAccount: (id:string)=> `admin/account/${id}`,
  deleteGeneralAccount:(id:string)=>`admin/account/${id}`,
  accountHolderType:(id:string)=>`admin/accounts/${id}`,
  getNumericCode:(id:string)=>`admin/account/count/${id}`,


  // for sub Account
  createSubAccount: () => 'admin/account/sub-type',
  listSubAccount:()=> 'admin/account/sub-type',
  accountDetailSubAccount: (id:string)=> `admin/account/sub-type/${id}`,
  deleteSubAccount:(id:string)=>`admin/account/sub-type/${id}`,
  updateSubAccount:(id:string)=>`admin/account/sub-type/${id}`,
  
  // for Entries
  getEntries:()=>'admin/transactions',

  // for Add Voucher
  manualTransaction:()=>`admin/account/manual-transaction`,
  }

export default Accounts
  