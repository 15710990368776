export const validationRulesSubAdmin: any = {

    username: {
        required: true,
    },

    email: {
        required: true,
        pattern:/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
    },
   
    mobile: {
        required: true,
        minLength: 10,
        maxLength: 10,
    },

    // password: {
    //     required: true,
    // },

    role_id: {
        required: true,
    },

};