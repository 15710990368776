import React, { useContext, useEffect, useState } from 'react';
import organisms from '../../../../components/organisms'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services';
import {Default_Page_Limit,Default_Page_Number} from '../../../../constants/defaultValues';
import { HeaderHeading } from '../../../../Context'
const { Supplier } = organisms
const Index = () => {
    const [supplierData, setSupplierData] = useState<any>([])
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [search, setSearch] = useState('');
    const { setHeader,setSidebar }: any = useContext(HeaderHeading);

    useEffect(() => {
        setHeader("Content - Supplier")
        setSidebar(false)
        getSupplierData()
    }, [currentPage])

    useEffect(()=> {
        setCurrentPage(1)
        if(currentPage==1 && initialLoading){
            getSupplierData()
        }
        setInitialLoading(true)
    },[limitPerPage,search])


    const getSupplierData = async () => {
        try {
            let response = await service.supplier.view({ "page": currentPage, "search": search, "limit": limitPerPage })
            if (response.status === 200) {
                setSupplierData(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Wrapper>
            <Supplier
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                setSearch={setSearch}
                supplierData={supplierData}
                currentPage={currentPage}
                limit={limitPerPage}
            />
        </Wrapper>
    )
}
export default Index