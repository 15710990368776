import React, {useState} from 'react'
import atoms from '../../../atoms'
import molecules from '../../../molecules'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { FiEdit } from 'react-icons/fi'
import { RiDeleteBin6Line } from "react-icons/ri";
const { Search, Button, Table, Pagination } = atoms
const {ConfirmationModal}= molecules
const Index = ({ setSearch, setLimitPerPage, setCurrentPage, subAdminListing, currentPage, limit,_handleDelete }: any) => {
    const navigate = useNavigate()
    const [deletePopup, setDeletePopup] = useState<boolean>(false)
    const [subAdminId, setSubAdminId] = useState<string>("")

    const columns = [
        { name: 'User Name', selector: (row: any) => row.username, sortable: true, maxWidth: '200px' },
        { name: 'Role Type', selector: (row: any) => row?.role?.role_name, maxWidth: '150px' },
        { name: 'Contact No.', selector: (row: any) => row.mobile, maxWidth: '150px' },
        { name: 'Email Address.', selector: (row: any) => row.email, maxWidth: '250px' },
        { name: 'Action', selector: (row: any) => row.action, },

    ]

   const _handleDeletePopup =(id:string)=>{
    setSubAdminId(id)
    setDeletePopup(!deletePopup)
    }


    return (
        <>
            <div className="row">
                <div className="col-6"><Search searchCallBack={(e: string) => { setSearch(e) }} /></div>
                <div className="col-6 text-end">
                    <Button name={"Add Sub Admin"}
                        className=''
                        onClick={() => navigate('/sub-admin/add')}
                    />
                </div>
            </div>
            <div>
                <Table
                    columns={columns}
                    data={subAdminListing?.data?.map((item: any) => {
                        return ({
                            ...item,
                            action:
                                <>
                                    <Link
                                        to={`/sub-admin/edit/${item.id}`}>

                                        <Button className="table-edit-button fs-14 fw-400">
                                            <div className='d-flex align-items-center'>
                                                <FiEdit />
                                                <span className='ms-2 mb-0'>Edit</span>
                                            </div>
                                        </Button>
                                    </Link>
                                    <Button className="table-delete-button fs-14 fw-400 ms-3"
                                        onClick={() => _handleDeletePopup(item.id)}>
                                        <div className='d-flex align-items-center'>
                                            <RiDeleteBin6Line />
                                            <span className='ms-2 mb-0'>Delete</span>
                                        </div>
                                    </Button>
                                </>
                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={subAdminListing?.count}
                    currentPage={currentPage}
                    totalPages={subAdminListing?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
            <div>
            {deletePopup && <ConfirmationModal
                    show={deletePopup}
                    handleConfirmItem={() => {_handleDelete(subAdminId); setDeletePopup(!deletePopup) }}
                    onHide={() => setDeletePopup(!deletePopup)}
                    message={'delete this Account'} />}
            </div>
        </>
    )
}

export default Index