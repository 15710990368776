import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../../components/common/Wrapper/Wrapper'
import { HeaderHeading } from '../../../../../Context';
import organisms from '../../../../../components/organisms';
import { useToasts } from 'react-toast-notifications';
import service from '../../../../../api/services';
import { useNavigate, useParams } from 'react-router';
import constant from '../../../../../constants/constant';
const { AddBridgeSize } = organisms

const Index = () => {
    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    const params = useParams()
    const navigate = useNavigate()
    const [size, setSize] = useState<any>({
        key: '',
        value: NaN
    })
    const { addToast } = useToasts();

    const addSize = async () => {
        setIsSubmit(true)

        try {
            const payload = {
                size: size?.value,
                size_for: constant.SIZE_FOR.BRIDGE
            }
            const response = await service.SettingService.addSize(payload)
            if (response.status === 200 || response.status === 201) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                setIsSubmit(false)
                navigate('/bridge-size/list')

            }
        } catch (error: any) {
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const updateSize = async () => {
        setIsSubmit(true)

        try {
            const payload = {
                size: size?.value,
                size_for: constant.SIZE_FOR.BRIDGE
            }
            const response = await service.SettingService.updateSize(params.id || '', payload)
            if (response.status === 200) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                setIsSubmit(false)
                navigate('/bridge-size/list')

            }
        } catch (error: any) {
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    useEffect(() => {
        setSize({ ...size, value: localStorage.getItem("size") })
    }, [])

    return (
        <Wrapper>
            <AddBridgeSize
                addSize={() => addSize()}
                updateSize={() => updateSize()}
                sizeId={params.id}
                isSubmit={isSubmit}
                size={size}
                setSize={setSize}
            />
        </Wrapper>
    )
}

export default Index