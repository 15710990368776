import React, { useEffect, useState } from 'react'
import organisms from '../../../../components/organisms'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import { useToasts } from 'react-toast-notifications'
import { useNavigate, useParams } from 'react-router'
const { AddRoleOrg } = organisms

const AddRole = () => {
    const { addToast } = useToasts();
    const navigate = useNavigate()
    let params = useParams();
    let roleID: string = params.id || ''
    const [structuredPermission, setStructuredPermission] = useState<any>([])
    const [rolesDetails, setRolesDetails] = useState<any>({
        role_name: '', 
        permissions: []
    })
    const [defaultPermission, setDefaultPermission] = useState<any>([])

    const getStructuredPermission = async () => {
        try {
            const response = await service.permission.structuredPermission()
            if (response.status === 200) {
                setStructuredPermission(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const _handleSubmit = async () => {
        let payload = {
            role_name: rolesDetails.role_name,
            permissions: rolesDetails.permissions
        }
        try {
            if (roleID) {
                const response = await service.roles.update(roleID, payload)
                if (response.status === 200) {
                    navigate('/setting/role')
                    addToast('Role Updated Successfully', {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                }
            } else {
                const response = await service.roles.add(payload)
                if (response.status === 201) {
                    navigate('/setting/role')
                    addToast('Role Added Successfully', {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                }
            }
        } catch (error: any) {
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    const getRolesData = async () => {
        try {
            const response = await service.roles.getById(roleID)
            if (response.status === 200) {
                setRolesDetails({
                    roleDetails: response.data.data.roleDetails,
                    permissions: response.data.data.permissions,
                })
                setDefaultPermission(response.data.data.permissions)
            }
        } catch (error) {

        }
    }
    useEffect(() => {
        roleID && getRolesData()
        getStructuredPermission()
    }, [])
    return (
        <Wrapper>
            <AddRoleOrg
                structuredPermission={structuredPermission}
                setRolesDetails={setRolesDetails}
                _handleSubmit={_handleSubmit}
                defaultPermission={defaultPermission}
                rolesDetails={rolesDetails}
            />
        </Wrapper>
    )
}

export default AddRole