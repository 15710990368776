import axios from 'axios'
import { BASE_URL } from '../../constants/defaultValues'
import dictionary from '../dictionary'

const movement = {
  movementList: async (paramsObj: any) => {
    return axios.get(BASE_URL + dictionary.movement.procurement(), { params: paramsObj })
  },
  detailsListById: async (id:string) => {
    return axios.get(BASE_URL + dictionary.movement.details(id))
  },
  pendingListing: async (paramsObj: any) => {
    return axios.get(BASE_URL + dictionary.movement.pending(), { params: paramsObj })
  },
  assignStore: async (id:string,payload: {}) => {
    return axios.post(BASE_URL + dictionary.movement.store(id), payload)
  },
  storeListing: async (id: string) => {
    return axios.get(BASE_URL + dictionary.movement.storeListing(id))
  },
  // -------------------in-process-----------------------------------------------------------
  inProcessListing: async (paramsObj: any) => {
    return axios.get(BASE_URL + dictionary.movement.inProcess(), { params: paramsObj })
  },
  inProcessStoreById: async (id:string) => {
    return axios.get(BASE_URL + dictionary.movement.inProcessById(id))
  },
  inProcessBarcodeById: async (id:string) => {
    return axios.get(BASE_URL + dictionary.movement.barcodeById(id))
  },
  addInProcess: async (id:string,payload: {}) => {
    return axios.post(BASE_URL + dictionary.movement.inProcessPostById(id), payload)
  },
  // ----------------------------------------delivered--------------------------------------------
  deliveredListing: async (paramsObj: any) => {
    return axios.get(BASE_URL + dictionary.movement.deliver(), { params: paramsObj })
  },
  cancelledListing: async (paramsObj: any) => {
    return axios.get(BASE_URL + dictionary.movement.cancelListing(), { params: paramsObj })
  },

  cancelMovementItem:async(id:string)=>{
    return axios.delete(BASE_URL + dictionary.movement.cancelMovement(id))
  },

  inProcessMovementCancelledProduct: async (id: string,payload:any) => {
    return axios.put(BASE_URL + dictionary.movement.cancelledProduct(id),payload)
  }
}
export default movement
