const barcodeStatus = {
    // approved 
    barcodeStatus: () => 'inventory/barcode-list',
    barcodeStatusPending: () => 'inventory/barcode-list',
    barcodeStatusVerification: () => 'inventory/inventory-products/verification-status',
    barcodeStatusById: (id: string) => `inventory/inventory-products/${id}/status`,
    barcodeStatusMoveOut: () => 'inventory/sold-out-barcodes',

    // Lost Barcode
    getLostBarcode:()=>'inventory/barcode/lost',
    searchLostBarcode:()=>'inventory/barcode/search',
    markBarcodeLost:()=>'inventory/barcode/lost'
}
export default barcodeStatus
