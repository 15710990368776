import React from "react";
import molecules from "../../../molecules";
import BarcodeItemWise from './BarcodeItemWise'
import ProductWise from './ProductWise'

const { Report } = molecules

const Index = ({ handleChange,reportData,submitIndividualBarcode,submitIndividualProduct, clearData }: any) => {

    return (
        <>
            <div className="">
                <div className='custom-tabs mt-4'>
                    <ul className="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => clearData()}
                            >
                                Barcode Wise & Item Wise
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                                onClick={() => clearData()}
                            >
                                Product ID Wise
                            </button>
                        </li>

                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabIndex={0}
                            style={{ border: "#fff" }}
                        >
                            <BarcodeItemWise
                             handleChange={(name:string, value:any)=>handleChange(name, value)}
                              reportData={reportData}
                              submitIndividualBarcode={()=>submitIndividualBarcode()} />
                        </div>
                        <div
                            className="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabIndex={0}
                            style={{ border: "#fff" }}
                        >
                            <ProductWise 
                            handleChange={(name:string, value:any)=>handleChange(name, value)}
                            reportData={reportData}
                            submitIndividualProduct={()=>submitIndividualProduct()} />

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Index