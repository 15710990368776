import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from "../../../../components/organisms";
import service from '../../../../api/services';
import { HeaderHeading } from '../../../../Context'
const { ProductType } = organisms
const Index = () => {
    const [productTypeListing, setProductTypeListing] = useState<any>()
    const [search, setSearch] = useState('');
    const { setHeader,setSidebar }: any = useContext(HeaderHeading);
    const getProductTypeListing = async () => {
        try {
            const response = await service.productData.productTypeListingService({ search: search });
            setProductTypeListing(response.data)
        } catch (error) {

        }
    }
    useEffect(() => {
        setHeader("Content - Product Type")
        setSidebar(false)
        getProductTypeListing()
    }, [search])

    return (
        <Wrapper>
            <ProductType productTypeListing={productTypeListing} setSearch={setSearch} getProductTypeListing={getProductTypeListing} />
        </Wrapper>
    )
}

export default Index