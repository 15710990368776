import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { auth } = Dictionary

const authentication = {
  login: async (data?: {}, headers?: any) => {
    return axios.post(BASE_URL + auth.login(), data, { headers: headers })
  },
  logout: async (headers: any) => {
    return axios.post(BASE_URL + auth.logout(), { headers: headers })
  },
}
export default authentication
