import store from './store'
import movement from './movement'
import product from './product'
import productData from './productData'
import auth from './auth'
import supplier from './supplier'
import subAdmin from './subAdmin'
import permission from './permission'
import inventory from './inventory'
import barcodeStatus from './barcodeStatus'
import roles from './roles'
import allocation from './allocation'
import AccountsServices from './accounts'
import BulkEditService from "./bulkEditService"
import loyaltyPointsService from './loyaltyPoints'
import procurement from './procurement'
import InventoryAuditService from './InventoryAuditService'
import warrantyService from './warranty'
import reportService from './report'
import SettingService from './setting'
import DashboardService from './dashboard'
import WebsiteFormService from './websiteForms'

const service = {
  store,
  movement,
  product,
  productData,
  auth,
  supplier,
  subAdmin,
  permission,
  inventory,
  barcodeStatus,
  roles,
  allocation,
  AccountsServices,
  BulkEditService,
  loyaltyPointsService,
  procurement,
  InventoryAuditService,
  warrantyService,
  reportService,
  SettingService,
  DashboardService,
  WebsiteFormService,
}
export default service
