import React from 'react'
import atoms from '../../atoms';
// import Images from '../../../assets/Images'
import BackIcon from '../../../assets/Images/backIcon.svg';
import { useNavigate } from 'react-router';
const { Image } = atoms
// const { BackIcon } = Images
const TopBar = ({ heading }: any) => {
    const navigate = useNavigate()
    return (
        <>
            <div className='col-12'>
                <div className='d-flex align-items-center gap-4'>
                    <Image src={BackIcon} alt="Back Icon" className='img-fluid cursor-pointer' onClick={() => navigate(-1)} />
                    <div className='col-9'>
                        <h2 className='mb-0 fs-20 fw-600'>{heading}</h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopBar