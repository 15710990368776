export const validationRulesStepOne: any = {
    store_name: {
        required: true,
    },

    store_address: {
        required: true,
    },

    store_location: {
        required: true,
    },
    
    city: {
        "label": {
            required: true,
        },
    },

    state: {
        "label": {
            required: true,
        },
    },
    pin_code: {
        required: true,
    },
    owner_name: {
        required: true,
    },
    owner_mobile: {
        required: true,
        minLength: 10,
        maxLength: 10,
    },

    owner_is_manager: {
        required: true,
    },
    manager_name: {
        required: true,
    },
    manager_mobile: {
        required: true,
    },
    legal_status: {
        "label": {
            required: true,
        },
    },
    opening_time: {
     
    },
    closing_time: {
   
    },
    latitude:{
        
    },
    longitude:{

    }

};
export const validationRulesStepTwo: any = {
    admin_username: {
        required: true,
    },
    admin_name: {
        required: true,
    },
    admin_email: {
        // valid: true,
        pattern:/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
    },

}
export const validationRulesStepThree: any = {

}