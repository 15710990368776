import React, { useState, useEffect, useContext } from "react";
import { HeaderHeading } from "../../../../Context";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from 'react-toast-notifications';
import service from "../../../../api/services";
import { BASE_URL } from "../../../../constants/defaultValues";
import { getDropdown, handleReportDownload } from "../../../../constants/utils";
import * as XLSX from "xlsx"
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import constant from "../../../../constants/constant";

const { StoreSalesReportInvoiceWise, StoreSalesReportBrandWise, StoreSalesReportMonthWise } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [storeData, setStoreData] = useState();
    const [reportData, setReportData] = useState<any>({
        store_ids: [],
        store_id: "",
        created_from: "",
        created_to: "",
        month_from: "",
        month_to: "",
        report_format: NaN,
        report_type: 1
    })

    const { addToast } = useToasts();

    const getInvoiceData = async () => {
        try {
            let storeResponse = await service.inventory.storeView()
            if (storeResponse.status === 200) {
                setStoreData(getDropdown(storeResponse?.data?.data, 'store_location', 'store_name'))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (name: string, value: any) => {
        setReportData({ ...reportData, [name]: value })
    }

    const submitIndividualInvoices = async () => {
        const storeIds = reportData?.store_ids?.map((item: any) => item.value)

        const payload = {
            store_ids: storeIds,
            created_from: reportData?.created_from,
            created_to: reportData?.created_to
        }
        try {
            const response = await service.reportService?.storeSalesInvoice(payload)
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "store-sales-invoice-wise-report", constant?.DailyReportFormat[1].value, "", true)
            }

        }
        catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const submitIndividualMonth = async () => {

        const payload = {
            store_id: reportData?.store_id?.value,
            created_from: reportData?.month_from ? new Date(reportData.month_from) : null,
            created_to: reportData?.month_to ? new Date(reportData.month_to) : null
        }
        try {
            const response = await service.reportService?.storeSalesMonth(payload)
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "store-sales-month-wise-report", reportData?.report_format?.value, "Specxweb", true)
            }

        }
        catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const submitIndividualBrand = async () => {

        const payload = {
            store_id: reportData?.store_id?.value,
            created_from: reportData?.created_from,
            created_to: reportData?.created_to
        }
        try {
            const response = await service.reportService?.storeSalesBrand(payload)
            if (response.status === 200) {
                let path = response?.data?.data
                let fullPath = `${BASE_URL}${path}`
                let cleanPAth = fullPath.replace(/api\/\.\//, '')
                let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
                // window.open(`${cleanPAth}`, '_blank')

                if (reportData?.report_format?.value === constant?.DailyReportFormat[0].value) {

                    // convert the data to json
                    const xlsResponse = await fetch(cleanPAth);
                    const xlsData = await xlsResponse.arrayBuffer();

                    // Convert XLS data to JSON
                    const workbook = XLSX.read(new Uint8Array(xlsData), { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet);
                    generatePdfHeader(jsonData, 5, 'store-sales-brand-wise-report',"Specxweb", true)
                }

                if (reportData?.report_format?.value === constant?.DailyReportFormat[1].value) {

                    setTimeout(() => {
                        fetch(`${cleanPAth}`).then((response) => {
                            response.blob().then((blob) => {

                                // Creating new object of PDF file
                                const fileURL = window.URL.createObjectURL(blob);

                                // Setting various property values
                                let alink = document.createElement("a");
                                alink.href = fileURL;
                                alink.download = `${fileName}`;
                                alink.click();
                            });
                        });
                    }, 2000);
                }
                // handleReportDownload(response?.data?.data, "store-sales-brand-wise-report", reportData?.report_format?.value,"Specxweb", true)
            }

        }
        catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }


    const clearData = () => {
        setReportData((prevReportData: any) => ({
            ...prevReportData,
            store_ids: [],
            store_id: "",
            created_from: "",
            created_to: "",
            month_from: "",
            month_to: "",
            report_format: NaN,
            report_type: 1
        }))
    }

    // to generate pdf

    const generatePdfHeader = (data: any, countOfRowHaveData: number, pdfName: string,report_heading:string , date_section?:boolean,) => {
        try {
            if (!data || data.length < countOfRowHaveData) {
                throw new Error("Invalid input data or insufficient rows.");
            }
    
            const headerRow = data[countOfRowHaveData - 1]; // Get the fifth-to-last row
            const headerValues = Object.values(headerRow);
            generatePdfData(data, headerValues, countOfRowHaveData, pdfName, report_heading ,date_section)
        } catch (error) {
            console.log("Error in generatePdfHeader", error)
        }
    }

    const getValueFromString = (valueArr: any, name: string) => {
        try {
            if (!valueArr) {
                throw new Error("Value array is null or undefined.");
            }
            let lastIndex = valueArr.lastIndexOf([name]); // last occurrence of space
            if(lastIndex<0){
                return valueArr?.split(name)[1]
            }
            let value = valueArr?.substring(lastIndex + 1);
            return value
        } catch (error) {
            console.log("Error in getValueFromString", error)
        }
    }
    
    const generatePdfData = (data: any, header: any, countOfRowHaveData: number, pdfName: string , report_heading:string, date_section?:boolean) => {
        try {
            if (!data) {
                throw new Error("Input data is null or undefined.");
            }
    
            const pdfData = data.slice(countOfRowHaveData).map((row: any, index: number) => {
                const headerValues = Object.values(row);
                const obj: any = { id: index + 1 };
                header.forEach((key: any, j: number) => {
                    obj[key] = headerValues[j];
                });
                return obj;
            });

            // (0,1,2) are the row in which storeName , date in the xlsx last 0 add to access the data form array
            // ":","n","m", is take to separate the string it take from xlsx 
            pdfData.reportHeading = report_heading ? report_heading : ''
            pdfData.reportStoreName = getValueFromString(Object.values(data[0])[0], ":")
            pdfData.storeName = getValueFromString(Object.values(data[1])[0], ":")
            pdfData.reportGeneratedOnTime = (getValueFromString(Object.values(data[2])[0], "n").split(" ")[2]);
            pdfData.reportGeneratedOnDate = (getValueFromString(Object.values(data[2])[0], "n").split(" ")[1]);
            pdfData.reportIsFrom = date_section ? getValueFromString(Object.values(data[3])[0], "m") : getValueFromString(Object.values(data[2])[0], " ")
    
            generatePDF(header, pdfData, pdfName);
    
        } catch (error) {
            console.log("Error in generatePdfData", error)
        }
    };
    
    const createHeaders = (keys: string[]) => {
        try {
            const result = keys.map(key => {
                return {
                    id: key,
                    name: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').replace(/_/g, ' ').trim(),
                    prompt: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
                    width: 65,
                    align: "center",
                    padding: 0
                };
            });
            return result;
        } catch (error) {
            console.log("Error in createHeaders")
        }
    };
    
    const generatePDF = (header: any[], pdfData: any, pdfName: string) => {
        try {
            if (!pdfData || !Array.isArray(header) || header.length === 0) {
                throw new Error("Invalid input data for PDF generation.");
            }
            const headers: any = createHeaders(header);
    
            const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape", format: 'a4', });
            const data = pdfData?.map((entry: any) => ({
                ...entry,
                id: entry.id.toString() // Convert id to string explicitly
            }));
    
            // Add heading
            if(pdfData?.reportHeading){
                const heading = `${pdfData?.reportHeading}`
                doc.setFontSize(20);
                let textWidth = doc.getStringUnitWidth(heading) * doc.getFontSize() / doc.internal.scaleFactor;
                let xCoordinate = parseFloat(((doc.internal.pageSize.width - textWidth) / 2).toFixed(2));
                doc.text(heading, xCoordinate, 10);
            }
    
            // Add date

            if (pdfData?.reportStoreName) {
                const report_date_to = `${pdfData?.reportStoreName}`;
                doc.setFontSize(10);
                doc.text(`Store Name :`, 15, 15);
                doc.setTextColor(80, 90, 111);
                doc.text(`${report_date_to}`, 37, 15);
            }
    
            if (pdfData?.storeName) {
                const report_date_to = `${pdfData?.storeName}`;
                doc.setFontSize(10);
                doc.setTextColor(0, 0, 0);
                doc.text(`Report Heading :`, 15, 20);
                doc.setTextColor(80, 90, 111);
                doc.text(`${report_date_to}`, 41, 20);
            }
    
            if (pdfData?.reportGeneratedOnDate) {
                const report_date_to = `${pdfData?.reportGeneratedOnDate}, ${pdfData?.reportGeneratedOnTime}`;
                doc.setFontSize(10);
                doc.setTextColor(0, 0, 0);
                doc.text(`Report Generated on :`, 15, 25);
                doc.setTextColor(80, 90, 111);
                doc.text(`${report_date_to}`, 52, 25);
            }
    
            if (pdfData?.reportIsFrom) {
                const report_date_from = ` ${pdfData?.reportIsFrom}`;
                doc.setFontSize(10);
                doc.setTextColor(0, 0, 0);
                doc.text("Report is from :", 15, 30);
                doc.setTextColor(80, 90, 111);
                doc.text(`${report_date_from}`, 38, 30);
            }
    
            (doc as any).autoTable({
                head: [headers.map((header: any) => header.prompt)],
                body: data.map((row: any) => headers.map((header: any) => row[header.id])),
                startY: 33,// Adjust starting Y position as needed,
            });
            doc.save(`${pdfName}.pdf`);
        } catch (error) {
            console.error("Error in generatePDF", generatePDF)
        }
    };

    useEffect(() => {
        setHeader("Report - Store Sales Report")
        getInvoiceData()
    }, [])

    return (
        <Wrapper>
            <div className="">
                <div className='custom-tabs'>
                    <ul className="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => clearData()}
                            >
                                Invoice wise
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                                onClick={() => clearData()}
                            >
                                Month Wise
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-brand-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-brand"
                                type="button"
                                role="tab"
                                aria-controls="pills-brand"
                                aria-selected="false"
                                onClick={() => clearData()}
                            >
                                Brand Wise
                            </button>
                        </li>

                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        {/* section 1 */}
                        <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabIndex={0}
                            style={{ border: "#fff" }}
                        >
                            <StoreSalesReportInvoiceWise
                                handleChange={(name: string, value: any) => handleChange(name, value)}
                                reportData={reportData}
                                storeData={storeData}
                                submitIndividualInvoices={() => submitIndividualInvoices()}
                            />

                        </div>

                        {/* section 2 */}
                        <div
                            className="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabIndex={1}
                            style={{ border: "#fff" }}
                        >
                            <StoreSalesReportMonthWise
                                handleChange={(name: string, value: any) => handleChange(name, value)}
                                reportData={reportData}
                                storeData={storeData}
                                submitIndividualMonth={() => submitIndividualMonth()}
                            />

                        </div>

                        {/* section 3 */}
                        <div
                            className="tab-pane fade"
                            id="pills-brand"
                            role="tabpanel"
                            aria-labelledby="pills-brand-tab"
                            tabIndex={0}
                            style={{ border: "#fff" }}
                        >
                            <StoreSalesReportBrandWise
                                handleChange={(name: string, value: any) => handleChange(name, value)}
                                reportData={reportData}
                                storeData={storeData}
                                submitIndividualBrand={() => submitIndividualBrand()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Index