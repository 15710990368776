import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import { useToasts } from 'react-toast-notifications'
import { HeaderHeading } from '../../../../Context'
const { Store } = organisms
const Index = () => {
  const { addToast } = useToasts();
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [storeListing, setStoreListing] = useState<any>()
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState<number>(1);
  const [limit, setLimit] = useState(Default_Page_Limit);
  const getStoreListing = async () => {
    try {
      const response = await service.store.storeListingService({ search: search, page: pageCount, limit: limit, });
      setStoreListing(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    setHeader("Store - Add Store")
    setSidebar(false)
    getStoreListing()
  }, [pageCount])

  useEffect(() => {
    setPageCount(1)
    if (pageCount == 1 && initialLoading) {
      getStoreListing()
    }
    setInitialLoading(true)
  }, [limit, search])

  const handleStatus = async (id: string, status:number) => {
    const payload = {
      "status": status
    }
    try {
      // let response = await service.store.storeStatus(id);
      let response = await service.store.storeStatus(id, payload);
      if (response.status === 200) {
        addToast('Status updated successfully', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        getStoreListing()
      }
    } catch (error) {

    }
  }
  return (
    <Wrapper>
      <Store
        limit={limit}
        currentPage={pageCount}
        storeListing={storeListing}
        setSearch={setSearch}
        setLimitPerPage={setLimit}
        setCurrentPage={setPageCount}
        handleStatus={(id: string, status:number) => handleStatus(id, status)}
      />
    </Wrapper>
  )
}

export default Index