import React, { useState, useEffect, useContext } from "react";
import { HeaderHeading } from "../../../../Context";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from 'react-toast-notifications';
import service from "../../../../api/services";
import { handleReportDownload } from "../../../../constants/utils";
import constant from "../../../../constants/constant";

const { InventoryReport } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();
    const [reportData, setReportData] = useState<any>({
        // created_from: "",
        // created_to: "",
        report_date:"",
        report_format: "",
    })

    const submitIndividualBarcode = async() => {
        try {
            const response = await service.reportService?.inventoryBarcode({report_date:reportData?.report_date})
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "inventory-barcode-wise-report", constant?.DailyReportFormat[1].value,"Specxweb")
            }

        }
        catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const submitIndividualProduct = async() => {
 
        try {
            const response = await service.reportService?.inventoryProduct({report_date:reportData?.report_date})
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "inventory-product-wise-report", reportData?.report_format?.value,"Specxweb")
            }

        }
        catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleChange = (name: string, value: any) => {
        setReportData({ ...reportData, [name]: value })
    }
    const clearData = () => {
        setReportData((prevReportData: any) => ({...prevReportData, report_date: "", report_format: "" }))
    }

    useEffect(() => {
        setHeader("Report - Inventory Report")
    }, [])

    return (
        <Wrapper>
            <InventoryReport
                handleChange={(name: string, value: any) => handleChange(name, value)}
                reportData={reportData}
                submitIndividualBarcode={() => submitIndividualBarcode()}
                submitIndividualProduct={() => submitIndividualProduct()} 
                clearData={()=>clearData()}/>
        </Wrapper>
    )
}

export default Index