import React, { useEffect, useState } from 'react'
import organisms from '../../../../components/organisms'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import { ISelectedRole, ISubAdmin } from './interface'
import service from '../../../../api/services'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { useParams } from "react-router-dom";
import { validationRulesSubAdmin } from './validationRules'
import { emailValidation } from '../../../../constants/utils'
const { AddSubAdminOrg } = organisms
const AddSubAdmin = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate()
  let params = useParams();
  let subAdminId: string = params.id || ''
  const [showPassword, setShowPassword] = useState(false)
  const [subAdminDetails, setSubAdminDetails] = useState<any>({
    user_id:"",
    username: '',
    email: '',
    mobile: '',
    password: '',
    permissions: [],
    role_id: ''
  })
  const [structuredPermission, setStructuredPermission] = useState<any>([])
  const [defaultPermission, setDefaultPermission] = useState<any>([])
  const [rolesList, setRolesList] = useState<any>()
  const [rolesData, setRolesData] = useState<any>()
  const [selectedRole, setSelectedRole] = useState<string>('')
  const [errors, setErrors] = useState({});

  const [submitClicked, setSubmitClicked] = useState<boolean>(false)

  const getStructuredPermission = async () => {
    try {
      const response = await service.permission.structuredPermission()
      if (response.status === 200) {
        setStructuredPermission(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const validate = () => {
    let newErrors: any = {};
    for (const field in validationRulesSubAdmin) {
      // if (validationRulesSubAdmin.hasOwnProperty(field)) {
      if (validationRulesSubAdmin[field]?.required && !subAdminDetails[field]) {
        newErrors[field] = "This field is required.";
      } else if ((validationRulesSubAdmin[field]?.label?.required) && (!subAdminDetails[field]?.label)) {
        newErrors[field] = "Please Select field";
      } else if ((validationRulesSubAdmin[field][0]?.label?.required) && (!subAdminDetails[field][0]?.label)) {
        newErrors[field] = "Please Select field";
      } else if (validationRulesSubAdmin[field]?.minLength < 10) {
        newErrors[field] = `Maximum length is ${validationRulesSubAdmin[field].minLength}`
      }
      if (!subAdminId && !subAdminDetails.password) {
        newErrors.password = "This field is required.";
      }
      if (validationRulesSubAdmin[field]?.required && subAdminDetails[field]) {
        if (field === "email") {
          let error = validationRulesSubAdmin[field]?.pattern.test(subAdminDetails[field])
          if (!error) {
            newErrors[field] = "Invalid Field";
          }
        }
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handleChangePassword = async (password:any) => {
    let payload = {
        user_id: subAdminId,
        password: password
    }
    try {
        let response = await service.store.changePassword(payload)
        if (response.status === 200) {
            addToast('Password Change successfully', {
                appearance: 'success',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    } catch (error) {

    }
}

  const _handleSubmitBtnClick = () => {
    const isValid = validate();

    if (isValid)
      setSubmitClicked(!submitClicked)
  };
  useEffect(() => {
    if (submitClicked) {
      _handleSubmitSubAdmin()
    }
    setSubmitClicked(false)
  }, [submitClicked])

  const _handleSubmitSubAdmin = async () => {
    const payload = {
      user_id:subAdminDetails?.user_id,
      username: subAdminDetails.username,
      email: subAdminDetails.email,
      mobile: subAdminDetails.mobile,
      password: subAdminDetails.password,
      permissions: subAdminDetails.permissions,
      role_id: selectedRole
    }
    try {
      if (subAdminId) {
        const response = await service.subAdmin.updateSubAdmin(subAdminId, payload)
        if (response.status === 200) {
          navigate('/sub-admin')
          addToast('Sub Admin Updated Successfully', {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        }
      } else {
        const response = await service.subAdmin.createSubAdmin(payload)
        if (response.status === 201) {
          navigate('/sub-admin')
          addToast('Sub Admin Added Successfully', {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        }
      }
    } catch (error: any) {
      console.log('error', error)
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }
  const getSubAdminDetails = async () => {
    try {
      const response = await service.subAdmin.getSubAdminById(subAdminId)
      if (response.status === 200) {
        setSubAdminDetails({
          user_id:response.data.data.subadminDetails.login_id,
          username: response.data.data.subadminDetails.username,
          email: response.data.data.subadminDetails.email,
          mobile: response.data.data.subadminDetails.mobile,
          password: '',
          permissions: response.data.data.permissions,
          role_id: {
            key: response.data.data?.subadminDetails?.role?.id,
            value: response.data.data?.subadminDetails?.role?.role_name,
            label: response.data.data?.subadminDetails?.role?.role_name,
          }
        })
        setDefaultPermission(response.data.data.permissions)

      }
    } catch (error) {
      console.log(error)
    }
  }
  const getRolesList = async () => {
    try {
      let response = await service.roles.list({ search: '' })
      setRolesData(response?.data?.data)
      let list = response.data.data.map((item: any) => {
        return {
          key: item.id,
          value: item.role_name,
          label: item.role_name,
        }
      })
      setRolesList(list)
    } catch (error) {
      console.log(error)
    }
  }
  const handleSelectedRole = (role: ISelectedRole) => {
    rolesData && rolesData.filter((item: any) => {
      if (item.id === role.key) {
        setDefaultPermission(item.permissions)
      }
    })
    setErrors((prevErrors: any) => ({ ...prevErrors, role_id: "" }));
    setSelectedRole(role.key)
    setSubAdminDetails({ ...subAdminDetails, role_id: role })
  }




  useEffect(() => {
    if (subAdminId) {
      getSubAdminDetails()
    }
    getStructuredPermission()
    getRolesList()
  }, [])

  return (
    <Wrapper>
      <AddSubAdminOrg
        setSubAdminDetails={setSubAdminDetails}
        subAdminDetails={subAdminDetails}
        structuredPermission={structuredPermission}
        // _handleSubmitSubAdmin={_handleSubmitSubAdmin}
        _handleSubmitSubAdmin={_handleSubmitBtnClick}
        defaultPermission={defaultPermission}
        rolesList={rolesList}
        errors={errors}
        handleSelectedRole={handleSelectedRole}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        setErrors={setErrors}
        handleChangePassword={(e:any)=>handleChangePassword(e)}
      />
    </Wrapper>
  )
}

export default AddSubAdmin