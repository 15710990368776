import React, { useState, useEffect } from 'react'
import atoms from "../../../../components/atoms";
import constant from '../../../../constants/constant'
import { findConstantLabel } from '../../../../constants/utils'
import { RxCross2 } from "react-icons/rx";
import molecules from '../../../molecules';
// import { CiLight } from 'react-icons/ci';

const { Button, CustomTable } = atoms
const { BulkSidePopUp } = molecules
const Index = ({ data, imageData, handleImageUpload, handleBulkSubmit, errorRow, handleRemove }: any) => {
    const [showSidebarModal, setShowSidebarModal] = useState(false)
    const [previewImage, setPreviewImage] = useState([])

    const altAttr = {
        directory: "",
        webkitdirectory: ""
    }
    const imageUrl = (id: number) => {
        if (imageData) {
            const foundImages: any = imageData?.filter((element: any) => parseInt(element.id) === id);
            if (foundImages) {
                setPreviewImage(foundImages);
                setShowSidebarModal(true);
            }
        }
    };
    const isNumber = (n: any) => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }
    const columns = [
        { name: "Image Folder Name", selector: (row: any) => `${row.image_folder_name ? row.image_folder_name : "-"} ` },
        { name: 'brand color name', selector: (row: any) => row.brand_color_name },
        {
            name: 'Features',
            cell: (row: any) => {
                const style: any = {
                    fontWeight: (row?.features ? !isNumber(row?.features) : false) && "bold",
                    color: (row?.features ? !isNumber(row?.features) : false) && "red",
                    border: (row?.features ? !isNumber(row?.features) : false) && "2px solid",
                    padding: (row?.features ? !isNumber(row?.features) : false) && "5px"
                }
                if (isNumber(row?.features)) {
                    return <span style={style}>{(isNumber(row?.features)) ? findConstantLabel(constant.PRODUCT_FEATURES, parseInt(row?.features)) : "--"}</span>;

                } else {
                    const arr = row?.features && row?.features?.split(',')
                    return <span>{arr?.map((item: any, index: any) => {
                        return `${findConstantLabel(constant.PRODUCT_FEATURES, parseInt(item))}, `
                    })}</span>
                }
            },
        },
        {
            name: 'Gender',
            cell: (row: any) => {
                const style: any = {
                    fontWeight: (row?.gender ? !isNumber(row?.gender) : false) && "bold",
                    color: (row?.gender ? !isNumber(row?.gender) : false) && "red",
                    border: (row?.gender ? !isNumber(row?.gender) : false) && "2px solid",
                    padding: (row?.gender ? !isNumber(row?.gender) : false) && "5px"
                }
                if (isNumber(row?.gender)) {
                    return <span style={style}>{(isNumber(row?.gender)) ? findConstantLabel(constant.GENDER, parseInt(row?.gender)) : "--"}</span>;

                } else {
                    const arr = row?.gender && row?.gender?.split(',')
                    return <span>{arr?.map((item: any, index: any) => {
                        return `${findConstantLabel(constant.GENDER, parseInt(item))}, `
                    })}</span>
                }
            },
        },

        {
            name: 'brand code',
            cell: (row: any) => {
                let x = errorRow.some((error: any) => error.message === row?.brand_code)
                const style = {
                    fontWeight: x && "bold",
                    border: x && "2px solid",
                    padding: x && "5px"
                }
                return <span style={style}>{row.brand_code ? row?.brand_code : "error"}</span>;
            },
        },
        {
            name: 'product type initials',
            cell: (row: any) => {
                let x = errorRow.some((error: any) => error.message == row.product_type_initials)
                const style = {
                    fontWeight: x && "bold",
                    border: x && "2px solid",
                    padding: x && "5px"
                }
                return <span style={style}>{row.product_type_initials ? row?.product_type_initials : "error"}</span>;
            },
        },
        {
            name: 'brand color code',
            cell: (row: any) => {
                const style = {
                    fontWeight: row.brand_color_code ? "inherit" : "bold",
                    border: row.brand_color_code ? "inherit" : "2px solid",
                    padding: row.brand_color_code ? "inherit" : "5px"
                }
                return <span style={style}>{row.brand_color_code ? row?.brand_color_code : "error"}</span>;
            },
        },
        {
            name: 'glass size',
            cell: (row: any) => {
                const style = {
                    fontWeight: row.glass_size ? "inherit" : "bold",
                    border: row.glass_size ? "inherit" : "2px solid",
                    padding: row.glass_size ? "inherit" : "5px",
                }
                return <span style={style}>{row.glass_size ? row?.glass_size : "error"}</span>;
            },
        },

        {
            name: 'polarised',
            cell: (row: any) => {

                const style = {
                    fontWeight: (row?.isPolarised && row?.isPolarised < 4 && isNumber(row?.isPolarised)) ? "inherit" : "bold",
                    border: (row?.isPolarised && row?.isPolarised < 4 && isNumber(row?.isPolarised)) ? "inherit" : "2px solid",
                    padding: (row?.isPolarised && row?.isPolarised < 4 && isNumber(row?.isPolarised)) ? "inherit" : "5px"
                }
                return <span style={style}>{(row?.isPolarised && isNumber(row?.isPolarised)) ? findConstantLabel(constant.POLARIZED, parseInt(row?.isPolarised)) : "--"}</span>;
            },
        },
        {
            name: 'model number',
            sortable: true,
            cell: (row: any) => {
                const style = {
                    fontWeight: row.modal_number ? "inherit" : "bold",
                    border: row.modal_number ? "inherit" : "2px solid",
                    padding: row.modal_number ? "inherit" : "5px"
                }
                return <span style={style}>{row.modal_number ? row?.modal_number : "error"}</span>;
            },
        },
        {
            name: 'product material',
            cell: (row: any) => {
                let x = errorRow.some((error: any) => error.message == row.product_material)
                const style = {
                    fontWeight: x && "bold",
                    border: x && "2px solid",
                    padding: x && "5px"
                }
                return <span style={style}>{row.product_material ? row?.product_material : " "}</span>;
            },
        },
        {
            name: 'sw_color_name',
            cell: (row: any) => {
                let x = errorRow.some((error: any) => error.message == row.sw_color_name)
                const style = {
                    fontWeight: x && "bold",
                    border: x && "2px solid",
                    padding: x && "5px"
                }
                return <span style={style}>{row?.sw_color_name ? row?.sw_color_name : " "}</span>;
            },
        },
        { name: 'product srp', selector: (row: any) => row?.product_srp || "-" },

        { name: 'product shape', selector: (row: any) => `${row?.product_shape || "-"}` },
        { name: 'product_with_case', selector: (row: any) => (row?.product_with_case ? 'Yes' : 'No') },
        {
            name: 'Rim Type',
            cell: (row: any) => {
                const style: any = {
                    fontWeight: (row?.rim_type ? !isNumber(row?.rim_type) : false) && "bold",
                    color: (row?.rim_type ? !isNumber(row?.rim_type) : false) && "red",
                    border: (row?.rim_type ? !isNumber(row?.rim_type) : false) && "2px solid",
                    padding: (row?.rim_type ? !isNumber(row?.rim_type) : false) && "5px"
                }
                return <span style={style}>{(isNumber(row?.rim_type)) ? findConstantLabel(constant.PRODUCT_RIM_TYPE, parseInt(row.rim_type)) : "--"}</span>;
            },
        },
        { name: 'sw_lens_color', selector: (row: any) => row.sw_lens_color },
        { name: 'temple_size', selector: (row: any) => row.temple_size },
        {
            name: 'Warranty',
            // selector: (row: any) => `${row.brand_code}`,
            cell: (row: any) => {
                const style: any = {
                    fontWeight: (row?.warranty ? !isNumber(row?.warranty) : false) && "bold",
                    color: (row?.warranty ? !isNumber(row?.warranty) : false) && "red",
                    border: (row?.warranty ? !isNumber(row?.warranty) : false) && "2px solid",
                    padding: (row?.warranty ? !isNumber(row?.warranty) : false) && "5px"
                }
                return <span style={style}> {(isNumber(row?.warranty)) ? findConstantLabel(constant?.PRODUCT_WARRANTY, parseInt(row?.warranty)) : "--"}</span>;
            },
        },
        {
            name: 'Weight',
            cell: (row: any) => {
                const style: any = {
                    fontWeight: (row?.weight ? !isNumber(row?.weight) : false) && "bold",
                    color: (row?.weight ? !isNumber(row?.weight) : false) && "red",
                    border: (row?.weight ? !isNumber(row?.weight) : false) && "2px solid",
                    padding: (row?.weight ? !isNumber(row?.weight) : false) && "5px"
                }
                return <span style={style}> {(isNumber(row?.weight)) ? findConstantLabel(constant.PRODUCT_WEIGHT, parseInt(row?.weight)) : "--"}</span>;
            },
        },
        {
            name: 'Description',
            selector: (row: any) => row.description ? row?.description : "-",
            cell: (row: any) => {
                const style: any = {
                    // whiteSpace: 'inherit',
                    height: "80%",
                    overflow: "hidden",
                    textOverflow: 'ellipsis',
                    // overflowX: 'hidden',
                    // overFlowY:"scroll",
                    // Add other styles if needed
                };

                return <span style={style}>{row.description ? row.description : "-"}</span>;
            },
        },
        // { name: 'Description', selector: (row: any) => row.description ? row?.description : "-" },
        { name: 'Photo', selector: (row: any, index: number) => row.modal_number && row.photo, style: { position: 'relative', zIndex: '3', }, },
        { name: 'Action', selector: (row: any, index: number) => row.modal_number && row.action, },
    ]
    const conditionalRowStyles = [
        {
            when: (row: any) => {
                let x = errorRow.some((error: any) => error.id === row.image_folder_name)
                return x;
            },
            style: {
                color: '#f00',
            },
        },]


    // colums for error
    const column = [
        { name: "Image Folder Name", selector: (row: any) => row.id, sortable: true },
        { name: "Column", selector: (row: any) => row.id ? row?.column : "", sortable: true },
        { name: "Error", selector: (row: any) => row.error ? `${row?.error}, ` : "Empty", sortable: true },
    ]
    return (
        <div>
            <div>
                <div>
                    {/* <div><span>Upload file length:--</span> {data?.length}</div> */}
                    {data?.length > 0 && <CustomTable
                        columns={columns}
                        className="inventory-product-border"
                        conditionalRowStyles={conditionalRowStyles}
                        rowHeight="200px"
                        data={data?.map((item: any, index: number) => {
                            return ({
                                ...item,
                                action:
                                    <>
                                        <button
                                            className="btn dropdown-item dropdown-item-size"
                                            onClick={() => handleRemove(item?.image_folder_name)}
                                        >
                                            <RxCross2 style={{ color: '#222' }} />
                                        </button>
                                    </>,
                                photo:
                                    <>
                                        <button
                                            className="btn dropdown-dots shadow-none action-button"
                                            type="button"
                                        >
                                            <div className='d-flex align-items-center' onClick={() => {
                                                imageUrl(parseInt(item?.image_folder_name));
                                            }}>
                                                <span className='ms-2 mb-0'>View</span>
                                            </div>
                                        </button>
                                    </>
                            })
                        })}
                    />}
                </div>
                {data?.length > 0 &&
                    <div className='mt-3'>
                        {errorRow.length > 0 && <p className='fs-16 text-danger'>Error(s) found in uploaded file(s)</p>}
                        <div className='d-flex justify-content-center'>
                            <div className='mx-3 my-auto'>
                                <Button name={"Submit"}
                                    className='add-btn'
                                    isDisabled={!imageData}
                                    onClick={() => handleBulkSubmit()}
                                />
                            </div>
                            <div>
                                <input
                                    className='inputs form-control w-75'
                                    {...altAttr}
                                    type="file"
                                    onChange={handleImageUpload}
                                    onClick={(e: any) => (e.target.value = null)}
                                />
                            </div>
                        </div>
                    </div>}
                <div>
                    {errorRow.length > 0 && <p className='fs-16 text-danger'>Error Table</p>}
                    {errorRow?.length > 0 &&
                        <CustomTable
                            columns={column}
                            className="inventory-product-border"
                            conditionalRowStyles={conditionalRowStyles}
                            data={errorRow?.map((item: any, index: number) => {

                                return ({
                                    ...item,
                                })
                            })}
                        />}
                </div>
            </div>


            {showSidebarModal && <div>
                <BulkSidePopUp show={showSidebarModal}
                    onHide={() => setShowSidebarModal(!showSidebarModal)}
                    previewImage={previewImage}
                />
            </div>}
        </div>
    )
}

export default Index