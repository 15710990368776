import axios from 'axios'
import { BASE_URL } from '../../constants/defaultValues'
import dictionary from '../dictionary'

const store = {
  storeListingService: async (paramsObj: any) => {
    return axios.get(BASE_URL + dictionary.store.store(), { params: paramsObj })
  },
  createStoreService: async (payload: {}, headers: any) => {
    return axios.post(BASE_URL + dictionary.store.store(), payload, {
      headers: headers,
    })
  },
  updateStoreService: async (id: string, payload: {}, headers: any) => {
    return axios.put(BASE_URL + dictionary.store.storeByID(id), payload, {
      headers: headers,
    })
  },
  deleteStoreService: async (id: string) => {
    return axios.delete(BASE_URL + dictionary.store.storeByID(id))
  },
  storeStatus: async (id: string, payload:{}) => {
    return axios.put(BASE_URL + dictionary.store.storeStatus(id), payload)
  },
  getStoreByID: async (id: string) => {
    return axios.get(BASE_URL + dictionary.store.storeByID(id))
  },
  deleteStoreImages: async (payload: {}) => {
    return axios.post(BASE_URL + dictionary.store.storeImages(), payload)
  },
  changePassword: async (payload: {}) => {
    return axios.put(BASE_URL + dictionary.store.changePassword(), payload)
  },
  storeCount: async () => {
    return axios.get(BASE_URL + dictionary.store.storeCount())
  },
  searchUser: async (userId: string) => {
    return axios.get(BASE_URL + dictionary.store.searchUser(userId))
  },
  searchStoreLocation: async (payload: {}) => {
    return axios.post(BASE_URL + dictionary.store.searchStoreLocation(), payload)
  },
}
export default store
