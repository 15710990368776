import React from "react";
import atoms from "../../../atoms";
import Delete from "../../../../assets/Images/dustbin.svg"
import {VoucherComponent} from './voucherComponentInterface'

const { Input, Select, Image } = atoms

const Index = ({ Index, handleChange, glNumber, glName, amount, glAccountList, getGlName, handleDelete, addDebitComponent }: VoucherComponent) => {
    return (
        <>
            <div>
                <div className="row">
                    <div className="col-4">
                        <Select
                            className={"w-100 fs-12"}
                            options={glAccountList}
                            value={glNumber}
                            onChange={(e: any) => { handleChange("gl_number", e); getGlName(e?.value, "credit_gl_name") }}
                            placeholder={"GL Number"}
                            label={"GL Number"} />
                    </div>
                    <div className="col-4" style={{ overflow: "hidden" }}>
                        <h2 className='inputs-label mt-1 fs-16 fw-600  secondary-color'>GL Name</h2>
                        <div className='id-input-design d-flex' style={{ height: "57px",overflow:'hidden' }}>
                            <h2 className='fs-12 fw-400 my-auto dark-gray' style={{ wordWrap: "break-word",width:"100%" }}>{glName}</h2>
                        </div>
                    </div>
                    <div className="col-3">
                        <Input
                            label={"Amount"}
                            className="fs-12"
                            value={amount}
                            placeholder={"Amount"}
                            type="number"
                            onChange={(e: any) => handleChange("amount", e?.target?.value)}
                        />
                    </div>
                    <div className="col-1 p-0 my-auto">
                        <div className="d-flex">
                            <div className=" cursor-pointer" onClick={handleDelete}>
                                <Image src={Delete} />
                            </div>
                        </div>
                       {Index && <div className="d-flex mt-3 cursor-pointer" onClick={() => addDebitComponent()}>
                            <p className="brand-add-Button text-center">
                                <span className="fs-20">+</span>
                            </p>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index