import { Navigate, Route, Routes } from 'react-router';
import { PublicRoutes, privateRoutes } from './routes';
import NonAuthLayout from './layout/NonAuthLayout';
import AuthLayout from './layout/AuthLayout';
import Interceptor from './api/interceptor'
import Error404 from './pages/App/Error404'
import React, { useState } from 'react';
import { PermissionData, HeaderHeading, BadgesCount, isLoaderLoading } from './Context';
const App = () => {
  const [context, setContext] = useState<string[]>();
  const [notificationCount, setNotificationCount] = useState<any>();
  const [header, setHeader] = useState<any>('');
  const [sidebar, setSidebar] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<any>(false);

  return (
    <React.Fragment>
      <PermissionData.Provider value={[context, setContext]}>
        <BadgesCount.Provider value={[notificationCount, setNotificationCount]}>
          <isLoaderLoading.Provider value={{ isLoading, setIsLoading }}>
            <HeaderHeading.Provider value={{ header, setHeader, sidebar, setSidebar }}>
              <Interceptor />
              <Routes >
                {PublicRoutes.map((route, idx) => (
                  <Route
                    path={route.path}
                    element={
                      <NonAuthLayout>
                        {route.component}
                      </NonAuthLayout>
                    }
                    key={idx}
                  />
                ))}
                {privateRoutes.map((route, idx) => (
                  <Route
                    path={route.path}
                    element={
                      <AuthLayout>
                        {route.component}
                      </AuthLayout>}
                    key={idx}
                  />
                ))}
                <Route path='error' element={<Error404 />} />
                <Route path='*' element={<Navigate to='/error' />} />
              </Routes>
            </HeaderHeading.Provider>
          </isLoaderLoading.Provider>
        </BadgesCount.Provider>
      </PermissionData.Provider>
    </React.Fragment>
  );
}

export default App;