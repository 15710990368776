import React, { useState, useEffect, useContext } from "react";
import { HeaderHeading } from "../../../../Context";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from 'react-toast-notifications';
import { BASE_URL } from "../../../../constants/defaultValues";
import service from "../../../../api/services";
import * as XLSX from "xlsx"
import constant from "../../../../constants/constant";
import { handleReportDownload } from '../../../../constants/utils'

const { InventoryMovementReport } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();
    const [reportData, setReportData] = useState({
        created_from: Date,
        created_to: Date,
        report_format: NaN
    })

    useEffect(() => {
        setHeader("Report - Inventory Movement Report")
    }, [])

    const handleChange = (name: string, value: Date) => {
        setReportData({ ...reportData, [name]: value })
    }

    const handleSubmit = async () => {

        try {
            const response = await service.reportService?.inventoryMovement({ created_from: reportData?.created_from, created_to: reportData?.created_to })
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "inventory-movement-report", reportData?.report_format,"Specxweb",true)
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    return (
        <Wrapper>
            <InventoryMovementReport reportData={reportData} handleChange={(name: string, value: Date) => handleChange(name, value)} handleSubmit={() => handleSubmit()} />
        </Wrapper>
    )
}

export default Index