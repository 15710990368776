import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import molecules from '../../../../components/molecules'
import { useToasts } from 'react-toast-notifications';
import constant from '../../../../constants/constant';
import service from '../../../../api/services'
import { findConstantLabel } from '../../../../constants/utils';
import { HeaderHeading } from '../../../../Context'



const { InventoryAudit } = organisms
const { TopBar } = molecules


const Index = () => {
    const [barcodeData, setBarcodeData] = useState<any>()
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [searchValue, setSearchValue]= useState<string>("")
    const [updateData, setUpdateData] = useState<any>({
        item_condition: [],
        conditionRemark: "",
    })
    const { addToast } = useToasts();

    const handleBarcodeSearch = async (search: string) => {
        try {
            const response = await service.InventoryAuditService.barcodeSearchApi({ search: search })
            setSearchValue(search)
            if (response.status === 200) {
                setBarcodeData(response?.data?.data)
                setUpdateData({ ...updateData, item_condition: {}, conditionRemark: "" })
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });

        }
    }

    const updateItemCondition = async (id: string) => {
        const payload = {
            "item_condition": updateData.item_condition.value,
            "condition_remark": updateData.conditionRemark
        }
        try {
            const response = await service.InventoryAuditService.updateItemCondition(id, payload)
            if (response.status === 200) {
                handleBarcodeSearch(searchValue)
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleChange = (name: string, value: any) => {
        setUpdateData({ ...updateData, [name]: value })
    }

    useEffect(() => {
        setHeader("Inventory - Inventory Audit")
        setSidebar(false)
    }, [])

    return (
        <Wrapper>
            <InventoryAudit
                handleBarcodeSearch={(search: string) => handleBarcodeSearch(search)}
                updateItemCondition={updateItemCondition}
                barcodeData={barcodeData}
                handleChange={handleChange}
                updateData={updateData}
            />
        </Wrapper>
    )
}

export default Index