import React, { useState, useEffect } from "react";
import atoms from "../../../../atoms";
import molecules from "../../../../molecules";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from 'react-router-dom'
import { loyaltyPointsProps } from "./interface";
import { Default_Page_Number } from '../../../../../constants/defaultValues'

const { Button, Table, Pagination, Search } = atoms
const { TopBar } = molecules


const Index: React.FC<loyaltyPointsProps> = ({ setSearch, loyaltyPointsList, setLimitPerPage, limit }) => {
    const [displayedData, setDisplayedData] = useState([]);
    const navigate = useNavigate()

    const columns = [
        { name: 'Customer Name', selector: (row: any) => row.customer?.name || '-', sortable: true, },
        { name: 'Contact Number', selector: (row: any) => row.customer?.mobile || '-' },
        { name: 'Points Earned', selector: (row: any) => `+ ${row?.total_earned_points}` || '-' },
        { name: 'Points Redeemed', selector: (row: any) => `- ${row?.total_redeemed_points}` || '-', },
        { name: 'Balanced Loyalty Points', selector: (row: any) => row?.customer?.loyalty_points || '-' },
        { name: 'Action', selector: (row: any) => row.action, },
    ]

    const [newCurrentPage, setNewCurrentPage] = useState(Default_Page_Number);
    const [pageIndex, setPageIndex] = useState({
        tableStartIndex: 0,
        tableEndIndex: 0
    })
    // const itemsPerPage = limit;
    const totalPages = Math.ceil(loyaltyPointsList?.data?.length / limit);
    useEffect(() => {
        const startIndex = (newCurrentPage - 1) * limit;
        const endIndex = startIndex + limit;
        const slicedData = loyaltyPointsList?.data?.slice(startIndex, endIndex);
        setPageIndex({ ...pageIndex, tableStartIndex: startIndex, tableEndIndex: endIndex })
        setDisplayedData(slicedData)
    }, [newCurrentPage, loyaltyPointsList?.data, limit]);

    useEffect(() => {
        setNewCurrentPage(1)
    }, [limit])

    const handlePageChange = (newPage: any) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setNewCurrentPage(newPage);
        }
    };
    return (
        <>
            <div className="d-flex align-items-center gap-3">
                <Search className="w-100" placeholder="Search By Mobile No." searchCallBack={(e: string) => { setSearch(e) }} />
                {/* <Button name={'search'} /> */}
            </div>
            <div >
                <Table columns={columns}
                    AdditionalClassName="add-invertory-table"
                    data={displayedData?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            action:
                                <>
                                    <div style={{ overflow: 'unset', textOverflow: 'unset' }}>
                                        <Button className="table-edit-buttons fs-14 fw-500" onClick={() => navigate(`/report/loyalty-points/Items/${item?.customer_id}`)}>
                                            <div className='d-flex align-items-center'>
                                                <AiOutlineEye className='fs-20' />
                                                <span className='ms-2 mb-0'>View</span>
                                            </div>
                                        </Button>
                                    </div>

                                </>

                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={loyaltyPointsList?.data?.length}
                    currentPage={newCurrentPage}
                    totalPages={totalPages}
                    onChangePage={handlePageChange}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
        </>
    )
}

export default Index