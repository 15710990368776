import React, { useEffect, useMemo, useState } from 'react';
import molecules from '../../../molecules';
import atoms from '../../../atoms';
import { BrandAllocation, BrandData, StoreAllocationData } from './interface'
import moment from 'moment';
import './index'
import { BsXCircle } from 'react-icons/bs';
const { BrandTable } = molecules
const { Table, Search, } = atoms
const Allocation = ({ brandList, setBrandList, step, storeList, selectedBrand, setSelectedBrand, error, selectedStore, search, setSearch,
    setSelectedStore }: BrandAllocation) => {
    const filterData = useMemo(() => {
        return storeList.filter((store: StoreAllocationData | any) => {
            return (
                (store?.store_name).toLowerCase().includes(search?.toLowerCase()))
        })
    }, [storeList, search])
    const columns = [
        {
            name: step === 1 && <input type="checkbox" checked={selectedStore.length === filterData?.length} onChange={(e: any) => handleAllChecked(e.target.checked)} />,
            selector: (row: any) => step === 1 && <input type="checkbox" checked={selectedStore?.some((item: StoreAllocationData) => item?.id === row?.id)} onClick={() => handleSelectToggle(row)} />
        },
        { name: 'Store ID', selector: (row: StoreAllocationData) => row.store_id_display, sortable: true, },
        { name: 'Store Name', selector: (row: StoreAllocationData) => row?.store_location ? row?.store_location : row?.store_name, minWidth: '180px' },
        { name: 'Store Incharge', selector: (row: StoreAllocationData) => row?.manager_name, minWidth: '180px' },
        { name: 'Incharge Contact No.', selector: (row: StoreAllocationData) => row?.manager_mobile, },
        { name: 'Store City', selector: (row: StoreAllocationData) => row.city, },
        { name: 'Area Pincode', selector: (row: StoreAllocationData) => row.pin_code },
        { name: step === 2 && 'Action', selector: (row: any) => step === 2 && row.action, },
    ]

    const handleAllChecked = (e: boolean) => {
        let temp: any = [];
        if (e) {
            temp = filterData
        }
        setSelectedStore(temp);
    };

    const handleSelectToggle = async (obj: StoreAllocationData) => {
        if (selectedStore.some((item: StoreAllocationData) => item.id === obj.id)) {
            setSelectedStore(selectedStore.filter((item: StoreAllocationData) => item.id !== obj.id));
        } else {
            setSelectedStore([...selectedStore, obj]);
        }
    };

    const handleRemoveStore = (index: number) => {
        let tempSelectedStore = [...selectedStore]
        tempSelectedStore?.splice(index, 1)
        setSelectedStore(tempSelectedStore)
    }

    return (
        <>
            {step === 0 && <div id="step1">
                <BrandTable brandList={brandList} setBrandList={setBrandList}
                    selectedBrand={selectedBrand}
                    setSelectedBrand={setSelectedBrand} step={step}
                    search={search}
                    setSearch={setSearch} />
            </div>}
            {step === 1 && <div id="step2">
                <div className="row">
                    <div className='col-3'></div>
                    <div className="col-6"><Search className={"w-100"} searchCallBack={(e: string) => { setSearch(e) }} /></div>
                    <div className='col-3'></div>
                </div>
                <div>
                    <Table columns={columns}
                        data={filterData} /> </div>
            </div>}
            {step === 2 && <div id="step3">
                <div className='card' style={{ padding: '25px' }}>
                    <BrandTable brandList={selectedBrand} step={step} setBrandList={setSelectedBrand} setSelectedBrand={setSelectedBrand} search={search}
                        setSearch={setSearch} />
                </div>
                <div className='card mt-3' style={{padding:'25px'}} >

                    <Table
                        columns={
                            columns.slice(1, columns.length)
                        }
                        data={selectedStore.map((item: StoreAllocationData, index: number) => {
                            return ({
                                ...item,
                                action: <><BsXCircle className='fs-3 cursor-pointer' onClick={() => handleRemoveStore(index)} /></>
                            })
                        })} />
                </div>
            </div>}
        </>
    )
}

export default Allocation