import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { websiteForm } = Dictionary

const websiteFormService = {
  feedbackList: async () => {
    return axios.get(BASE_URL + websiteForm.feedbackList())
  },
  feedbackDetail: async (id:string) => {
    return axios.get(BASE_URL + websiteForm.feedbackDetail(id))
  },
  retailerList: async () => {
    return axios.get(BASE_URL + websiteForm.retailerList())
  },
  retailerDetail: async (id:string) => {
    return axios.get(BASE_URL + websiteForm.retailerDetail(id))
  },

}
export default websiteFormService
