import React, { useState, useEffect } from "react";
import atoms from "../../../atoms";
import CopyIcon from '../../../../assets/Images/copy-icon.svg'
import { addSupplier } from './interface'
import { Store_Legal_Status } from '../../../../constants/defaultValues'
import { useParams } from "react-router-dom";
import { emailValidation } from "../../../../constants/utils";
import molecules from "../../../molecules";
import { City_List, State_List } from "../../../../constants/state_cities";
const { Image, Select, Input, Button, RadioInput }: any = atoms
const {TopBar}:any= molecules
const AddSupplier: React.FC<addSupplier> = ({ supplier, setSupplier, setButtonClick }) => {
    const [errors, setErrors] = useState<any>({});
    const [cities, setCities] = useState<any>([]);
    let params = useParams();
    let supplierEditID = params.id || ''
    const validationRules: any = {
        supplier_name: {
            required: true,
        },
        owner_name: {
            required: true,
        },
        owner_contact_number: {
            required: true,
            minLength: 10,
            maxLength: 10,
        },

        owner_email: {
            // required: true,
            pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        },
        contact_person_name: {
            required: true,
        },
        contact_number: {
            required: true,
            minLength: 10,
            maxLength: 10,
        },
        contact_email: {
            // required: true,
            pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        },
        address: {
            required: true,
        },
        pin_code: {
            required: true,
        },
        city: {
            required: true,
        },
        state: {
            required: true,
        },

    };
    const handleChange = (name: string, value: any) => {
        setSupplier((prevState: any) => ({ ...prevState, [name]: value }));
        setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
    };
    const validate = () => {
        let newErrors: any = {};
        for (const field in validationRules) {
            if (validationRules.hasOwnProperty(field)) {
                if (validationRules[field]?.required && !supplier[field]) {
                    newErrors[field] = "This field is required.";
                } else if ((validationRules[field]?.label?.required) && (!supplier[field]?.label)) {
                    newErrors[field] = "Please Select field";
                }
                // if (validationRules[field].pattern && !validationRules[field].pattern.test(supplier[field])
                // ) {
                //     newErrors[field] = "Invalid format"
                // }
                if (validationRules[field].pattern && supplier[field]) {
                    if (field === "contact_email" || field === "owner_email") {
                        let error = validationRules[field]?.pattern.test(supplier[field])
                        if (!error) {
                            newErrors[field] = "Invalid Field";
                        }
                    }
                }
                if (
                    validationRules[field].minLength &&
                    supplier[field].length < validationRules[field].minLength
                ) {
                    newErrors[field] = `Minimum length is ${validationRules[field].minLength}`;
                }

                if (
                    validationRules[field].maxLength &&
                    supplier[field].length > validationRules[field].maxLength
                ) {
                    newErrors[field] = `Maximum length is ${validationRules[field].maxLength}`;
                }
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        const isValid = validate();
        if (isValid) {
            setButtonClick(true)
        }
    };

    const emailValidationMessage = (email: string, type: string) => {
        if (!emailValidation(email)) {
            setErrors({ ...errors, [type]: "Invalid Email" })
        } else {
            setErrors({ ...errors, [type]: "" })
        }
    };
    const handleStateChange = (stateValue: any) => {
        setSupplier((prevState: any) => ({ ...prevState, city: '' }));
        if (stateValue) {
            const filteredCities = City_List.filter((city) => city.stateID === stateValue.stateID);
            setCities(filteredCities);
        }
    };

    return (
        <div>
            <div className='row align-items-center mb-4'>
                {/* <div className='col-6 '>
                    <h4 className='mb-0'>{supplierEditID ? 'Update' : "Add"} Supplier</h4>
                </div> */}
                <div className="col-6 d-flex my-auto">
                    <TopBar heading={`${supplierEditID ? 'Update' : "Add"} Supplier`} />
                </div>

                <div className=' col-6 d-flex justify-content-end align-item-end gap-3 align-items-center'>
                    <h2 className='mb-0 fs-18 fw-700 secondary-color '>Supplier ID:</h2>
                    <div className='d-flex justify-content-between align-items-center px-2 py-2 add-product-custom-div cursor-pointer' >
                        <h2 className='mb-0 fs-16 fw-600 secondary-color' placeholder="32SJFHG44842">{supplier.supplier_id}</h2>
                        <div className=''>
                            <Image src={CopyIcon} onClick={() => navigator.clipboard.writeText(supplier.supplier_id)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-4">
                    <Input label={"Supplier Name"} type={'text'} placeholder={"Enter Supplier Name"}
                        errors={errors.supplier_name}
                        value={supplier.supplier_name}
                        onChange={(e: any) => { handleChange("supplier_name", e.target.value) }}
                    />
                </div>
                <div className="col-4">
                    <Select label={"Supplier Legal Status"}
                        options={Store_Legal_Status}
                        errors={errors.legal_status}
                        className={"w-100"}
                        placeholder={"Select Status"}
                        value={supplier.legal_status || ''}
                        onChange={(e: any) => { handleChange("legal_status", e) }}
                    />
                </div>
                <div className="col-4"></div>
            </div>
            <div className="row mt-4">
                <div className="col-4">
                    <Input label={"Owner Name"} type={'text'} placeholder={"Enter Owner Name"} errors={errors.owner_name}
                        value={supplier.owner_name}
                        onChange={(e: any) => { handleChange("owner_name", e.target.value) }}
                    />
                </div>
                <div className="col-4">
                    <Input label={"Owner Contact Number"} type={'number'} placeholder={"Enter Contact Number"} errors={errors.owner_contact_number}
                        value={supplier.owner_contact_number}
                        onChange={(e: any) => { handleChange("owner_contact_number", e.target.value) }}
                    />
                </div>
                <div className="col-4">
                    <Input label={"Owner Email ID"} type={'email'} placeholder={"Enter Email Address"} errors={errors.owner_email}
                        value={supplier.owner_email}
                        onChange={(e: any) => { handleChange("owner_email", e.target.value), emailValidationMessage(e.target.value, "owner_email") }}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-4">
                    <Input label={"Contact Person Name"} type={'text'} placeholder={"Enter Person Name"} errors={errors.contact_person_name}
                        value={supplier.contact_person_name}
                        onChange={(e: any) => { handleChange("contact_person_name", e.target.value) }}
                    />
                </div>
                <div className="col-4">
                    <Input label={"Contact Number"} type={'number'} placeholder={"Enter Contact Number"} errors={errors.contact_number}
                        value={supplier.contact_number}
                        onChange={(e: any) => { handleChange("contact_number", e.target.value) }}
                    />
                </div>
                <div className="col-4">
                    <Input label={"Email ID"} type={'email'} placeholder={"Enter Email Address"} errors={errors.contact_email}
                        value={supplier.contact_email}
                        onChange={(e: any) => { handleChange("contact_email", e.target.value), emailValidationMessage(e.target.value, "contact_email") }}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-4">
                    <Input label={"Address Line 1"} type={'text'} placeholder={"Enter Address"} errors={errors.address}
                        value={supplier.address}
                        onChange={(e: any) => { handleChange("address", e.target.value) }}
                    />
                </div>
                <div className="col-4">
                    <Select label={"State"} placeholder={"Select State"} className={"w-100"} errors={errors.state}
                        options={State_List}
                        value={supplier.state}
                        onChange={(e: any) => {
                            handleChange("state", e)
                            handleStateChange(e)
                        }}
                    />
                </div>
                <div className="col-4">
                    <Select label={"City"} placeholder={"Select city"} className={"w-100"} errors={errors.city}
                        disabled={!cities}
                        options={cities}
                        value={supplier.city}
                        onChange={(e: any) => { handleChange("city", e) }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-4">
                    <Input label={"Pin Code"} type={'number'} placeholder={"Enter PIN Code"} errors={errors.pin_code}
                        value={supplier.pin_code}
                        onChange={(e: any) => { handleChange("pin_code", e.target.value) }}
                    />
                </div>
            </div>

            <div className="mt-4">
                <h5>Bank Account Details</h5>
            </div>

            <div className="row mt-4">
                <div className="col-4">
                    <Input label={"Bank Name"} type={'text'} placeholder={"Enter Bank Name"} className={"w-100"} errors={errors.bank_name}
                        value={supplier.bank_name}
                        onChange={(e: any) => { handleChange("bank_name", e.target.value) }}
                    />
                </div>
                <div className="col-4">
                    <Input label={"IFSC Code"} type={'text'} placeholder={"Enter IFSC Code"} errors={errors.ifsc_code}
                        value={supplier.ifsc_code}
                        onChange={(e: any) => { handleChange("ifsc_code", e.target.value) }}
                    />
                </div>
                <div className="col-4">
                    <Input label={"Account Number"} type={'number'} placeholder={"Enter Account Number"} errors={errors.account_number}
                        value={supplier.account_number}
                        onChange={(e: any) => { handleChange("account_number", e.target.value) }}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-4">
                    <Input label={"GST Number"} type={'text'} placeholder={"Enter GST Number"} className={"w-100"} errors={errors.gst_number}
                        value={supplier.gst_number}
                        onChange={(e: any) => { handleChange("gst_number", e.target.value) }}
                    />
                </div>
                <div className="col-4">
                    <Input label={"PAN Number"} type={'text'} placeholder={"Enter PAN Number"} errors={errors.pan_number}
                        value={supplier.pan_number}
                        onChange={(e: any) => { handleChange("pan_number", e.target.value) }}
                    />
                </div>

            </div>

            <div className="justify-content-center d-flex mt-4">
                <Button name={supplierEditID ? " Update Supplier" : "Add Supplier"} className={"w-25"} onClick={() => handleSubmit()} />
            </div>
        </div >
    )
}
export default AddSupplier

function setEmailError(arg0: string) {
    throw new Error("Function not implemented.");
}
