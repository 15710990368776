const Inventory = {
    inventory: () => 'inventory/invoice',
    invoiceById: (id: string) => `inventory/invoice/${id}`,
    verificationStatus: (id: string) => `inventory/invoice/${id}/verification-status`,
    invoiceDataCount: () => `inventory/invoice/count`,
    supplierAndStore: () => `inventory/invoice/stores/suppliers`,
    brandListing: () => `inventory/invoice/brand-filters`,
    quantitySearch: () => `inventory/invoice/products-min-quantity`,
    updateQuantity: (id: string) => `inventory/invoice/${id}/min-quantity`,
    inventoryBrand:()=> 'inventory/invoice/brand-filters',
    inventoryStore:()=> 'inventory/invoice/store-filters',
    inventoryItems:()=> 'inventory/invoice/items-search',
    deletePendingInventory:(id:string)=>`inventory/invoice/${id}`,
}
export default Inventory