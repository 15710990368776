import React from 'react'
import Header from '../components/common/Header/Header';
import SideBar from '../components/common/Sidebar/SideBar';
import { Navigate } from 'react-router';

const AuthLayout = (props: any) => {


    if (!localStorage.getItem("access_token")) {
        return (
            <Navigate to={{ pathname: "/login", }} />
        );
    }


    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <SideBar />
                <div className="main-content">{props.children}</div>
                {/* <Footer /> */}
            </div>

        </React.Fragment>
    )
}

export default AuthLayout