import './Search.scss'
import { MdOutlineVerified } from "react-icons/md"
import atoms from "../../atoms";
const { Image } = atoms
const Index = ({ data, searchCallBack, productData }: any) => {
    const handleSelect = (e: any) => {
        searchCallBack(e)
        // setPreviewTab(false)
    }
    return (

        <ul className="custom-search card">
            {data?.length > 0 ? data?.map((item: any) => {
                return (
                    <li className="list-unstyled list-hover cursor-pointer p-1 px-3 d-flex">
                        <div className='fs-16 cursor-pointer my-auto'>
                            <div className="mx-3 my-auto" style={{ width: "50px" }}>
                                <Image src={item?.images[0]} className='w-100 rounded object-contain searchClass' />
                            </div>
                        </div>
                        <div className='fs-16 cursor-pointer col-9' onClick={() => handleSelect(item)}>
                            <h2 className="fs-14 fw-600 text-capitalize">{item?.brand.brand_name}, {item?.product_type?.name}</h2>
                            <div className='d-flex'>
                                <h3 className="fs-12 fw-600 secondary-color">Model No : </h3>
                                <h3 className="fs-12 fw-600 secondary-color" style={{ marginLeft: "10px" }}>{item?.modal_number}</h3>
                            </div>
                            <div className='d-flex' >
                                <h3 className="fs-12 fw-600 secondary-color" >Product ID : </h3>
                                <h3 className=" fs-12 fw-600 secondary-color" style={{ marginLeft: "10px" }}>{item?.product_id_display}</h3>
                            </div>
                        </div>
                        {productData?.map((product: any, index: any) => (
                            <div className='fs-16 cursor-pointer my-auto'>
                                {item?.id == product?.id &&
                                    <div className="color-green fs-20">
                                        <MdOutlineVerified />
                                    </div>}
                            </div>
                        ))}
                    </li>
                )
            }) :
                <li className="list-unstyled list-hover cursor-pointer p-1 px-3 d-flex">
                    <div className='fs-16 cursor-pointer col-11'>
                        <div className="fw-500 text-capitalize py-3">
                            No product found
                        </div>
                    </div>
                </li>
            }
        </ul>
    )
}
export default Index