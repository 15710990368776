import React, { useEffect, useState, useRef } from "react";
import atoms from "../../../atoms";
import Delete from "../../../../assets/Images/dustbin.svg"
import { BASE_URL } from "../../../../constants/defaultValues";
import ProductComponent from "./productComponent"
import molecules from "../../../molecules";
import './index.scss'
import { useParams } from "react-router";
const { Image, Input, Button } = atoms
const { TopBar } = molecules
const AddBrand = ({ productTypeList, brandData, setBrandData, setCreateClick, components,
    setComponents, productSelectDropdown, setProductSelectDropdown }: any) => {
    let params = useParams();
    let brandID = params.id || ''
    const ref: any = useRef(null)
    const [selectImage, setSelectImage] = useState(false)
    const [filterOption, setFilterOption] = useState([])
    const [brandError, setBrandError] = useState({
        brand_logo: '',
        brand_name: "",
        brand_code: "",

    })
    const [errors, setErrors] = useState<any>({
        brand_margin: '',
        brand_discount: '',
        brand_landing_cost: '',
        specx_landing_cost: '',
        product_type_id: ''
    });
    const handleClick = (event: any) => {
        ref.current.click();
    };
    const handleImageChange = (event: any) => {
        const errorComponent: any = brandError
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result;
            setBrandData({ ...brandData, brand_logo: base64String });
            setSelectImage(true)
            errorComponent.brand_logo = '';
            setErrors(errorComponent);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const handleChange = (name: string, value: any) => {
        const errorComponent: any = brandError
        errorComponent[name] = '';
        setBrandData((prevState: any) => ({ ...prevState, [name]: value }));
        setBrandError(errorComponent);
    };

    const addInputComponent = () => {
        setComponents([...components, {
            brand_margin: '',
            brand_discount: '',
            brand_landing_cost: '',
            specx_landing_cost: '',
            product_type_id: ''
        }]);

    };

    const handleComponentChange = (index: number, field: string, value: string) => {
        const errorComponent: any = errors
        const updatedComponents: any = [...components];
        updatedComponents[index][field] = value;
        setComponents(updatedComponents);

        if (errors[index]) {
            errorComponent[index][field] = '';
        }
        setErrors(errorComponent);

    };

    const handleDelete = (component: any, index: number) => {
        let tempComponent = [...components]
        let tempBrand = [...brandData.product_types]
        let tempSelectDropdown = [...productSelectDropdown]
        if (component?.product_type_id?.length > 0) {
            tempComponent.splice(index, 1);
            tempBrand.splice(index, 1);
            tempSelectDropdown.splice(index, 1);
        } else {
            tempComponent.splice(index, 1);
            tempBrand.splice(index, 1);
        }
        setBrandData({ ...brandData, product_types: tempBrand })
        setComponents(tempComponent)
        setProductSelectDropdown(tempSelectDropdown)
    }

    useEffect(() => {
        setBrandData({ ...brandData, product_types: components })
    }, [components])

    const handleSubmit = () => {
        let hasError = false;
        let brandDataError = false
        const newErrors: any = {};
        let tempBrandError: any = {}
        if (brandData.brand_logo === '' || brandData.brand_name === '' || brandData.brand_code === '') {
            tempBrandError = {
                brand_logo: brandData.brand_logo.length > 0 ? '' : "Please select a logo",
                brand_name: brandData.brand_name.length > 0 ? '' : "Field is Required",
                brand_code: brandData.brand_code.length > 0 ? '' : "Field is Required",
            }
            setBrandError(tempBrandError)
            brandDataError = true
        }
        components.forEach((component: any, index: number) => {
            if (component.brand_margin === '' || component.brand_discount === '' || component.product_type_id === '' || component.brand_landing_cost === '' || component.specx_landing_cost === '') {
                newErrors[index] = {
                    brand_margin: component?.brand_margin === '' ? 'Field is Required' : '',
                    brand_discount: component?.brand_discount === '' ? 'Field is Required' : '',
                    brand_landing_cost: component?.brand_landing_cost === '' ? 'Field is Required' : '',
                    specx_landing_cost: component?.specx_landing_cost === '' ? 'Field is Required' : '',
                    product_type_id: component?.product_type_id === '' ? 'Field is Required' : '',
                };
                hasError = true;
            }
        });
        if (hasError || brandDataError) {
            setErrors(newErrors);
            return;
        } else {
            setCreateClick(true)
        }
    };
    useEffect(() => {
        let temp: any = [...productTypeList];
        temp = temp.filter((item: any) => {
            return !components.some((value: any) => value.product_type_id === item.value);
        });
        setFilterOption(temp);
    }, [components, productTypeList]);

    return (
        <>
            <div className="row">
                <div className="col-6 d-flex my-auto">
                    <TopBar heading={`${brandID ? 'Update' : 'Create'} Brand`} />
                    {/* <h5 className="fw-600">{`${brandID ? 'Update' : 'Create'} Brand`}</h5> */}
                </div>
            </div>
            <div className="d-flex">
                <div className="brand-profile mt-4" >
                    <Image className="div-fit-container" src={selectImage ? brandData.brand_logo : brandData.brand_logo} alt={"Add Brand Logo"} />
                </div>
                <div onClick={(e) => { handleClick(e) }} className="brand-add-Button text-center add-image" ><span style={{ fontSize: '20px' }}>+</span></div>

                <input
                    type="file"
                    accept={"image/png, image/gif, image/jpeg, image/webp"}
                    ref={ref}
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                />
                {brandError?.brand_logo?.length > 0 && <span className="error-text add-image-text-error">{brandError?.brand_logo}</span>}
            </div>

            <div className="row mt-4">
                <div className="col-4">
                    <Input type={"text"} name="brand_name" onChange={(e: any) => handleChange("brand_name", e.target.value)}
                        value={brandData.brand_name} placeholder={"Enter Brand Name"} label={"Brand Name"} errors={brandError?.brand_name} />
                </div>
                <div className="col-4">
                    <Input type={"text"} onChange={(e: any) => handleChange("brand_code", e.target.value)}
                        value={brandData.brand_code} placeholder={"Enter Brand Code"} label={"Brand Code"} errors={brandError?.brand_code} />
                </div>
                <div className="col-4">
                    <Input type={"text"} onChange={(e: any) => handleChange("owner_name", e.target.value)}
                        value={brandData.owner_name} placeholder={"Enter Brand Owner"} label={"Brand Owner"} />
                </div>
            </div>
            {components && components?.map((component: any, index: number) => (
                <div className="row mt-4 product-component">
                    <ProductComponent
                        currentKey={index}
                        errors={errors[index]}
                        productTypeList={filterOption}
                        brand_margin={component?.brand_margin}
                        brand_discount={component?.brand_discount}
                        brand_landing_cost={component?.brand_landing_cost}
                        specx_landing_cost={component?.specx_landing_cost}
                        product_type_id={component?.product_type_id}
                        productSelectDropdown={productSelectDropdown[index]}
                        selectDropDown={productSelectDropdown}
                        setProductSelectDropdown={setProductSelectDropdown}
                        handleChangeType={(type: string, value: any) => handleComponentChange(index, type, value)}
                    />
                    <div className="delete-btn cursor-pointer" onClick={() => handleDelete(component, index)}>
                        <Image src={Delete} />
                    </div>
                </div>
            ))}
            <div className="mt-4 py-2 dashed-border cursor-pointer" onClick={() =>
                productTypeList?.length !== components?.length && addInputComponent()
            }
            >
                <div className="d-flex justify-content-center align-items-center my-2" >
                    <p className={`${productTypeList?.length !== components?.length ? "brand-add-Button " : "disabled-add-Button"} text-center`}>
                        <span className="fs-20">+</span>
                    </p>
                    <p className="ms-2" >Add Product Type </p>
                </div>
            </div>
            {/* {error && <p>{error}</p>} */}
            <div className="mt-5 d-flex justify-content-center ">
                <Button className={""} onClick={() => handleSubmit()}>{`${brandID ? 'Update' : 'Create'} Brand`}</Button>
            </div>
        </>
    )

}
export default AddBrand