import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import { HeaderHeading } from '../../../../Context'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import atoms from '../../../../components/atoms';
import { getDropdown } from '../../../../constants/utils';
import { useToasts } from 'react-toast-notifications';
const { Select } = atoms
const { Procurement } = organisms

const Index = () => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [productList, setProductList] = useState([])
    const [selectedProductList, setSelectedProductList] = useState([])
    const { setHeader }: any = useContext(HeaderHeading);
    const [storeList, setStoreList] = useState<any>()
    const [selectedStoreID, setSelectedStoreID] = useState<any>()
    const [storeBillingDetail, setStoreBillingDetail]= useState<any>()

    const getStoreList = async () => {
        try {
            let response = await service.inventory.storeView()

            if (response.status === 200) {
                setStoreList(getDropdown(response?.data?.data, 'store_location', "store_name"))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSearch = async (search: string) => {
        try {
            const response = await service.procurement.searchProducts({
                search: search,
                storeId: selectedStoreID?.value
            })
            if (response.status === 200) {
                setProductList(response.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleSearchSelect = async (selectedItem: String) => {
        let tempProductData: any = [...selectedProductList]
        tempProductData.push(selectedItem);
        setSelectedProductList(tempProductData)
    }

    const removeItem = async (item: string, index: number) => {
        let tempData = [...selectedProductList]
        tempData?.splice(index, 1);
        setSelectedProductList(tempData)
    }

    const findLandingDiscount = (product: any, store: any) => {
        if (!product?.brand?.brand_name || !product.product_type_id || !product.product_srp || !store?.brands) {
            return null;
        }

        const brand = store.brands.find((brand: any) => {
            if (brand?.brand_name == product?.brand?.brand_name) {
                return brand
            }
        })
        if (!brand || !brand?.store_brands?.store_brand_product_type_data) {
            return null;
        }

        const productTypeData = brand?.store_brands?.store_brand_product_type_data.find((data: any) => {
            if (data.product_type_id === product?.product_type_id) {
                return data
            }
        });
        if (!productTypeData || !productTypeData?.brand_landing_cost) {
            return null;
        }
        let percentage = parseFloat((productTypeData.brand_landing_cost / 100).toFixed(2))
        const brandLandingCost = (product.product_srp * percentage)
        return brandLandingCost;
    }

    const getCheckoutPayload = () => {
        const purchase_cart_items: any = []
        selectedProductList && selectedProductList.map((items: any) => {
            purchase_cart_items.push(items?.id)
        })
        checkoutPurchaseCart(purchase_cart_items)
    }


    const checkoutPurchaseCart = async (productIdsArray: any) => {
        const payload = {
            store_id: selectedStoreID?.value,
            products: productIdsArray
        }
        try {
            const response = await service.procurement.checkout(payload)
            if (response.status === 200) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate("/store/procurement")
                setProductList([])
                setSelectedStoreID("")
                setStoreBillingDetail([])
                setSelectedProductList([])
                setStoreList("")

                // location.reload()
                // navigate('/procurement/order/history')
            }
        } catch (error) {
            console.log('error', error)
        }

    }

    const procurementStoreBillingAccountDetail = async (id: string) => {
        try {
            const response = await service.procurement?.procurementStoreAccountDetail(id)
            if (response.status === 200) {
                setStoreBillingDetail(response?.data?.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        setHeader("Store - Procurement")
        getStoreList()
    }, [])

    return (
        <Wrapper>
            <div className='col-12 col-md-3'>
                <div className=''>
                    <Select
                        options={storeList}
                        name={"type"}
                        value={selectedStoreID}
                        className={"w-100"}
                        placeholder={"Select Store"}
                        onChange={(e: any) => {setSelectedStoreID(e); procurementStoreBillingAccountDetail(e.value)}}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <Procurement
                    handleSearch={handleSearch}
                    handleSearchSelect={handleSearchSelect}
                    productList={productList}
                    selectedProductList={selectedProductList}
                    removeItem={removeItem}
                    checkout={() => getCheckoutPayload()}
                    storeBillingDetail={storeBillingDetail}
                    findLandingDiscount={(product: any, store: any) => findLandingDiscount(product, store)} />
            </div>
        </Wrapper>

    )
}

export default Index