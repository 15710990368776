import React, { useState,useEffect } from 'react'
import atoms from '../../atoms'
import './index.scss'
// import { IoChevronBackOutline } from 'react-icons/fa'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6"
import { RxCross2 } from "react-icons/rx";

const { Image } = atoms

const ImagePopup = ({ imageUrlData, handleClose, Index }: any) => {
    const [current, setCurrent] = useState(0);
    const length = imageUrlData.length;
    const customStyle = {
        top: '1%',
        right: '1%',
        backgroundColor: 'transparent',
        border: '1px solid #222',
        borderRadius: '50%',
        cursor: 'pointer'
    }

    useEffect(()=>{
        setCurrent(Index);
    },[])

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(imageUrlData) || imageUrlData.length <= 0) {
        return null;
    }
    return (
        <>
            {/* <div className="popup-main">
                <div className="popup-content">
                    <div className="position-absolute py-1 px-2"
                        style={customStyle}
                        onClick={handleClose}>
                        <RxCross2 style={{ color: '#222' }} />
                    </div>
                    
                    <img className="popup-image" src={imageUrl} alt="Popup Image" />
                </div>
            </div> */}
            <div className="popup-main">
                <div className="popup-content">
                    <section className='slider'>
                        <div className="position-absolute py-1 px-2"
                            style={customStyle}
                            onClick={handleClose}>
                            <RxCross2 style={{ color: '#222' }} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div className='d-flex'>
                                <div className='left-icon' onClick={prevSlide} >
                                    <FaChevronLeft className='mx-auto' onClick={prevSlide} />
                                </div>
                            </div>
                            <div className='d-flex image-height'>
                                {imageUrlData.map((slide, index) => {
                                    return (
                                        <div
                                            className={index === current ? 'slide active border-0 m-auto' : 'slide d-none border-0 m-auto'}
                                            key={index}
                                        >
                                            {index === current && (
                                                <img src={slide} alt='travel image' className='popup-image' />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            {/* style={{borderColor:(productDetails?.glass_size==item?.glass_size) ? "#32a1ce" : "black"}} */}
                            <div className='d-flex'>
                                <div className='right-icon' onClick={prevSlide} >
                                <FaChevronRight className='mx-auto' onClick={nextSlide} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </div >
        </>
    )
}

export default ImagePopup