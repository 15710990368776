import React, { useState, useContext, useEffect } from "react";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import atoms from "../../../../components/atoms";
import molecules from "../../../../components/molecules";
import { HeaderHeading } from '../../../../Context'
import { getDropdown } from "../../../../constants/utils";
import { useToasts } from 'react-toast-notifications';


const { Button, Input } = atoms
const { ConfirmationModal } = molecules
const { StoreBooks, SpecxwebBooks, } = organisms
const Index = () => {
    const { addToast } = useToasts();
    const { setHeader }: any = useContext(HeaderHeading);
    const [storeData, setStoreData] = useState<any>()
    const [submitConfirmation, setSubmitConfirmation] = useState<boolean>(false)

    // for specxweb component
    const [glAccountList, setGlAccountList] = useState<any>();
    const [glAccountData, setGlAccountData] = useState<any>()

    const [specxWebDebitData, setSpecxWebDebitData] = useState<any>([{
        gl_number: '',
        gl_name: '',
        amount: NaN
    }])

    const [specxWebCreditData, setSpecxWebCreditData] = useState<any>([{
        gl_number: '',
        gl_name: '',
        amount: NaN
    }])

    // for store Book component
    const [storeBookGLAccountList, setStoreBookGLAccountList] = useState<any>()
    const [storeBookGLAccountData, setStoreBookGLAccountData] = useState<any>()

    const [storeBookDebitData, setStoreBookDebitData] = useState<any>([{
        gl_number: '',
        gl_name: '',
        amount: NaN
    }])

    const [storeBookCreditData, setStoreBookCreditData] = useState<any>([{
        gl_number: '',
        gl_name: '',
        amount: NaN
    }])

    // common component
    const [commonFieldData, setCommonFieldData] = useState<any>({
        store_id: "",
        date: "",
        narration: "",
    })

    const getStoreList = async () => {
        try {
            let storeResponse = await service.inventory.storeView()
            if (storeResponse.status === 200) {
                setStoreData(getDropdown(storeResponse?.data?.data, 'store_location','store_name'))
            }
        } catch (error) {
            console.log(error)
        }
    }

    // for specxWeb Book component
    const getGlAccountListData = async (id: string, books_of_account: boolean) => {
        try {
            const response = await service.reportService.getGeneralAccountList({ store_id: id });
            books_of_account ? setGlAccountList(getDropdown(response?.data?.data, "gl_number")) : setStoreBookGLAccountList(getDropdown(response?.data?.data, "gl_number"))
            books_of_account ? setGlAccountData(response?.data?.data) : setStoreBookGLAccountData(response?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleCommonFieldDataChange = (name: string, value: any) => {
        setCommonFieldData({ ...commonFieldData, [name]: value })
    }


    const handleClearData = () => {
        setSpecxWebDebitData([{
            gl_number: '',
            gl_name: '',
            amount: NaN
        }]);
        setSpecxWebCreditData([{
            gl_number: '',
            gl_name: '',
            amount: NaN
        }]);
        setCommonFieldData({
            store_id: "",
            date: "",
            narration: "",
        })
        handleClearStoreData()
    }

    const handleClearStoreData = () => {
        setStoreBookDebitData([{
            gl_number: '',
            gl_name: '',
            amount: NaN
        }]);
        setStoreBookCreditData([{
            gl_number: '',
            gl_name: '',
            amount: NaN
        }]);
        setStoreBookGLAccountList([])
    }


    const handleSubmit = async () => {

        const specxWeb_debit_account_details: any = [];
        const specxweb_credit_account_details: any = [];

        const store_debit_account_details: any = [];
        const store_credit_account_details: any = [];

        specxWebDebitData?.forEach((item: any) => {
            if (item?.gl_number) {
                specxWeb_debit_account_details.push({
                    account_id: item?.gl_number?.value,
                    amount: parseFloat(item?.amount),
                });
            }
        });

        specxWebCreditData?.forEach((item: any) => {
            if (item?.gl_number) {
                specxweb_credit_account_details.push({
                    account_id: item?.gl_number?.value,
                    amount: parseFloat(item?.amount),
                });
            }
        });

        storeBookDebitData?.forEach((item: any) => {
            if (item?.gl_number) {
                store_debit_account_details.push({
                    account_id: item?.gl_number?.value,
                    amount: parseFloat(item?.amount),
                });
            }
        });

        storeBookCreditData?.forEach((item: any) => {
            if (item?.gl_number) {
                store_credit_account_details.push({
                    account_id: item?.gl_number?.value,
                    amount: parseFloat(item?.amount),
                });
            }
        });

        const payload: any = {
            record_date: commonFieldData?.date,
            narration: commonFieldData?.narration,
        };

        if (specxWeb_debit_account_details.length > 0 || specxweb_credit_account_details.length > 0) {
            payload.specxbook = {
                debit_account_details: specxWeb_debit_account_details,
                credit_account_details: specxweb_credit_account_details,
            };
        }

        if (store_debit_account_details.length > 0 || store_credit_account_details.length > 0) {
            payload.storebook = {
                debit_account_details: store_debit_account_details,
                credit_account_details: store_credit_account_details,
            };
        }

        try {
            let manualTransactionResponse = await service.AccountsServices?.ManualTransaction(payload)
            if (manualTransactionResponse.status === 200) {
                addToast(manualTransactionResponse?.data.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                handleClearData()
            }
        } catch (error: any) {
            console.log(error)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const isValidEntry = (entry: any) => {
        // Check if all fields are empty or all fields are filled
        return (entry.gl_number === '' && isNaN(entry.amount)) || (entry.gl_number !== '' && !isNaN(entry.amount));
    }

    const validation = () => {
        if (commonFieldData?.date === '' || commonFieldData?.narration === '') {
            return false
        }

        // validation for SpecxWeb book
        const validSpecxWebDebit = specxWebDebitData?.every(isValidEntry);
        const validSpecxWebCredit = specxWebCreditData?.every(isValidEntry);
        const specxWebDebitAmount = specxWebDebitData?.reduce((sum: number, item: any) => Number(sum )+ (Number(item?.amount) || 0), 0);
        const specxWebCreditAmount = specxWebCreditData?.reduce((sum: number, item: any) => sum + (item?.amount || 0), 0);

        if (!validSpecxWebDebit || !validSpecxWebCredit || !(Number(specxWebDebitAmount) === Number(specxWebCreditAmount))) {
            return false
        }

        // validation storeBook book
        const validStoreBookDebit = storeBookDebitData?.every(isValidEntry);
        const validStoreBookCredit = storeBookCreditData?.every(isValidEntry);
        const storeBookDebitAmount = storeBookDebitData?.reduce((sum: number, item: any) => sum + (item?.amount || 0), 0);
        const storeBookCreditAmount = storeBookCreditData?.reduce((sum: number, item: any) => sum + (item?.amount || 0), 0);

        if (!validStoreBookDebit || !validStoreBookCredit || !(Number(storeBookDebitAmount) === Number(storeBookCreditAmount))) {
            return false
        }

        return true
    }

    const isValid = () => {
        let value = validation()
        if (value) {
            setSubmitConfirmation(!submitConfirmation);
        } else {
            handleSubmit();
        }
    }

    useEffect(() => {
        setHeader("Accounts - Add Voucher")
        getStoreList()
        getGlAccountListData("", true)
    }, [])

    return (
        <Wrapper>
            <div className="row mb-3">
                <div className="col-4 py-0">
                    <Input
                        placeholder={"Date"}
                        type="date"
                        value={commonFieldData?.date}
                        onChange={(e: any) => handleCommonFieldDataChange("date", e)}
                    />
                </div>
                <div className="col-7 py-0">
                    <Input
                        placeholder={"Narration"}
                        type="text"
                        value={commonFieldData?.narration}
                        onChange={(e: any) => handleCommonFieldDataChange("narration", e?.target?.value)}
                    />
                </div>
                <div className="col-1"></div>

            </div>
            <div className="row">
                <div className="col-6" style={{ borderRight: '1px dashed #222' }}>
                    <SpecxwebBooks
                        glAccountList={glAccountList}
                        glAccountData={glAccountData}
                        debitData={specxWebDebitData}
                        setDebitData={setSpecxWebDebitData}
                        creditData={specxWebCreditData}
                        setCreditData={setSpecxWebCreditData}
                    />
                </div>

                <div className="col-6">
                    <StoreBooks
                        storeData={storeData}
                        storeValue={commonFieldData?.store_id}
                        handleChangeStoreData={(value: any) => { value ? getGlAccountListData(value?.value, false) : handleClearStoreData(); handleCommonFieldDataChange("store_id", value) }}
                        getGlAccountListData={(id: string, book_of_account: boolean) => getGlAccountListData(id, book_of_account)}
                        storeBookGLNumberList={storeBookGLAccountList}
                        storeBookGLNumber={storeBookGLAccountData}
                        debitData={storeBookDebitData}
                        setDebitData={setStoreBookDebitData}
                        creditData={storeBookCreditData}
                        setCreditData={setStoreBookCreditData}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-center mt-4"><Button onClick={() => isValid()}>Submit</Button></div>
            {/* <div className="d-flex justify-content-center mt-4"><Button onClick={() => handleSubmit()}>Submit</Button></div> */}

            {submitConfirmation && <ConfirmationModal
                show={submitConfirmation}
                handleConfirmItem={() => { handleSubmit(); setSubmitConfirmation(!submitConfirmation) }}
                onHide={() => setSubmitConfirmation(!submitConfirmation)}
                message={'Add Voucher'} />}
        </Wrapper>
    )
}

export default Index