import React, { useState } from 'react'
import atoms from '../../../atoms'
import molecules from '../../../molecules'
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import service from '../../../../api/services'
import { useToasts } from 'react-toast-notifications';
import './index.scss'
import { checkPermissionsInArray } from '../../../../constants/utils';
import { AllPermissions } from '../../../../constants/permissions';
const { Search, Button, Table, Input } = atoms
const { ConfirmationModal, CreateModal } = molecules

const Index = ({ setSearch, materialListing, getMaterialListing }: any) => {
    const { addToast } = useToasts();
    const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false)
    const [errors, setErrors] = useState<boolean>(false)
    const [createModal, setCreateModal] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [materialValues, setProductMaterialValues] = useState({ name: '', })
    const [selectedId, setSelectedId] = useState<string>('')
    const columns = [
        { name: 'Product Material', selector: (row: any) => row.name, sortable: true, },
        { name: 'Action', selector: (row: any) => row.action, },

    ]
    const handleSubmit = async () => {
        const payload = {
            name: materialValues.name,
        }
        if (materialValues.name) {
            try {
                if (isEdit) {
                    // for update product type values
                    const response = await service.productData.updateProductMaterialService(selectedId, payload)
                    if (response.status === 200) {
                        setCreateModal(false)
                        addToast('Product material updated successfully', {
                            appearance: 'success',
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                    }
                } else {
                    // for Create product type values
                    const response = await service.productData.createProductMaterialService(payload)
                    if (response.status === 201) {
                        setCreateModal(false)
                        addToast('Product material added successfully', {
                            appearance: 'success',
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                    }
                }
            } catch (error: any) {
                console.log("error", error);
                addToast(error.response.data.message, {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
            getMaterialListing()
            setProductMaterialValues({
                name: '',
            })
            setIsEdit(false)
        } else {
            setErrors(true)
        }
    }
    const _handleDelete = (id: string) => {
        setSelectedId(id)
        setHandleDeleteModal(true)
    }
    const handleDeleteType = async () => {
        try {
            const response = await service.productData.deleteProductMaterialService(selectedId)
            if (response.status === 200) {
                setHandleDeleteModal(false)
                addToast('Product material deleted successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log("error", error);
            setHandleDeleteModal(false)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        getMaterialListing()
    }

    const _handleEdit = async (id: string) => {
        setSelectedId(id)
        handleProductMaterialValues(id)
    }
    const handleProductMaterialValues = async (id: string) => {
        setIsEdit(true)

        try {
            const response = await service.productData.getProductMaterialByIdService(id)
            let resData = response.data.data
            if (response.status === 200) {
                setProductMaterialValues({
                    name: resData?.name,
                })
                setCreateModal(true)
            }
        } catch (error: any) {
            console.log("error", error);
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    const _handleClose = () => {
        setCreateModal(!createModal)
        setProductMaterialValues({
            name: '',
        })
        setIsEdit(false)
    }
    const handleChange = (name: string, value: string) => {
        setProductMaterialValues({ ...materialValues, [name]: value })
        setErrors(false);
    }
    return (
        <div>
            <div>
                <div className="row align-items-center">
                    <div className="col-6">
                        <Search searchCallBack={(e: string) => { setSearch(e) }} />
                    </div>
                    {checkPermissionsInArray(AllPermissions.Product_Material_Add) && <div className="col-6 text-end">
                        <Button name={'Create Product Material'}
                            onClick={() => setCreateModal(!createModal)}
                        />
                    </div>}
                </div>
            </div>
            <div>
                <Table
                    className='column-style'
                    columns={columns}
                    data={materialListing?.data?.map((item: any) => {
                        return ({
                            ...item,
                            action: <>
                                {checkPermissionsInArray(AllPermissions.Product_Material_Edit) && <Button className="table-edit-button fs-14 fw-400"
                                    onClick={() => _handleEdit(item.id)}>
                                    <div className='d-flex align-items-center'>
                                        <FiEdit />
                                        <span className='ms-2 mb-0'>Edit</span>
                                    </div>
                                </Button>}
                                {checkPermissionsInArray(AllPermissions.Product_Material_Delete) && <Button className="table-delete-button fs-14 fw-400 ms-3"
                                    onClick={() => _handleDelete(item.id)}>
                                    <div className='d-flex align-items-center'>
                                        <RiDeleteBin6Line />
                                        <span className='ms-2 mb-0'>Delete</span>
                                    </div>
                                </Button>}
                            </>
                        })
                    })}
                />
            </div>
            {handleDeleteModal && <ConfirmationModal
                show={handleDeleteModal}
                handleConfirmItem={() => handleDeleteType()}
                onHide={() => setHandleDeleteModal(!handleDeleteModal)}
                message={'delete this Product Material'} />}

            {createModal && <CreateModal
                show={createModal}
                header={`${isEdit ? 'Update' : 'Create'} Material Type`}
                handleSubmit={handleSubmit}
                submitBtnText={`${isEdit ? 'Update' : 'Create'} Material Type`}
                onHide={() => _handleClose()}
            >
                <div>
                    <form>
                        <div className="row">
                            <div className="col-12">
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    placeholder="Enter Material Type"
                                    onChange={(e: any) => handleChange("name", e.target.value)}
                                    defaultValue={materialValues.name}
                                    label='Material Name'
                                />
                                {(errors && (materialValues.name.length === 0)) && <span className="error-text">This field is required.</span>}
                            </div>
                        </div>
                    </form>
                </div>
            </CreateModal>}
        </div>
    )
}

export default Index