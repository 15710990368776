import React from "react";
import atoms from "../../../atoms";
import constant from "../../../../constants/constant";

const { Input, Select, Button, Table } = atoms

const Index = ({ handleChange, reportData, handleSubmit, handleView, barcodeAuditData }: any) => {

    const columns = [
        { name: 'Date', selector: (row: any) => row.Date, sortable: true, maxWidth: "20%", minWidth: "10%" },
        { name: 'Particulars', selector: (row: any) => row.Particulars, minWidth: "10%", maxWidth: "30%" },
        { name: 'Physical Store', selector: (row: any) => row.PhysicalStore, minWidth: "20%", maxWidth: "40%" },
        { name: 'Reference', selector: (row: any) => row.Reference, minWidth: "auto", maxWidth: "auto" },
    ];

    return (
        <>
            <div className="row">
                <div className="col-3">
                    <Input label={"Enter Barcode"}
                        placeholder={"Barcode"} type="number"
                        onChange={(e: any) => {
                            handleChange("barcode", e.target.value)
                        }}
                    />
                </div>
                <div className="col-4">
                    <Select
                        className="w-100"
                        options={constant.DailyReportFormat}
                        onChange={(e: any) => handleChange("report_format", e.value)} placeholder={"Select"}
                        label={"Select Report Format"} />
                </div>
                <div className="col-5 position-relative">
                    <div className="d-flex position-absolute gap-3" style={{bottom:0, right:0}}>
                        <Button isDisabled={!reportData?.barcode} onClick={() => handleView()} style={{ minWidth: "15vw" }}>View</Button>
                        <Button isDisabled={!reportData?.barcode || !reportData?.report_format} onClick={() => handleSubmit()} style={{ minWidth: "15vw" }}>Submit</Button>
                    </div>
                </div>
            </div>

            {barcodeAuditData && <div className="mt-4">
                <Table
                    // className="column-style"
                    columns={columns}
                    data={barcodeAuditData?.map((item: any) => {
                        return ({
                            ...item

                        })
                    })}
                />
            </div>}
        </>
    )
}

export default Index