import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { roles } = Dictionary

const Roles = {
    list: async (paramsObj: any) => {
        return axios.get(BASE_URL + roles.roles(), { params: paramsObj })
    },
    add: async (data: any) => {
        return axios.post(BASE_URL + roles.roles(), data)
    },
    update: async (id: string, data: any) => {
        return axios.put(BASE_URL + roles.rolesById(id), data)
    },
    getById: async (id: string) => {
        return axios.get(BASE_URL + roles.rolesById(id))
    },
}
export default Roles