import React, { useEffect, useState, useContext } from 'react'
import service from '../../../../api/services';
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms';
import { Default_Page_Limit, Default_Page_Number } from '../../../../constants/defaultValues';
import { listAllocationStore } from '../BrandAllocation/interface';
import { HeaderHeading } from '../../../../Context'
const { StoreAllocation } = organisms
const Index = () => {
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [search, setSearch] = useState('');
    const [storeAllocationList, setStoreAllocationList] = useState<listAllocationStore | any>()
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const getStoreAllocationList = async () => {
        try {
            let response = await service.allocation.view({ "page": currentPage, "search": search, "limit": limitPerPage })
            if (response.status === 200) {
                setStoreAllocationList(response.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        setHeader("Store - Allocated Brands")
        setSidebar(false)
        getStoreAllocationList()
    }, [currentPage])

    useEffect(() => {
        setCurrentPage(1)
        if (currentPage == 1 && initialLoading) {
            getStoreAllocationList()
        }
        setInitialLoading(true)
    }, [limitPerPage,search])
    return (
        <Wrapper>
            <StoreAllocation
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                setSearch={setSearch}
                storeAllocationList={storeAllocationList}
                currentPage={currentPage}
                limit={limitPerPage}
            />
        </Wrapper>
    )
}

export default Index