import React, { useState, useEffect, useContext } from "react";
import { HeaderHeading } from "../../../../Context";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from 'react-toast-notifications';
import { handleReportDownload } from "../../../../constants/utils";
import constant from "../../../../constants/constant";
import service from "../../../../api/services";

const { InventoryAdditionInvoiceReport,InventoryAdditionBarcodeReport } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();
    const [reportData, setReportData] = useState({
        created_from: Date,
        created_to: Date,
        report_format: NaN,
    })

    useEffect(() => {
        setHeader("Report - Inventory Addition Report")
    }, [])

    const handleChange = (name: string, value: Date) => {
        setReportData({ ...reportData, [name]: value })
    }

    const handleSubmitInvoiceWise = async () => {

        try {
            const response = await service.reportService?.inventoryAdditionalInvoiceWise({ created_from: reportData?.created_from, created_to: reportData?.created_to })
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "inventory-addition-report", constant?.DailyReportFormat[1].value, "")
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleSubmitBarcodeWise = async () => {

        try {
            const response = await service.reportService?.inventoryAdditionalBarcodeWise({ created_from: reportData?.created_from, created_to: reportData?.created_to })
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "inventory-addition-report", constant?.DailyReportFormat[1].value, "")
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const clearData = () => {
        setReportData((prevReportData: any) => ({
            ...prevReportData,
            created_from: "",
            created_to: "",
            report_format: NaN,
        }))
    }

    return (
        // <Wrapper>
        //     <InventoryAdditionReport reportData={reportData} handleChange={(name: string, value: Date) => handleChange(name, value)} handleSubmit={() => handleSubmit()} />
        // </Wrapper>
        <Wrapper>
            <div className="">
                <div className='custom-tabs'>
                    <ul className="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => clearData()}
                            >
                                Invoice wise
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                                onClick={() => clearData()}
                            >
                                Barcode Wise
                            </button>
                        </li>

                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        {/* section 1 */}
                        <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabIndex={0}
                            style={{ border: "#fff" }}
                        >
                            <InventoryAdditionInvoiceReport
                                reportData={reportData}
                                handleChange={(name: string, value: Date) => handleChange(name, value)}
                                handleSubmit={() => handleSubmitInvoiceWise()}
                            />

                        </div>

                        {/* section 2 */}
                        <div
                            className="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabIndex={1}
                            style={{ border: "#fff" }}
                        >
                              <InventoryAdditionBarcodeReport
                                reportData={reportData}
                                handleChange={(name: string, value: Date) => handleChange(name, value)}
                                handleSubmit={() => handleSubmitBarcodeWise()}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Index