import React, { useState, useContext } from 'react'
import User from '../../../assets/Images/user.svg';
import Setting from '../../../assets/Images/setting.svg';
import Notification from '../../../assets/Images/notification.svg'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "./Header.scss"
import Atoms from '../../atoms'
import service from '../../../api/services';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router';
import { TbLogout } from "react-icons/tb";
import { HeaderHeading } from '../../../Context'
const { Image } = Atoms
const Header = () => {
  const { addToast } = useToasts();
  const { header }: any = useContext(HeaderHeading);
  const navigate = useNavigate()
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const token = localStorage.getItem("access_token")
  const userEmail = localStorage.getItem("user_email")
  const username = localStorage.getItem("username")
  const role: string = localStorage.getItem("role") || ''

  const logout = async () => {
    try {
      let response = await service.auth.logout(token)
      if (response.status === 200) {
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        localStorage.clear()
        navigate("/login");
      }
    } catch (error: any) {
      console.log(error)
      addToast(error.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }
  return (
    <div className="s-layout">
      <main className="s-layout__content header-bg justify-content-between">
        <h3 className="mb-0 fs-24 fw-700"> {header}</h3>
        <div className="me-4 d-flex gap-2 ">
          {/* <Image src={User} className={"btn"}/> */}
          <div className='m-auto color-black fs-20 fw-500 text-capitalize'>
            {userEmail && !role && <div className=''>
              {userEmail}
            </div>}
            {role != '' && <div>
              {role}
            </div>}
            <div>
              {username}
            </div>
          </div>
          <Dropdown group isOpen={toggleDropdown} size="lg" toggle={() => setToggleDropdown(!toggleDropdown)}>
            <DropdownToggle className='dropdown-button-hide cursor-pointer'>
              <Image src={User} />
            </DropdownToggle>
            <DropdownMenu>
              <div className='p-0 d-flex header-dropdown align-items-center cursor-pointer gap-1 ps-3'
                onClick={() => logout()}>
                <TbLogout />
                <span className=' p-2'>Logout</span>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </main>
    </div>
  )
}

export default Header

