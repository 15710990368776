import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import { useToasts } from 'react-toast-notifications';
import service from '../../../../api/services'
import { HeaderHeading } from '../../../../Context'
import { useNavigate } from 'react-router';

const { LostBarcode } = organisms


const Index = () => {
    const navigate = useNavigate()
    const [barcodeData, setBarcodeData] = useState<any>()
    const [confirmationPopup,setConfirmationPopup]=useState<boolean>(false)
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [searchValue, setSearchValue]= useState<string>("")
    const [updateData, setUpdateData] = useState<any>({
        conditionRemark: "",
    })
    const { addToast } = useToasts();

    const handleBarcodeSearch = async (search: string) => {
        try {
            const response = await service.barcodeStatus.searchLostBarcode({ search: search })
            setSearchValue(search)
            if (response.status === 200) {
                setBarcodeData(response?.data?.data)
                setUpdateData({ ...updateData, item_condition: {}, conditionRemark: "" })
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });

        }
    }

    const updateItemCondition = async (id: string) => {
        const payload = {
            "inventory_item_id": id,
            "remarks": updateData.conditionRemark
        }
        try {
            const response = await service.barcodeStatus.markBarcodeLost(payload)
            if (response.status === 200) {
                navigate('/inventory/lost-barcode-history')
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleChange = (name: string, value: any) => {
        setUpdateData({ ...updateData, [name]: value })
    }

    useEffect(() => {
        setHeader("Inventory - Lost Barcode")
        setSidebar(false)
    }, [])

    return (
        <Wrapper>
            <LostBarcode
                handleBarcodeSearch={(search: string) => handleBarcodeSearch(search)}
                updateItemCondition={updateItemCondition}
                barcodeData={barcodeData}
                handleChange={handleChange}
                updateData={updateData}
                confirmationPopup={confirmationPopup}
                setConfirmationPopup={(value:boolean)=>setConfirmationPopup(value)}
            />
        </Wrapper>
    )
}

export default Index