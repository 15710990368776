import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import { HeaderHeading } from '../../../../Context'
import { useParams } from 'react-router'
const { MovementDetail } = organisms
const MovementDetails = () => {
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);
  const [detailsListing, setDetailsListing] = useState<any>()
  const [search, setSearch] = useState('');
  const params = useParams()
  const getStoreListing = async () => {
    try {
      const response = await service.movement.detailsListById(params?.id || '');
      if (response.status == 200) {
        setDetailsListing(response?.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const cancelButton = async (id: string) => {

    try {
      const response = await service.movement.cancelMovementItem(id);
      if (response.status == 200) {
        getStoreListing()
      }
    } catch (error) {
      console.log("error===>", error)
    }

  }

  useEffect(() => {
    setHeader("Movement Details")
    setSidebar(false)
    getStoreListing()
  }, [])

  return (
    <Wrapper>
      <MovementDetail detailsListing={detailsListing} cancelButton={(id: string) => cancelButton(id)} />
    </Wrapper>
  )
}

export default MovementDetails