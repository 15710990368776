import React, { useState, useEffect, useContext } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import { HeaderHeading } from "../../../../Context";
import service from "../../../../api/services";
import { useToasts } from 'react-toast-notifications';
import { BASE_URL } from "../../../../constants/defaultValues";
import * as XLSX from "xlsx"
import constant from "../../../../constants/constant";
import { generatePdfHeader, handleReportDownload } from '../../../../constants/utils'

const { CustomerReport } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [reportData, setReportData] = useState({
        report_date: Date,
        report_format: NaN,
    })
    const { addToast } = useToasts();


    useEffect(() => {
        setHeader("Report - Customer Report")
    }, [])

    const handleSubmit = async () => {
        try {
            const response = await service.reportService?.customer({ report_date: reportData?.report_date })
            if (response.status === 200) {
                handleReportDownload(response?.data?.data, "customer-report" ,reportData?.report_format, "SpecxWeb")
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleChange = (name: string, value: any) => {
        setReportData({ ...reportData, [name]: value })
    }
    return (
        <Wrapper>
            <CustomerReport handleSubmit={handleSubmit} handleChange={(name: string, value: any) => handleChange(name, value)} reportData={reportData} />
        </Wrapper>
    )
}

export default Index