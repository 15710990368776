import React, { useState } from 'react';
import atoms from "../../../components/atoms";
import { PlaceHolderImage } from '../../../constants/defaultValues';
import molecules from '../../molecules';
import constant from '../../../constants/constant';
import CopyIcon from '../../../assets/Images/copy-icon.svg'
import { AiOutlineEye } from "react-icons/ai";
import { findConstantLabel } from '../../../constants/utils';

const { Search, Button, Table, Pagination, Image, CustomImages } = atoms
const { CreateSideModal, ConfirmationModal } = molecules

const Index = ({ setLimitPerPage, setCurrentPage, setSearch, warrantyData, currentPage, limit, getWarrantyProductDetail, warrantDetail, warrantApproveReject }: any) => {
    const [deletePopup, setDeletePopup] = useState(false)
    const [approvalPopup, setApprovalPopup] = useState<boolean>(false)
    const [showSidebarModal, setShowSidebarModal] = useState(false)

    const columns = [
        { name: 'Claim ID', selector: (row: any) => row.claim_id || "-", sortable: true, minWidth: "120px" },
        { name: 'Picture', cell: (row: any) => <CustomImages src={row?.product?.images?.length > 0 && row?.product?.images[0]} />, center: true },
        { name: 'Product Type', selector: (row: any) => row?.product?.product_type?.name || "-", minWidth: "150px" },
        { name: 'Product ID', selector: (row: any) => row?.product?.product_id_display || '-', minWidth: "150px" },

        { name: 'Brand', selector: (row: any) => row?.product?.brand?.brand_name || "-", minWidth: "150px" },
        { name: 'Model Number', selector: (row: any) => row?.product?.modal_number || "-", minWidth: "150px" },
        { name: 'Brand Color Code', selector: (row: any) => row.product?.brand_color_code || "-", minWidth: "150px" },
        { name: 'Size', selector: (row: any) => `${row.product?.glass_size} MM`, minWidth: "150px" },

        // { name: 'Store Pickup From', selector: (row: any) => row.supplier_id_display, minWidth: "150px" },
        // { name: 'Assign Driver', selector: (row: any) => row.supplier_name, minWidth: "150px" },
        { name: 'status', selector: (row: any) => row.verification_status, minWidth: "100px" },

        { name: 'Action', selector: (row: any) => row.action, minWidth: "150px" },
    ]

    const productDetail = {
        product_id_display: "#78945d6",
        images: [PlaceHolderImage, PlaceHolderImage, PlaceHolderImage],
        product_condition_remark: "This is test product for show Warrant condition remark"
    }

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} /></div>
            </div>
            <div >
                <Table columns={columns}
                    className="inventory-product-border"
                    data={warrantyData?.data?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            verification_status: item.claim_approval_status === constant.VERIFICATION_STATUS.PENDING ? (
                                <div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : item.claim_approval_status === constant.VERIFICATION_STATUS.VERIFIED ?
                                (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Approve"}</p></div>) : item.claim_approval_status === constant.VERIFICATION_STATUS.REWORK ?
                                    (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"Rework"}</p></div>) : ''
                            ,
                            action:
                                <div style={{ overflow: 'unset', textOverflow: 'unset' }}>
                                    <Button className="table-edit-buttons fs-14 fw-500"
                                        onClick={() => { getWarrantyProductDetail(item.id); setShowSidebarModal(true); }}>
                                        <div className='d-flex align-items-center'>
                                            <AiOutlineEye className='fs-20' />
                                            <span className='ms-2 mb-0'>View</span>
                                        </div>
                                    </Button>
                                </div>
                        })
                    })
                    }
                />


            </div>
            <div>
                <Pagination
                    totalCount={warrantyData?.count}
                    currentPage={currentPage}
                    totalPages={warrantyData?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>

            {showSidebarModal && <div>
                <CreateSideModal
                    show={showSidebarModal}
                    onHide={() => setShowSidebarModal(false)}
                    buttons={[
                        { label: 'Reject', onClick: () => setDeletePopup(!deletePopup), className: "fs-16 fw-500 reject-btn" },
                        // { label: 'Approve', onClick: () => { warrantApproveReject(warrantDetail?.id, constant.VERIFICATION_STATUS.VERIFIED); setShowSidebarModal(false) }, className: "fs-16 fw-500 approve-btn" },
                        { label: 'Approve', onClick: () => setApprovalPopup(!approvalPopup), className: "fs-16 fw-500 approve-btn" },
                    ]}
                    header="Warranty Claim"
                >
                    <>
                        <div className=' d-flex gap-3 align-items-center p-0 mb-4'>
                            <h2 className='mb-0 fs-16 fw-700 secondary-color p-0 info-text'>Claim ID:</h2>
                            <div className='d-flex w-auto justify-content-around align-items-center p-2 add-product-custom-div cursor-pointer' >
                                <h2 className='mb-0 fs-16 fw-400 secondary-color'>{warrantDetail?.claim_id}</h2>
                                <div className='ms-2'>
                                    <Image src={CopyIcon} onClick={() => navigator.clipboard.writeText(warrantDetail?.claim_id)} />
                                </div>
                            </div>
                        </div>
                        <hr className="mt-3" style={{ border: '1px dashed #222', }} />

                        <div className='row'>
                            <div className='col-4'>
                                <h2 className='mb-0 fs-16 fw-600 info-text'>Physical Store</h2>
                                <h2 className='mb-0 fs-16 fw-400 secondary-color info-text'>{warrantDetail?.order?.store?.store_name}</h2>
                            </div>
                            <div className='col-4'>
                                <h2 className='mb-0 fs-16 fw-600 info-text'>Order ID</h2>
                                <h2 className='mb-0 fs-16 fw-400 secondary-color info-text'>{warrantDetail?.order?.order_id_display}</h2>
                            </div>
                            <div className='col-4'>
                                <h2 className='mb-0 fs-16 fw-600 info-text'>Product ID</h2>
                                <h2 className='mb-0 fs-16 fw-400 secondary-color info-text'>{warrantDetail?.product?.product_id_display}</h2>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h2 className='mb-0 fs-16 fw-600 info-text'>Warranty Claim For</h2>
                            <h2 className='mb-0 fs-16 fw-400 secondary-color info-text'>{findConstantLabel(constant?.warrantee_claim_for, warrantDetail?.claimed_for)}</h2>
                        </div>

                        <div className="mt-4 fs-20 fw-600 secondary-color info-text">Product Images</div>
                        <div className='row'>
                            {warrantDetail?.images && warrantDetail?.images.map((productImage: any, index: number) => {
                                return (
                                    <div className="d-flex flex-wrap gap-4 col-2">
                                        <div className="mt-3 info-cover-img position-relative" >
                                            <Image className="info-cover-img" src={productImage} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="info-style mt-3" >
                            <h2 className="mb-0 fs-16 fw-600 secondary-color info-text"> Product Condition Remark :</h2>
                            <h2 className="mb-0 fs-16 fw-400 secondary-color info-text mx-2">{warrantDetail?.condition_remark}</h2>
                        </div>

                    </>

                </CreateSideModal>
            </div>}

            {deletePopup && <ConfirmationModal
                show={deletePopup}
                handleConfirmItem={() => { warrantApproveReject(warrantDetail?.id, constant.VERIFICATION_STATUS.REWORK); setShowSidebarModal(false); setDeletePopup(!deletePopup) }}
                onHide={() => setDeletePopup(!deletePopup)}
                message={'reject this warranty claim'} />}

            {approvalPopup && <ConfirmationModal
                show={approvalPopup}
                handleConfirmItem={() => { warrantApproveReject(warrantDetail?.id, constant.VERIFICATION_STATUS.VERIFIED); setShowSidebarModal(false) ; setApprovalPopup(!approvalPopup) }}
                onHide={() => setApprovalPopup(!approvalPopup)}
                message={'approve this warranty claim'} />}
        </>
    )
}
export default Index