import React, {useState} from 'react'
import atoms from '../../../../atoms'
import molecules from '../../../../molecules'
import { FiEdit } from 'react-icons/fi'
import { useNavigate } from 'react-router'
import { RiDeleteBin6Line } from "react-icons/ri";
const { Table, Button, Search } = atoms
const { ConfirmationModal } = molecules


const Index = ({ sizeList,_handleDelete }: any) => {
    const navigate = useNavigate()
    const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false)
    const [sizeId, setSizeId]= useState("")

    const columns = [
        { name: 'Size', selector: (row: any) => row?.size, },
        { name: 'Action', selector: (row: any) => row?.action, },
    ]
    return (
        <>
            <div className='d-flex justify-content-end'>
                <Button onClick={() => {
                    navigate(`/bridge-size/add`);
                    localStorage.removeItem("size");
                }}>
                    Add Size
                </Button>
            </div>
            <div>
                <Table
                    AdditionalClassName="add-invertory-table"
                    columns={columns}
                    data={sizeList?.data.map((item: any, index: number) => {
                        return ({
                            ...item,
                            action:
                                <>
                                    <Button className="table-edit-button fs-14 fw-400"
                                        onClick={() => {
                                            navigate(`/bridge-size/edit/${item.id}`);
                                            localStorage.setItem("size", item?.size)
                                        }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <FiEdit />
                                            <span className='ms-2 mb-0'>Edit</span>
                                        </div>
                                    </Button>
                                    <Button className="table-delete-button fs-14 fw-400 ms-3"
                                        onClick={() => {setSizeId(item?.id); setHandleDeleteModal(!handleDeleteModal)}}>
                                        <div className='d-flex align-items-center'>
                                            <RiDeleteBin6Line />
                                            <span className='ms-2 mb-0'>Delete</span>
                                        </div>
                                    </Button>
                                </>
                        })
                    })}
                />
            </div>

            {handleDeleteModal && <ConfirmationModal
                show={handleDeleteModal}
                handleConfirmItem={() =>{_handleDelete(sizeId); setHandleDeleteModal(!handleDeleteModal)}}
                onHide={() => setHandleDeleteModal(!handleDeleteModal)}
                message={'delete this Bridge Size'} />}
        </>
    )
}

export default Index