import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from "../../../../components/organisms";
import service from '../../../../api/services';
import { HeaderHeading } from '../../../../Context'
const { Color } = organisms
const Index = () => {
    const [colorListing, setColorListing] = useState<any>()
    const [search, setSearch] = useState('');
    const { setHeader ,setSidebar}: any = useContext(HeaderHeading);
    const getColorListing = async () => {
        try {
            const response = await service.productData.productColorListingService({ search: search });
            setColorListing(response.data)
        } catch (error) {

        }
    }
    useEffect(() => {
        setHeader("Content - Specx Color Code")
        setSidebar(false)
        getColorListing()
    }, [search])

    return (
        <Wrapper>
            <Color colorListing={colorListing} setSearch={setSearch} getColorListing={getColorListing}/>
        </Wrapper>
    )
}

export default Index