import React from "react";
import molecules from "../../../molecules";

const { Report } = molecules

const Index = ({ handleChange, reportData, storeData,submitIndividualInvoices,submitIndividualMonth,submitIndividualBrand }: any) => {

    return (
        <>
            <div className="">
                <div className='custom-tabs'>
                    <ul className="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                            >
                                Invoice wise
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                            >
                                Month Wise
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                            >
                                Brand Wise
                            </button>
                        </li>

                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        {/* section 1 */}
                        <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabIndex={0}
                            style={{ border: "#fff" }}
                        >
                            <Report handleChange={handleChange} reportData={reportData} storeData={storeData} submitIndividualInvoices={submitIndividualInvoices}/>

                        </div>

                        {/* section 2 */}
                        <div
                            className="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabIndex={0}
                            style={{ border: "#fff" }}
                        >
                            <Report handleChange={handleChange} reportData={reportData} storeData={storeData} submitIndividualInvoices={()=>submitIndividualMonth()}/>


                        </div>

                        {/* section 3 */}
                        <div
                            className="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabIndex={0}
                            style={{ border: "#fff" }}
                        >
                            <Report handleChange={handleChange} reportData={reportData} storeData={storeData} submitIndividualInvoices={()=>submitIndividualBrand()}/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Index