import React from "react";
import atoms from "../../../../atoms";
import molecules from "../../../../molecules";
import moment from "moment";
import { numberWithCommas } from '../../../../../constants/utils'
import { loyaltyPointItemProps } from './interface'
const { Button, Table, Pagination } = atoms
const { TopBar } = molecules


const Index = ({ loyaltyItemList, currentPage, setCurrentPage, setLimitPerPage, limit }: loyaltyPointItemProps) => {

    const columns = [
        { name: 'Date', selector: (row: any) => moment(row?.createdAt).format('DD/MM/YYYY') || '-', sortable: true, },
        { name: 'Invoice Number', selector: (row: any) => row.order?.invoice_number || '-' },
        { name: 'Net Sales (₹)', selector: (row: any) => numberWithCommas(parseFloat(row?.order?.total_amount?.toFixed(2))) || '-' },
        { name: 'Loyalty Points Earned', selector: (row: any) => `+ ${numberWithCommas(parseFloat(row?.earned_points?.toFixed(2)))}` || '-', },
        { name: 'Loyalty Points Redeem/Reversed', selector: (row: any) => `- ${numberWithCommas(parseFloat(row?.reedemed_points?.toFixed(2)))}` || '-' },
        // { name: 'Balanced Loyalty Points', selector: (row: any) => numberWithCommas(parseFloat(row?.balanced_loyalty_points?.toFixed(2))) || '-' },
    ]


    const totalPoints = (data: [], name: string) => (data || []).reduce((sum: number, item: any) => sum + (item?.[name] || 0), 0);
    const formattedTotalPoints = (data: number) => numberWithCommas(parseFloat(data?.toFixed(2)));
    return (
        <>
            <div>
                <TopBar />
            </div>
            <div className="mt-3">
                <div className="row">
                    <div className="col-4">
                        <span className="fs-16 fw-600">Customer Name</span>
                        <p className="fs-16 fw-400 secondary-color mt-1">{loyaltyItemList?.data?.name}</p>
                    </div>
                    <div className="col-8">
                        <span className="fs-16 fw-600">Customer Number</span>
                        <p className="fs-16 fw-400 secondary-color mt-1">{loyaltyItemList?.data?.mobile}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="fs-16 fw-600">Total Loyalty Points Earned</span>
                        <p className="fs-16 fw-400 secondary-color mt-1">{totalPoints(loyaltyItemList?.data?.loyalty_points_transactions, "earned_points")}</p>
                    </div>
                    <div className="col-4">
                        <span className="fs-16 fw-600">Total Loyalty Points Redeemed / Reversed</span>
                        <p className="fs-16 fw-400 secondary-color mt-1">{totalPoints(loyaltyItemList?.data?.loyalty_points_transactions, "reedemed_points")}</p>
                    </div>
                    <div className="col-4">
                        <span className="fs-16 fw-600">Total Loyalty Points As of Today</span>
                        <p className="fs-16 fw-400 secondary-color mt-1">{formattedTotalPoints(loyaltyItemList?.data?.loyalty_points)}</p>
                    </div>
                </div>
            </div>
            <div >
                <Table
                    columns={columns}
                    AdditionalClassName="add-inventory-table"
                    data={loyaltyItemList?.data?.loyalty_points_transactions?.map((item: any) => ({
                        ...item
                    }))}
                />
            </div>
            <div>
                <Pagination
                    totalCount={loyaltyItemList?.count}
                    currentPage={currentPage}
                    totalPages={loyaltyItemList?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(limits: number) => setLimitPerPage(limits)}
                    limit={limit}
                />
            </div>
        </>
    )
}

export default Index